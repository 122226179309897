import React, { useState, useEffect } from 'react';
import { Row, Col, Container } from 'reactstrap';
import Card from 'react-bootstrap/Card';
import Logo from './../assets/images/Logo1.png';
import { Link, useHistory } from 'react-router-dom';
import Sidebar from './../Components/Sidebar/sidebar';
import PlacesAutocomplete from 'react-places-autocomplete';
import './styles.desktop.css';
import Variables from './../Globals/variables';
import { geoCodeAddress } from './../services/GoogleMapsService';
import './../Globals/Fonts.css';
import { confirmAlert } from "react-confirm-alert";

const BaseURL = Variables.baseUrl

const PromocodePayment = props => {
  const totalAmount = localStorage.getItem('totalAmount');
  const totalDiscount = localStorage.getItem('totalDiscount');
  const name = localStorage.getItem('name');
  const new_name = name.replace(/"/g, '');
  const email = localStorage.getItem('email');
  const contact1 = localStorage.getItem('contact');
  const role = localStorage.getItem("role");

  const initState = {
    name: new_name,
    email: email,
    contact1: contact1,
    role: role,
    isInsured: props.location.state.isInsured,
    files: props.location.state.files,
    insuranceAmount: props.location.state.insuranceAmount,
    insuranceEstimate: props.location.state.insuranceEstimate,
    senderAdd: props.location.state.senderAdd,
    senderAdd1: props.location.state.senderAdd1,
    senderBuilding: props.location.state.senderBuilding,
    senderContact: props.location.state.senderContact,
    receiverAdd: props.location.state.receiverAdd,
    receiverAdd1: props.location.state.receiverAdd,
    receiverBuilding: props.location.state.receiverBuilding,
    receiverContact: props.location.state.receiverContact,
    promoCode: props.location.state.promoCode,
    orgLat: props.location.state.orgLat,
    orgLng: props.location.state.orgLng,
    destLat: props.location.state.destLat,
    destLng: props.location.state.destLng,
    orderId: new Date().toLocaleString(),
    checked: false,
    contactError: "",
    SenderError: '',
    ReceiverError: '',
    senderAddressModified: false,
    receiverAddressModified: false,
    amount: props.location.state.amount,
    totalAmount: parseInt(totalAmount),
    totalDiscount: totalDiscount,
    deliveryMethod: props.location.state.deliveryMethod,
    btnValue: "Make Payment",
    savedAddresspickup: props.location.state.savedAddresspickup,
    savedAddressdrop: props.location.state.savedAddressdrop,
    popup1: props.location.state.popup1,
    popup2: props.location.state.popup2,
    alreadyInsured: props.location.state.alreadyInsured
  }

  const [state, setState] = useState(initState)
  const history = useHistory();

  function changepromocode() {
    history.push("/Promocode", {
      isInsured: state.isInsured,
      amount: state.amount,
      senderAdd: state.senderAdd,
      senderAdd1: state.senderAdd1,
      senderBuilding: state.senderBuilding,
      senderContact: state.senderContact,
      orgLat: state.orgLat,
      orgLng: state.orgLng,
      receiverAdd: state.receiverAdd,
      receiverAdd1: state.receiverAdd1,
      receiverBuilding: state.receiverBuilding,
      receiverContact: state.receiverContact,
      destLat: state.destLat,
      destLng: state.destLng,
      deliveryMethod: state.deliveryMethod,
      files: state.files,
      insuranceAmount: state.insuranceAmount,
      insuranceEstimate: state.insuranceEstimate,
      alreadyInsured: state.alreadyInsured,
      savedAddresspickup: state.savedAddresspickup,
      savedAddressdrop: state.savedAddressdrop,
      popup1: state.popup1,
      popup2: state.popup2
    })

  }
  console.log("PromoCode Payment:-", initState)

  useEffect(() => {
    if (state.insuranceAmount === "1") {
      setState({
        ...state,
        insuranceEstimate: 0,
        insuranceAmount: 1,
      })
    }

    ; (async function () {
      const {
        senderAddressModified,
        senderAdd,
        receiverAddressModified,
        receiverAdd
      } = state
      try {
        if (senderAddressModified) {
          const { lat, lng } = await geoCodeAddress(senderAdd)
          setState({
            ...state,
            senderAddressModified: false,
            orgLat: lat,
            orgLng: lng,
            pickupURL: `https://www.google.com/maps/search/?api=1&query=${lat},${lng}`
          })
        }

        if (receiverAddressModified) {
          const { lat, lng } = await geoCodeAddress(receiverAdd)
          setState({
            ...state,
            receiverAddressModified: false,
            destLat: lat,
            destLng: lng,
            pickupURL: `https://www.google.com/maps/search/?api=1&query=${lat},${lng}`
          })
        }
      } catch (e) {
        console.error('DesktopForm::useEffect::uncaught error', e)
        setState({
          senderAddressModified: false,
          receiverAddressModified: false
        })
      }
    })()
  }, [state])

  // onChange Function
  const change = e => {
    if (e.target.name === 'checked') {
      setState({
        ...state,
        checked: e.target.checked
      })
    } else {
      setState({
        ...state,
        [e.target.name]: e.target.value
      })
    }
  }

  //Used for RazorPay
  const loadScript = () => {
    return new Promise(resolve => {
      const script = document.createElement('script')
      script.src = 'https://checkout.razorpay.com/v1/checkout.js'
      document.body.appendChild(script)
      script.onload = () => {
        resolve(true)
      }
      script.onerror = () => {
        resolve(false)
      }
    })
  }

  const displayRazorpay = async () => {
    setState({
      ...state,
      btnValue: "Making Payment..."
    })
    const token = localStorage.getItem("token")
    const new_token = token.replace(/"/g, '')
    const res = await loadScript();
    if (!res) {
      alert(
        "Cannot connect to Razorpay. Check your Internet connection and try again later."
      );
      setState({
        ...state,
        btnValue2: "Make Payment"
      })
      return;
    }

    var bodyFormData = new FormData();
    bodyFormData.append('isInsured', state.isInsured)
    bodyFormData.append("files", state.files)
    bodyFormData.append("insuranceAmount", state.insuranceAmount)
    bodyFormData.append('orgLat', state.orgLat)
    bodyFormData.append('orgLng', state.orgLng)
    bodyFormData.append('destLat', state.destLat)
    bodyFormData.append('destLng', state.destLng)
    bodyFormData.append('senderAdd', state.senderAdd1)
    bodyFormData.append('senderBuilding', state.senderBuilding)
    bodyFormData.append('senderContact', state.senderContact)
    bodyFormData.append('receiverAdd', state.receiverAdd1)
    bodyFormData.append('receiverBuilding', state.receiverBuilding)
    bodyFormData.append('receiverContact', state.receiverContact)
    bodyFormData.append('deliveryMethod', state.deliveryMethod)
    bodyFormData.append('promoCode', state.promoCode)
    bodyFormData.append('alreadyInsured', state.alreadyInsured)

    let data = await fetch(`${BaseURL}/orders`, {
      method: "POST",
      body: bodyFormData,
      headers: {
        Authorization: `bearer ${new_token}`
      },
    })
    data = await data;
    if (data.status === 200 || data.status === 201) {
      data = await data.json();
      setState({
        ...state,
        btnValue2: "Make Payment"
      })
      const { orderId, paymentProcessorOrderId } = data;
      if (typeof orderId === "string") {
        const options = {
          key: Variables.RazorPay,
          currency: data.currency,
          order_id: paymentProcessorOrderId,
          prefill: {
            email: email,
            contact: contact1,
            name: new_name
          },
          name: "Gordian Technologies Pvt. Ltd.",
          description: "Please pay for the delivery.",
          handler: function (response) {
            setState({
              ...state,
              orderId,
            });
            localStorage.setItem('orderId', orderId)
            alert(
              "Thank you for choosing Gordian. Your order has been placed successfully. Please check your E-mail for tracking and delivery executive related details"
            );
            setState(initState);
            history.push("/trackOrder")
          },
        };
        var paymentObject = new window.Razorpay(options);
        paymentObject.open();
      }
      return data;
    } else if (data.status === 401) {
      const refreshToken = localStorage.getItem('refreshToken');
      const data = {
        refreshToken
      };

      var response = await fetch(`${BaseURL}/users/refresh-token`, {
        method: 'POST',
        body: JSON.stringify(data),
        headers: {
          "Content-Type": "application/json",
          // "Accept": 'application/json'
        }
      })
      response = await response;
      if (response.status === 200 || response.status === 201) {
        response = await response.json();
        localStorage.setItem('refreshToken', response.refreshToken)
        localStorage.setItem('token', response.jwtToken)
        let data1 = await fetch(`${BaseURL}/orders`, {
          method: "POST",
          body: bodyFormData,
          headers: {
            Authorization: `bearer ${response.jwtToken}`
          },
        })
        data1 = await data1
        if (data1.status === 200 || data1.status === 201) {
          data1 = await data1.json();
          setState({
            ...state,
            btnValue2: "Make Payment"
          })

          const { orderId, paymentProcessorOrderId } = data1;
          if (typeof orderId === "string") {
            const options = {
              key: Variables.RazorPay,
              currency: data.currency,
              order_id: paymentProcessorOrderId,
              prefill: {
                email: email,
                contact: contact1,
                name: new_name
              },
              name: "Gordian Technologies Pvt. Ltd.",
              description: "Please pay for the delivery.",
              handler: function (response) {
                setState({
                  ...state,
                  orderId,
                });
                localStorage.setItem('orderId', orderId)
                alert(
                  "Thank you for choosing Gordian. Your order has been placed successfully. Please check your E-mail for tracking and delivery executive related details"
                );
                setState(initState)
                history.push("/trackOrder")

              },
            };

            var paymentObject1 = new window.Razorpay(options);
            paymentObject1.open();
          }
          return data1;

        } else if (data1.status === 404 || data1.status === 400) {
          data1 = await data1.json();
          alert(data1.message);
          setState({
            ...state,
            btnValue2: "Make Payment"
          })
          return data1;

        }
        else if (data1.status === 412 || data1.status === 500 || data1.status === 501 || data1.status === 502) {
          data1 = await data1.json();
          alert(data1.message);
          setState({
            ...state,
            btnValue2: "Make Payment"
          })
          return data1;

        }
        return response;
      } else {
        alert("Session expired. Please login to continue.");
        localStorage.clear("All");
        history.push("/Home")
      }

    } else if (data.status === 404 || data.status === 400) {
      data = await data.json();
      alert(data.message);
      setState({
        ...state,
        btnValue2: "Make Payment"
      })
      return data
    }
    else if (data.status === 412 || data.status === 500 || data.status === 501 || data.status === 502) {
      data = await data.json();
      alert(data.message);
      setState({
        ...state,
        btnValue2: "Make Payment"
      })
      return data;
    }

  };

  // Sender Address Field
  // On address change, update the input field
  const handleSenderChange = senderAdd => {
    setState({
      ...state,
      senderAdd
    })
  }

  // On address select, update the input field with the address selected
  // then fetch the lat, lng
  // update the state
  // --> check side effect ( useEffect )
  const handleSenderSelect = address => {
    const senderAddressModified = true
    setState({
      ...state,
      senderAddressModified,
      senderAdd: address
    })
  }

  // Receiver Address Field
  const handleReceiverChange = receiverAdd => {
    setState({
      ...state,
      receiverAdd
    })
  }

  const handleReceiverSelect = address => {
    const receiverAddressModified = true
    setState({
      ...state,
      receiverAddressModified,
      receiverAdd: address
    })
  }

  const SameAsPrimaryNumber = (e) => {
    setState({
      ...state,
      [e.target.name]: state.contact1,
    });
  };

  // Validations
  const isValid = e => {
    // console.log(state)
    e.preventDefault()

    if (state.senderAdd.length === 0 || state.receiverAdd.length === 0) {
      alert("Details pertaining to the address is missing. Please enter the same to proceed further.")
      return false;
    } else if (state.senderContact.length === 0 || state.receiverContact.length === 0) {
      alert("Details pertaining to the phone number is missing. Please enter the same to proceed further.")
      return false;
    } else if ((state.senderBuilding === undefined || state.senderBuilding.length === 0) || (state.receiverBuilding === undefined || state.receiverBuilding.length === 0)) {
      alert('Details pertaining to the building number are missing. Please enter the same to proceed further.')
      return false;
    } else if (!state.checked) {
      alert('Please agree to the Terms and Conditions.')
      return false;
    } else
      return true;

    // let contactError = ""
    // let SenderError = ''
    // let ReceiverError = ''
    // let checked = false;

    // if (state.contact1.length !== 10) {
    //   contactError = "Invalid Contact Number";
    // }

    // if (state.senderContact.length !== 10) {
    //   SenderError = 'Invalid Contact Number'
    //   alert("The sender phone number entered is incorrect, please try again")
    // }

    // if (state.receiverContact.length !== 10) {
    //   ReceiverError = 'Invalid Contact Number'
    //   alert("The receiver phone number entered is incorrect, please try again")
    // }

    // if (state.checked === true) {
    //   checked = true;
    // }

    // if (ReceiverError || SenderError || contactError || !checked) {
    //   setState({
    //     ...state,
    //     contact1Error: contactError,
    //     recContactError: ReceiverError,
    //     SenContactError: SenderError
    //   })
    //   return false
    // }
    // return true
  }

  // Submit Function
  const submit = e => {
    e.preventDefault()

    setState({
      ...state,
      contact1Error: '',
      recContactError: '',
      SenContactError: ''
    })

    if (isValid(e)) {
      confirmOrder();
    }
  }

  const Termschange = (e) => {
    setState({
      ...state,
      checked: e.target.checked,
    });
  };

  const confirmOrder = () => {
    confirmAlert({
      title: "Important",
      message: "The delivery partner will reach the pickup location within 60 minutes of placing the order.",
      buttons: [
        {
          label: "Yes",
          onClick: displayRazorpay,
        },
        {
          label: "No",
          onClick: () => { },
        },
      ],
    });
  };

  return (
    <Row className='m-0'>
      <Col
        lg={2} md={2} sm={2}
        className='text-center pt-5'
        style={{ backgroundColor: 'white' }}
      >
        <Row className="gordian-logo" style={{ justifyContent: "center", alignItem: "center" }}>
          <img src={Logo} alt='Logo' className='logo' />
        </Row>
        <h1
          style={{ fontSize: '1.56rem', fontWeight: 'bold', textAlign: 'center' }}
        >
          Gordian
        </h1>
        <Sidebar />
      </Col>
      <Col lg={7} md={7} sm={7} className='Form'>
        <div className='NewOrder' style={{ marginLeft: "23px" }}>Place Order</div>
        <Container>
          <div
            style={{ fontSize: '1.25rem', fontWeight: '600', color: '#000000' }}
          >
            &emsp; Pickup Details
          </div>
          <Row className='row'>
            <Col lg={12} md={12}>
              <PlacesAutocomplete
                value={state.senderAdd}
                onChange={handleSenderChange}
                onSelect={handleSenderSelect}
              >
                {({
                  getInputProps,
                  suggestions,
                  getSuggestionItemProps,
                  loading
                }) => (
                  <div className='placesDiv'>
                    <input
                      {...getInputProps({
                        placeholder: 'Enter Pickup Address',
                        className: 'location-search-input fieldAddress field'
                      })}
                    />
                    <div className='autocomplete-dropdown-container'>
                      {loading && <div>Loading . . . .</div>}
                      {suggestions.map(suggestion => {
                        const className = suggestion.active
                          ? 'suggestion-item--active'
                          : 'suggestion-item'
                        const style = suggestion.active
                          ? {
                            backgroundColor: '#fafafa',
                            cursor: 'pointer'
                          }
                          : {
                            backgroundColor: '#ffffff',
                            cursor: 'pointer'
                          }
                        return (
                          <div
                            {...getSuggestionItemProps(suggestion, {
                              className,
                              style
                            })}
                          >
                            <hr />
                            <span>{suggestion.description}</span>
                          </div>
                        )
                      })}
                    </div>
                  </div>
                )}
              </PlacesAutocomplete>
            </Col>
          </Row>
          <Row className='row'>
            <Col lg={6} md={6} sm={6}>
              <input
                type='text'
                placeholder='Building / Landmark'
                className='field'
                name='senderBuilding'
                value={state.senderBuilding}
                onChange={e => change(e)}
                required
              />
            </Col>
            <Col lg={6} md={6} sm={6}>
              <input
                type='number'
                placeholder='Enter Pickup Mobile Number'
                className='field'
                name='senderContact'
                value={state.senderContact}
                onChange={e => change(e)}
                required
              />
              <br />
              <div
                style={{
                  textAlign: "right",
                  fontSize: "0.75rem",
                }}
              >
                {/* <div className="error">{state.SenContactError}</div> */}
                <input
                  style={{ marginTop: "16px" }}
                  type="checkbox"
                  name="senderContact"
                  onChange={(e) => SameAsPrimaryNumber(e)}
                  id="primarynumber"
                />
                &ensp;
                <label>Same as Primary number</label>
              </div>
              {/* <br />
              <div
                style={{
                  textAlign: 'right',
                  fontSize: '12px'
                }}
              >
                <div className='error'>{state.SenContactError}</div>
              </div> */}
            </Col>
          </Row>
          <hr />
          <div
            style={{ fontSize: '1.25rem', fontWeight: '600', color: '#000000' }}
          >
            &emsp; Drop Details
          </div>
          <Row className='row'>
            <Col lg={12} md={12} sm={12}>
              <PlacesAutocomplete
                value={state.receiverAdd}
                onChange={handleReceiverChange}
                onSelect={handleReceiverSelect}
              >
                {({
                  getInputProps,
                  suggestions,
                  getSuggestionItemProps,
                  loading
                }) => (
                  <div className='placesDiv'>
                    <input
                      {...getInputProps({
                        placeholder: 'Enter the Drop Address',
                        className: 'location-search-input fieldAddress field'
                      })}
                    />
                    <div className='autocomplete-dropdown-container'>
                      {loading && <div>Loading...</div>}
                      {suggestions.map(suggestion => {
                        const className = suggestion.active
                          ? 'suggestion-item--active'
                          : 'suggestion-item'

                        const style = suggestion.active
                          ? {
                            backgroundColor: '#fafafa',
                            cursor: 'pointer'
                          }
                          : {
                            backgroundColor: '#ffffff',
                            cursor: 'pointer'
                          }
                        return (
                          <div
                            {...getSuggestionItemProps(suggestion, {
                              className,
                              style
                            })}
                          >
                            <hr />
                            <span>{suggestion.description}</span>
                          </div>
                        )
                      })}
                    </div>
                  </div>
                )}
              </PlacesAutocomplete>
            </Col>
          </Row>
          <Row className='row'>
            <Col lg={6} md={6} sm={6}>
              <input
                type='text'
                placeholder='Building / Landmark'
                className='field'
                name='receiverBuilding'
                value={state.receiverBuilding}
                onChange={e => change(e)}
                required
              />
            </Col>
            <Col lg={6} md={6} sm={6}>
              <input
                type='number'
                placeholder='Enter Drop Mobile Number'
                className='field'
                name='receiverContact'
                value={state.receiverContact}
                onChange={e => change(e)}
                required
              />
              <br />
              <div
                style={{
                  textAlign: "right",
                  fontSize: "0.75rem",
                }}
              >
                {/* <div className="error">{state.recContactError}</div> */}
                <input
                  style={{ marginTop: "16px" }}
                  type="checkbox"
                  name="receiverContact"
                  onChange={(e) => SameAsPrimaryNumber(e)}
                  id="primarynumber1"
                />
                &ensp;
                <label>Same as Primary number</label>
              </div>

            </Col>
          </Row>
        </Container>
      </Col>
      <Col lg={3} md={3} sm={3} style={{ backgroundColor: 'white', height: '100vh' }}>
        <Container>
          <h1
            className="name"
            style={{
              fontSize: '1.25rem',
              fontWeight: '600',
              marginTop: '20px',
              fontFamily: 'Manrope-SemiBold'
            }}
          >
            Welcome {new_name}
          </h1>
          <hr />
          <Row style={{ justifyContent: "center", alignItem: "center" }}>
            <Card className="price-card"
              style={{
                width: '270px',
                height: '160px',
                borderRadius: '20px',
                backgroundColor: '#F5F6FA',
                boxShadow: '5px 5px 11px #0000000D',
                marginLeft: '-5px'
              }}
            >
              <Card.Body>
                <Row
                  style={{ justifyContent: 'space-between', marginTop: '5px' }}
                >
                  <Card.Text className="total-text" style={{ fontFamily: 'Manrope-Regular' }}>
                    Total Price
                  </Card.Text>
                  <Card.Text className="total-text" style={{ fontFamily: 'Manrope-Regular' }}>
                    Rs.{state.amount}
                  </Card.Text>
                </Row>
                <Row
                  style={{ justifyContent: 'space-between', marginTop: '-17px' }}
                >
                  <Card.Text className="discount-text" style={{ fontFamily: 'Manrope-Regular' }}>
                    Discount Amount
                  </Card.Text>
                  <Card.Text className="discount-text" style={{ fontFamily: 'Manrope-Regular' }}>
                    Rs.{state.totalDiscount}
                  </Card.Text>
                </Row>
                {state.role === "Bussiness" ? (
                  <Row
                    style={{ justifyContent: 'space-between', marginTop: '-17px' }}
                  >
                    <Card.Text className="discount-text" style={{ fontFamily: 'Manrope-Regular' }}>
                      Insurance Amount
                    </Card.Text>
                    <Card.Text className="discount-text" style={{ fontFamily: 'Manrope-Regular' }}>
                      Rs.{state.insuranceEstimate}
                    </Card.Text>
                  </Row>) : null}
                <Row
                  style={{ justifyContent: 'space-between', marginTop: '-17px' }}
                >
                  <Card.Text className="total-amount" style={{ fontFamily: 'Manrope-Bold' }}>
                    Grand Total
                  </Card.Text>
                  <Card.Text className="total-amount" style={{ fontFamily: 'Manrope-Bold' }}>
                    Rs.{state.totalAmount + state.insuranceEstimate}
                  </Card.Text>
                </Row>
              </Card.Body>
            </Card>
          </Row>
          <Row style={{ justifyContent: "center", alignItem: "center" }}>
            <button className='promocode' onClick={changepromocode}>Change Promo Code</button>
          </Row>

          <Row style={{ justifyContent: "center", alignItem: "center" }}>
            <div style={{ position: 'fixed', bottom: '15px' }}>
              <Row style={{ justifyContent: "center", alignItem: "center" }}>
                <input
                  className="details-checkbox"
                  style={{ marginTop: "5px" }}
                  value={state.checked}
                  onChange={(e) => Termschange(e)}
                  type="checkbox"
                />
                &ensp;
                <label className="details-label" style={{ fontSize: "0.875rem" }}>
                  I agree to the &nbsp;
                  <b>
                    <Link
                      to="/terms"
                      style={{ color: "black", textDecoration: "none" }}
                    >
                      terms and conditions
                    </Link>
                  </b>
                </label>
              </Row>
              <hr />
              <Row style={{ justifyContent: "center", alignItem: "center", marginLeft: "0px" }}>
                <button className='makepayment' onClick={e => submit(e)}>
                  {state.btnValue}
                </button>
              </Row>
            </div>
          </Row>
        </Container>
      </Col>
    </Row>
  )
}

export default PromocodePayment