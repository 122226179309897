import React, { Fragment, useState } from "react";
import { Row, Col, Container } from "reactstrap";
import { Link } from "react-router-dom";
import ProgressBar from "../Components/ProgressBar";
import "./styles.signuplogin.css";
import Card from 'react-bootstrap/Card';
import BackIcon from "./../assets/images/back.png";
// import Variables from "../Globals/variables";
import "./../Globals/Fonts.css";

// const BaseURL = Variables.baseUrl;

const MobileBusinessOtpSignup3 = (props) => {

    const phonenumber = localStorage.getItem("contact");
    const role = localStorage.getItem("role");

    const initError = {
        fullnameError: "",
        emailError: "",
        phonenumberError: "",
    };

    const [state, setState] = useState({
        fullname: "",
        email: "",
        phonenumber: phonenumber,
        role: role,
        otp: props.location.state.otp,
        checked: false,
        btnValue: "Next"
    });

    var [error] = useState(initError);

    const isValid = () => {
        error = initError;
        const re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

        if (state.fullname.length === 0) {
            error.nameError = "Name is required";
        }
        if (!re.test(state.email)) {
            error.emailError = "Invalid Email address";
        }
        if (state.phonenumber.length !== 10) {
            error.phonenumberError = "Invalid Phone Number";
        }

        if (
            error.fullnameError.length > 0 ||
            error.emailError.length > 0 ||
            error.phonenumberError.length > 0
        ) {
            return false;
        } else {
            return true;
        }
    };

    const Termschange = (e) => {
        setState({
            ...state,
            checked: e.target.checked,
        })
    }

    const ValidSubmission = () => {
        if (isValid() && state.checked) {
            return (
                <Link to={{
                    pathname: "/MobileBusinessSignup4",
                    state,
                }}
                    type="button"
                    className="btn btn-dark rounded-pill button1 mb-3"
                >
                    {state.btnValue}
                </Link>
            );
        } else {
            return (
                <button
                    disabled
                    type="button"
                    className="btn btn-dark rounded-pill button1 mb-3"
                >
                    {state.btnValue}
                </button>
            );
        }
    };

    return (
        <Fragment>
            <Container>
                <Row style={{ marginTop: "15px", marginLeft: "5px", justifyContent: "space-between", alignItem: "space-between", marginRight: "5px" }}>
                    <Link to="/MobileBusinessSignupOtp1" style={{ textDecoration: 'none' }} >
                        <Card style={{ width: '50px', height: "50px", borderRadius: "30px", backgroundColor: "#F5F6FA", border: "none", boxShadow: "5px 5px 11px 5px #0000000D" }}>
                            <Card.Body>
                                <img style={{ height: "30px", width: "27px", marginTop: "-15px", marginLeft: "-11px" }} src={BackIcon} alt="backicon" />
                            </Card.Body>
                        </Card>
                    </Link>
                    <div style={{ marginTop: "12px" }}>
                        <ProgressBar width="100%" steps="Step 3/3" />
                    </div>
                </Row>
                <div style={{ marginTop: "40px", marginLeft: "20px" }}>
                    <h1 style={{ fontSize: "32px", fontWeight: "bold", color: "#000000" }} >Enter Details</h1>
                </div>

                <Row style={{ marginTop: "10px" }}>
                    <Col>
                        <label style={{ marginTop: "10px", fontSize: "16px", fontFamily: "Manrope-Bold", marginLeft: "15px", color: "#000000" }}>Full Name</label>
                        <input
                            type="text"
                            className="field"
                            value={state.fullname}
                            onChange={(e) => setState({ ...state, fullname: e.target.value })}
                            required
                        />
                        <br />
                    </Col>
                </Row>
                <Row>
                    <Col>
                        <label style={{ fontSize: "16px", fontFamily: "Manrope-Bold", marginLeft: "15px", color: "#000000" }}>Mobile Number</label>
                        <input
                            type="number"
                            className="field"
                            disabled
                            value={state.phonenumber}
                            onChange={(e) => setState({ ...state, phonenumber: e.target.value })}
                            required
                        />
                        <br />
                    </Col>
                </Row>
                <Row>
                    <Col>
                        <label style={{ fontSize: "16px", fontFamily: "Manrope-Bold", marginLeft: "15px", color: "#000000" }}>Email Addres</label>
                        <input
                            type="email"
                            className="field"
                            value={state.email}
                            onChange={(e) => setState({ ...state, email: e.target.value })}
                            required
                        />
                        <br />
                    </Col>
                </Row>

                {/* <Row>
                    <Col>
                        <input
                            type="text"
                            placeholder="Role Eg:- Customer/Busseness User"
                            className="field"
                            value={state.role}
                            onChange={(e) => setState({ ...state, role: e.target.value })}
                            required
                        />
                        <br />
                    </Col>
                </Row> */}
                <div style={{ marginLeft: "15px" }}>
                    <input
                        value={state.checked}
                        onChange={(e) => Termschange(e)}
                        type="checkbox"
                    />
          &ensp;
          <label style={{ fontSize: "15px" }}>
                        I agree to the &nbsp;
            <b>
                            <Link to="/terms" target="_blank" style={{ color: "black", textDecoration: "none" }}> Terms and Conditions </Link>
                        </b>
                    </label>
                </div>
                <Row style={{ justifyContent: "center", alignItem: "center" }}>{ValidSubmission()}</Row>

                <h1 style={{ color: "#92979D", fontSize: "12px", fontFamily: "Manrope-SemiBold", textAlign: "center", marginTop: "50px" }}>For any difficulties</h1>
                <Link to="/ContactUs">
                    <h1 style={{ fontFamily: "Manrope-Bold", color: "#000000", fontSize: "15px", textAlign: "center" }}>Contact Us</h1>
                </Link>
            </Container>
        </Fragment>
    );
};

export default MobileBusinessOtpSignup3;
