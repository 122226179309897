import React, { useState } from "react";
import { Row, Container } from "reactstrap";
import Card from "react-bootstrap/Card";
import Button from 'react-bootstrap/Button';
import { Link, useHistory } from "react-router-dom";
import BackIcon from "./../assets/images/back.png";
import ChatIcon from "./../assets/images/chat.png";
import "./../Globals/Fonts.css";
import Variables from "./../Globals/variables";
import "react-confirm-alert/src/react-confirm-alert.css";

const BaseURL = Variables.baseUrl;

function MobileCancelOrder(props) {
    const initState = {
        senderAdd: props.location.state.senderAdd,
        receiverAdd: props.location.state.receiverAdd,
        btnValue: 'Yes, Cancel the order',
    };

    const [state, setState] = useState(initState);
    const history = useHistory();

    async function cancelOrder() {
        const token = localStorage.getItem("token");
        const new_token = token.replace(/"/g, "");
        setState({
            ...state,
            btnValue: "Cancelling Order...",
        });
        let results = await fetch(`${BaseURL}/orders/${props.location.state.orderId}/cancel`, {
            method: "POST",
            headers: {
                "Content-Type": "application/json",
                Authorization: `bearer ${new_token}`,
            },
        });
        results = await results;
        if (results.status === 200 || results.status === 201) {
            results = await results.json();
            alert("Your order has been successfully cancelled");
            setState({
                ...state,
                btnValue: "Yes, Cancel the order",
            });
            history.push("/placeOrder");
            return results;

        } else if (results.status === 401) {
            const refreshToken = localStorage.getItem("refreshToken");
            const data = {
                refreshToken,
            };

            var response = await fetch(`${BaseURL}/users/refresh-token`, {
                method: "POST",
                body: JSON.stringify(data),
                headers: {
                    "Content-Type": "application/json"
                },
            });
            response = await response;
            if (response.status === 200 || response.status === 201) {
                response = await response.json();
                localStorage.setItem("refreshToken", response.refreshToken);
                localStorage.setItem("token", response.jwtToken);
                results = await fetch(`${BaseURL}/orders/${props.location.state.orderId}/cancel`, {
                    method: "POST",
                    headers: {
                        "Content-Type": "application/json",
                        Authorization: `bearer ${response.jwtToken}`,
                    },
                });
                results = await results;
                if (results.status === 200 || results.status === 201) {
                    results = await results.json();
                    alert("Your order has been successfully cancelled");
                    setState({
                        ...state,
                        btnValue: "Yes, Cancel the order",
                    });
                    history.push("/placeOrder");
                    return results;
                } else if (results.status === 412 || results.status === 400 || results.status === 401 || results.status === 404 || results.status === 500) {
                    results = await results.json();
                    alert(results.message);
                    setState({
                        ...state,
                        btnValue: "Yes, Cancel the order",
                    });
                    return results;
                }
                return response;
            }
        } else if (results.status === 401 || results.status === 412 || results.status === 400 || results.status === 404 || results.status === 500) {
            results = await results.json();
            alert(results.message);
            setState({
                ...state,
                btnValue: "Yes, Cancel the order",
            });
            return results;
        }
    }

    return (
        <Container>
            <Row
                style={{
                    marginTop: "15px",
                    marginLeft: "5px",
                    justifyContent: "space-between",
                    alignItem: "space-between",
                    marginRight: "5px",
                }}
            >
                <Card
                    style={{
                        width: "50px",
                        height: "50px",
                        borderRadius: "30px",
                        backgroundColor: "#F5F6FA",
                        border: "none",
                        boxShadow: "5px 5px 11px 5px #0000000D",
                    }} onClick={() => props.history.goBack()}
                >
                    <Card.Body>
                        <img
                            style={{
                                height: "30px",
                                width: "27px",
                                marginTop: "-15px",
                                marginLeft: "-11px",
                            }}
                            src={BackIcon}
                            alt="backicon"
                        />
                    </Card.Body>
                </Card>

                <Link to="/ContactUs1">
                    <Card
                        style={{
                            width: "50px",
                            height: "50px",
                            borderRadius: "30px",
                            backgroundColor: "#F5F6FA",
                            border: "none",
                            boxShadow: "5px 5px 11px 5px #0000000D",
                        }}
                    >
                        <Card.Body>
                            <img
                                style={{
                                    height: "30px",
                                    width: "30px",
                                    marginTop: "-18px",
                                    marginLeft: "-11px",
                                }}
                                src={ChatIcon}
                                alt="chaticon"
                            />
                        </Card.Body>
                    </Card>
                </Link>
            </Row>
            <h1
                style={{
                    fontSize: "28px",
                    fontFamily: "Manrope-ExtraBold",
                    marginLeft: "20px",
                    color: "#000000",
                    letterSpacing: "-1px",
                }}
            >
                Are you sure you{" "}
            </h1>
            <h1
                style={{
                    fontSize: "28px",
                    fontFamily: "Manrope-ExtraBold",
                    marginLeft: "20px",
                    color: "#000000",
                    letterSpacing: "-1px",
                }}
            >
                Would like to{" "}
            </h1>
            <h1
                style={{
                    fontSize: "28px",
                    fontFamily: "Manrope-ExtraBold",
                    marginLeft: "20px",
                    color: "#000000",
                    letterSpacing: "-1px",
                }}
            >
                cancel your Order?{" "}
            </h1>
            <Card style={{ width: '330px', height: "250px", borderRadius: "15px", backgroundColor: "#ffffff", border: "none", boxShadow: "5px 5px 11px 5px #0000000D", marginTop: "20px", marginBottom: "10px" }}>
                <Card.Body>
                    <Row style={{ justifyContent: "space-between", alignItem: "space-between", marginTop: "10px" }}>
                        <h3 style={{ fontFamily: "Manrope-SemiBold", fontSize: "15px", color: "grey", marginLeft: "10px" }}>Pickup</h3>
                        <h3 style={{ fontFamily: "Manrope-Regular", fontSize: "13px", color: "#000000", width: "235px" }}>{state.senderAdd}</h3>
                    </Row>
                    <Row style={{ justifyContent: "space-between", alignItem: "space-between", marginTop: "-10px", }}>
                        <h3 style={{ fontFamily: "Manrope-SemiBold", fontSize: "15px", color: "grey", marginLeft: "10px" }}>Drop</h3>
                        <h3 style={{ fontFamily: "Manrope-Regular", fontSize: "13px", color: "#000000", width: "235px" }}>{state.receiverAdd}</h3>
                    </Row>

                </Card.Body>
            </Card>
            <Row style={{ paddingLeft: "20px", paddingRight: "20px" }}>
                <Button style={{ backgroundColor: "black", borderColor: "black", fontFamily: "Manrope-Bold", fontSize: "1.3rem", width: "100%", borderRadius: "20px" }} onClick={cancelOrder}>{state.btnValue}</Button>{' '}
            </Row>

            <Row style={{ justifyContent: "center", alignItem: "center" }}>
                <h1
                    style={{
                        color: "#92979D",
                        fontSize: "12px",
                        fontFamily: "Manrope-SemiBold",
                        position: "fixed",
                        bottom: "40px",
                    }}
                >
                    For any difficulties
        </h1>
                <Link to="/ContactUs1">
                    <h1
                        style={{
                            fontFamily: "Manrope-Bold",
                            color: "#000000",
                            fontSize: "15px",
                            position: "fixed",
                            bottom: "15px",
                            marginLeft: "-40px",
                        }}
                    >
                        Contact Us
          </h1>
                </Link>
            </Row>
        </Container>
    );
}

export default MobileCancelOrder;
