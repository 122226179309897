import React, { Fragment } from "react";
import Navbar from "../Components/Navbar/Navbar";
import { Container } from "reactstrap";

function DeliveryPartnerTerms() {
  return (
    <Fragment>
      <Navbar />
      <hr />
      <Container style={{ textAlign: "left" }}>
        <h3 style={{ textAlign: "center" }}>
          <strong>Terms of Use for Delivery Partners</strong>
        </h3>
        <p align="center">
          <strong>&nbsp;</strong>
        </p>
        <p>
          These terms of use (&ldquo;Terms of Use&rdquo;) describe the terms on
          which the delivery partners (&ldquo;You&rdquo; or &ldquo;Your&rdquo;
          or &ldquo;Yourself&rdquo; or &ldquo;Delivery Partner&rdquo;)
          interested in registering or availing Gordian Services (defined below)
          and accessing the website{" "}
          <a href="http://www.gordian.in/">www.gordian.in</a>, the mobile
          application &ldquo;Gordian&rdquo;, the web application
          www.orders.gordian.in, and the mobile application &ldquo;Tookan&rdquo;
          (&ldquo;Tookan&rdquo;) owned and operated by Click Labs Inc., and
          licensed for use to Gordian Technologies Private Limited, collectively
          referred to as, the &ldquo;Platform&rdquo;, connect with the users
          registered on the Platform (&ldquo;Users&rdquo;) to provide Your
          transportation, delivery, and logistics services to them, as may
          initiated by the Users on the Platform.
        </p>
        <p>&nbsp;</p>
        <p>
          PLEASE READ THE TERMS OF USE CAREFULLY BEFORE USING OR REGISTERING ON
          THE PLATFORM OR AVAILING THE GORDIAN SERVICES OR ACCESSING/USING ANY
          MATERIAL, INFORMATION THROUGH THE PLATFORM. YOUR USE OF THE PLATFORM
          OR GORDIAN SERVICES SHALL SIGNIFY YOUR ACCEPTANCE OF THE TERMS OF USE
          AND YOUR AGREEMENT TO BE LEGALLY BOUND BY THE SAME.
        </p>
        <p>&nbsp;</p>
        <p>
          Use of and access to the Platform is offered to You only upon
          acceptance of all the terms, conditions and, notices contained in this
          Terms of Use, along with any amendments made by Gordian Technologies
          Private Limited (&ldquo;Gordian&rdquo;) at its sole discretion and
          posted on the Platform. By using the Platform or the Gordian Services,
          You agree that You have read, understood and agreed to be bound by
          these Terms of Use and the Platform&rsquo;s Privacy Policy available
          at https://www.orders.gordian.in/privacy.
        </p>
        <p>&nbsp;</p>
        <p>
          You hereby acknowledge and agree that Gordian is a technology services
          provider that provides You a platform to connect with Users who wish
          to avail Your DP Services and does not (i) provide Delivery Partner/DP
          Services (defined below), or (ii) function as a
          transportation/logistics carrier, (iii) operate as an agent for the
          delivery of good(s)/item(s) purchased, logistic(s) services, and/or
          transportation of passengers.
        </p>
        <p>&nbsp;</p>
        <ol>
          <li>
            <strong>Delivery Partner/DP Services:</strong>
          </li>
          <p>&nbsp;</p>
          <ul>
            <li>
              You may choose to perform the DP Services (defined below) to Users
              as per your choice and convenience. You may choose to perform
              these services on any day of the week and for as long as you may
              wish for. When you accept a task on the Platform, you expressly consent
              each time for providing your services to the User. You agree that
              You provide services directly to the User.
            </li>
          </ul>
          <p>&nbsp;</p>
          <ul>
            <li>
              Pick Up and Drop Off Services: You acknowledge that Users may
              initiate a transaction on the Platform by which You will be
              required to pick up packages/Items from a particular location and
              drop off the packages/Items at another location (&ldquo;Pick Up
              and Drop Off Services&rdquo;). You agree that before pick up of
              item you shall ensure the packaging is not tampered, lose, or
              broken. You further agree that before dropping off the packages,
              You shall take reasonable measures to ensure that the
              packages/items are dropped off at the correct drop off location in
              original condition/packaging and handed over to the correct
              person. You shall be responsible for safely delivering the Items
              to the Users or to any person as may be specified by the User.
            </li>
          </ul>
          <p>&nbsp;</p>
          <ul>
            <li>
              You further agree and acknowledge that for any of the Services
              mentioned above, You shall act as an agent of the User and act in
              accordance with the instructions provided by the User and Gordian
              will not be responsible for the item or for any loss/damaged
              caused to the Item during transit.
            </li>
          </ul>
          <p>&nbsp;</p>
          <ul>
            <li>
              You further agree and acknowledge that Gordian shall provide you
              with technology, including IoT-enabled devices (&ldquo;IoT
              Devices&rdquo;) that unlock upon entering one-time passwords, and
              the Platform to perform Your required DP Services.
            </li>
          </ul>
          <p>&nbsp;</p>
          <ul>
            <li>
              For the purposes of this Terms of Use, Pick Up and Drop Off
              Services shall hereinafter together be referred to as the
              &ldquo;DP Services&rdquo;.
            </li>
          </ul>
          <p>&nbsp;</p>
          <ul>
            <li>
              You shall provide the DP Services, only in the applicable
              Territory in accordance with these Terms of Use and applicable
              law. For the purposes of this Terms of Use,
              &ldquo;Territory&rdquo; means the city or metro areas in the
              territory of India in which Delivery Partners are enabled by the
              Platform to receive requests for DP Services.
            </li>
          </ul>
          <p>&nbsp;</p>
          <li>
            <strong>Registration</strong>
          </li>
          <p>&nbsp;</p>
          <ul>
            <li>
              You shall be permitted to access the Platform, avail the Gordian
              Services, and connect with the Users on the Platform, as the case
              maybe, to provide DP Services only upon creating an Account
              (defined below) and obtaining a registration on the Platform. Your
              ability to continue using the Platform, avail Gordian Services,
              and provide DP Services to Users is subject to Your continued
              registration on the Platform. To register, You are required to
              provide all required details as may be sought by Gordian. It is
              your responsibility to provide all your current, updated, and
              requisite details. You shall be required to provide Gordian, at
              the time of registration, forth with upon any revision, and at any
              time on Gordian&rsquo;s request, with information and documentary
              evidence pertaining to You, any authorizations that You may have,
              contact details, and other details as requested by Gordian. The
              document/information that Gordian may need from Your end shall be
              intimated to You from time to time. Gordian reserves the right to
              independently verify Your documentation from time to time in any
              way Gordian deems appropriate in its reasonable discretion.
            </li>
          </ul>
          <p>&nbsp;</p>
          <ul>
            <li>
              Upon registration on the Platform, you will receive a Delivery
              Partner identification number or code or rider code or rider
              identification number (&ldquo;DP ID&rdquo;). The DP ID is specific
              to You only. You are responsible for all DP Services provided
              under the DP ID allocated to You.
            </li>
          </ul>
          <p>&nbsp;</p>
          <ul>
            <li>
              You will be responsible for maintaining the confidentiality of the
              Account information and are fully responsible for all activities
              that occur under Your Account. You agree to immediately notify
              Gordian of any unauthorized use of Your Account information or any
              other breach of security. It is a good practice to exit from your
              Account at the end of every session. Gordian cannot and will not
              be liable for any loss or damage arising from Your failure to
              comply with this provision. You may be held liable for losses
              incurred by Gordian or any other User of or visitor to the
              Platform due to authorized or unauthorized use of Your Account.
              Use of another Delivery Partner&rsquo;s Account information for
              using the Platform is expressly prohibited.
            </li>
          </ul>
          <p>&nbsp;</p>
          <ul>
            <li>
              Gordian shall have the right to display the information provided
              by You on the Platform. You shall ensure that all the information
              provided by You is sufficient to give a clear description of
              Yourself and the DP Services performed by You for the Users, and
              is not false or misleading in any manner. Gordian does not
              independently verify the information. Gordian shall in no way be
              responsible or liable for the accuracy or completeness of any
              information provided by You.
            </li>
          </ul>
          <p>&nbsp;</p>
          <ul>
            <li>
              Registration Fee: Gordian may charge a non-refundable on-boarding
              fee at the time of registration on the platform.
            </li>
          </ul>
          <p>&nbsp;</p>
          <li>
            <strong>Gordian Services</strong>
          </li>
          <p>&nbsp;</p>
          <ul>
            <li>
              Gordian and the Platform provides you with the following services
              (&ldquo;Gordian Services&rdquo;):
              <ul>
                <li>
                  Authorization to access and use the Platform and the services
                  provided therein
                </li>
                <li>A platform to:</li>
                <li>Connect with Users who wish to avail DP Services</li>
                <li>
                  Accept requests from Users to avail Your DP Services
                  (&ldquo;tasks&rdquo;)
                </li>
                <li>
                  Perform DP Services for Users&rsquo; tasks accepted by You
                  <ul>
                    <li>
                      Where authorized, collection of your fee for DP Services
                    </li>
                    <li>
                      It may facilitate the issuance of invoice/payment
                      statement to the Users, on Your behalf
                    </li>
                  </ul>
                </li>
              </ul>
            </li>
          </ul>
          <p>&nbsp;</p>
          <ul>
            <li>
              Gordian may also provide you with IoT Devices solely for the
              purpose of provision of DP Services to Users. You agree and
              acknowledge that:
            </li>
            <ul>
              <li>
                Gordian reserves all ownership and title of the IoT Devices and
                that such IoT Devices are provided to you free of cost only
                during the Term (defined below) solely for the purpose of
                provision of DP Services to Users
              </li>
              <li>
                You shall not tamper with the IoT Devices or cause any damage to
                the IoT Devices
              </li>
              <li>
                You shall take all reasonable care of the IoT Devices provided
                to You to avoid any damage or loss or theft to the IoT Devices
              </li>
              <li>
                You shall not transfer the possession or custody of the IoT
                Devices to anyone except with prior written approval of Gordian
              </li>
              <li>
                You shall return the IoT Devices in original condition upon
                termination of these Terms of Use
              </li>
            </ul>
          </ul>
          <p>&nbsp;</p>
          <li>
            <strong>Provision of DP Services</strong>
          </li>
          <p>&nbsp;</p>
          <ul>
            <li>
              When You are logged into Your Account on the Platform,
              Users&rsquo; requests for DP Services may appear to You on Your
              Account on the Platform if You are available and visible in the
              vicinity of the task raised by the User. You have the choice to
              accept the request from the User. If you accept the User request
              for DP Services, You will receive in an automated manner certain
              user information which may inter alia include the pick-up
              location, User&rsquo;s name, drop-off location, contact
              information etc. (&ldquo;User Information&rdquo;).
            </li>
          </ul>
          <p>&nbsp;</p>
          <ul>
            <li>
              Once you have accepted a User&rsquo;s request for DP Services, you
              shall be encouraged to follow the guidelines and standards as may
              be formulated by Gordian with respect to safety, etiquettes, etc.
              from time to time. While performing these DP Services, You shall
              also be responsible to abide by the safety conditions as may be
              required by a relevant state / central authority.
            </li>
          </ul>
          <p>&nbsp;</p>
          <ul>
            <li>
              You hereby acknowledge and agree that once You have accepted a
              User&rsquo;s request for DP Services, the Platform may display to
              the User in an automated manner, certain information about You
              which may inter alia include Your name, contact information, photo
              and location, Your rating, Your driving license information and
              Your vehicle registration number and other personal information
              necessary for the successful and timely accomplishment of the DP
              Services.
            </li>
          </ul>
          <p>&nbsp;</p>
          <ul>
            <li>
              You agree and undertake that You shall not contact Users or use
              any User&rsquo;s personal data/User Information for any reason
              other than for the purposes of fulfilling DP Services through the
              Platform in accordance with these Term of Use and applicable law.
            </li>
          </ul>
          <p>&nbsp;</p>
          <ul>
            <li>
              For Your services, the Platform generates an automated service
              receipt/ Payment Statement/ Invoice as the case maybe, on Your
              behalf to the User.
            </li>
          </ul>
          <p>&nbsp;</p>
          <ul>
            <li>
              You shall transport all Items for the Users and provide all DP
              Services to the User directly to their specified destination or
              otherwise complete all logistics related tasks, as directed by the
              applicable User, without undue delay. In the event, You, at your
              own discretion feel that there will be an inordinate delay, You
              shall on a best efforts basis try and reach out to the User.
            </li>
          </ul>
          <p>&nbsp;</p>
          <ul>
            <li>
              With respect to DP Services, You hereby agree that You shall not
              open or attempt to open the Items/packages to be dropped
              off/delivered to or on behalf of the User. However, if it comes to
              Your knowledge that a package contains illegal substance or Items
              not permissible to be delivered through the DP Services, You shall
              immediately report the same to the concerned law enforcement
              authorities. You expressly agree that You will not
              deliver/transport any alcoholic beverages or entertain any
              User&rsquo;s request to deliver/purchase or transport any
              alcoholic beverages or any other contraband items.
            </li>
          </ul>
          <p>&nbsp;</p>
          <li>
            <strong>Delivery Partner&rsquo;s Relationship with Gordian</strong>
          </li>
          <p>&nbsp;</p>
          <ul>
            <li>
              Gordian does not, and shall not be deemed to have any form of
              direct or indirect control over Delivery Partners inter alia with
              respect to the availability of Delivery Partners, performance of
              DP Services by the Delivery Partners, or maintenance of Delivery
              Partner&rsquo;s vehicle or compliance with applicable laws
              applicable to Delivery Partners of DP Services. It is hereby
              clarified that there is no employer-employee relationship between
              Gordian and the Delivery Partners. Delivery Partner acknowledges
              that Gordian does not control, or purport to control:
            </li>
            <li>
              when or for how long will the Delivery Partner utilize the
              Platform or the Gordian Services; or
            </li>
            <li>
              Delivery Partner&rsquo;s decision, via the Platform, to attempt to
              accept or to decline or ignore a User&rsquo;s request for DP
              Services on the Platform, subject to Gordian&rsquo;s then-current
              cancellation policies.
            </li>
          </ul>
          <p>&nbsp;</p>
          <ul>
            <li>
              You are not an employee of Gordian. You may thus choose to engage
              with other occupations or means of livelihood. There is no
              exclusivity arrangement with Gordian and You have complete
              discretion to operate Your independent business or enroll with
              other platform for performing similar services. However, you agree
              that during the time you are logged into your Account on the
              Platform, you will not engage with other occupations or means of
              livelihood. You have no authority to bind Gordian and undertake
              not to hold Yourself out as an employee, agent, or authorized
              representative of Gordian or its affiliates. Where, by implication
              of mandatory law or otherwise, You may be deemed an employee,
              agent or representative of Gordian, You undertake and agree to
              indemnify, defend and hold Gordian and its affiliates harmless
              from and against any claims by any person, entity, regulators or
              governmental authorities based on such implied employment, agency
              or representative relationship.
            </li>
          </ul>
          <p>&nbsp;</p>
          <ul>
            <li>
              Your Account may be deactivated or otherwise restricted from
              accessing or using the Platform or the Gordian Services in the
              event of a violation of these Terms of Use, disparagement of
              Gordian or any of its affiliates, or Your act or omission that
              causes harm to Gordian&rsquo;s or any of its affiliates&rsquo;
              brand, reputation or business as determined by Gordian in its sole
              discretion. Gordian also retains the right to deactivate or
              otherwise restrict You from accessing or using the Platform or the
              Gordian Services for any other reason at the sole and reasonable
              discretion of Gordian.
            </li>
          </ul>
          <p>&nbsp;</p>
          <ul>
            <li>
              You agree that You may require certain enablers such as bikes,
              mobile phones, active internet connection, helmets, bags, or other
              such instruments for you to perform DP Services. Gordian is not
              responsible to provide any such support to You. In particular,
              Gordian is not responsible to provide You with insurance premium
              paid by You, or helmets purchased by you. You shall solely be
              responsible for maintaining the necessary equipment and internet
              connections that may be required to access, use, and transact on
              the Platform and avail the Gordian Services.
            </li>
          </ul>
          <p>&nbsp;</p>
          <ul>
            <li>
              Gordian may from time to time provide You with certain advisories
              in respect of Your performance of the DP Services. These
              advisories may be issued to facilitate compliance with applicable
              law or to generate a uniform platform experience for Users and
              other Delivery Partners. You are encouraged to follow them.
            </li>
          </ul>
          <p>&nbsp;</p>
          <ul>
            <li>
              You may from time to time receive notice of certain
              welfare/benefit programs that You may avail as part of the Gordian
              eco-system. Availing such benefits are subject to Your choice and
              opt-in. These benefits may be provided to you by third party
              service providers who Gordian does not control or sponsor.
            </li>
          </ul>
          <p>&nbsp;</p>
          <ul>
            <li>
              To facilitate provision of DP Services, You may choose to avail
              certain third-party services such as personal loans, accident
              insurance policies etc. Some of these third-party services may be
              &nbsp;introduced to You through Gordian. The choice of availing
              all such services is Yours. Gordian is not responsible for such
              services provided by third parties. If you choose to avail such
              benefits You authorize Gordian to share information about You as
              required for such third party to provide the services to You.
            </li>
          </ul>
          <p>&nbsp;</p>
          <li>
            <strong>Delivery Partner&rsquo;s Relationship with Users</strong>
          </li>
          <p>
            <strong>&nbsp;</strong>
          </p>
          <ul>
            <li>
              Delivery Partner acknowledges and agrees that it&rsquo;s provision
              of DP Services to Users creates a legal and direct business
              relationship between the Delivery Partner and the User, to which
              Gordian is not a party. Gordian is not responsible or liable for
              the actions or inactions of a User in relation to the activities
              of the Delivery Partner. You shall have the sole responsibility
              for any obligations or liabilities that may arise towards the
              Users or any third parties that arise from the provision of Your
              DP Services. You are solely responsible for taking such
              precautions as may be reasonable and proper (including maintaining
              adequate insurance policies that meets the requirements of all
              applicable laws) regarding any acts or omissions of a User or
              third party.
            </li>
          </ul>
          <p>
            <strong>&nbsp;</strong>
          </p>
          <li>
            <strong>Ratings</strong>
          </li>
          <p>
            <strong>&nbsp;</strong>
          </p>
          <ul>
            <li>
              You agree that: (i) after providing DP Services to a User, the
              Platform may prompt the User with an option to provide a rating of
              such DP Service provided by You and, optionally, to provide
              comments or feedback about You and such DP Service; and (ii) after
              providing the DP Services to the Users, You may be prompted on the
              Platform to provide a rating of the User and, optionally, to
              provide comments or feedback about the User. You shall provide
              ratings and feedback in good faith and unbiased manner.
            </li>
          </ul>
          <p>&nbsp;</p>
          <ul>
            <li>
              In order to continue to receive access to the Platform and the
              Gordian Services, You hereby acknowledge that You must maintain an
              average rating by Users that exceeds the minimum average
              acceptable rating established by Gordian for the Territory, as may
              be updated from time to time (&ldquo;Minimum Average
              Rating&rdquo;). In the event Your average rating falls below the
              Minimum Average Rating, Gordian may provide You a limited period
              of time to raise Your average rating above the Minimum Average
              Rating. You hereby agree that if You do not increase Your average
              rating above the Minimum Average Rating within the time period
              allowed (if any), Gordian may deactivate Your access to the
              Platform and the Gordian Services.
            </li>
          </ul>
          <p>&nbsp;</p>
          <ul>
            <li>
              Gordian and its affiliates reserve the right to use, share and
              display Your ratings and comments in any manner in connection with
              the business of Gordian and its affiliates without attribution to
              or approval of Delivery Partners and You hereby consent to the
              same. Gordian and its affiliates reserve the right to remove
              comments from Platform in the event such comments include
              obscenities or other objectionable content, include an
              individual&rsquo;s name or other personal information, or violate
              any privacy laws, intermediary guidelines, other applicable laws
              or Gordian&rsquo;s or its affiliates&rsquo; content policies.
            </li>
          </ul>
          <p>&nbsp;</p>
          <li>
            <strong>Mobile Devices</strong>
          </li>
          <p>
            <strong>&nbsp;</strong>
          </p>
          <ul>
            <li>
              In order to access the Platform and provide the DP Services to the
              Users, You may use Your own mobile device (&ldquo;Delivery Partner
              Device&rdquo;).
            </li>
          </ul>
          <p>&nbsp;</p>
          <ul>
            <li>
              While using a Delivery Partner Device: (i) You shall be
              responsible for the acquisition, cost and maintenance of such
              Delivery Partner Device as well as any necessary wireless data
              plan; and (ii) Gordian shall make available the Platform for
              installation on such Delivery Partner Device. The foregoing right
              shall immediately terminate and You will delete and fully delete
              the Platform from the Delivery Partner Device in the event You
              cease to provide DP Services to the User using the Delivery
              Partner Device; or You have deleted Your Account from the
              Platform. You hereby agree that: (i) use of the Platform and
              Gordian Services on a Delivery Partner Device requires an active
              data plan with a wireless carrier associated with the Delivery
              Partner Device, which data plan will be provided by You at Your
              own expense; and (ii) use of the Platform on a Delivery Partner
              Device as an interface with the Gordian Services may consume very
              large amounts of data through the data plan. Gordian advises that
              Delivery Partner Devices should only be used under a data plan
              with unlimited or very high data usage limits, and Gordian shall
              not be responsible or liable for any fees, costs, or overage
              charges associated with any data plan during the performance of DP
              Services.
            </li>
          </ul>
          <p>&nbsp;</p>
          <li>
            <strong>Location Based Services</strong>
          </li>
          <p>
            <strong>&nbsp;</strong>
          </p>
          <p>
            You acknowledge and agree that Your geo-location information is
            required from You to provide the DP Services to the Users, using the
            Platform and is required by Gordian for it to provide You with
            Gordian Services. You acknowledge and hereby consent to the
            following: (a) Your geo‐location information will be monitored and
            tracked by Gordian, when You are logged into Your Account on the
            Platform and available to receive requests for providing DP Services
            from the Users, or when You are providing transportation and/or
            logistics services to the Users; and (b) the approximate location of
            Your vehicle will be displayed to the User before and during the
            provision of DP Services to such User. In addition, Gordian may
            monitor, track and share Your geo‐location information obtained by
            the Platform and Delivery Partner Device, as the case may be, for
            safety, security, technical, marketing and commercial purposes,
            including to provide and improve Gordian&rsquo;s products and
            services.
          </p>
          <p>&nbsp;</p>
          <li>
            <strong>Delivery Partners and Vehicles</strong>
          </li>
          <p>&nbsp;</p>
          <ul>
            <li>
              Delivery Partner Requirements. In order to register as a Delivery
              Partner, You must be above 18 years of age. You acknowledge and
              agree that You shall at all times hold and maintain (i) a valid
              driver's license (if applicable) with the appropriate level of
              certification to operate the vehicle driven/ridden by You, and
              (ii) all licenses, permits, approvals and authority applicable to
              Your vehicle (if applicable) that are necessary to provide DP
              Services; (iii) the appropriate and current level of training,
              expertise and experience to provide DP Services in a professional
              manner with due skill, care and diligence; and (iv) high standards
              of professionalism, service and courtesy. You acknowledge and
              agree that You may be subject to certain background and driving
              record checks from time to time.
            </li>
          </ul>
          <p>&nbsp;</p>
          <ul>
            <li>
              Vehicle Requirements. You acknowledge and agree that Your vehicle
              with which You choose to provide DP Services shall be: (i)
              operated in compliance with all applicable laws; (ii) properly
              registered, insured and licensed in accordance with law to operate
              as a vehicle to transport/deliver Item(s) on behalf of the User;
              (iii) suitable to transport Item(s) as contemplated by these Terms
              of Use; and (iv) maintained in good operating condition,
              consistent with industry safety and maintenance standards for a
              vehicle of its kind and any additional standards or requirements
              in the applicable Territory, and in a clean and sanitary condition
              (together herein after referred to as &ldquo;Vehicle
              Specification&rdquo;).
            </li>
          </ul>
          <p>&nbsp;</p>
          <li>
            <strong>Financial Terms</strong>
          </li>
          <p>&nbsp;</p>
          <ul>
            <li>
              Delivery Partner Fees: For the provision of DP Services, You may
              charge a fee to the User, the amount of which is determined in
              accordance with the guidelines framed by Gordian for Delivery
              Partners (&ldquo;DP Fees&rdquo;). Note that Gordian frames the
              guidelines for the purposes of providing a uniform experience on
              the Platform to Users and Delivery Partners. Please review
              carefully the DP Fees applicable to the DP Services You choose to
              provide before You do so.
            </li>
          </ul>
          <p>&nbsp;</p>
          <ul>
            <li>
              Fare Calculation: For the DP Services provided by You to Users in
              specified Territories, You are entitled to charge a fare to the
              User for each instance of completed DP Services provided to a User
              through the Platform (&ldquo;Fare&rdquo;). The Fare is calculated
              based upon a base fare amount (which shall be determined as per
              rate card/guidelines provided by Gordian to the Delivery Partner)
              plus distance (as determined by Gordian using location-based
              services enabled through the Delivery Partner Device) and/or time
              amounts and/or Item value amounts (i.e., value of packages/Items
              being delivered by the User through You), for the applicable
              Territory and toll charges, if any (&ldquo;Fare
              Calculation&rdquo;).
            </li>
          </ul>
          <p>&nbsp;</p>
          <ul>
            <li>
              Changes to Fare Calculation: Gordian reserves the right to change
              the Fare Calculation at any time based upon local market factors,
              and Gordian will provide notice to the Delivery Partner in the
              event of such change that would result in a change in the
              recommended Fare. Continued use of the Gordian Services after any
              such change in the Fare Calculation shall constitute Your consent
              to such change.
            </li>
          </ul>
          <p>&nbsp;</p>
          <ul>
            <li>
              Fare Cost Adjustment. Any adjustment to Fare Cost for instances
              such as technical error in the Gordian Services or force majeure
              events etc., shall be subject to uniform polices applicable from
              time to time.
            </li>
          </ul>
          <p>&nbsp;</p>
          <ul>
            <li>
              Cancellation Charges. You acknowledge and agree that Users may
              elect to cancel requests for DP Services that have been accepted
              by You at any time prior to the provision of DP Services. In the
              event that a User cancels an accepted request for DP Services,
              Gordian may charge the User a cancellation fee on behalf of You.
              If charged, this cancellation fee shall be deemed the Fare for the
              cancelled DP Services and shall be remitted to You
              (&ldquo;Cancellation Fee&rdquo;).
            </li>
          </ul>
          <p>&nbsp;</p>
          <ul>
            <li>
              Taxes: You acknowledge and agree that You are required to: (i)
              complete all tax registration obligations (if any) and calculate
              and remit all tax liabilities related to the provision of DP
              Services as required by applicable law; and (ii) provide Gordian
              with all relevant tax information. You further acknowledge and
              agree that You are responsible for paying taxes on Your own income
              arising from the performance of DP Services. Notwithstanding
              anything to the contrary in this Agreement, Gordian may in its
              reasonable discretion based on applicable tax and regulatory
              considerations, collect and remit taxes resulting from Your
              provision of DP Services and/or provide any of the relevant tax
              information You have provided pursuant to the foregoing
              requirements in this Section directly to the applicable
              governmental tax authorities on Your behalf or otherwise. You
              further agree and acknowledge that Gordian shall be entitled to
              deduct tax at source in accordance with applicable law, prior to
              making any payouts to You.
            </li>
          </ul>
          <p>&nbsp;</p>
          <li>
            <strong>Proprietary Rights and License</strong>
          </li>
          <p>
            <strong>&nbsp;</strong>
          </p>
          <ul>
            <li>
              License Grant: Subject to the terms and conditions of these Terms
              of Use, Gordian hereby grants the Delivery Partner a
              non-exclusive, royalty-free, non-transferable, non-sub-licensable,
              non-assignable license to the Platform in connection with the
              provision of the Gordian Services solely for the purpose of
              providing DP Services to Users. Further, subject to the terms and
              conditions of these Terms of Use, Gordian hereby grants the
              Delivery Partner a non-exclusive, royalty-free, non-transferable,
              non-sub-licensable, non-assignable license to use the proprietary
              marks of Gordian for the sole purpose of providing the DP
              Services. All rights not expressly granted to Delivery Partner are
              reserved by Gordian, its affiliates, and their respective
              licensors.
            </li>
          </ul>
          <p>&nbsp;</p>
          <ul>
            <li>
              Restrictions. You shall not, and shall not allow any other party
              to: (i) license, sublicense, sell, resell, transfer, assign,
              distribute or otherwise provide or make available to any other
              party the Gordian Services, Platform or (if applicable) in any
              way; (ii) modify or make derivative works based upon the Gordian
              Services or Platform; (iii) improperly use the Gordian Services or
              Platform, including creating Internet &ldquo;links&rdquo; to any
              part of the Gordian Services or Platform, &ldquo;framing&rdquo; or
              &ldquo;mirroring&rdquo; any part of the Gordian Services or
              Platform on any other websites or systems, or
              &ldquo;scraping&rdquo; or otherwise improperly obtaining data from
              the Gordian Services or the Platform; (iv) reverse engineer,
              decompile, modify, or disassemble the Gordian Services or
              Platform,; or (v) send spam or otherwise duplicative or
              unsolicited messages. In addition, You shall not, and shall not
              allow any other party to, access or use the Gordian Services or
              Platform to: (i) design or develop a competitive or substantially
              similar product or service; (ii) copy or extract any features,
              functionality, or content thereof; (iii) launch or cause to be
              launched on or in connection with the Gordian Services an
              automated program or script, including web spiders, crawlers,
              robots, indexers, bots, viruses or worms, or any program which may
              make multiple server requests per second, or unduly burden or
              hinder the operation and/or performance of the Gordian
              Services/Platform; or (iv) attempt to gain unauthorized access to
              the Gordian Services or its related systems or networks, (v)
              defame, abuse, harass, threaten or otherwise violate the legal
              rights of others; (vi) impersonate any person or entity, or
              falsely state or otherwise misrepresent Your affiliation with a
              person or entity; (vi) publish, post, upload, distribute or
              disseminate any information that is harmful, harassing,
              blasphemous, defamatory, obscene, pornographic, pedophilic,
              libelous, hateful, or racially, ethnically objectionable,
              disparaging, inappropriate, profane, infringing or otherwise
              unlawful in any manner whatever; or that threatens the unity,
              integrity, defense, security or sovereignty of India, friendly
              relations with foreign states, or public order or causes
              incitement to the commission of any cognizable offence or prevents
              investigation of any offence or is insulting any other nation;
              (vii) upload files that contain software or other material
              protected by applicable intellectual property laws unless You own
              or control the rights there to or have received all necessary
              consents; (viii) upload or distribute files that contain viruses,
              corrupted files, or any other similar software or programs that
              may damage the operation of the Platform or another's computer;
              (ix) engage in any activity that interferes with or disrupts
              access to the Platform or the Gordian Services (or the servers and
              networks which are connected to the Platform); (x) attempt to gain
              unauthorized access to any portion or feature of the Platform, any
              other systems or networks connected to the Platform, to any
              Gordian server, or to any of the Gordian Services offered on or
              through the Platform, by hacking, password mining or any other
              illegitimate means; (xi) probe, scan or test the vulnerability of
              the Platform or any network connected to the Platform, nor breach
              the security or authentication measures on the Platform or any
              network connected to the Platform. (xii) reverse look-up, trace or
              seek to trace any information on any other user (User or Delivery
              Partner), of or visitor to, the Platform, to its source, or
              exploit the Platform or Gordian Services or information made
              available or offered by or through the Platform, in any way
              whether or not the purpose is to reveal any information, including
              but not limited to personal identification information, other than
              Your own information, as provided on the Platform; (xiii) disrupt
              or interfere with the security of, or otherwise cause harm to, the
              Platform, systems resources, accounts, passwords, servers or
              networks connected to or accessible through the Platform or any
              affiliated or linked sites; (xiv) collect or store data about
              other users (User or Delivery Partner), in connection with the
              prohibited conduct and activities set forth in this Terms of Use;
              and (xv) use any device or software to interfere or attempt to
              interfere with the proper working of the Platform or any
              transaction being conducted on the Platform, or with any other
              person&rsquo;s use of the Platform; (xvi) use the Platform or any
              material or content on the Platform for any purpose that is
              unlawful or prohibited by these Terms of Use, or to solicit the
              performance of any illegal activity or other activity which
              infringes the rights of Gordian or other third parties; (xvii)
              falsify or delete any author attributions, legal or other proper
              notices or proprietary designations or labels of the origin or
              source of software or other material contained in a file that is
              uploaded; (xviii) misuse the personal information of the
              registered users or use their personal information or disclose
              such personal information for any purpose other than to fulfil
              Your obligations under these Terms of Use; (xix) violate any code
              of conduct or other guidelines, which may be applicable for or to
              any particular Gordian Service; (xx) violate the Terms of Use
              contained herein or elsewhere, or violate any applicable laws or
              regulations for the time being in force within or outside India;
            </li>
          </ul>
          <p>&nbsp;</p>
          <ul>
            <li>
              Ownership: The Gordian Services, Platform and Gordian Data
              (defined below), including all intellectual property rights
              therein are and shall at all time remain the property of Gordian,
              its affiliates or their respective licensors, as the case maybe.
              Neither this Terms of Use nor Your use of the Gordian Services,
              Platform or Gordian Data conveys or grants to You any rights: (i)
              in or related to the Gordian Services, Platform or Gordian Data,
              except for the limited license granted under these Terms of Use;
              or (ii) to use or reference in any manner Gordian&rsquo;s, its
              affiliates&rsquo;, or their respective licensors&rsquo; company
              names, logos, product and service names, trademarks, service marks
              or other indicia of ownership. Additionally, You acknowledge
              Gordian&rsquo;s rights in its GORDIAN family of trademarks and
              names, including GORDIAN, alone and in combination with other
              letters, punctuation, words, symbols and/or designs, and the
              GORDIAN Logo (&ldquo;GORDIAN Marks and Names&rdquo;). You agree
              that You will not try to register or otherwise claim ownership in
              any of the GORDIAN Marks and Names, alone or in combination with
              other letters, punctuation, words, symbols and/or designs, or in
              any confusingly similar mark or name.
            </li>
          </ul>
          <p>&nbsp;</p>
          <ul>
            <li>
              For the purposes of these Terms of Use &ldquo;Gordian Data&rdquo;
              means all data related to the access and use of the Gordian
              Services hereunder, including all data related to Users (including
              User information), all data pertaining to Merchant, Items
              (Merchant Information), all data related to the provision of
              transportation and/or logistics services by the Delivery Partner
              via the Gordian Services and the Platform, and the DP ID.
            </li>
          </ul>
          <p>
            <strong>&nbsp;</strong>
          </p>
          <li>
            <strong>Confidentiality</strong>
          </li>
          <p>
            <strong>&nbsp;</strong>
          </p>
          <ul>
            <li>
              Each party acknowledges and agrees that in the performance of
              these Terms of Use it may have access to or may be exposed to,
              directly or indirectly, confidential information of the other
              party ("Confidential Information"). Confidential Information
              includes Gordian Data, DP IDs, User Information, information
              related to the Platform, information related to any transaction
              initiated on the Platform, and the transaction volume, marketing
              and business plans, business, financial, technical, operational
              and such other non-public information of each party (whether
              disclosed in writing or verbally and whether expressly marked as
              confidential or not).
            </li>
          </ul>
          <p>&nbsp;</p>
          <ul>
            <li>
              Each party acknowledges and agrees that: (i) all Confidential
              Information shall remain the exclusive property of the disclosing
              party; (ii) it shall not use Confidential Information of the other
              party for any purpose except in furtherance of its obligation
              under the Terms of Use; (iii) it shall not disclose Confidential
              Information of the other party to any third party, except to its
              employees, officers, contractors, agents and service providers
              ("Permitted Persons") as necessary to perform under this Terms of
              Use, provided Permitted Persons are bound in writing to
              obligations of confidentiality and non‐use of Confidential
              Information no less protective than the terms hereof; and (iv) it
              shall return or destroy all Confidential Information of the
              disclosing party upon the termination of this Terms of Use or at
              the request of the other party (subject to applicable law and,
              with respect to Gordian, its internal record keeping requirements)
            </li>
          </ul>
          <p>&nbsp;</p>
          <ul>
            <li>
              You hereby acknowledge and agree that all Confidential Information
              provided to You or which is in Your custody shall be used by You
              only for the purposes of providing the DP Services in accordance
              with the provisions of this Terms of Use.
            </li>
          </ul>
          <p>&nbsp;</p>
          <ul>
            <li>
              If You choose to avail certain ancillary services from third party
              services, Your information may be required to be provided to such
              third-party services providers. You hereby consent to sharing your
              Confidential Information when You agree to receive such ancillary
              services.
            </li>
          </ul>
          <p>&nbsp;</p>
          <li>
            <strong>Access to Platform</strong>
          </li>
          <p>
            <strong>&nbsp;</strong>
          </p>
          <p>
            Notwithstanding these Terms of Use, Gordian reserves the right to
            temporarily or permanently, as it may deem fit, discontinue Your
            access to the Platform, Gordian Services, and/or de-list You
            from the Platform with immediate effect in the following instances:
          </p>
          <p>&nbsp;</p>
          <ul>
            <li>
              User complaints received by Gordian which are directly
              attributable to You; or
            </li>
            <li>Breach of the provisions of any applicable law; or</li>
            <li>
              Breach of the representations and warranties under these Terms of
              Use; or
            </li>
            <li>
              Any other breach of the Terms of Use, Gordian&rsquo;s Privacy
              Policy or any other terms, conditions, or policies that may be
              applicable to You from time to time (or have acted in a manner
              that clearly shows that You do not intend to not comply, or are
              unable to, comply with the same);
            </li>
            <li>
              The provision of the Gordian Services to You by Gordian is, in the
              opinion of Gordian, no longer commercially viable or in any way
              detrimental to Gordian, its business or the Platform;
            </li>
            <li>
              You provide any information that is untrue, inaccurate, not
              current or incomplete (or becomes untrue, inaccurate, not current
              or incomplete), or Gordian has reasonable grounds to suspect that
              such information is untrue, inaccurate, not current or incomplete.
            </li>
            <li>
              Gordian has elected to discontinue, with or without reason, Your
              access to the Platform, Gordian Services or any part thereof.
            </li>
          </ul>
          <p>&nbsp;</p>
          <li>
            <strong>Eligibility to Use</strong>
          </li>
          <p>&nbsp;</p>
          <p>
            You shall not have more than one active Account (as defined
            hereinafter) on the Platform. Additionally, You are prohibited from
            selling, trading, or otherwise transferring Your Account to another
            party.
          </p>
          <p>&nbsp;</p>
          <li>
            <strong>Third Party Content</strong>
          </li>
          <p>&nbsp;</p>
          <p>
            The Platform makes available general third-party information and
            other data from external sources (&ldquo;Third Party
            Content&rdquo;). The provision of Third-Party Content is for general
            informational purposes only. You acknowledge that the Third-Party
            Content provided to You is obtained from sources believed to be
            reliable. Gordian does not provide any guarantee with respect to any
            the Third-Party Content and Gordian shall not be held liable for any
            loss suffered by You based on Your reliance on or use of Third-Party
            Content. Further, to the extent that such Third-Party Content is
            infringing upon some other party&rsquo;s intellectual property
            rights or proprietary rights, Gordian shall not be held liable for
            the same at any instances.
          </p>
          <p>&nbsp;</p>
          <li>
            <strong>Insurance</strong>
          </li>
          <p>
            <strong>&nbsp;</strong>
          </p>
          <p>
            Delivery Partner represents and agrees that he or she holds or is
            otherwise covered by a valid insurance policy of liability
            insurance, including, but not limited to third party liability
            insurance (as per industry-standard coverage amounts and in
            pursuance of mandatory regulatory requirements) with respect to
            Delivery Partner&rsquo;s operation of his/her vehicle(s) under these
            Terms of Use.
          </p>
          <p>&nbsp;</p>
          <li>
            <strong>Warranties, Covenants and Disclaimers</strong>
          </li>
          <p>
            <strong>&nbsp;</strong>
          </p>
          <ul>
            <li>
              You agree to use the Platform and the materials provided therein
              only: (i) for purposes that are permitted by the Terms of Use; and
              (ii) in accordance with any applicable law, regulation or
              generally accepted practices or guidelines.
            </li>
          </ul>
          <p>&nbsp;</p>
          <ul>
            <li>
              You hereby represent and warrant that: (i) You have full power and
              authority to enter into this Terms of Use and perform your
              obligations hereunder; (ii) You have not entered into, and will
              not enter into, any arrangement that would prevent it from
              complying with this provisions of the Terms of Use and applicable
              law; (iii) You will comply with all applicable laws in its
              performance of this Terms of Use, including holding and complying
              with all permits, licenses, registrations, certifications and
              other governmental authorizations necessary to provide DP Services
              using the required vehicle pursuant to this Terms of Use.
            </li>
          </ul>
          <p>&nbsp;</p>
          <ul>
            <li>
              You represent and warrant that You have not received any notice
              from any third party or any governmental authority and no
              litigation is pending against You in any court of law which may
              have an adverse effect on the provision of
              logistic/delivery/transportation services.
            </li>
            <li>
              You represent and warrant that You upon performing the DP
              Services, promptly and accurately update on the Platform that You
              have completed such DP Services or delivered the Item, as the case
              may be.
            </li>
          </ul>
          <p>&nbsp;</p>
          <ul>
            <li>
              You represent and warrant that all governmental authorisations,
              consents, licenses, registration, approvals and other consents
              required under applicable laws for the provision of DP Services
              have been obtained and shall remain in force for as long as You
              are using the Platform and availing the Gordian Services or during
              the provision of DP Services by You.
            </li>
          </ul>
          <p>&nbsp;</p>
          <ul>
            <li>
              You agree not to access (or attempt to access) the Platform and
              the materials or by any means other than through the interface
              that is provided by Gordian. You shall not use any deep-link,
              robot, spider or other automatic device, program, algorithm or
              methodology, or any similar or equivalent manual process, to
              access, acquire, copy or monitor any portion of the Platform or
              any of its content (as defined below), or in any way reproduce or
              circumvent the navigational structure or presentation of the
              Platform, materials or any content therein, to obtain or attempt
              to obtain any materials, documents or information through any
              means not specifically made available through the Platform.
            </li>
          </ul>
          <p>&nbsp;</p>
          <ul>
            <li>
              You acknowledge and agree that by accessing or using the Platform
              or Gordian Services, You may be exposed to content from other
              users (including but not limited to other Users and Delivery
              Partners) that You may consider offensive, indecent or otherwise
              objectionable. Gordian disclaims all liabilities arising in
              relation to such offensive content on the Platform.
            </li>
          </ul>
          <p>&nbsp;</p>
          <ul>
            <li>
              If the Platform allows You to post and upload any material on the
              Platform, You hereby undertake to ensure that such material is not
              offensive and is in accordance with applicable laws. All material
              added, created, uploaded, submitted, distributed, or posted to the
              Platform by You is Your sole responsibility. You hereby do and
              shall grant Gordian a worldwide, non-exclusive, perpetual,
              royalty-free, sub-licensable and transferable license to use,
              reproduce, disclose, distribute, translate and otherwise fully
              exploit any such material, in connection with the Platform and
              Gordian&rsquo;s (and Gordian&rsquo;s successors&rsquo; and
              assigns&rsquo;) businesses, including without limitation, for
              promoting the Platform in any media formats and through any media
              channels. You represent and warrant that You have all rights to
              grant such licenses to Gordian without infringement or violation
              of any third party rights, including without limitation, any
              privacy rights, publicity rights, copyrights, trademarks, contract
              rights, or any other intellectual property or proprietary rights.
            </li>
          </ul>
          <p>&nbsp;</p>
          <ul>
            <li>
              Disclaimer of Warranties: You hereby accept and acknowledge that,
              the Gordian Services and Platform is provided on an "as is" and
              "as available" basis. Gordian does not represent, warrant, or
              guarantee that Your access to or use of the Gordian Services or
              Platform: (i) will be uninterrupted or error free; or (ii) will
              result in any guaranteed requests for DP Services. Gordian
              functions as an on‐demand lead generation and related service only
              and makes no representations, warranties, or guarantees as to the
              actions or inactions of the Users, who may request or receive DP
              Services from You, and Gordian need not screen or otherwise
              evaluate Users. By using the Gordian Services and Platform, You
              acknowledge and agree that You may be introduced to a third party
              (including Users) that may pose harm or risk to You or other third
              parties. You are advised to take reasonable precautions with
              respect to interactions with third parties encountered in
              connection with the use of the Gordian Services or the Platform.
              Gordian expressly disclaims all liability for any act or omission
              of any Delivery Partner, any User, Merchant or other third party.
            </li>
          </ul>
          <p>&nbsp;</p>
          <ul>
            <li>
              No Service Guarantee: Gordian does not guarantee the availability
              or uptime of the Gordian Services or the Platform. You acknowledge
              and agree that the Gordian Services or Platform may be unavailable
              at any time and for any reason (e.g., due to scheduled maintenance
              or network failure). Further, the Gordian Services or Platform may
              be subject to limitations, delays, and other problems inherent in
              the use of the internet and electronic communications or for any
              other technological reasons, and Gordian is not responsible for
              any delays, delivery failures or other damages, liabilities or
              losses, costs resulting from such problems.
            </li>
          </ul>
          <p>
            <strong>&nbsp;</strong>
          </p>
          <li>
            <strong>Indemnification</strong>
          </li>
          <p>&nbsp;</p>
          <p>
            You shall indemnify, defend and hold harmless Gordian and its
            affiliates and the irrespective officers, directors, employees,
            agents, successors and assigns (&ldquo;Indemnified Parties&rdquo;)
            from and against any and all liabilities, losses (including
            reputational loss and brand value loss caused due to deficiency of
            services on part of You), demands, expenses (including legal fees
            and disbursements in connection therewith and interest chargeable
            upon), damages, penalties, fines, social security contributions and
            taxes asserted against or incurred by the Indemnified Parties
            arising out of or related to: (i) Your breach of Your
            representations, warranties or obligations under this Terms of Use;
            or (ii) a claim by a third party (including Users, Merchant,
            regulators and governmental authorities) directly or indirectly
            related to (x) Your provision of DP Services or use of the Gordian
            Services or the Platform, (y) Your acts of negligence or willful
            misconduct in performance of this Terms of Use.
          </p>
          <p>&nbsp;</p>
          <li>
            <strong>Limits of Liability</strong>
          </li>
          <p>&nbsp;</p>
          <p>You agree and acknowledge that:</p>
          <p>&nbsp;</p>
          <ul>
            <li>
              Gordian and its affiliates shall not be liable under or related to
              this Terms of Use for any of the following, whether based on
              contract, tort or any other legal theory, even if a party has been
              advised of the possibility of such damages: (i) any incidental,
              punitive, special, exemplary, consequential, or other indirect
              damages of any type or kind; or (ii) Your or the User&rsquo;s or
              any third party&rsquo;s property damage, or loss or inaccuracy of
              data, or loss of business, revenue, profits, use or other economic
              advantage. In no event shall the liability of Gordian or its
              affiliates under this Terms of Use exceed INR 100/- (Rupees One
              Hundred Only). Yaou acknowledge and agree that any and all claims
              You have or purport to have against Gordian and/or its affiliates
              should be notified to Gordian and/or its affiliates immediately
              and no later than 30 days from the event. You forfeit all rights
              in respect of that claim if You fail to do so. These limitations
              do not purport to limit liability that cannot be excluded by
              applicable law.
            </li>
          </ul>
          <p>&nbsp;</p>
          <ul>
            <li>
              Gordian shall not be responsible for any loss/theft/damages
              occurring to the packages/Items being transported by you on behalf
              of the Users.
            </li>
          </ul>
          <p>&nbsp;</p>
          <li>
            <strong>Term and Termination</strong>
          </li>
          <p>&nbsp;</p>
          <ul>
            <li>
              These Terms of Use will continue to apply until terminated by
              either You or Gordian as set forth below (&ldquo;Term&rdquo;).
              These Terms shall continue to apply so long as You continue to
              access the Platform.
            </li>
          </ul>
          <p>&nbsp;</p>
          <ul>
            <li>
              If You want to terminate these Terms, You can do so by (i)
              notifying Gordian to close Your Account; and (ii) not accessing
              the Platform. Such termination shall take effect after 15 days of
              receipt of the above notice and Gordian shall delist the Delivery
              Partner at the end of the 15th Day.
            </li>
          </ul>
          <p>&nbsp;</p>
          <ul>
            <li>
              Upon termination of Your Account, the DP ID allotted to You,
              and/or any other content or materials related to You shall be
              deleted. Gordian may however retain Your transaction history on
              the Platform or in Your Account and any other Delivery Partner
              records, for be legal purposes without any obligation to provide
              you with the data.
            </li>
          </ul>
          <p>&nbsp;</p>
          <ul>
            <li>
              The termination of Your Account shall not relieve You of any
              liability that You may have incurred or may incur in relation to
              use of Gordian Services or the Platform prior to such termination.
              Further, Gordian shall not be liable to You or any third party for
              any termination of Your Account, or Your access to the Platform
              and Gordian Services.
            </li>
          </ul>
          <p>&nbsp;</p>
          <li>
            <strong>Violation of the Terms of Use</strong>
          </li>
          <p>&nbsp;</p>
          <ul>
            <li>
              You also agree that any violation by You of these Terms of Use
              will constitute an unlawful and unfair business practice, and will
              cause irreparable harm to Gordian, for which monetary damages
              would be inadequate, and You consent to Gordian obtaining any
              injunctive or equitable relief that Gordian may deems necessary or
              appropriate in such circumstances. These remedies are in addition
              to any other remedies that Gordian may have at law or in equity.
            </li>
          </ul>
          <p>&nbsp;</p>
          <ul>
            <li>
              If Gordian does take any legal action against You as a result of
              Your violation of these Terms of Use, Gordian will be entitled to
              recover from You, and You agree to pay, all reasonable
              attorneys&rsquo; fees and costs of such action, in addition to any
              other relief granted to Gordian.
            </li>
          </ul>
          <p>&nbsp;</p>
          <li>
            <strong>Relationship of the Parties</strong>
          </li>
          <p>&nbsp;</p>
          <p>
            Except as otherwise expressly provided herein to the relationship
            between Gordian and Delivery Partner is solely that of independent
            contractors. The parties expressly agree that: (i) these Terms of
            Use do not create any relationship of employment with Gordian nor
            does it impose any employer obligations on Gordian (inter alia
            including obligations under labor laws and tax laws); and (ii) no
            joint venture, partnership, or principal-agent relationship exists
            between Gordian and Delivery Partner(s). However, for the limited
            sole purpose of collection of DP Fees, etc., Gordian may be engaging
            with third parties&rsquo; services on behalf of Delivery Partner,
            for collecting payments from Users on behalf of the Delivery
            Partner.
          </p>
          <p>&nbsp;</p>
          <li>
            <strong>Miscellaneous Terms</strong>
          </li>
          <p>&nbsp;</p>
          <ul>
            <li>
              a. Modification. Gordian reserves the right to modify these Terms
              of Use, effective upon publishing an updated version on the
              Platform. Gordian shall not be required to notify You of any
              changes made to the Terms of Use. You are requested to regularly
              visit the homepage to view the most current Terms of Use. You can
              determine when Gordian last modified the Terms of Use by referring
              to the &ldquo;Last Updated&rdquo; legend above. It shall be Your
              responsibility to check these Terms of Use periodically for
              changes. Gordian may require You to provide Your consent to the
              updated Terms of Use in a specified manner prior to any further
              use of the Platform. If no such separate consent is sought, Your
              continued use of the Gordian Services, or the Platform will
              constitute Your acceptance of those changes.
            </li>
          </ul>
          <p>&nbsp;</p>
          <ul>
            <li>
              b. Supplemental Terms. Supplemental terms may apply to Your use of
              the Platform or the Gordian Services, such as use policies or
              terms related to certain features and functionality and/or zero
              tolerance/shipping policies, which may be modified by Gordian from
              time to time (&ldquo;Supplemental Terms&rdquo;). You may be
              presented with certain Supplemental Terms from time to time.
              Supplemental Terms are in addition to, and shall be deemed a part
              of,these Term of Use. Supplemental Terms shall prevail over these
              Terms of Use in the eventof a conflict.
            </li>
          </ul>
          <p>&nbsp;</p>
          <ul>
            <li>
              c. Severability.If any provision of these Terms of Use is held to
              be illegal, invalid or unenforceable, in whole or in part, under
              any law, such provision or part thereof shall to that extent be
              deemed not to form part of this Terms of Use but the legality,
              validity and enforceability of the remainder of this Agree shall
              not be affected. In that event, the parties shall replace the
              illegal, invalid or unenforceable (part of the) provision with
              a(part of a) provision that is legal, valid and enforceable and
              that has, to the greatest extent possible, a similar effect as the
              illegal, invalid or unenforceable (part of the)provision, given
              the contents and purpose of these Terms of Use.
            </li>
          </ul>
          <p>&nbsp;</p>
          <ul>
            <li>
              d. Assignment. You shall not assign or transfer these Terms of Use
              or any of its rights or obligations hereunder, in whole or in
              part, without the prior written consent of Gordian. Gordian may
              assign or transfer any or all of its rights or obligations
              hereunder, in whole or in part, under these Terms from time to
              time without consent
            </li>
          </ul>
          <p>&nbsp;</p>
          <ul>
            <li>
              e. Notices. Any notice delivered by Gordian to You under these
              Terms of Use will be deliveredby email to the email address
              associated with Your Account or by posting the same on the
              Platform. Any notice delivered by You to Gordian under these Terms
              of Use will be delivered by contacting Gordian on legal@Gordian.in
              and/or its support representatives.
            </li>
          </ul>
          <p>&nbsp;</p>
          <ul>
            <li>
              f. Governing Law; Arbitration. This Terms of Use shall be governed
              exclusively by laws of India and the Parties expressly submit to
              the exclusive jurisdiction of the courts of Bangalore. In the
              event of any dispute, claim or controversy arising under, or in
              relationto, this Terms of Use (&ldquo;Dispute&rdquo;), such
              Dispute shall be resolved by arbitration inaccordance with the
              Arbitration and Conciliation Act, 1996. The Dispute shall be
              settled by a sole arbitrator, solely appointed by Gordian pursuant
              to the provisions of the Arbitration and Conciliation Act, 1996.
              The seat of arbitration shall be Bengaluru, India and the
              arbitration proceedings shall be governed by the provisions of the
              Arbitration and Conciliation Act, 1996, as amended from time to
              time. All arbitration proceedings shall be conducted in English.
              The arbitration award shall be final and binding on the Parties
              and shall be enforceable in any competent court of law, and the
              Parties agree to be bound thereby and to act accordingly.
            </li>
          </ul>
        </ol>
      </Container>
    </Fragment>
  );
}

export default DeliveryPartnerTerms;
