import React, { useState, useEffect } from "react";
import { Row, Col, Button, Modal, Table, Card } from "react-bootstrap";
import Variables from "./../Globals/variables";
import Logo from "./../assets/images/Logo1.png";
import MapIcon from "./../assets/icons/mapIcon.png";
import Sidebar from "./../Components/Sidebar/sidebar";
import "./../Globals/Fonts.css";
import "./OrderStatus.css";
import "react-confirm-alert/src/react-confirm-alert.css";
import Loader from "react-loader-spinner";
import LiveMap, { StaticGps } from "./../Admin/LiveGps";
import { useHistory } from "react-router-dom";

const BaseURL = Variables.baseUrl;

const LocationCard = ({
  latitude,
  longitude,
  locationUpdated,
  deviceId,
  riderId,
  showMapCb,
  centerDevice,
  validLatitude,
  validLongitude,
  deviceUpdatedAt,
  tookanUpdatedAt,
  device,
}) => {
  const getDevicePosition = () => {
    if (device) {
      return {
        lat: parseFloat(validLatitude),
        lng: parseFloat(validLongitude),
      };
    } else return false;
  };

  const getRiderPosition = () => {
    if (!device) {
      return {
        lat: parseFloat(validLatitude),
        lng: parseFloat(validLongitude),
      };
    } else {
      return false;
    }
  };

  return (
    <Card className="locationCard">
      {latitude && longitude && deviceId ? (
        <>
          lat and long
          <div className="locationCardDetails">
            {validLatitude && parseFloat(validLatitude) !== 0 && (
              <>
                {parseFloat(validLatitude).toFixed(2)} and{" "}
                {parseFloat(validLongitude).toFixed(2)}
                <br />
              </>
            )}
            {locationUpdated && (
              <span>Location updated at {locationUpdated}</span>
            )}
            {validLatitude && parseFloat(validLatitude) !== 0 && (
              <div
                style={{
                  cursor: "pointer",
                  paddingTop: "10px",
                }}
                onClick={() =>
                  showMapCb(
                    riderId,
                    deviceId,
                    device,
                    deviceUpdatedAt,
                    tookanUpdatedAt,
                    getRiderPosition(),
                    getDevicePosition(),
                    true
                  )
                }
              >
                <img
                  src={MapIcon}
                  alt="Map"
                  width="15px"
                  style={{ marginRight: "10px" }}
                ></img>
                See on Map
              </div>
            )}
          </div>
          {parseFloat(latitude) === 0 && <span className="zeroLat">zero</span>}
        </>
      ) : (
        <span className="centerLoader">
          <Loader type="Oval" color="#000000" height={20} width={20} />
        </span>
      )}
    </Card>
  );
};

function OrderStatus() {
  const history = useHistory();
  const [refresh, setRefresh] = useState(false);
  const [refreshedAt, setRefreshedAt] = useState(false);
  const [orders, setOrders] = useState({
    loading: true,
    list: [
      {
        id: undefined,
        order_id: undefined,
        device_id: undefined,
        device_gps: undefined,
        tookan_rider_id: undefined,
        tookan_gps: undefined,
        displacement: undefined,
        alert: undefined,
        deviceUpdatedAt: undefined,
        tookanUpdatedAt: undefined,
        riderName: undefined,
        lastValidDeviceLocation: undefined,
        lastValidTookanLocation: undefined,
      },
    ],
  });
  const [show, setShow] = useState(false);
  const [selection, setSelection] = useState({
    riderId: undefined,
    deviceId: undefined,
    centerDevice: undefined,
    deviceUpdatedAt: undefined,
    tookanUpdatedAt: undefined,
    rider: undefined,
    device: undefined,
    staticGps: false,
  });

  const handleClose = () => setShow(false);
  // const handleShow = () => setShow(true);

  const getCurrentTime = () => {
    const currentDate = new Date();
    return (
      currentDate.getHours() +
      ":" +
      currentDate.getMinutes() +
      ":" +
      currentDate.getSeconds() +
      " on " +
      currentDate.getDate() +
      "/" +
      (currentDate.getMonth() + 1) +
      "/" +
      currentDate.getFullYear()
    );
  };

  useEffect(() => {
    setTimeout(() => setRefresh(!refresh), 20000);

    setRefreshedAt(getCurrentTime());
    return () => clearTimeout();
  }, [refresh]);
  useEffect(
    () => {
      console.log("getOrderDetails hook called");
      getOrderDetails();
    },
    // eslint-disable-next-line
    [refresh]
  );

  useEffect(
    () => {
      console.log("getGpsId hook called");
      getGpsDeviceId();
    },
    // eslint-disable-next-line
    [orders.loading, refresh]
  );
  var interval = 2500;
  async function refreshToken() {
    const refreshToken = localStorage.getItem("refreshToken");
    const data = {
      refreshToken,
    };

    var response = await fetch(`${BaseURL}/users/refresh-token`, {
      method: "POST",
      body: JSON.stringify(data),
      headers: {
        "Content-Type": "application/json",
      },
    });
    response = await response;
    if (response.status === 200 || response.status === 201) {
      response = await response.json();
      localStorage.setItem("refreshToken", response.refreshToken);
      localStorage.setItem("token", response.jwtToken);
    } else {
      localStorage.clear();
      localStorage.clear("All");
      console.log("Session expired. Please login to continue.");
      history.push("/Home");
    }
  }

  async function getOrderDetails() {
    var url = `${BaseURL}/admin/getOrderList`;
    let results = await fetch(url, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: `bearer ${localStorage.getItem("token")}`,
        Accept: "application/json",
      },
    });
    if (results.status === 200 || results.status === 201) {
      results = await results.json();
      setOrder(results.data);
      return results;
    } else if (results.status === 401) {
      await refreshToken();
      results = await fetch(url, {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Authorization: `bearer ${localStorage.getItem("token")}`,
        },
      });
      if (results.status === 200 || results.status === 201) {
        results = await results.json();
        if (results.data.length === 0) {
          console.log("There are no order to show.");
        } else {
          setOrder(results.data);
        }
        return results;
      } else {
        console.log("Bad Request");
      }
    } else {
      console.log("Bad Request");
    }
  }

  function setOrder(result) {
    const newList = result.map((value, index) => {
      const existingOrders = orders.list.map((order) => order.order_id);

      if (existingOrders.includes(value.orderId)) {
        return orders.list.find((order) => order.order_id === value.orderId);
      }

      return {
        id: index + 1,
        order_id: value.orderId,
        device_gps: { latitude: undefined, longitude: undefined },
        tookan_gps: { latitude: undefined, longitude: undefined },
        displacement: undefined,
        alert: undefined,
        status: 0,
        lastValidDeviceLocation: { latitude: undefined, longitude: undefined },
        lastValidTookanLocation: { latitude: undefined, longitude: undefined },
      };
    });
    setOrders({
      loading: false,
      list: newList,
    });
  }
  const wait = () =>
    new Promise((resolve, reject) => {
      setTimeout(() => {
        resolve("Your transaction is successful");
      }, interval); //second argument is in milliseconds
    });
  // async function getRiderAndDeviceId(order_id){
  //   await transactionStatus().then(async()=>{
  //     await getId(order_id)
  //   })
  // }

  async function getRiderAndDeviceId(order_id) {
    var url = `${BaseURL}/admin/getRiderAndDeviceId?orderId=${order_id}`;
    let results = await fetch(url, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: `bearer ${localStorage.getItem("token")}`,
        Accept: "application/json",
      },
    });
    if (results.status === 200 || results.status === 201) {
      results = await results.json();
      setGpsDeviceId(
        order_id,
        results.data.boxId,
        results.data.riderId,
        results.data.riderName
      );
      await wait()
        .then(async () => {
          await getDeviceGps(order_id, results.data.boxId);
        })
        .then(async () => {
          await getRiderGps(order_id, results.data.riderId);
        });
      return results;
    } else if (results.status === 401) {
      await refreshToken();
      results = await fetch(url, {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Authorization: `bearer ${localStorage.getItem("token")}`,
        },
      });
      if (results.status === 200 || results.status === 201) {
        results = await results.json();
        if (results.data.length === 0) {
          console.log("Rider/Device not found.");
        } else {
          setGpsDeviceId(
            order_id,
            results.data.boxId,
            results.data.riderId,
            results.data.riderName
          );
          await wait()
            .then(async () => {
              await getDeviceGps(order_id, results.data.boxId);
            })
            .then(async () => {
              await getRiderGps(order_id, results.data.riderId);
            });
        }
        return results;
      } else {
        console.log("Bad request");
      }
    } else if (results.status === 422) {
      results = await results.json();
      console.log(results.message);
    } else {
      console.log("Bad request");
    }
  }
  async function getRiderGps(order_id, riderId) {
    if (!riderId) {
      return;
    }
    var url = `${BaseURL}/admin/getRiderLocation?riderId=${riderId}`;
    let results = await fetch(url, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: `bearer ${localStorage.getItem("token")}`,
        Accept: "application/json",
      },
    });
    if (results.status === 200 || results.status === 201) {
      results = await results.json();
      if (results.data[0].length !== 0) {
        console.log("Rider update", riderId);
      }
      setTookanGps(order_id, results.data[0]);
      return results;
    } else if (results.status === 401) {
      await refreshToken();
      results = await fetch(url, {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Authorization: `bearer ${localStorage.getItem("token")}`,
        },
      });
      results = await results;
      if (results.status === 200 || results.status === 201) {
        results = await results.json();
        if (results.data.length === 0) {
          console.log("No Rider gps data");
        } else {
          console.log("Rider update", riderId);
        }
        setTookanGps(order_id, results.data[0]);
        return results;
      } else {
        console.log("Bad request");
      }
    } else {
      console.log("Bad request");
    }
  }
  async function getDeviceGps(order_id, deviceId) {
    if (!deviceId) {
      return;
    }
    var url = `${BaseURL}/admin/getDeviceLocation?deviceId=${deviceId}`;
    let results = await fetch(url, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: `bearer ${localStorage.getItem("token")}`,
        Accept: "application/json",
      },
    });
    if (results.status === 200 || results.status === 201) {
      results = await results.json();
      if (results.data) {
        console.log("Device update", deviceId);
      }
      setDeviceGps(order_id, results.data);
      return results;
    } else if (results.status === 401) {
      await refreshToken();
      results = await fetch(url, {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Authorization: `bearer ${localStorage.getItem("token")}`,
        },
      });
      if (results.status === 200 || results.status === 201) {
        results = await results.json();
        if (results.data.length === 0) {
          console.log("No device gps data");
        } else {
          console.log("Device update", deviceId);
        }
        setDeviceGps(order_id, results.data);
        return results;
      } else {
        console.log("Bad request");
      }
    } else {
      console.log("Bad request");
    }
  }

  async function getGpsDeviceId() {
    if (orders.list.length > 0 && orders.list[0].id !== undefined) {
      orders.list.forEach(async (item) => {
        await getRiderAndDeviceId(item.order_id);
        setOrders((prevState) => ({
          ...prevState,
          list: prevState.list.map((el) =>
            el.order_id === item.order_id
              ? {
                  ...el,
                  displacement: getDisplacement(
                    el.device_gps.latitude,
                    el.device_gps.longitude,
                    el.tookan_gps.latitude,
                    el.tookan_gps.longitude
                  ),
                }
              : el
          ),
        }));
      });
    }
  }

  function setGpsDeviceId(order_id, deviceId, riderId, riderName) {
    // const newList = orders.list.map((item) => {
    //   if (item.order_id === order_id) {
    //     return {
    //       ...item,
    //       device_id: deviceId,
    //       tookan_rider_id: riderId,
    //     };
    //   }
    //   return item;
    // });
    // setOrders({
    //   loading: false,
    //   list: newList,
    // });
    setOrders((prevState) => ({
      ...prevState,
      list: prevState.list.map((el) =>
        el.order_id === order_id
          ? {
              ...el,
              device_id: deviceId,
              tookan_rider_id: riderId,
              riderName: riderName,
            }
          : el
      ),
    }));
  }
  function setDeviceGps(order_id, gps) {
    setOrders((prevState) => ({
      ...prevState,
      list: prevState.list.map((el) =>
        el.order_id === order_id
          ? {
              ...el,
              device_gps: { latitude: gps.latitude, longitude: gps.longitude },
              deviceUpdatedAt:
                gps.latitude !== "0.000000"
                  ? gps.lastUpdated
                  : el.deviceUpdatedAt,
              lastValidDeviceLocation:
                gps.latitude !== "0.000000"
                  ? { latitude: gps.latitude, longitude: gps.longitude }
                  : el.lastValidDeviceLocation,
            }
          : el
      ),
    }));
  }
  function setTookanGps(order_id, gps) {
    setOrders((prevState) => ({
      ...prevState,
      list: prevState.list.map((el) =>
        el.order_id === order_id
          ? {
              ...el,
              tookan_gps: { latitude: gps.latitude, longitude: gps.longitude },
              tookanUpdatedAt:
                gps.latitude !== "0.000000"
                  ? gps.lastUpdated
                  : el.tookanUpdatedAt,
              lastValidTookanLocation:
                gps.latitude !== "0.000000"
                  ? { latitude: gps.latitude, longitude: gps.longitude }
                  : el.lastValidTookanLocation,
            }
          : el
      ),
    }));
  }
  function getDisplacement(lat1, lon1, lat2, lon2) {
    if (
      parseFloat(lat1) === 0 ||
      parseFloat(lon1) === 0 ||
      parseFloat(lat2) === 0 ||
      parseFloat(lon2) === 0
    ) {
      return "Latitude/Longitude is 0";
    }
    const displacement = getDistanceFromLatLonInKm(lat1, lon1, lat2, lon2);
    if (isNaN(displacement)) {
      return "Displacement cannot be measured";
    }
    return displacement.toFixed(2) + " km";
  }
  function getDistanceFromLatLonInKm(lat1, lon1, lat2, lon2) {
    var R = 6371; // Radius of the earth in km
    var dLat = deg2rad(lat2 - lat1); // deg2rad below
    var dLon = deg2rad(lon2 - lon1);
    var a =
      Math.sin(dLat / 2) * Math.sin(dLat / 2) +
      Math.cos(deg2rad(lat1)) *
        Math.cos(deg2rad(lat2)) *
        Math.sin(dLon / 2) *
        Math.sin(dLon / 2);
    var c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a));
    var d = R * c; // Distance in km
    return d;
  }
  function deg2rad(deg) {
    return deg * (Math.PI / 180);
  }
  function showLiveMap(
    riderId,
    deviceId,
    centerDevice,
    deviceUpdatedAt,
    tookanUpdatedAt,
    rider = undefined,
    device = undefined,
    staticGps = false
  ) {
    setSelection({
      riderId: riderId,
      deviceId: deviceId,
      centerDevice: centerDevice,
      deviceUpdatedAt: deviceUpdatedAt,
      tookanUpdatedAt: tookanUpdatedAt,
      rider: rider,
      device: device,
      staticGps: staticGps,
    });
    setShow(true);
  }
  function renderLiveMap() {
    return (
      <Modal
        show={show}
        onHide={handleClose}
        animation={false}
        dialogClassName="modalLive"
      >
        <Modal.Header closeButton>
          Live
          <div style={{ width: "100%" }}>
            {selection.deviceUpdatedAt && (
              <span style={{ display: "block", textAlign: "right" }}>
                Device updated at {selection.deviceUpdatedAt.split(",")[1]}
              </span>
            )}
            {selection.tookanUpdatedAt && (
              <span style={{ display: "block", textAlign: "right" }}>
                Tookan updated at {selection.tookanUpdatedAt.split(",")[1]}
              </span>
            )}
          </div>
        </Modal.Header>

        <Modal.Body>
          {!selection.staticGps && (
            <LiveMap
              riderId={selection.riderId}
              deviceId={selection.deviceId}
              centerDevice={selection.centerDevice}
            ></LiveMap>
          )}
          {selection.rider && (
            <StaticGps
              rider={selection.rider}
              centerDevice={selection.centerDevice}
            ></StaticGps>
          )}
          {selection.device && (
            <StaticGps
              device={selection.device}
              centerDevice={selection.centerDevice}
            ></StaticGps>
          )}
        </Modal.Body>
      </Modal>
    );
  }

  console.log(orders);
  const renderOrders = () => {
    return orders.list.map((item) => {
      return (
        <tr key={item.id}>
          <td>{item.id}</td>
          <td>{item.order_id}</td>
          {item.device_id && item.riderName ? (
            <td>
              DeviceId: {item.device_id}
              <br />
              Rider: {item.riderName}
            </td>
          ) : (
            <td>
              <span className="centerLoader">
                <Loader type="Oval" color="#000000" height={20} width={20} />
              </span>
            </td>
          )}

          <td>
            <LocationCard
              latitude={item.device_gps?.latitude}
              longitude={item.device_gps?.longitude}
              validLatitude={item.lastValidDeviceLocation?.latitude}
              validLongitude={item.lastValidDeviceLocation?.longitude}
              locationUpdated={item.deviceUpdatedAt}
              riderId={item.tookan_rider_id}
              deviceId={item.device_id}
              showMapCb={showLiveMap}
              centerDevice={true}
              deviceUpdatedAt={item.deviceUpdatedAt}
              tookanUpdatedAt={item.tookanUpdatedAt}
              device={true}
            ></LocationCard>
          </td>

          <td>
            <LocationCard
              latitude={item.tookan_gps?.latitude}
              longitude={item.tookan_gps?.longitude}
              validLatitude={item.lastValidTookanLocation?.latitude}
              validLongitude={item.lastValidTookanLocation?.longitude}
              locationUpdated={item.tookanUpdatedAt}
              riderId={item.tookan_rider_id}
              deviceId={item.device_id}
              showMapCb={showLiveMap}
              deviceUpdatedAt={item.deviceUpdatedAt}
              tookanUpdatedAt={item.tookanUpdatedAt}
              device={false}
            ></LocationCard>
          </td>

          <td>
            {item.displacement ? (
              item.displacement
            ) : (
              <span className="centerLoader">
                <Loader type="Oval" color="#000000" height={20} width={20} />
              </span>
            )}
          </td>
          <td className="text-center">
            <Button
              variant="secondary"
              onClick={() =>
                showLiveMap(
                  item.tookan_rider_id,
                  item.device_id,
                  false,
                  item.deviceUpdatedAt,
                  item.tookanUpdatedAt
                )
              }
            >
              View
            </Button>
          </td>
          {/* <td>View</td> */}
          {/* <td>{item.alert}</td> */}
        </tr>
      );
    });
  };
  const renderHeader = () => {
    return (
      <tr key={0}>
        <th>S.No.</th>
        <th>OrderId</th>
        <th>DeviceId</th>
        <th>Device Gps</th>
        <th>Tookan Gps</th>
        <th>Displacement</th>
        <th className="text-center">Live Location</th>
        {/* <th>Route map</th>
        <th>Alert</th> */}
      </tr>
    );
  };

  const renderTable = () => {
    return (
      <>
        {refreshedAt && (
          <div
            style={{ width: "100%", textAlign: "right", marginBottom: "10px" }}
          >
            <span>Last updated at {refreshedAt}</span>
          </div>
        )}

        <Table striped bordered hover>
          <thead>{renderHeader()}</thead>
          <tbody>
            {renderOrders()}
            {renderLiveMap()}
          </tbody>
        </Table>
      </>
    );
  };

  return (
    <Row className="m-0">
      <Col
        lg={2}
        md={2}
        sm={2}
        className="text-center pt-5"
        style={{ backgroundColor: "white", height: "100vh" }}
      >
        <Row
          className="gordian-logo"
          style={{ justifyContent: "center", alignItem: "center" }}
        >
          <img src={Logo} alt="Logo" className="logo" />
        </Row>
        <h1
          style={{
            fontSize: "1.56rem",
            textAlign: "center",
            fontFamily: "Manrope-Bold",
          }}
        >
          Gordian
        </h1>
        <Sidebar />
      </Col>
      <Col lg={10} md={10} sm={10} style={{ backgroundColor: "white" }}>
        <Col lg={12} md={12} sm={12}>
          <h1
            className="name"
            style={{
              fontSize: "20px",
              fontWeight: "600",
              marginTop: "1.25rem",
              fontFamily: "Manrope-SemiBold",
            }}
          >
            Live Gps Tracking of Orders
          </h1>
          <hr />
          {orders.loading ? (
            <Row
              style={{
                justifyContent: "center",
                alignItem: "center",
                marginTop: "100px",
              }}
            >
              <Loader type="Oval" color="#000000" height={40} width={40} />
            </Row>
          ) : (
            <Row>{renderTable()}</Row>
          )}
        </Col>
      </Col>
    </Row>
  );
}

export default OrderStatus;
