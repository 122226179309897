import React, { useState } from 'react';
import { Row, Col, Container } from "reactstrap";
import { Link, useHistory } from "react-router-dom"
import Slider from "./Slider";
import BackIcon from "./../assets/images/back.png";
import ChatIcon from "./../assets/images/chat.png";
import Card from 'react-bootstrap/Card';
import Variables from "../Globals/variables";
import "./../Globals/Fonts.css"

const BaseURL = Variables.baseUrl;

function ForgotPassword(props) {

    const initError = {
        emailError: ""
    };

    const [state, setState] = useState({
        email: "",
        btnValue: "Reset Password"
    });
    const history = useHistory();

    async function resetPassword() {
        setState({
            ...state,
            btnValue: "Resetting Password...",
        });
        const item = { email: state.email }

        let result = await fetch(`${BaseURL}/users/reset-password`, {
            method: 'POST',
            body: JSON.stringify(item),
            headers: {
                "Content-Type": 'application/json',
                "Accept": 'application/json'
            }
        })
        result = await result.json()
        // console.warn("result", result)
        localStorage.setItem("info", JSON.stringify(result));
        if (result.message === "Failed to send password reset link") {
            alert("Your Email ID isn't registered. Please sign up.")
            setState({
                ...state,
                btnValue: "Reset Password",
            });

        }
        else if (result.message === "check your email") {
            alert("An email has been sent to your registered email address with a link to reset your password.")
            setState({
                ...state,
                btnValue: "Reset Password",
            });
            history.push("/Signin")
        }

    }

    var [error] = useState(initError);

    const isValid = () => {
        error = initError;
        const re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

        if (!re.test(state.email)) {
            error.emailError = "Invalid Email address";
        }

        if (
            error.emailError.length > 0
        ) {
            return false;
        } else {
            return true;
        }
    };

    const ValidSubmission = () => {
        if (isValid()) {
            return (
                <button onClick={resetPassword}
                    type="button"
                    className="btn btn-dark rounded-pill button mb-3"
                >
                    {state.btnValue}
                </button>
            );
        } else {
            return (
                <button
                    disabled
                    type="button"
                    className="btn btn-dark rounded-pill button mb-3"
                >
                    {state.btnValue}
                </button>
            );
        }
    };


    return (
        <Row style={{ marginBottom: 0, marginTop: 0, backgroundColor: '#F5F6FA' }}>
            <Col lg={7} md={7} sm={7} >
                <Slider />
            </Col>

            <Col lg={5} md={5} sm={7} >
                <Row style={{ marginTop: "15px", marginLeft: "5px", marginRight: "30px", justifyContent: "space-between", alignItem: "space-between" }}>
                    {/* <Link to="/Signin" style={{ textDecoration: 'none' }} > */}
                    <Card style={{ width: '50px', height: "50px", borderRadius: "30px", backgroundColor: "#F5F6FA", marginLeft: "12px", border: "none", boxShadow: "5px 5px 11px 5px #0000000D" }} onClick={() => props.history.goBack()}>
                        <Card.Body>
                            <img style={{ height: "30px", width: "27px", marginTop: "-15px", marginLeft: "-11px" }} src={BackIcon} alt="backicon" />
                        </Card.Body>
                    </Card>
                    {/* </Link> */}

                    <Link to="/ContactUs" style={{ textDecoration: "none" }}>
                        <Card style={{ width: '50px', height: "50px", borderRadius: "30px", backgroundColor: "#F5F6FA", border: "none", boxShadow: "5px 5px 11px 5px #0000000D" }}>
                            <Card.Body>
                                <img style={{ height: "30px", width: "30px", marginTop: "-18px", marginLeft: "-11px" }} src={ChatIcon} alt="chaticon" />
                            </Card.Body>
                        </Card>
                    </Link>
                </Row>
                <Container style={{ marginTop: "80px" }}>
                    <h1 className="enter-details" style={{ fontSize: "25px", fontFamily: "Manrope-ExtraBold", marginLeft: "70px", marginBottom: "20px", color: "#000000" }}>Forgot Password</h1>
                    <Row style={{ justifyContent: "center", alignItem: "center" }}>
                        <Col lg={9} >
                            <label style={{ fontSize: "16px", fontFamily: "Manrope-Bold", marginLeft: "15px", color: "#000000" }}>Email Address</label>
                            <input
                                type="email"
                                className="fields"
                                name="email"
                                value={state.email}
                                onChange={(e) => setState({ ...state, email: e.target.value })}
                                required
                            />
                        </Col>
                    </Row>
                    <Row style={{ justifyContent: "center", alignItem: "center" }} className="buttons">
                        <Col lg={9} md={12} sm={12} >
                            {ValidSubmission()}
                            {/* <button className="button" >Send Email</button> */}
                        </Col>
                    </Row>
                    <Row style={{ alignItem: "center", justifyContent: "center" }}>
                        <h1 className="goto-text" style={{ color: "#92979D", fontSize: "14px", fontFamily: "Manrope-Bold", position: "fixed", bottom: "40px", }}>Go to www.Gordian.in</h1>
                        <Link to="/ContactUs" style={{ textDecoration: "none", marginLeft: "-65px" }}>
                            <h1 className="contact-text" style={{ color: "#000000", fontSize: "14px", fontFamily: "Manrope-Bold", position: "fixed", bottom: "15px", }}>Contact Us</h1>
                        </Link>
                    </Row>
                </Container>
            </Col>
        </Row>
    )
}

export default ForgotPassword