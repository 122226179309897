import React, { useState } from 'react';
import VisibilityIcon from '@material-ui/icons/Visibility';
import VisibilityOffIcon from '@material-ui/icons/VisibilityOff';

const usePasswordToggle = () => {
    const [visible, setVisibility] = useState(false);

    const Icon = (
        visible ? <VisibilityOffIcon style={{ fontSize: 35 }} onClick={() => setVisibility(visiblity => !visiblity)} /> : <VisibilityIcon style={{ fontSize: 35 }} onClick={() => setVisibility(visiblity => !visiblity)} />
    );

    const InputType = visible ? "text" : "password";

    return [InputType, Icon];


}

export default usePasswordToggle
