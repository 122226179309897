import React, { Fragment, useState } from "react";
import { Row, Col, Container } from "reactstrap";
import { Link } from "react-router-dom";
import ProgressBar from "../Components/ProgressBar";
import BackIcon from "./../assets/images/back.png";
import "./styles.signuplogin.css";
import Card from 'react-bootstrap/Card';
import "./../Globals/Fonts.css";
import usePasswordToggle from "./../Components/usePasswordToggle";
import useConfirmpasswordToggle from "./../Components/useConfirmpassToggle";

const MobileSignupPage2 = (props) => {

    const initError = {
        emailError: "",
        passwordError: "",
        confirmpasswordError: "",
    };

    const [state, setState] = useState({
        email: "",
        password: "",
        confirmpassword: "",
    });

    const [PasswordInputType, ToggleIcon] = usePasswordToggle();
    const [PasswordInputType1, ToggleIcon1] = useConfirmpasswordToggle();

    var [error] = useState(initError);

    const isValid = () => {
        error = initError;
        const re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

        if (!re.test(state.email)) {
            error.emailError = "Invalid Email address";
        }
        if (state.password.length === 0) {
            error.passwordError = "Password is required";
        }
        if (state.confirmpassword.length === 0) {
            error.confirmpasswordError = "Confrim Password is required";
        }
        if (state.confirmpassword.length < state.password.length) {
            error.confirmpasswordError = "Confirm Password is required";
        }

        if (
            error.emailError.length > 0 ||
            error.passwordError.length > 0 ||
            error.confirmpasswordError.length > 0 ||
            error.passwordError !== error.confirmpasswordError
        ) {
            return false;
        } else {
            return true;
        }
    };

    const ValidSubmission = () => {
        if (isValid()) {
            if (state.password !== state.confirmpassword) {
                alert("Passwords don't match.")
                return (
                    <button
                        disabled
                        type="button"
                        className="btn btn-dark rounded-pill button1 mb-3"
                    >
                        Next
                    </button>
                );
            } else
                return (
                    <Link
                        to={{
                            pathname: "/mobilesignup2",
                            state,
                        }}
                        type="button"
                        className="btn btn-dark rounded-pill button1 mb-3"
                    >
                        Next
                    </Link>
                );
        } else {
            return (
                <button
                    disabled
                    type="button"
                    className="btn btn-dark rounded-pill button1 mb-3"
                >
                    Next
                </button>
            );
        }
    };

    return (
        <Fragment>
            <Container>
                <Row style={{ marginTop: "15px", marginLeft: "5px", justifyContent: "space-between", alignItem: "space-between", marginRight: "5px" }}>
                    <Link to="/mobilesignup" style={{ textDecoration: 'none' }} >
                        <Card style={{ width: '50px', height: "50px", borderRadius: "30px", backgroundColor: "#F5F6FA", border: "none", boxShadow: "5px 5px 11px 5px #0000000D" }}>
                            <Card.Body>
                                <img style={{ height: "30px", width: "27px", marginTop: "-15px", marginLeft: "-11px" }} src={BackIcon} alt="backicon" />
                            </Card.Body>
                        </Card>
                    </Link>
                    <div style={{ marginTop: "12px" }}>
                        <ProgressBar width="50%" steps="Step 1/2" />
                    </div>
                </Row>
                <div style={{ marginTop: "30px", marginLeft: "20px" }}>
                    <h1 style={{ fontSize: "32px", fontFamily: "Manrope-ExtraBold", color: "#000000", letterSpacing: "-1px" }} >Sign up with</h1>
                    <h1 style={{ fontSize: "32px", fontFamily: "Manrope-ExtraBold", color: "#000000", letterSpacing: "-1px" }}>Email Address</h1>
                </div>

                <Row >
                    <Col>
                        <label style={{ marginTop: "10px", fontSize: "16px", fontFamily: "Manrope-Bold", marginLeft: "15px" }}>Email Address</label>
                        <input
                            type="email"
                            className="field"
                            value={state.email}
                            onChange={(e) => setState({ ...state, email: e.target.value })}
                            required
                        />
                        <br />
                    </Col>
                </Row>
                <Row>
                    <Col>
                        <label style={{ fontSize: "16px", fontFamily: "Manrope-Bold", marginLeft: "15px" }}>Password</label>
                        <input
                            type={PasswordInputType}
                            className="field"
                            value={state.password}
                            onChange={(e) => setState({ ...state, password: e.target.value })}
                            required
                        />
                        <span style={{ position: "absolute", cursor: "pointer", right: "20px" }}>{ToggleIcon}</span>
                        <br />
                    </Col>
                </Row>
                <Row>
                    <Col>
                        <label style={{ fontSize: "16px", fontFamily: "Manrope-Bold", marginLeft: "15px" }}>Confirm Password</label>
                        <input
                            type={PasswordInputType1}
                            className="field"
                            value={state.confirmpassword}
                            onChange={(e) => setState({ ...state, confirmpassword: e.target.value })}
                            required
                        />
                        <span style={{ position: "absolute", cursor: "pointer", right: "20px" }}>{ToggleIcon1}</span>
                        <br />
                    </Col>
                </Row>
                {/* <div>
                    <input
                        value={state.checked}
                        onChange={(e) => Termschange(e)}
                        type="checkbox"
                    />
          &ensp;
          <label style={{ fontSize: "15px" }}>
                        I agree to the &nbsp;
            <b>
                            <Link
                                to="/terms"
                                style={{ color: "black", textDecoration: "none" }}
                            >
                                terms and conditions
              </Link>
                        </b>
                    </label>
                </div> */}
                <Row style={{ justifyContent: "center", alignItem: "center" }}>{ValidSubmission()}</Row>

                <div>
                    <h1
                        style={{
                            textAlign: "center",
                            color: "#92979D",
                            fontSize: "12px",
                            fontFamily: "Manrope-SemiBold",
                        }}
                    >
                        Already have an Account?
            </h1>
                    <Link to="/mobilesignin">
                        <h1
                            style={{
                                textAlign: "center",
                                fontFamily: "Manrope-Bold",
                                color: "#000000",
                                fontSize: "17px",
                            }}
                        >
                            Sign In
              </h1>
                    </Link>
                </div>

                <h1 style={{ color: "#92979D", fontSize: "12px", fontFamily: "Manrope-SemiBold", textAlign: "center", marginTop: "60px" }}>For any difficulties</h1>
                <Link to="/ContactUs">
                    <h1 style={{ fontFamily: "Manrope-Bold", color: "#000000", fontSize: "15px", textAlign: "center" }}>Contact Us</h1>
                </Link>
            </Container>
        </Fragment>
    );
};

export default MobileSignupPage2;
