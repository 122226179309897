import React, { useState } from "react";
import axios from "axios";
import Variables from "../../Globals/variables";
import { Link, useHistory } from "react-router-dom";
import { Row, Col } from "reactstrap";
import "./../../Globals/Fonts.css";
import usePasswordToggle from "../usePasswordToggle";
import useConfirmpasswordToggle from "../useConfirmpassToggle";

const BaseUrl = Variables.baseUrl;

const MobilePass = (props) => {
  const [password, setPassword] = useState("");
  const [confirmPass, setConfirmpass] = useState("");
  const history = useHistory();

  const [PasswordInputType, ToggleIcon] = usePasswordToggle();
  const [PasswordInputType1, ToggleIcon1] = useConfirmpasswordToggle();

  const [state, setState] = useState({
    btnValue: "Change Password"
  });

  const submit = (e) => {
    setState({
      ...state,
      btnValue: "Changing Password..."
    })
    e.preventDefault();

    if (password === confirmPass) {
      const payload = {
        token: props.match.params.token,
        newPassword: password,
      };

      axios({
        url: `${BaseUrl}/users/new-password`,
        data: payload,
        method: "POST",
      })
        .then((res) => {
          console.log(res.data.message);
          alert("Password updated successfully. You can login now.");
          setState({
            ...state,
            btnValue: "Change Password"
          })
          history.push("/Signin")
        })
        .catch((error) => {
          alert("Something went wrong. Please try again.");
          setState({
            ...state,
            btnValue: "Change Password"
          })
          console.log("Error, ", error);
        });
    } else {
      alert("Passwords don't match.");
      setState({
        ...state,
        btnValue: "Change Password"
      })
    }
  };

  return (
    <>
      <Row className="m-0">
        <Col
          lg={10}
          className="Form"
          style={{ marginTop: "7%", width: "100%" }}
        >
          <h3 className=" mt-3 " style={{ fontFamily: "Manrope-ExtraBold", fontSize: "32px", }}>Reset password</h3>
          <form onSubmit={submit}>
            <Row className="row text-center">
              <Col lg={{ size: 6, offset: 3 }}>
                <label style={{ fontSize: "16px", fontFamily: "Manrope-Bold", marginLeft: "15px" }}> Password</label>
                <input
                  className="field"
                  type={PasswordInputType}
                  value={password}
                  onChange={(e) => setPassword(e.target.value)}
                  required
                />
                <span style={{ position: "absolute", cursor: "pointer", right: "20px" }}>{ToggleIcon}</span>
              </Col>
            </Row>
            <Row className="row">
              <Col lg={{ size: 6, offset: 3 }}>
                <label style={{ fontSize: "16px", fontFamily: "Manrope-Bold", marginLeft: "15px" }}>Confirm Password</label>
                <input
                  type={PasswordInputType1}
                  className="field"
                  value={confirmPass}
                  onChange={(e) => setConfirmpass(e.target.value)}
                  required
                />
                <span style={{ position: "absolute", cursor: "pointer", right: "20px" }}>{ToggleIcon1}</span>
              </Col>
            </Row>
            <button className="button" type="submit" style={{ marginLeft: "90px" }}>
              {state.btnValue}
            </button>
          </form>
        </Col>
      </Row>
      <h1 style={{ color: "#92979D", fontSize: "12px", fontFamily: "Manrope-SemiBold", textAlign: "center", marginTop: "230px" }}>For any difficulties</h1>
      <Link to="/ContactUs">
        <h1 style={{ fontFamily: "Manrope-Bold", color: "#000000", fontSize: "15px", textAlign: "center" }}>Contact Us</h1>
      </Link>
    </>
  );
};

export default MobilePass;
