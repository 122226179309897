import React, { useState, useEffect } from "react";
import { Row, Col } from "reactstrap";

import Variables from "./../Globals/variables";
import "./../Globals/Fonts.css";
import "react-confirm-alert/src/react-confirm-alert.css";
import { useHistory } from "react-router-dom";
import { GoogleMap, useJsApiLoader, Marker } from "@react-google-maps/api";

import LogoRider from "../assets/images/Rider.png";
import LogoDevice from "../assets/images/Device.png";
import Loader from "react-loader-spinner";

const BaseURL = Variables.baseUrl;
const GoogleApiKey = Variables.GOOGLE_API_KEY;

// const center = {
//   lat: 12.9,
//   lng: 77.5,
// };
const mapContainerStyle = {
  height: "80vh",
  width: "100%",
};

const LiveGps = (props) => {
  const { isLoaded } = useJsApiLoader({
    id: "google-map-script",
    googleMapsApiKey: GoogleApiKey,
  });

  const [rider, setRider] = useState({
    id: props.riderId,
    position: { lat: 0, lng: 0 },
  });
  const [device, setDevice] = useState({
    id: props.deviceId,
    position: { lat: 0, lng: 0 },
  });

  const history = useHistory();

  const MINUTE_MS = 10000;

  useEffect(() => {
    updateLocation();
    const interval = setInterval(() => {
      console.log("Auto exec event - ", new Date());
      updateLocation();
    }, MINUTE_MS);

    return () => clearInterval(interval); // This represents the unmount function, in which you need to clear your interval to prevent memory leaks.
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  async function getRiderGps() {
    const riderId = rider.id;
    if (!riderId) {
      return;
    }
    var url = `${BaseURL}/admin/getRiderLocation?riderId=${riderId}`;
    let results = await fetch(url, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: `bearer ${localStorage.getItem("token")}`,
        Accept: "application/json",
      },
    });
    if (results.status === 200 || results.status === 201) {
      results = await results.json();
      if (results.data[0].length !== 0) {
        setRider({
          id: riderId,
          position: {
            lat: parseFloat(results.data[0].latitude),
            lng: parseFloat(results.data[0].longitude),
          },
        });
        console.log("Rider update", rider);
      }
      return results;
    } else if (results.status === 401) {
      await refreshToken();
      results = await fetch(url, {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Authorization: `bearer ${localStorage.getItem("token")}`,
        },
      });
      results = await results;
      if (results.status === 200 || results.status === 201) {
        results = await results.json();
        if (results.data.length === 0) {
          console.log("No Rider gps data");
        } else {
          setRider({
            id: riderId,
            position: {
              lat: parseFloat(results.data[0].latitude),
              lng: parseFloat(results.data[0].longitude),
            },
          });
          console.log("Rider update", rider);
        }
        return results;
      } else {
        console.log("Bad request");
      }
    } else {
      console.log("Bad request");
    }
  }
  async function getDeviceGps() {
    const deviceId = device.id;
    if (!deviceId) {
      return;
    }
    var url = `${BaseURL}/admin/getDeviceLocation?deviceId=${deviceId}`;
    let results = await fetch(url, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: `bearer ${localStorage.getItem("token")}`,
        Accept: "application/json",
      },
    });
    results = await results;
    if (results.status === 200 || results.status === 201) {
      results = await results.json();
      if (results.data) {
        setDevice({
          id: deviceId,
          position: {
            lat: parseFloat(results.data.latitude),
            lng: parseFloat(results.data.longitude),
          },
        });
        console.log("Device update", device);
      }
      return results;
    } else if (results.status === 401) {
      await refreshToken();
      results = await fetch(url, {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Authorization: `bearer ${localStorage.getItem("token")}`,
        },
      });
      if (results.status === 200 || results.status === 201) {
        results = await results.json();
        if (results.data.length === 0) {
          console.log("No device gps data");
        } else {
          setDevice({
            id: deviceId,
            position: {
              lat: parseFloat(results.data.latitude),
              lng: parseFloat(results.data.longitude),
            },
          });
          console.log("Device update", device);
        }
        return results;
      } else {
        console.log("Bad request");
      }
    } else {
      console.log("Bad request");
    }
  }
  const updateLocation = async () => {
    await getDeviceGps();
    await getRiderGps();
  };

  async function refreshToken() {
    const refreshToken = localStorage.getItem("refreshToken");
    const data = {
      refreshToken,
    };

    var response = await fetch(`${BaseURL}/users/refresh-token`, {
      method: "POST",
      body: JSON.stringify(data),
      headers: {
        "Content-Type": "application/json",
      },
    });
    if (response.status === 200 || response.status === 201) {
      response = await response.json();
      localStorage.setItem("refreshToken", response.refreshToken);
      localStorage.setItem("token", response.jwtToken);
    } else {
      localStorage.clear();
      localStorage.clear("All");
      console.log("Session expired. Please login to continue.");
      history.push("/Home");
    }
  }

  const riderPositionChanged = (e) => {
    console.log("Rider Position changed event", rider);
  };
  const devicePositionChanged = (e) => {
    console.log("Device Position changed event", device);
  };

  return (
    <Row className="m-0">
      <Col lg={12} md={12} sm={12} style={{ backgroundColor: "white" }}>
        <Col lg={12} md={12} sm={12}>
          <Row>
            {isLoaded && rider.position.lat ? (
              <GoogleMap
                id="gpsTrackerMap"
                mapContainerStyle={mapContainerStyle}
                center={props.centerDevice ? device.position : rider.position}
                zoom={15}
                options={{ streetViewControl: false }}
              >
                <Marker
                  icon={LogoRider}
                  position={rider.position}
                  id="markerRider"
                  onPositionChanged={riderPositionChanged}
                />
                <Marker
                  id="markerDevice"
                  icon={LogoDevice}
                  position={device.position}
                  onPositionChanged={devicePositionChanged}
                />
              </GoogleMap>
            ) : (
              <Row style={{ height: "75vh" }}>
                <Loader
                  style={{
                    position: "absolute",
                    left: "50%",
                    top: "50%",
                    transform: "translate(-50%,-50%)",
                  }}
                  type="Oval"
                  color="#000000"
                  height={40}
                  width={40}
                />
              </Row>
            )}
          </Row>
        </Col>
      </Col>
    </Row>
  );
};

export const StaticGps = ({ rider, device, centerDevice }) => {
  console.log({
    rider,
    device,
    centerDevice,
  });
  const { isLoaded } = useJsApiLoader({
    id: "google-map-script",
    googleMapsApiKey: GoogleApiKey,
  });
  return (
    <Row className="m-0">
      <Col lg={12} md={12} sm={12} style={{ backgroundColor: "white" }}>
        <Col lg={12} md={12} sm={12}>
          <Row>
            {isLoaded && (rider?.lat || device?.lat) ? (
              <GoogleMap
                id="gpsTrackerMap"
                mapContainerStyle={mapContainerStyle}
                center={centerDevice ? device : rider}
                zoom={15}
                options={{ streetViewControl: false }}
              >
                {rider && (
                  <Marker icon={LogoRider} position={rider} id="markerRider" />
                )}
                {device && (
                  <Marker
                    id="markerDevice"
                    icon={LogoDevice}
                    position={device}
                  />
                )}
              </GoogleMap>
            ) : (
              <Row style={{ height: "75vh" }}>
                <Loader
                  style={{
                    position: "absolute",
                    left: "50%",
                    top: "50%",
                    transform: "translate(-50%,-50%)",
                  }}
                  type="Oval"
                  color="#000000"
                  height={40}
                  width={40}
                />
              </Row>
            )}
          </Row>
        </Col>
      </Col>
    </Row>
  );
};

export default LiveGps;
