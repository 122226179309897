import React, { useState, useEffect } from "react";
import { Row, Col, Container } from "reactstrap";

import "./styles.desktop.css";
import PlacesAutocomplete from "react-places-autocomplete";
import Logo from "./../assets/images/Logo1.png";
import { Link } from "react-router-dom";
import Sidebar from "./../Components/Sidebar/sidebar";
import { geoCodeAddress } from "./../services/GoogleMapsService";
import "./../Globals/Fonts.css";
import Card from 'react-bootstrap/Card';
import BackIcon from "./../assets/images/back.png";

const Contactus = (props) => {

    const name = localStorage.getItem("name");
    const new_name = name.replace(/"/g, "");
    const email = localStorage.getItem("email");
    const contact1 = localStorage.getItem("contact");

    // State Variables
    const initState = {
        name: new_name,
        email: email,
        contact1: contact1,

        isInsured: false,
        files: "",
        checked: false,
        orgLat: "",
        orgLng: "",
        destLat: "",
        destLng: "",

        senderName: "",
        senderAdd: "",
        senderAddressModified: false,
        senderFlat: "",
        senderBuilding: "",
        senderContact: "",

        receiverName: "",
        receiverAdd: "",
        receiverAddressModified: false,
        receiverFlat: "",
        receiverBuilding: "",
        receiverContact: "",
        promoCode: "",
        orderId: new Date().toLocaleString(),
        amount: "",
        totalAmount: "",
        totalDiscount: "",
        buttonColor: "#f6f6fa",
        buttonColor1: "#f6f6fa",
        deliveryMethod: "secure",
        btnValue: "Cancle Order",
        btnValue1: "Apply Promocode"
    };

    const [state, setState] = useState(initState);

    useEffect(() => {
        (async function () {
            const { senderAddressModified, senderAdd, receiverAddressModified, receiverAdd } = state;
            try {
                if (senderAddressModified) {
                    const { lat, lng } = await geoCodeAddress(senderAdd);
                    setState({
                        ...state,
                        senderAddressModified: false,
                        orgLat: lat,
                        orgLng: lng,
                        pickupURL: `https://www.google.com/maps/search/?api=1&query=${lat},${lng}`,
                    });
                }

                if (receiverAddressModified) {
                    const { lat, lng } = await geoCodeAddress(receiverAdd);
                    setState({
                        ...state,
                        receiverAddressModified: false,
                        destLat: lat,
                        destLng: lng,
                        pickupURL: `https://www.google.com/maps/search/?api=1&query=${lat},${lng}`
                    })
                };
            } catch (e) {
                console.error("DesktopForm::useEffect::uncaught error", e);
                setState({ senderAddressModified: false, receiverAddressModified: false })
            }
        })()
    }, [state])

    // onChange Function
    const change = (e) => {
        if (e.target.name === "checked") {
            setState({
                ...state,
                checked: e.target.checked,
            });
        } else {
            setState({
                ...state,
                [e.target.name]: e.target.value,
            });
        }
    };

    // Keep Contact Number for Reciever & Sender Same as Primary Number
    const SameAsPrimaryNumber = (e) => {
        setState({
            ...state,
            [e.target.name]: state.contact1,
        });
    };

    // Sender Address Field
    // On address change, update the input field
    const handleSenderChange = (senderAdd) => {
        setState({
            ...state,
            senderAdd,
        });
    };

    // On address select, update the input field with the address selected
    // then fetch the lat, lng
    // update the state
    // --> check side effect ( useEffect )
    const handleSenderSelect = (address) => {
        const senderAddressModified = true;
        setState({
            ...state,
            senderAddressModified,
            senderAdd: address
        });
    };

    // Receiver Address Field
    const handleReceiverChange = (receiverAdd) => {
        setState({
            ...state,
            receiverAdd,
        });
    };

    const handleReceiverSelect = (address) => {
        const receiverAddressModified = true;
        setState({
            ...state,
            receiverAddressModified,
            receiverAdd: address
        });
    };

    return (
        <Row className="m-0">
            <Col
                lg={2} md={2} sm={2}
                className="text-center pt-5"
                style={{ backgroundColor: "white" }}
            >
                <Row className="gordian-logo" style={{ justifyContent: "center", alignItem: "center" }}>
                    <img src={Logo} alt="Logo" className="logo" />
                </Row>
                <h1 style={{ fontSize: "25px", textAlign: "center", fontFamily: "Manrope-Bold" }}>Gordian</h1>
                <Sidebar />
            </Col>
            <Col lg={7} md={7} sm={7} className="Form">
                <div className="NewOrder" style={{ marginLeft: "23px" }}>Place Order</div>
                <Container>
                    <div className="SubHeading row">&emsp; Your Details</div>
                    <Row className="row">
                        <Col lg={12} md={12} sm={12}>
                            <input
                                type="text"
                                placeholder="Enter your name"
                                disabled
                                className="field"
                                name="name"
                                value={state.name}
                                onChange={(e) => change(e)}
                                required
                            />
                        </Col>
                    </Row>
                    <Row className="row">
                        <Col lg={6} md={6} sm={6}>
                            <input
                                type="email"
                                placeholder="Enter your email"
                                disabled
                                className="field"
                                name="email"
                                value={state.email}
                                onChange={(e) => change(e)}
                                required
                            />
                        </Col>
                        <Col lg={6} md={6} sm={6}>
                            <input
                                type="number"
                                placeholder="Enter Your Contact No"
                                className="field"
                                disabled
                                name="contact1"
                                value={state.contact1}
                                onChange={(e) => change(e)}
                                required
                            />
                            <br />
                            <div className="error">{state.contact1Error}</div>
                        </Col>
                    </Row>
                    <hr />
                    <div className="SubHeading row">&emsp; Pickup Details</div>
                    <Row className="row">
                        <Col lg={12} md={12} sm={12}>
                            <PlacesAutocomplete
                                value={state.senderAdd}
                                onChange={handleSenderChange}
                                onSelect={handleSenderSelect}
                            >
                                {({
                                    getInputProps,
                                    suggestions,
                                    getSuggestionItemProps,
                                    loading,
                                }) => (
                                    <div className="placesDiv">
                                        <input
                                            {...getInputProps({
                                                placeholder: "Enter Pickup Address",
                                                className: "location-search-input fieldAddress field",
                                            })}
                                        />
                                        <div className="autocomplete-dropdown-container">
                                            {loading && <div>Loading . . . .</div>}
                                            {suggestions.map((suggestion) => {
                                                const className = suggestion.active
                                                    ? "suggestion-item--active"
                                                    : "suggestion-item";
                                                const style = suggestion.active
                                                    ? {
                                                        backgroundColor: "#fafafa",
                                                        cursor: "pointer",
                                                    }
                                                    : {
                                                        backgroundColor: "#ffffff",
                                                        cursor: "pointer",
                                                    };
                                                return (
                                                    <div
                                                        {...getSuggestionItemProps(suggestion, {
                                                            className,
                                                            style,
                                                        })}
                                                    >
                                                        <hr />
                                                        <span>{suggestion.description}</span>
                                                    </div>
                                                );
                                            })}
                                        </div>
                                    </div>
                                )}
                            </PlacesAutocomplete>
                        </Col>
                    </Row>
                    <Row className="row">
                        <Col lg={6} md={6} sm={6}>
                            <input
                                type="text"
                                placeholder="Building / Landmark"
                                className="field"
                                name="senderBuilding"
                                value={state.senderBuilding}
                                onChange={(e) => change(e)}
                                required
                            />
                        </Col>
                        <Col lg={6} md={6} sm={6}>
                            <input
                                type="number"
                                placeholder="Enter Pickup Mobile Number"
                                className="field"
                                name="senderContact"
                                value={state.senderContact}
                                onChange={(e) => change(e)}
                                required
                            />
                            <br />
                            <div
                                style={{
                                    textAlign: "right",
                                    fontSize: "12px",
                                }}
                            >
                                {/* <div className="error">{state.SenContactError}</div> */}
                                <input
                                    style={{ marginTop: "16px" }}
                                    type="checkbox"
                                    name="senderContact"
                                    onChange={(e) => SameAsPrimaryNumber(e)}
                                />
                &ensp;
                <label>Same as Primary number</label>
                            </div>
                        </Col>
                    </Row>
                    <hr />
                    <div className="SubHeading row">&emsp; Drop Details</div>
                    <Row className="row">
                        <Col lg={12} md={12} sm={12}>
                            <PlacesAutocomplete
                                value={state.receiverAdd}
                                onChange={handleReceiverChange}
                                onSelect={handleReceiverSelect}
                            >
                                {({
                                    getInputProps,
                                    suggestions,
                                    getSuggestionItemProps,
                                    loading,
                                }) => (
                                    <div className="placesDiv">
                                        <input
                                            {...getInputProps({
                                                placeholder: "Enter Drop Address",
                                                className: "location-search-input fieldAddress field",
                                            })}
                                        />
                                        <div className="autocomplete-dropdown-container">
                                            {loading && <div>Loading...</div>}
                                            {suggestions.map((suggestion) => {
                                                const className = suggestion.active
                                                    ? "suggestion-item--active"
                                                    : "suggestion-item";

                                                const style = suggestion.active
                                                    ? {
                                                        backgroundColor: "#fafafa",
                                                        cursor: "pointer",
                                                    }
                                                    : {
                                                        backgroundColor: "#ffffff",
                                                        cursor: "pointer",
                                                    };
                                                return (
                                                    <div
                                                        {...getSuggestionItemProps(suggestion, {
                                                            className,
                                                            style,
                                                        })}
                                                    >
                                                        <hr />
                                                        <span>{suggestion.description}</span>
                                                    </div>
                                                );
                                            })}
                                        </div>
                                    </div>
                                )}
                            </PlacesAutocomplete>
                        </Col>
                    </Row>
                    <Row className="row">
                        <Col lg={6} md={6} sm={6}>
                            <input
                                type="text"
                                placeholder="Building / Landmark"
                                className="field"
                                name="receiverBuilding"
                                value={state.receiverBuilding}
                                onChange={(e) => change(e)}
                                required
                            />
                        </Col>
                        <Col lg={6} md={6} sm={6}>
                            <input
                                type="number"
                                placeholder="Enter Drop Mobile Number"
                                className="field"
                                name="receiverContact"
                                value={state.receiverContact}
                                onChange={(e) => change(e)}
                                required
                            />
                            <br />
                            <div
                                style={{
                                    textAlign: "right",
                                    fontSize: "12px",
                                }}
                            >
                                {/* <div className="error">{state.recContactError}</div> */}
                                <input
                                    style={{ marginTop: "16px" }}
                                    type="checkbox"
                                    name="receiverContact"
                                    onChange={(e) => SameAsPrimaryNumber(e)}
                                />
                &ensp;
                <label>Same as Primary number</label>
                            </div>
                        </Col>
                    </Row>
                </Container>
            </Col>
            <Col lg={3} md={3} sm={3} style={{ backgroundColor: "white" }}>
                <Container>
                    <h1 className="name" style={{ fontSize: "20px", fontWeight: "600", marginTop: "20px", fontFamily: "Manrope-SemiBold" }}>Welcome, {new_name}</h1>
                    <hr />
                    <Row style={{ marginTop: "15px" }}>
                        {/* <Link to="/placeOrder" style={{ textDecoration: 'none' }} > */}
                        <Card style={{ width: '50px', height: "50px", borderRadius: "30px", backgroundColor: "#F5F6FA", marginLeft: "12px", border: "none", boxShadow: "5px 5px 11px 5px #0000000D" }} onClick={() => props.history.goBack()}>
                            <Card.Body>
                                <img style={{ height: "30px", width: "27px", marginTop: "-15px", marginLeft: "-11px" }} src={BackIcon} alt="backicon" />
                            </Card.Body>
                        </Card>
                        {/* </Link> */}
                    </Row>
                    <Row style={{ justifyContent: "center", alignItem: "center" }}>
                        <Link style={{ textDecoration: 'none' }} >
                            <div className="contactus-icon">
                                <div style={{ flexDirection: "row" }}>
                                    <h1 className="contact-text" style={{ fontSize: "20px", fontFamily: "Manrope-Bold", textAlign: 'center', color: "#ffffff", paddingTop: "45px" }}>Phone Number</h1>
                                    <h1 className="contact-text1" style={{ fontSize: "20px", fontFamily: "Manrope-Bold", textAlign: 'center', color: "#ffffff" }}>+91 8762918529</h1>
                                </div>
                            </div>
                        </Link>
                        {/* <Link style={{ textDecoration: 'none' }}> */}
                        <div className="contactus-icon">
                            <div style={{ flexDirection: "row" }}>
                                <a href='mailto:support@gordian.in' target="blank" style={{ textDecoration: "none" }}>
                                    <h1 className="contact-text2" style={{ fontSize: "20px", fontFamily: "Manrope-ExtraBold", textAlign: 'center', color: "#ffffff", paddingTop: "45px" }}>Email</h1>
                                    <h1 className="contact-text3" style={{ fontSize: "20px", fontFamily: "Manrope-ExtraBold", textAlign: 'center', color: "#ffffff" }}>support@gordian.in</h1>
                                </a>
                            </div>
                        </div>
                        {/* </Link> */}

                    </Row>

                </Container>
            </Col>
        </Row>
    );
};

export default Contactus;