import React, { Fragment, useState } from 'react';
import { Row, Container } from "reactstrap";
import { Link, useHistory } from "react-router-dom"
import "./styles.signuplogin.css";
import BackIcon from "./../assets/images/back.png";
import ProgressBar from "../Components/ProgressBar";
import Card from 'react-bootstrap/Card';
import "./../Globals/Fonts.css";
import Variables from "../Globals/variables";

const BaseURL = Variables.baseUrl;

function MobileOtpLogin1(props) {

    const phonenumber = localStorage.getItem("contact");

    const initError = {
        pinError: "",
        // pin2Error: "",
        // pin3Error: "",
        // pin4Error: "",
        // pin5Error: "",
        // pin6Error: ""
    };

    const [state, setState] = useState({
        // phonenumber: props.location.state.phonenumber,
        pin: "",
        pin1: "",
        pin2: "",
        pin3: "",
        pin4: "",
        pin5: "",
        pin6: "",
        phonenumber: phonenumber,
        btnValue: "Sign In",
        btnValue1: "Resend OTP"
    });

    const history = useHistory();

    // const handlePin1 = (pin1) => {
    //     setState({
    //         ...state,
    //         pin1: pin1
    //     })
    //     document.getElementById("pin2").focus()
    // }

    // const handlePin2 = (pin2) => {
    //     setState({
    //         ...state,
    //         pin2: pin2
    //     })
    //     document.getElementById("pin3").focus()
    // }

    // const handlePin3 = (pin3) => {
    //     setState({
    //         ...state,
    //         pin3: pin3
    //     })
    //     document.getElementById("pin4").focus()
    // }

    // const handlePin4 = (pin4) => {
    //     setState({
    //         ...state,
    //         pin4: pin4
    //     })
    //     document.getElementById("pin5").focus()
    // }

    // const handlePin5 = (pin5) => {
    //     setState({
    //         ...state,
    //         pin5: pin5
    //     })
    //     document.getElementById("pin6").focus()
    // }

    // const handlePin6 = (pin6) => {
    //     setState({
    //         ...state,
    //         pin6: pin6
    //     })
    //     document.getElementById("pin6").focus()
    // }

    // const cancle1 = (e) => {
    //     if (e.keyCode === 8) {
    //         setState(() => ({
    //             ...state,
    //             pin1: ""
    //         }))
    //         document.getElementById("pin1").focus()
    //     }
    // }

    // const cancle2 = (e) => {
    //     if (e.keyCode === 8) {
    //         setState(() => ({
    //             ...state,
    //             pin2: ""
    //         }))
    //         document.getElementById("pin1").focus()
    //     }
    // }

    // const cancle3 = (e) => {
    //     if (e.keyCode === 8) {
    //         setState(() => ({
    //             ...state,
    //             pin3: ""
    //         }))
    //         document.getElementById("pin2").focus()
    //     }
    // }

    // const cancle4 = (e) => {
    //     if (e.keyCode === 8) {
    //         setState(() => ({
    //             ...state,
    //             pin4: ""
    //         }))
    //         document.getElementById("pin3").focus()
    //     }
    // }

    // const cancle5 = (e) => {
    //     if (e.keyCode === 8) {
    //         setState(() => ({
    //             ...state,
    //             pin5: ""
    //         }))
    //         document.getElementById("pin4").focus()
    //     }
    // }

    // const cancle6 = (e) => {
    //     if (e.keyCode === 8) {
    //         setState(() => ({
    //             ...state,
    //             pin6: ""
    //         }))
    //         document.getElementById("pin5").focus()
    //     }
    // }

    async function resendotp() {
        const item = { phonenumber: state.phonenumber }
        setState({
            ...state,
            btnValue1: "Resending OTP..."
        })

        let result = await fetch(`${BaseURL}/users/otp-sender-login`, {
            method: 'POST',
            body: JSON.stringify(item),
            headers: {
                "Content-Type": 'application/json',
                "Accept": 'application/json'
            }
        })
        result = await result;
        if (result.status === 200 || result.status === 201) {
            result = await result.json();
            localStorage.setItem("user-info", JSON.stringify(result));
            alert("OTP sent successfully.");
            setState({
                ...state,
                btnValue1: "Resend OTP"
            })
            return result;

        } else {
            alert("Something went wrong. Please try again");
            setState({
                ...state,
                btnValue1: "Resend OTP"
            })

        }
        // result = await result.json()
        // localStorage.setItem("user-info", JSON.stringify(result));
        // alert("OTP sent successfully.")
        // setState({
        //     ...state,
        //     btnValue1: "Resend OTP"
        // })

    }

    async function verifyotp() {
        // const item = { phonenumber: state.phonenumber, otp: state.pin1 + state.pin2 + state.pin3 + state.pin4 + state.pin5 + state.pin6 }
        const item = { phonenumber: state.phonenumber, otp: state.pin.toString() }
        setState({
            ...state,
            btnValue: "Signing In..."
        })

        let result = await fetch(`${BaseURL}/users/authenticate-with-otp`, {
            method: 'POST',
            body: JSON.stringify(item),
            headers: {
                "Content-Type": 'application/json',
                "Accept": 'application/json'
            }
        })
        result = await result;
        if (result.status === 200 || result.status === 201) {
            result = await result.json();
            localStorage.setItem('refreshToken', result.refreshToken)
            localStorage.setItem("token", JSON.stringify(result.jwtToken));
            localStorage.setItem("name", JSON.stringify(result.fullName));
            localStorage.setItem("email", result.email);
            localStorage.setItem("role", result.role);
            history.push("/placeOrder");
            return result;

        } else if (result.status === 401 || result.status === 412) {
            result = await result.json();
            // alert("You have entered an incorrect OTP," + "\n" + "Please re - check the same.");
            alert(result.message)
            setState({
                ...state,
                btnValue: "Sign In"
            })
            return result;

        }

    }

    var [error] = useState(initError);

    const isValid = () => {
        error = initError;

        if (state.pin.length === 0) {
            error.pinError = "pin is required!";
        }

        if (
            error.pinError.length > 0
        ) {
            return false;
        } else {
            return true;
        }
    };

    const ValidSubmission = () => {
        // if (isValid()) {
        if (isValid()) {
            return (
                <Link
                    onClick={verifyotp}
                    type="button"
                    className="btn btn-dark rounded-pill button1 mb-3"
                >
                    {state.btnValue}
                </Link>
            );
        } else {
            return (
                <button
                    disabled
                    type="button"
                    className="btn btn-dark rounded-pill button1 mb-3"
                >
                    {state.btnValue}
                </button>
            );
        }
    };

    const handlePin = (pin) => {
        setState({
            ...state,
            pin: pin,
        })
    }

    // const ValidSubmission = () => {
    //     if (isValid()) {
    //         return (
    //             <Link >
    //                 <button
    //                     onClick={verifyotp}
    //                     type="button"
    //                     className="btn btn-dark rounded-pill button mb-3"
    //                 >
    //                     Done
    //             </button>
    //             </Link>
    //         );
    //     } else {
    //         return (
    //             <button
    //                 disabled
    //                 type="button"
    //                 className="btn btn-dark rounded-pill button mb-3"
    //             >
    //                 Done
    //             </button>
    //         );
    //     }
    // };

    return (

        <Fragment>
            <Container>
                <Row style={{ marginTop: "15px", marginLeft: "5px", justifyContent: "space-between", alignItem: "space-between", marginRight: "5px" }}>
                    <Link to="/mobilesigninotp" style={{ textDecoration: 'none' }} >
                        <Card style={{ width: '50px', height: "50px", borderRadius: "30px", backgroundColor: "#F5F6FA", border: "none", boxShadow: "5px 5px 11px 5px #0000000D" }}>
                            <Card.Body>
                                <img style={{ height: "30px", width: "27px", marginTop: "-15px", marginLeft: "-11px" }} src={BackIcon} alt="backicon" />
                            </Card.Body>
                        </Card>
                    </Link>
                    <div style={{ marginTop: "12px" }}>
                        <ProgressBar width="100%" steps="Step 2/2" />
                    </div>
                </Row>
                <div style={{ marginTop: "30px", marginLeft: "20px" }}>
                    <h1 style={{ fontSize: "32px", fontFamily: "Manrope-ExtraBold", color: "#000000", letterSpacing: "-1px" }} >OTP Generated</h1>
                    <h1 style={{ fontSize: "17px", fontFamily: "Manrope-Regular", color: "#000000", letterSpacing: "-1px" }}>A six digit OTP has been sent to the number entered.</h1>
                </div>

                <Row style={{ marginTop: "30px", justifyContent: "space-between", alignItem: "space-between", marginLeft: "1px", marginRight: "2px" }}>
                    <input type='number' name='pin' value={state.pin} onChange={e => handlePin(e.target.value)} style={{
                        height: '34px',
                        width: '100%',
                        border: 'none',
                        borderRadius: '30px',
                        boxShadow: '5px 5px 11px #0000000d',
                        paddingLeft: '16px',
                        fontSize: '15px',
                        outline: 'none', textAlign: 'center'
                    }} />
                    {/* <input
                        type="text"
                        id="pin1"
                        maxLength="1"
                        className="Otp-fields-mobile"
                        value={state.pin1}
                        onChange={e => handlePin1(e.target.value)}
                        // onChange={(e) => setState({ ...state, pin1: e.target.value })}
                        required
                        onKeyDown={e => cancle1(e)}
                    />

                    <input
                        type="text"
                        id="pin2"
                        maxLength="1"
                        className="Otp-fields-mobile"
                        value={state.pin2}
                        onChange={e => handlePin2(e.target.value)}
                        required
                        onKeyDown={e => cancle2(e)}
                    />

                    <input
                        type="text"
                        id="pin3"
                        maxLength="1"
                        className="Otp-fields-mobile"
                        value={state.pin3}
                        onChange={e => handlePin3(e.target.value)}
                        // onChange={(e) => setState({ ...state, pin3: e.target.value })}
                        required
                        onKeyDown={e => cancle3(e)}
                    />

                    <input
                        type="text"
                        id="pin4"
                        maxLength="1"
                        className="Otp-fields-mobile"
                        value={state.pin4}
                        onChange={e => handlePin4(e.target.value)}
                        required
                        onKeyDown={e => cancle4(e)}
                    />

                    <input
                        type="text"
                        id="pin5"
                        maxLength="1"
                        className="Otp-fields-mobile"
                        value={state.pin5}
                        onChange={e => handlePin5(e.target.value)}
                        // onChange={(e) => setState({ ...state, pin5: e.target.value })}
                        required
                        onKeyDown={e => cancle5(e)}
                    />

                    <input
                        type="text"
                        id="pin6"
                        maxLength="1"
                        className="Otp-fields-mobile"
                        value={state.pin6}
                        onChange={e => handlePin6(e.target.value)}
                        // onChange={(e) => setState({ ...state, pin6: e.target.value })}
                        required
                        onKeyDown={e => cancle6(e)}
                    /> */}
                </Row>

                <Row style={{ justifyContent: "center", alignItem: "center" }}>{ValidSubmission()}</Row>

                <div style={{ marginTop: "5px" }}>
                    <h1 style={{ fontSize: "12px", fontFamily: "Manrope-SemiBold", textAlign: "center", color: "#92979D" }}>Didn't receive your OTP?</h1>
                    <Link style={{ textDecoration: "none" }}>
                        <h1 onClick={resendotp} style={{ fontSize: "17px", fontFamily: "Manrope-Bold", textAlign: "center", color: "#000000" }}>{state.btnValue1}</h1>
                    </Link>
                </div>

                <h1 style={{ color: "#92979D", fontSize: "12px", fontFamily: "Manrope-SemiBold", textAlign: "center", marginTop: "190px" }}>For any difficulties</h1>
                <Link to="/ContactUs">
                    <h1 style={{ fontFamily: "Manrope-Bold", color: "#000000", fontSize: "15px", textAlign: "center" }}>Contact Us</h1>
                </Link>

            </Container>
        </Fragment>

        // <Container >
        //     <Row style={{ marginTop: "15px", marginLeft: "5px", justifyContent: "space-between", alignItem: "space-between", marginRight: "5px" }}>
        //         <Link to="/mobilesignup" style={{ textDecoration: 'none' }} >
        //             <Card style={{ width: '50px', height: "50px", borderRadius: "30px", backgroundColor: "#F5F6FA", border: "none", boxShadow: "5px 5px 11px 5px #0000000D" }}>
        //                 <Card.Body>
        //                     <img style={{ height: "30px", width: "27px", marginTop: "-15px", marginLeft: "-11px" }} src={BackIcon} alt="backicon" />
        //                 </Card.Body>
        //             </Card>
        //         </Link>
        //         <div style={{ marginTop: "12px" }}>
        //             <ProgressBar width="50%" steps="Step 1/2" />
        //         </div>
        //     </Row>
        //     <div style={{ marginTop: "40px" }}>
        //         <h1 style={{ fontSize: "25px", fontFamily: "Manrope-Bold", marginLeft: "10px", marginBottom: "10px" }}>OTP Generated</h1>
        //         <h1 style={{ fontSize: "15px", marginLeft: "10px", marginBottom: "20px", marginRight: "20px", fontFamily: "Manrope-SemiBold", color: "#92979D" }}>A four digit OTP has been sent to your mobile number. Please enter the same here.</h1>
        //     </div>
        //     <Row >
        //         <input
        //             type="text"
        //             className="Otp-fields-mobile"
        //             name="pin1"
        //             value={state.pin1}
        //             onChange={(e) => setState({ ...state, pin1: e.target.value })}
        //             required
        //         />

        //         <input
        //             type="text"
        //             className="Otp-fields-mobile"
        //             value={state.pin2}
        //             onChange={(e) => setState({ ...state, pin2: e.target.value })}
        //             name="pin2"
        //             required
        //         />

        //         <input
        //             type="text"
        //             className="Otp-fields-mobile"
        //             value={state.pin3}
        //             onChange={(e) => setState({ ...state, pin3: e.target.value })}
        //             name="pin3"
        //             required
        //         />

        //         <input
        //             type="text"
        //             className="Otp-fields-mobile"
        //             value={state.pin4}
        //             onChange={(e) => setState({ ...state, pin4: e.target.value })}
        //             name="pin4"
        //             required
        //         />
        //     </Row>

        //     <Link >
        //         <Col style={{ marginLeft: "80px" }}>
        //             {ValidSubmission()}
        //         </Col>
        //     </Link>

        //     <h1 style={{ fontSize: "10px", textAlign: "center", marginTop: "30px", color: "#92979D", fontFamily: "Manrope-Medium" }}>Did not receive SMS?</h1>


        //     <Link to="/Signupmobile2">
        //         <Col style={{ marginLeft: "80px" }}>
        //             <button className="button" onClick={signupotp} >Resend OTP</button>
        //         </Col>
        //     </Link>

        //     <div style={{ marginTop: "80px" }}>
        //         <h1 style={{ color: "#92979D", fontSize: "14px", textAlign: "center", fontFamily: "Manrope-Bold" }}>Go to www.Gordian.in</h1>
        //         <h1 style={{ color: "#000000", fontSize: "14px", textAlign: "center", marginTop: "15px", fontFamily: "Manrope-Bold" }}>Contact Help & Support</h1>
        //     </div>
        // </Container>
    )
}

export default MobileOtpLogin1