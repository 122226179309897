import React, { useState } from "react";
import { Row, Col } from "reactstrap";
import SidebarLogo from "../../assets/images/Artboard 20@3x_White.png";
import EstimateLogo from "../../assets/images/Artboard 8@3x_White.png";
import TrackingLogo from "../../assets/images/tracking-white.png";
import { Link, useHistory } from "react-router-dom";
import "./sidebar.css";
// import Variables from "./../../Globals/variables";
import "./../../Globals/Fonts.css";
// import { confirmAlert } from "react-confirm-alert";
import "react-confirm-alert/src/react-confirm-alert.css";

// const BaseURL = Variables.baseUrl;

function Sidebar() {
  const [state] = useState({
    btnValue: "Cancel Order",
    senderAdd: "",
    senderAdd1: "",
    senderBuilding: "",
    senderContact: "",
    senderName: "",
    senderFlat: "",
    orgLat: "",
    orgLng: "",
    receiverName: "",
    receiverFlat: "",
    receiverAdd: "",
    receiverAdd1: "",
    receiverBuilding: "",
    receiverContact: "",
    destLat: "",
    destLng: "",
    deliveryMethod: "secure",
    isInsured: false,
    files: [],
    insuranceAmount: "1",
    amount: "",
    saveaspickup: "",
    saveasdrop: "",
    insuranceEstimate: "",
    savedAddresspickup: [],
    savedAddressdrop: [],
    popup1: false,
    popup2: false,
    alreadyInsured: false,
  });

  const history = useHistory();

  const role = localStorage.getItem("role");

  function logout() {
    localStorage.clear("all");
    history.push("/Home");
  }

  function placeorder() {
    history.push("/placeOrder", {
      isInsured: state.isInsured,
      files: state.files,
      insuranceAmount: state.insuranceAmount,
      senderName: state.senderName,
      senderFlat: state.senderFlat,
      senderAdd: state.senderAdd,
      senderAdd1: state.senderAdd1,
      senderBuilding: state.senderBuilding,
      senderContact: state.senderContact,
      orgLat: state.orgLat,
      orgLng: state.orgLng,
      receiverName: state.receiverName,
      receiverFlat: state.receiverFlat,
      receiverAdd: state.receiverAdd,
      receiverAdd1: state.receiverAdd1,
      receiverBuilding: state.receiverBuilding,
      receiverContact: state.receiverContact,
      destLat: state.destLat,
      destLng: state.destLng,
      deliveryMethod: state.deliveryMethod,
      amount: state.amount,
      insuranceEstimate: state.insuranceEstimate,
      saveaspickup: state.saveaspickup,
      saveasdrop: state.saveasdrop,
      savedAddresspickup: state.savedAddresspickup,
      savedAddressdrop: state.savedAddressdrop,
      popup1: state.popup1,
      popup2: state.popup2,
      alreadyInsured: state.alreadyInsured,
    });
  }

  return (
    <>
      {/* <Link  className="link"> */}
      <Row className="SideHeading link" onClick={placeorder}>
        <Col lg={3} md={3}>
          <div
            style={{
              backgroundColor: "black",
              borderRadius: "4px",
              display: "inline-block",
            }}
          >
            <img
              src={SidebarLogo}
              alt="SideBar Logo"
              style={{ width: "29px" }}
            />
          </div>
        </Col>
        <Col className="order-text">Place Order</Col>
        <div className="SideBorder" />
      </Row>
      {/* </Link> */}
      <Link to="/Estimate" className="link">
        <Row className="SideHeading">
          <Col lg={3} md={3}>
            <div
              style={{
                backgroundColor: "black",
                borderRadius: "4px",
                display: "inline-block",
              }}
            >
              <img
                src={EstimateLogo}
                alt="Estimate Logo"
                style={{ width: "29px" }}
              />
            </div>
          </Col>
          <Col className="estimate-text">Get Estimate</Col>
          <div className="SideBorder" />
        </Row>
      </Link>
      <Link to="/TrackCurrentOrder" className="link">
        <Row className="SideHeading">
          <Col lg={3} md={3}>
            <div
              style={{
                backgroundColor: "black",
                borderRadius: "4px",
                display: "inline-block",
              }}
            >
              <img
                src={TrackingLogo}
                alt="Estimate Logo"
                style={{ width: "29px" }}
              />
            </div>
          </Col>
          <Col className="track-text">Track Your Current Orders</Col>
          <div className="SideBorder" />
        </Row>
      </Link>

      <Link to="/PastOrders" className="link">
        <Row className="SideHeading">
          <Col lg={3} md={3}>
            <div
              style={{
                backgroundColor: "black",
                borderRadius: "4px",
                display: "inline-block",
              }}
            >
              <img
                src={TrackingLogo}
                alt="Previous Order Logo"
                style={{ width: "29px" }}
              />
            </div>
          </Col>
          <Col className="track-text">Previous Orders</Col>
          <div className="SideBorder" />
        </Row>
      </Link>

      {role === "Admin" ? (
        <>
          <Link to="/OrderStatus" className="link">
            <Row className="SideHeading">
              <Col lg={3} md={3}>
                <div
                  style={{
                    backgroundColor: "black",
                    borderRadius: "4px",
                    display: "inline-block",
                  }}
                >
                  <img
                    src={TrackingLogo}
                    alt="Order Tracking Logo"
                    style={{ width: "29px" }}
                  />
                </div>
              </Col>
              <Col className="track-text">Active Order Status</Col>
              <div className="SideBorder" />
            </Row>
          </Link>

          <Link to="/toggleServiceability" className="link">
            <Row className="SideHeading">
              <Col lg={3} md={3}>
                <div
                  style={{
                    backgroundColor: "black",
                    borderRadius: "4px",
                    display: "inline-block",
                  }}
                >
                  <img
                    src={TrackingLogo}
                    alt="Order Tracking Logo"
                    style={{ width: "29px" }}
                  />
                </div>
              </Col>
              <Col className="track-text">Toggle Serviceability</Col>
              <div className="SideBorder" />
            </Row>
          </Link>
        </>
      ) : (
        ""
      )}
      {/* <button className="cancle" onClick={confirmCancelOrder}>
        {state.btnValue}
      </button> */}
      <Row style={{ justifyContent: "center", alignItem: "center" }}>
        <div
          className="logout-position"
          style={{ position: "fixed", bottom: "15px" }}
        >
          <hr />
          <button className="logout" onClick={logout}>
            Logout
          </button>
        </div>
      </Row>
    </>
  );
}

export default Sidebar;
