import React, { Fragment, useState } from "react";
import { Row, Col, Container } from "reactstrap";
import { Link, useHistory } from "react-router-dom";
import ChatIcon from "./../assets/images/chat.png";
import BackIcon from "./../assets/images/back.png";
import "./styles.signuplogin.css";
import Card from 'react-bootstrap/Card';
import "./../Globals/Fonts.css";
import Variables from "../Globals/variables";

const BaseURL = Variables.baseUrl;

function MobileForgotPassword(props) {

    const initError = {
        emailError: ""
    };

    const [state, setState] = useState({
        email: "",
        btnValue: "Reset Password"
    });
    const history = useHistory();

    async function resetPassword() {
        const item = { email: state.email }
        setState({
            ...state,
            btnValue: "Resetting Password..."
        })

        let result = await fetch(`${BaseURL}/users/reset-password`, {
            method: 'POST',
            body: JSON.stringify(item),
            headers: {
                "Content-Type": 'application/json',
                "Accept": 'application/json'
            }
        })
        result = await result.json()
        // console.warn("result", result)
        localStorage.setItem("info", JSON.stringify(result));
        if (result.message === "Failed to send password reset link") {
            alert("Your Email ID isn't registered. Please sign up.")
            setState({
                ...state,
                btnValue: "Reset Password",
            });

        }
        else if (result.message === "check your email") {
            alert("An email has been sent to your registered email address with a link to reset your password.")
            setState({
                ...state,
                btnValue: "Reset Password"
            })
            history.push("/mobilesignin1")
        }

    }

    var [error] = useState(initError);

    const isValid = () => {
        error = initError;
        const re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

        if (!re.test(state.email)) {
            error.emailError = "Invalid Email address";
        }

        if (
            error.emailError.length > 0
        ) {
            return false;
        } else {
            return true;
        }
    };

    const ValidSubmission = () => {
        if (isValid()) {
            return (
                <button onClick={resetPassword}
                    type="button"
                    className="btn btn-dark rounded-pill button1 mb-3"
                >
                    {state.btnValue}
                </button>
            );
        } else {
            return (
                <button
                    disabled
                    type="button"
                    className="btn btn-dark rounded-pill button1 mb-3"
                >
                    {state.btnValue}
                </button>
            );
        }
    };

    return (
        <Fragment>
            <Container>
                <Row style={{ marginTop: "15px", marginLeft: "5px", justifyContent: "space-between", alignItem: "space-between", marginRight: "5px" }}>
                    {/* <Link to="/Home" style={{ textDecoration: 'none' }} > */}
                    <Card style={{ width: '50px', height: "50px", borderRadius: "30px", backgroundColor: "#F5F6FA", border: "none", boxShadow: "5px 5px 11px 5px #0000000D" }} onClick={() => props.history.goBack()}>
                        <Card.Body>
                            <img style={{ height: "30px", width: "27px", marginTop: "-15px", marginLeft: "-11px" }} src={BackIcon} alt="backicon" />
                        </Card.Body>
                    </Card>
                    {/* </Link> */}

                    <Link to="/ContactUs">
                        <Card style={{ width: '50px', height: "50px", borderRadius: "30px", backgroundColor: "#F5F6FA", border: "none", boxShadow: "5px 5px 11px 5px #0000000D" }}>
                            <Card.Body>
                                <img style={{ height: "30px", width: "30px", marginTop: "-18px", marginLeft: "-11px" }} src={ChatIcon} alt="chaticon" />
                            </Card.Body>
                        </Card>
                    </Link>
                </Row>
                <div style={{ marginTop: "30px", marginLeft: "20px" }}>
                    <h1 style={{ fontSize: "32px", fontFamily: "Manrope-ExtraBold", color: "#000000", letterSpacing: "-1px" }} >Sign up with</h1>
                    <h1 style={{ fontSize: "32px", fontFamily: "Manrope-ExtraBold", color: "#000000", letterSpacing: "-1px" }}>Email Address</h1>
                </div>

                <Row >
                    <Col>
                        <label style={{ marginTop: "10px", fontSize: "16px", fontFamily: "Manrope-Bold", marginLeft: "15px" }}>Email Address</label>
                        <input
                            type="email"
                            className="field"
                            value={state.email}
                            onChange={(e) => setState({ ...state, email: e.target.value })}
                            required
                        />
                        <br />
                    </Col>
                </Row>
                <Row style={{ justifyContent: "center", alignItem: "center" }}>{ValidSubmission()}</Row>

                <h1 style={{ color: "#92979D", fontSize: "12px", fontFamily: "Manrope-SemiBold", textAlign: "center", marginTop: "230px" }}>For any difficulties</h1>
                <Link to="/ContactUs">
                    <h1 style={{ fontFamily: "Manrope-Bold", color: "#000000", fontSize: "15px", textAlign: "center" }}>Contact Us</h1>
                </Link>
            </Container>
        </Fragment>
    )
}

export default MobileForgotPassword
