import React from "react";
import EmailIcon from "../assets/images/emailWhite.png";
import MobileIcon from "../assets/images/mobileWhite.png";
import { Container, Row } from "reactstrap";
import { Link } from "react-router-dom";
import "./styles.signuplogin.css";
import Card from 'react-bootstrap/Card'
import "./../Globals/Fonts.css"

const MobileLoginPage1 = () => {
    return (
        <Container>
            <h1 style={{ marginTop: "50px", marginLeft: "20px", fontSize: "32px", fontFamily: "Manrope-ExtraBold", color: "#000000" }}>Sign In</h1>
            <Row style={{ marginTop: "40px", justifyContent: "center", alignItem: "center" }}>
                <Link to="/mobilesignin1" style={{ textDecoration: "none" }}>
                    <Card style={{ width: '150px', height: "150px", borderRadius: "20px", backgroundColor: "#000000", }}>
                        <Card.Body>
                            <img style={{ height: "30px", width: "30px" }} src={EmailIcon} alt="emailicon" />
                            <Card.Title style={{ color: "#ffffff", fontSize: "17px", marginTop: "15px", fontFamily: "Manrope-Bold" }}>Sign in with Email Address</Card.Title>
                        </Card.Body>
                    </Card>
                </Link>
                <Link to="/mobilesigninotp" style={{ textDecoration: "none" }}>

                    <Card style={{ width: '150px', height: "150px", borderRadius: "20px", backgroundColor: "#000000", marginLeft: "15px" }}>
                        <Card.Body>
                            <img style={{ height: "30px", width: "30px" }} src={MobileIcon} alt="mobileicon" />
                            <Card.Title style={{ color: "#ffffff", fontSize: "17px", marginTop: "15px", fontFamily: "Manrope-Bold" }}>Sign in with Mobile Number</Card.Title>
                        </Card.Body>
                    </Card>
                </Link>

            </Row>

            {/* <Row>
                <Card style={{ width: '150px', height: "150px", borderRadius: "20px", backgroundColor: "#F27070", marginLeft: "25px" }}>
                    <Card.Body>
                        <img style={{ height: "30px", width: "30px" }} src={GoogleIcon} alt="googleicon" />
                        <Card.Title style={{ color: "#ffffff", fontSize: "17px", marginTop: "15px" }}>Sign in with Google</Card.Title>
                    </Card.Body>
                </Card>

                <Card style={{ width: '150px', height: "150px", borderRadius: "20px", backgroundColor: "#5A8FE5", marginLeft: "15px" }}>
                    <Card.Body>
                        <img style={{ height: "30px", width: "30px" }} src={FacebookIcon} alt="facebokicon" />
                        <Card.Title style={{ color: "#ffffff", fontSize: "17px", marginTop: "15px" }}>Sign in with Facebook</Card.Title>
                    </Card.Body>
                </Card>

            </Row> */}
            <div>
                <h1 style={{ textAlign: "center", color: "#92979D", fontSize: "12px", fontFamily: "Manrope-SemiBold" }}>Do not have an Account?</h1>
                <Link to="/mobilesignupas">
                    <h1 style={{ textAlign: "center", fontFamily: "Manrope-Bold", color: "#000000", fontSize: "17px" }}>Sign Up</h1>
                </Link>
            </div>

            <Row style={{ justifyContent: "center", alignItem: "center" }}>
                <h1 style={{ color: "#92979D", fontSize: "12px", fontFamily: "Manrope-SemiBold", position: "fixed", bottom: "40px" }}>For any difficulties</h1>
                <Link to="/ContactUs">
                    <h1 style={{ fontFamily: "Manrope-Bold", color: "#000000", fontSize: "15px", position: "fixed", bottom: "15px", marginLeft: "-40px" }}>Contact Us</h1>
                </Link>
            </Row>
        </Container>
    );
};

export default MobileLoginPage1;
