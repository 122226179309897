import React, { useState } from 'react';
import { Row, Col, Container } from "reactstrap";
import { Link, useHistory } from "react-router-dom"
import Slider from "./Slider";
import "./styles.signup.css";
import BackIcon from "./../assets/images/back.png";
import ChatIcon from "./../assets/images/chat.png";
import Card from 'react-bootstrap/Card';
import "./../Globals/Fonts.css";
import Variables from "../Globals/variables";

const BaseURL = Variables.baseUrl;

function Otp() {

    const phonenumber = localStorage.getItem("contact");

    const initError = {
        pinError: "",
        // pin2Error: "",
        // pin3Error: "",
        // pin4Error: "",
        // pin5Error: "",
        // pin6Error: ""
    };

    const [state, setState] = useState({
        pin: "",
        pin1: "",
        pin2: "",
        pin3: "",
        pin4: "",
        pin5: "",
        pin6: "",
        phonenumber: phonenumber,
        btnValue: "Sign In",
        btnValue1: "Resend OTP",
        senderAdd: "",
        senderAdd1: "",
        senderBuilding: "",
        senderContact: "",
        senderName: "",
        senderFlat: "",
        orgLat: "",
        orgLng: "",
        receiverName: "",
        receiverFlat: "",
        receiverAdd: "",
        receiverAdd1: "",
        receiverBuilding: "",
        receiverContact: "",
        destLat: "",
        destLng: "",
        deliveryMethod: "secure",
        isInsured: false,
        files: [],
        insuranceAmount: "",
        amount: "",
        saveaspickup: "",
        saveasdrop: "",
        insuranceEstimate: "",
        savedAddresspickup: [],
        savedAddressdrop: [],
        popup1: false,
        popup2: false
    });


    const history = useHistory();

    // const handlePin1 = (pin1) => {
    //     setState({
    //         ...state,
    //         pin1: pin1
    //     })
    //     document.getElementById("pin2").focus()
    // }

    // const handlePin2 = (e, pin2) => {
    //     console.log(e)
    //     setState({
    //         ...state,
    //         pin2: pin2
    //     })
    //     document.getElementById("pin3").focus()
    // }

    // const handlePin3 = (pin3) => {
    //     setState({
    //         ...state,
    //         pin3: pin3
    //     })
    //     document.getElementById("pin4").focus()
    // }

    // const handlePin4 = (pin4) => {
    //     setState({
    //         ...state,
    //         pin4: pin4
    //     })
    //     document.getElementById("pin5").focus()
    // }

    // const handlePin5 = (pin5) => {
    //     setState({
    //         ...state,
    //         pin5: pin5
    //     })
    //     document.getElementById("pin6").focus()
    // }

    // const handlePin6 = (pin6) => {
    //     setState({
    //         ...state,
    //         pin6: pin6
    //     })
    //     document.getElementById("pin6").focus()
    // }

    // const cancle1 = (e) => {
    //     // console.log(e.target)
    //     // console.log(e.target.value)
    //     if (e.keyCode === 8) {
    //         setState({
    //             ...state,
    //             pin1: ""
    //         })
    //         // document.getElementById("pin1").focus()
    //     }
    // }

    // const cancle2 = (e) => {
    //     // console.log(e.target)
    //     // console.log(e.target.value)
    //     if (e.keyCode === 8) {
    //         setState({
    //             ...state,
    //             pin2: ""
    //         })
    //         console.log(state)
    //         console.log(document.getElementById('pin1'))
    //         document.getElementById("pin1").focus()
    //     }
    // }

    // const cancle3 = (e) => {
    //     if (e.keyCode === 8) {
    //         setState({
    //             ...state,
    //             pin3: ""
    //         })
    //         console.log(state)
    //         document.getElementById("pin2").focus()
    //     }
    // }

    // const cancle4 = (e) => {
    //     if (e.keyCode === 8) {
    //         setState({
    //             ...state,
    //             pin4: ""
    //         })
    //         document.getElementById("pin3").focus()
    //     }
    // }

    // const cancle5 = (e) => {
    //     if (e.keyCode === 8) {
    //         setState({
    //             ...state,
    //             pin5: ""
    //         })
    //         document.getElementById("pin4").focus()
    //     }
    // }

    // const cancle6 = (e) => {
    //     if (e.keyCode === 8) {
    //         setState({
    //             ...state,
    //             pin6: ""
    //         })
    //         document.getElementById("pin5").focus()
    //     }
    // }

    const handlePin = (pin) => {
        setState({
            ...state,
            pin: pin,
        })
    }

    async function resendotp() {
        const item = { phonenumber: state.phonenumber }
        setState({
            ...state,
            btnValue1: "Resending OTP..."
        })

        let result = await fetch(`${BaseURL}/users/otp-sender-login`, {
            method: 'POST',
            body: JSON.stringify(item),
            headers: {
                "Content-Type": 'application/json',
                "Accept": 'application/json'
            }
        })
        result = await result;
        if (result.status === 200 || result.status === 201) {
            result = await result.json();
            localStorage.setItem("user-info", JSON.stringify(result));
            alert("OTP sent successfully.");
            setState({
                ...state,
                btnValue1: "Resend OTP"
            })
            return result;

        } else {
            alert("Something went wrong. Please try again");
            setState({
                ...state,
                btnValue1: "Resend OTP"
            })

        }

    }

    async function signinotp() {
        // const item = { phonenumber: state.phonenumber, otp: state.pin1 + state.pin2 + state.pin3 + state.pin4 + state.pin5 + state.pin6 }
        const item = { phonenumber: state.phonenumber, otp: state.pin.toString() }
        setState({
            ...state,
            btnValue: "Signing In..."
        })
        console.log(item)

        let result = await fetch(`${BaseURL}/users/authenticate-with-otp`, {
            method: 'POST',
            body: JSON.stringify(item),
            headers: {
                "Content-Type": 'application/json',
                "Accept": 'application/json'
            }
        })
        result = await result;
        if (result.status === 200 || result.status === 201) {
            result = await result.json();
            localStorage.setItem('refreshToken', result.refreshToken)
            localStorage.setItem("token", JSON.stringify(result.jwtToken));
            localStorage.setItem("name", JSON.stringify(result.fullName));
            localStorage.setItem("email", result.email);
            localStorage.setItem("role", result.role);
            history.push("/placeOrder", {
                senderName: state.senderName,
                senderAdd: state.senderAdd,
                senderAdd1: state.senderAdd1,
                senderBuilding: state.senderBuilding,
                senderContact: state.senderContact,
                senderFlat: state.senderFlat,
                orgLat: state.orgLat,
                orgLng: state.orgLng,
                receiverName: state.receiverName,
                receiverAdd: state.receiverAdd,
                receiverAdd1: state.receiverAdd1,
                receiverBuilding: state.receiverBuilding,
                receiverContact: state.receiverContact,
                receiverFlat: state.receiverFlat,
                destLat: state.destLat,
                destLng: state.destLng,
                deliveryMethod: state.deliveryMethod,
                isInsured: state.isInsured,
                files: state.files,
                insuranceAmount: state.insuranceAmount,
                amount: "",
                insuranceEstimate: state.insuranceEstimate,
                saveaspickup: state.saveaspickup,
                saveasdrop: state.saveasdrop,
                savedAddresspickup: state.savedAddresspickup,
                savedAddressdrop: state.savedAddressdrop,
                popup1: state.popup1,
                popup2: state.popup2
            });
            return result;

        } else if (result.status === 401 || result.status === 412) {
            result = await result.json();
            // alert("You have entered an incorrect OTP," + "\n" + "Please re - check the same.");
            alert(result.message)
            setState({
                ...state,
                btnValue: "Sign In"
            })
            return result;

        }

    }

    var [error] = useState(initError);

    const isValid = () => {
        error = initError;

        if (state.pin.length === 0) {
            error.pinError = "pin is required!";
        }

        if (
            error.pinError.length > 0
        ) {
            return false;
        } else {
            return true;
        }
    };

    // const isValid = () => {
    //     error = initError;

    //     if (state.pin1.length === 0) {
    //         error.pin1Error = "pin 1 required!";
    //     }
    //     if (state.pin2.length === 0) {
    //         error.pin2Error = "pin 2 required!";
    //     }
    //     if (state.pin3.length === 0) {
    //         error.pin3Error = "pin 3 required!";
    //     }
    //     if (state.pin4.length === 0) {
    //         error.pin4Error = "pin 4 required!";
    //     }
    //     if (state.pin5.length === 0) {
    //         error.pin5Error = "pin 5 required!";
    //     }
    //     if (state.pin6.length === 0) {
    //         error.pin6Error = "pin 6 required!";
    //     }

    //     if (
    //         error.pin1Error.length > 0 ||
    //         error.pin2Error.length > 0 ||
    //         error.pin3Error.length > 0 ||
    //         error.pin4Error.length > 0 ||
    //         error.pin5Error.length > 0 ||
    //         error.pin6Error.length > 0
    //     ) {
    //         return false;
    //     } else {
    //         return true;
    //     }
    // };

    // const isValid = () => {
    //     error = initError;

    //     if(error.length == 0) {
    //         error.pin1Error = "otp is required!";
    //     }

    //     if()
    // }

    const ValidSubmission = () => {
        // if (isValid()) {
        if (isValid()) {
            return (
                <Link >
                    <button
                        onClick={signinotp}
                        type="button"
                        className="btn btn-dark rounded-pill button mb-3"
                    >
                        {state.btnValue}
                    </button>
                </Link>
            );
        } else {
            return (
                <button
                    disabled
                    type="button"
                    className="btn btn-dark rounded-pill button mb-3"
                >
                    {state.btnValue}
                </button>
            );
        }
    };

    return (<Row style={{ marginBottom: 0, marginTop: 0, backgroundColor: '#F5F6FA' }}>
        <Col lg={7} md={7} sm={7}>
            <Slider />
        </Col>

        <Col lg={5} md={5} sm={5}>
            <Row style={{ marginTop: "15px", marginLeft: "5px", marginRight: "30px", justifyContent: "space-between", alignItem: "space-between" }}>
                <Link to="/Signinmobile" style={{ textDecoration: 'none' }} >
                    <Card style={{ width: '50px', height: "50px", borderRadius: "30px", backgroundColor: "#F5F6FA", border: "none", boxShadow: "5px 5px 11px 5px #0000000D" }}>
                        <Card.Body>
                            <img style={{ height: "30px", width: "27px", marginTop: "-15px", marginLeft: "-11px" }} src={BackIcon} alt="backicon" />
                        </Card.Body>
                    </Card>
                </Link>

                <Link to="/ContactUs" style={{ textDecoration: "none" }}>
                    <Card style={{ width: '50px', height: "50px", borderRadius: "30px", backgroundColor: "#F5F6FA", border: "none", boxShadow: "5px 5px 11px 5px #0000000D" }}>
                        <Card.Body>
                            <img style={{ height: "30px", width: "30px", marginTop: "-18px", marginLeft: "-11px" }} src={ChatIcon} alt="chaticon" />
                        </Card.Body>
                    </Card>
                </Link>
            </Row>
            <Container style={{ marginTop: "80px" }}>
                <h1 style={{ fontSize: "1.563rem", fontFamily: "Manrope-Bold", marginLeft: "46px", marginBottom: "10px" }}>OTP Generated</h1>
                <h1 style={{ fontSize: "0.938rem", marginLeft: "46px", marginBottom: "20px", marginRight: "40px", fontFamily: "Manrope-SemiBold", color: "#92979D" }}>A six digit OTP has been sent to your mobile number. Please enter the same here.</h1>
                <Row style={{ justifyContent: "center", alignItem: "center" }}>
                    <input type='number' name='pin' value={state.pin} onChange={e => handlePin(e.target.value)} style={{
                        height: '34px',
                        width: '60%',
                        border: 'none',
                        borderRadius: '30px',
                        boxShadow: '5px 5px 11px #0000000d',
                        paddingLeft: '16px',
                        fontSize: '15px',
                        outline: 'none', textAlign: 'center'
                    }} />
                    {/* <input
                        type="text"
                        id="pin1"
                        // maxLength="1"
                        className="Otp-fields"
                        name="pin1"
                        value={state.pin1}
                        onChange={e => handlePin1(e.target.value)}
                        required
                        onKeyDown={e => cancle1(e)}
                        // onFocus={e => {
                        //     console.log('Here')
                        //     var temp_value = e.target.value
                        //     e.target.value = ''
                        //     e.target.value = temp_value
                        // }}
                    />

                    <input
                        type="text"
                        id="pin2"
                        className="Otp-fields"
                        // maxLength="1"
                        value={state.pin2}
                        onChange={e => handlePin2(e, e.target.value)}
                        // onChange={(e) => setState({ ...state, pin2: e.target.value })}
                        name="pin2"
                        required
                        onKeyDown={e => cancle2(e)}
                        // onFocus={e => {
                        //     console.log('In')
                        //     var temp_value = e.target.value
                        //     e.target.value = ''
                        //     e.target.value = temp_value
                        // }}
                    />

                    <input
                        type="text"
                        id="pin3"
                        className="Otp-fields"
                        // maxLength="1"
                        value={state.pin3}
                        onChange={e => handlePin3(e.target.value)}
                        // onChange={(e) => setState({ ...state, pin3: e.target.value })}
                        name="pin3"
                        required
                        onKeyDown={e => cancle3(e)}
                    />

                    <input
                        type="text"
                        id="pin4"
                        className="Otp-fields"
                        maxLength="1"
                        value={state.pin4}
                        onChange={e => handlePin4(e.target.value)}
                        // onChange={(e) => setState({ ...state, pin4: e.target.value })}
                        name="pin4"
                        required
                        onKeyDown={e => cancle4(e)}
                    />

                    <input
                        type="text"
                        id="pin5"
                        maxLength="1"
                        className="Otp-fields"
                        value={state.pin5}
                        onChange={e => handlePin5(e.target.value)}
                        // onChange={(e) => setState({ ...state, pin5: e.target.value })}
                        name="pin5"
                        required
                        onKeyDown={e => cancle5(e)}
                    />

                    <input
                        type="text"
                        id="pin6"
                        maxLength="1"
                        className="Otp-fields"
                        value={state.pin6}
                        onChange={e => handlePin6(e.target.value)}
                        // onChange={(e) => setState({ ...state, pin6: e.target.value })}
                        name="pin6"
                        required
                        onKeyDown={e => cancle6(e)}
                    /> */}
                </Row>

                <Row style={{ justifyContent: "center", alignItem: "center" }} className="buttons">
                    <Col lg={9} md={12} sm={12}>
                        {ValidSubmission()}
                    </Col>
                </Row>
                <Row style={{ justifyContent: "center", alignItem: "center", marginTop: "-20px" }}>
                    <h1 style={{ fontSize: "0.625rem", color: "#92979D", fontFamily: "Manrope-Medium" }}>Didn't receive your OTP?</h1>
                </Row>


                <Row style={{ justifyContent: "center", alignItem: "center", marginTop: "-15px" }} className="buttons">
                    <Col lg={9} md={12} sm={12} >
                        <button className="button" onClick={resendotp} >{state.btnValue1}</button>
                    </Col>
                </Row>

                <Row style={{ alignItem: "center", justifyContent: "center" }}>
                    <h1 className="goto-text" style={{ color: "#92979D", fontSize: "14px", fontFamily: "Manrope-Bold", position: "fixed", bottom: "40px", }}>Go to www.Gordian.in</h1>
                    <Link to="/ContactUs" style={{ textDecoration: "none", marginLeft: "-65px" }}>
                        <h1 className="contact-text" style={{ color: "#000000", fontSize: "14px", fontFamily: "Manrope-Bold", position: "fixed", bottom: "15px", }}>Contact Us</h1>
                    </Link>
                </Row>
            </Container>
        </Col>
    </Row >
    )
}

export default Otp