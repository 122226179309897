import React, { Fragment, useState } from "react";
import { Row, Col, Container } from "reactstrap";
import { Link } from "react-router-dom";
import ProgressBar from "../Components/ProgressBar";
import "./styles.signuplogin.css";
import Card from 'react-bootstrap/Card';
import BackIcon from "./../assets/images/back.png";
import "./../Globals/Fonts.css";

const MobileBusinessSignup3 = (props) => {
    const role = localStorage.getItem("role");

    const initError = {
        fullnameError: "",
        emailError: "",
        phonenumberError: "",
    };

    const [state, setState] = useState({
        fullname: "",
        email: props.location.state.email,
        password: props.location.state.password,
        confirmpassword: props.location.state.confirmpassword,
        phonenumber: "",
        role: role,
        checked: false,
        btnValue: "Next"
    });

    // async function signUp() {
    //     const item = { fullname: state.fullname, email: state.email, password: state.password, confirmpassword: state.confirmpassword, phonenumber: state.phonenumber, role: state.role }
    //     setState({
    //         ...state,
    //         btnValue: "Signing Up..."
    //     })

    //     let result = await fetch(`${BaseURL}/users/signup-customer`, {
    //         method: 'POST',
    //         body: JSON.stringify(item),
    //         headers: {
    //             "Content-Type": 'application/json',
    //             "Accept": 'application/json'
    //         }
    //     })
    //     result = await result.json()
    //     // console.warn("result", result)
    //     if (result.message === "You are registering with existing credentials") {
    //         // eslint-disable-next-line
    //         alert("User with these credentials already exist. Please try Logging In.")
    //         setState({
    //             ...state,
    //             btnValue: "Sign Up"
    //         })
    //     } else if (result.message === "Phone number is already exist!") {
    //         // eslint-disable-next-line
    //         alert("Phone Number alredy Exist," + "\n" + "Please try another Phone Number.")
    //         setState({
    //             ...state,
    //             btnValue: "Sign Up"
    //         })
    //     } else if (result.message === "passwords don't match") {
    //         // eslint-disable-next-line
    //         alert("Passwords don’t match," + "\n" + "Please recheck your password.")
    //         setState({
    //             ...state,
    //             btnValue: "Sign Up"
    //         })
    //     } else {
    //         localStorage.setItem('refreshToken', result.refreshToken)
    //         localStorage.setItem("name", JSON.stringify(result.fullName));
    //         localStorage.setItem("token", JSON.stringify(result.jwtToken));
    //         localStorage.setItem("email", result.email);
    //         localStorage.setItem("contact", result.phonenumber);
    //         history.push("/placeOrder")
    //     }

    // }

    var [error] = useState(initError);

    const isValid = () => {
        error = initError;
        const re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

        if (state.fullname.length === 0) {
            error.nameError = "Name is required";
            return false
        }
        if (!re.test(state.email)) {
            error.emailError = "Invalid Email address";
            return false
        }
        if (state.phonenumber.length !== 10) {
            error.phonenumberError = "Invalid Phone Number";
            return false
        } else {
            return true;
        }
    };

    const Termschange = (e) => {
        setState({
            ...state,
            checked: e.target.checked,
        })
    }

    const ValidSubmission = () => {
        if (isValid() && state.checked) {
            return (
                <Link to={{
                    pathname: "/MobileBusinessSignup4",
                    state,
                }}
                    type="button"
                    className="btn btn-dark rounded-pill button1 mb-3"
                >

                    {state.btnValue}
                </Link>
            );
        } else {
            return (
                <button
                    disabled
                    type="button"
                    className="btn btn-dark rounded-pill button1 mb-3"
                >
                    {state.btnValue}
                </button>
            );
        }
    };

    return (
        <Fragment>
            <Container>
                <Row style={{ marginTop: "15px", marginLeft: "5px", justifyContent: "space-between", alignItem: "space-between", marginRight: "5px" }}>
                    <Link to="/MobileBusinessSignup2" style={{ textDecoration: 'none' }} >
                        <Card style={{ width: '50px', height: "50px", borderRadius: "30px", backgroundColor: "#F5F6FA", border: "none", boxShadow: "5px 5px 11px 5px #0000000D" }}>
                            <Card.Body>
                                <img style={{ height: "30px", width: "27px", marginTop: "-15px", marginLeft: "-11px" }} src={BackIcon} alt="backicon" />
                            </Card.Body>
                        </Card>
                    </Link>
                    <div style={{ marginTop: "12px" }}>
                        <ProgressBar width="100%" steps="Step 2/2" />
                    </div>
                </Row>
                <div style={{ marginTop: "40px", marginLeft: "20px" }}>
                    <h1 style={{ fontSize: "32px", fontWeight: "bold", color: "#000000" }} >Enter Details</h1>
                </div>

                <Row style={{ marginTop: "10px" }}>
                    <Col>
                        <label style={{ marginTop: "10px", fontSize: "16px", fontFamily: "Manrope-Bold", marginLeft: "15px", color: "#000000" }}>Full Name</label>
                        <input
                            type="text"
                            className="field"
                            value={state.fullname}
                            onChange={(e) => setState({ ...state, fullname: e.target.value })}
                            required
                        />
                        <br />
                    </Col>
                </Row>
                <Row>
                    <Col>
                        <label style={{ fontSize: "16px", fontFamily: "Manrope-Bold", marginLeft: "15px", color: "#000000" }}>Mobile Number</label>
                        <input
                            type="number"
                            className="field"
                            value={state.phonenumber}
                            onChange={(e) => setState({ ...state, phonenumber: e.target.value })}
                            required
                        />
                        <br />
                    </Col>
                </Row>
                <Row>
                    <Col>
                        <label style={{ fontSize: "16px", fontFamily: "Manrope-Bold", marginLeft: "15px", color: "#000000" }}>Email Addres</label>
                        <input
                            type="email"
                            className="field"
                            disabled
                            value={state.email}
                            onChange={(e) => setState({ ...state, email: e.target.value })}
                            required
                        />
                        <br />
                    </Col>
                </Row>

                {/* <Row>
                    <Col>
                        <input
                            type="text"
                            placeholder="Role Eg:- Customer/Busseness User"
                            className="field"
                            value={state.role}
                            onChange={(e) => setState({ ...state, role: e.target.value })}
                            required
                        />
                        <br />
                    </Col>
                </Row> */}
                <div style={{ marginLeft: "15px" }}>
                    <input
                        value={state.checked}
                        onChange={(e) => Termschange(e)}
                        type="checkbox"
                    />
          &ensp;
          <label style={{ fontSize: "15px" }}>
                        I agree to the &nbsp;
            <b>
                            <Link to="/terms" target="_blank" style={{ color: "black", textDecoration: "none" }}> Terms and Conditions </Link>
                        </b>
                    </label>
                </div>
                <Row style={{ justifyContent: "center", alignItem: "center" }}>{ValidSubmission()}</Row>

                <h1 style={{ color: "#92979D", fontSize: "12px", fontFamily: "Manrope-SemiBold", textAlign: "center", marginTop: "50px" }}>For any difficulties</h1>
                <Link to="/ContactUs">
                    <h1 style={{ fontFamily: "Manrope-Bold", color: "#000000", fontSize: "15px", textAlign: "center" }}>Contact Us</h1>
                </Link>
            </Container>
        </Fragment>
    );
};

export default MobileBusinessSignup3;
