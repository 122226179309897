import React, { Fragment, useState } from "react";
import { Row, Col, Container } from "reactstrap";
import { Link } from "react-router-dom";
import ProgressBar from "../Components/ProgressBar";
import "./styles.mobile.css";
import BackIcon from "./../assets/images/back.png";
import Card from 'react-bootstrap/Card'

const MobileFormPage3 = (props) => {

  const name = localStorage.getItem("name");
  const new_name = name.replace(/"/g, "");
  const email = localStorage.getItem("email");
  const contact1 = localStorage.getItem("contact")

  const initError = {
    nameError: "",
    emailError: "",
    contactError: "",
  };

  const [state, setState] = useState({
    name: new_name,
    email: email,
    contact1: contact1,
    senderAdd: "",
    senderAdd1: "",
    senderBuilding: "",
    senderContact: "",
    orgLat: "",
    orgLng: "",
  });

  var [error] = useState(initError);

  const isValid = () => {
    error = initError;
    const re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

    if (state.name.length === 0) {
      error.nameError = "Name is required";
    }
    if (!re.test(state.email)) {
      error.emailError = "Invalid Email address";
    }
    if (state.contact1.length !== 10) {
      error.contactError = "Invalid Contact Number";
    }

    if (
      error.nameError.length > 0 ||
      error.emailError.length > 0 ||
      error.contactError.length > 0
    ) {
      return false;
    } else {
      return true;
    }
  };

  const ValidSubmission = () => {
    if (isValid()) {
      return (
        <Link
          to={{
            pathname: "/Sender-Details",
            state,
          }}
          type="button"
          className="btn btn-dark rounded-pill button3 mb-3"
        >
          Next
        </Link>
      );
    } else {
      return (
        <button
          disabled
          type="button"
          className="btn btn-dark rounded-pill button3 mb-3"
        >
          Next
        </button>
      );
    }
  };

  return (
    <Fragment>
      <Container>
        <Row className="m-0 mt-2" style={{ justifyContent: "space-between", alignItem: "space-between" }}>
          {/* <Col xs={8}></Col> */}
          <Link to="/Instructions" style={{ textDecoration: 'none' }} >
            <Card style={{ width: '50px', height: "50px", borderRadius: "30px", backgroundColor: "#F5F6FA", border: "none", boxShadow: "5px 5px 11px 5px #0000000D" }}>
              <Card.Body>
                <img style={{ height: "30px", width: "27px", marginTop: "-15px", marginLeft: "-11px" }} src={BackIcon} alt="backicon" />
              </Card.Body>
            </Card>
          </Link>
          <Col xs={4} style={{ marginTop: "10px" }}>
            <ProgressBar width="50%" steps="Step 2/4" />
          </Col>
        </Row>
        <div className="NewOrder">Place Order</div>
        <br />
        <div className="SubHeading">Your Details</div>
        <Row>
          <Col>
            <input
              type="text"
              disabled
              placeholder="Enter your name"
              className="field"
              value={state.name}
              onChange={(e) => setState({ ...state, name: e.target.value })}
              required
            />
            <br />
          </Col>
        </Row>
        <Row>
          <Col>
            <input
              type="email"
              disabled
              placeholder="Enter your email address"
              className="field"
              value={state.email}
              onChange={(e) => setState({ ...state, email: e.target.value })}
              required
            />
            <br />
          </Col>
        </Row>
        <Row>
          <Col>
            <input
              type="number"
              disabled
              placeholder="Enter Your number"
              className="field"
              value={state.contact1}
              onChange={(e) => setState({ ...state, contact1: e.target.value })}
              required
            />
            <br />
          </Col>
        </Row>
        <Row style={{ marginLeft: "-100px" }}>{ValidSubmission()}</Row>
      </Container>
    </Fragment>
  );
};

export default MobileFormPage3;
