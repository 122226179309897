import React, { useState, useEffect } from "react";
import { Row, Col, Container } from "reactstrap";

import "./styles.desktop.css";
import Instructions from "./../T&C/Instructions";
import PlacesAutocomplete from "react-places-autocomplete";
import Variables from "./../Globals/variables";
import Logo from "./../assets/images/Logo1.png";
import { Link, useHistory, withRouter } from "react-router-dom";
import Sidebar from "./../Components/Sidebar/sidebar";
import { geoCodeAddress } from "./../services/GoogleMapsService";
import "./../Globals/Fonts.css";
import Logout from "./../assets/images/Logout.png";
import Card from 'react-bootstrap/Card';
import Loader from "react-loader-spinner";
import Popup from 'reactjs-popup';
import { confirmAlert } from "react-confirm-alert";

const BaseURL = Variables.baseUrl;

const DesktopForm = (props) => {

  const name = localStorage.getItem("name");
  const new_name = name.replace(/"/g, "");
  const email = localStorage.getItem("email");
  const contact1 = localStorage.getItem("contact");
  const role = localStorage.getItem("role");

  // State Variables
  const initState = {
    name: new_name,
    email: email,
    contact1: contact1,
    isInsured: props.location.state === undefined ? false : props.location.state.isInsured,

    files:
      props.location.state === undefined ? [] :
        props.location.state.files,
    insuranceAmount: props.location.state === undefined || props.location.state === null || props.location.state.insuranceAmount === "" ? 1 : props.location.state.insuranceAmount,
    insuranceEstimate: props.location.state === undefined ? "" : props.location.state.insuranceEstimate,
    checked: false,
    orgLat: props.location.state === undefined ? "" : props.location.state.orgLat,
    orgLng: props.location.state === undefined ? "" : props.location.state.orgLng,
    destLat: props.location.state === undefined ? "" : props.location.state.destLat,
    destLng: props.location.state === undefined ? "" : props.location.state.destLng,

    senderName: props.location.state === undefined ? "" : props.location.state.senderName,
    senderAdd: props.location.state === undefined ? "" : props.location.state.senderAdd,
    senderAdd1: props.location.state === undefined ? "" : props.location.state.senderAdd1,
    senderAddressModified: false,
    senderFlat: props.location.state === undefined ? "" : props.location.state.senderFlat,
    senderBuilding: props.location.state === undefined ? "" : props.location.state.senderBuilding,
    senderContact: props.location.state === undefined ? "" : props.location.state.senderContact,
    saveaspickup: props.location.state === undefined ? "" : props.location.state.saveaspickup,

    receiverName: props.location.state === undefined ? "" : props.location.state.receiverName,
    receiverAdd: props.location.state === undefined ? "" : props.location.state.receiverAdd,
    receiverAdd1: props.location.state === undefined ? "" : props.location.state.receiverAdd1,
    receiverAddressModified: false,
    receiverFlat: props.location.state === undefined ? "" : props.location.state.receiverFlat,
    receiverBuilding: props.location.state === undefined ? "" : props.location.state.receiverBuilding,
    receiverContact: props.location.state === undefined ? "" : props.location.state.receiverContact,
    saveasdrop: props.location.state === undefined ? "" : props.location.state.saveasdrop,
    promoCode: "",
    orderId: new Date().toLocaleString(),
    amount: props.location.state === undefined ? "" : props.location.state.amount,
    totalAmount: "",
    totalDiscount: "",
    buttonColor: "#f6f6fa",
    buttonColor1: "#f6f6fa",
    deliveryMethod: "secure",
    btnValue: "Cancle Order",
    btnValue1: "Apply Code",
    btnValue2: "Make Payment",
    btnValue3: "Save Address",
    btnValue4: "Save Address",
    btnValue5: "Display saved addresses",
    btnValue6: "Display saved addresses",
    role: role,
    savedAddresspickup: props.location.state === undefined ? [] : props.location.state.savedAddresspickup,
    savedAddressdrop: props.location.state === undefined ? [] : props.location.state.savedAddressdrop,
    loadingpickup: false,
    loadingdrop: false,
    popup1: props.location.state === undefined ? false : props.location.state.popup1,
    popup2: props.location.state === undefined ? false : props.location.state.popup2,
    alreadyInsured: (props.location.state === undefined || props.location.state.alreadyInsured === undefined) ? false : props.location.state.alreadyInsured
  };

  const [state, setState] = useState(initState);
  const history = useHistory();

  async function getestimate() {
    const role = localStorage.getItem("role");
    if (role === "Bussiness") {
      if (state.insuranceEstimate.length === 0) {
        alert("Please enter invoice amount to place order")
        return true
      }
    }

    setState({
      ...state,
      btnValue1: "Applying Promocode..."
    })

    let results = await fetch(`${BaseURL}/orders/estimate?origins=${state.orgLat}&origins=${state.orgLng}&destinations=${state.destLat}&destinations=${state.destLng}&deliveryMethod=${state.deliveryMethod}&role=${role}`, {
      method: 'GET',
    })
    results = await results
    if (results.status === 200 || results.status === 201) {
      results = await results.json();
      localStorage.setItem("amount", results.data.amount);
      localStorage.setItem("distance", results.data.distance);
      history.push("/Promocode", {
        isInsured: state.isInsured,
        senderAdd: state.senderAdd,
        senderAdd1: state.senderAdd1,
        receiverAdd: state.receiverAdd,
        receiverAdd1: state.receiverAdd1,
        senderBuilding: state.senderBuilding,
        receiverBuilding: state.receiverBuilding,
        senderContact: state.senderContact,
        receiverContact: state.receiverContact,
        orgLat: state.orgLat,
        orgLng: state.orgLng,
        destLat: state.destLat,
        destLng: state.destLng,
        deliveryMethod: state.deliveryMethod,
        files: state.files,
        insuranceAmount: state.insuranceAmount,
        insuranceEstimate: state.insuranceEstimate,
        alreadyInsured: state.alreadyInsured,
        savedAddresspickup: state.savedAddresspickup,
        savedAddressdrop: state.savedAddressdrop,
        popup1: state.popup1,
        popup2: state.popup2

      })
      setState({
        ...state,
        btnValue1: "Apply Code"
      })
      return results;
    } else if (results.status === 400 || results.status === 404) {
      results = await results.json();
      alert("Please select address as available in the dropdown. You can enter more details in the Building/Landmark section for more accuracy.")
      setState({
        ...state,
        btnValue1: "Apply Code"
      })
      return results;
    }
  }

  async function getCurrentlocationpickup() {
    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition((position) => {
        console.log("Pickup current location:- ", position.coords)
        fetch(
          `https://maps.googleapis.com/maps/api/geocode/json?latlng=${position.coords.latitude},${position.coords.longitude}&key=${Variables.GOOGLE_API_KEY}`,
          {
            method: "GET",
          }
        ).then((response) => {
          return response.json();
        }).then((responseData) => {
          localStorage.setItem("orgLat", position.coords.latitude);
          localStorage.setItem("orgLng", position.coords.longitude);
          localStorage.setItem(
            "senderAdd",
            responseData.results[0].formatted_address,
          );
          setState({
            ...state,
            senderAdd: responseData.results[0].formatted_address,
            senderAdd1: responseData.results[0].formatted_address,
            orgLat: position.coords.latitude,
            orgLng: position.coords.longitude
          })
        }).catch((error) => {
          console.error("The error is", error);
        });
      })
    } else {
      alert("Location Permission Denied");
    }
  };

  async function getCurrentlocationdrop() {
    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition((position) => {
        console.log("Drop current location:- ", position.coords)
        fetch(
          `https://maps.googleapis.com/maps/api/geocode/json?latlng=${position.coords.latitude},${position.coords.longitude}&key=${Variables.GOOGLE_API_KEY}`,
          {
            method: "GET",
          }
        ).then((response) => {
          return response.json();
        }).then((responseData) => {
          localStorage.setItem("destLat", position.coords.latitude);
          localStorage.setItem("destLng", position.coords.longitude);
          localStorage.setItem(
            "receiverAdd",
            responseData.results[0].formatted_address
          );
          setState({
            ...state,
            receiverAdd: responseData.results[0].formatted_address,
            receiverAdd1: responseData.results[0].formatted_address,
            destLat: position.coords.latitude,
            destLng: position.coords.longitude
          })
        }).catch((error) => {
          console.error("The error is", error);
        });
      })
    } else {
      alert("Location Permission Denied");
    }
  };

  async function saveAddressaspickup() {
    setState({
      ...state,
      btnValue3: "Saving..."
    })

    const data = {
      address: state.senderAdd,
      lat: state.orgLat,
      lng: state.orgLng,
      label: state.saveaspickup,
      number: state.senderContact,
      buildingName: state.senderBuilding
    };

    const token = localStorage.getItem("token")

    let results = fetch(`${BaseURL}/users/saved-addresses`, {
      method: "PATCH",
      body: JSON.stringify(data),
      headers: {
        "Content-Type": "application/json",
        Authorization: `bearer ${token}`,
      },
    })
    results = await results;
    if (results.status === 200 || results.status === 201) {
      results = await results.json();
      alert("Address saved successfully");
      setState({
        ...state,
        btnValue3: "Save Address"
      })
      return results
    } else if (results.status === 401) {
      const refreshToken = localStorage.getItem('refreshToken');
      const data = {
        refreshToken
      };

      var response = await fetch(`${BaseURL}/users/refresh-token`, {
        method: 'POST',
        body: JSON.stringify(data),
        headers: {
          "Content-Type": "application/json",
        }
      })
      response = await response;
      if (response.status === 200 || response.status === 201) {
        response = await response.json();
        localStorage.setItem('refreshToken', response.refreshToken)
        localStorage.setItem('token', response.jwtToken)
        let results1 = fetch(`${BaseURL}/users/saved-addresses`, {
          method: "PATCH",
          body: JSON.stringify(data),
          headers: {
            "Content-Type": "application/json",
            Authorization: `bearer ${response.jwtToken}`,
          },
        })
        results1 = await results1;
        if (results1.status === 200 || results1.status === 201) {
          results1 = await results1.json();
          alert("Address saved successfully.");
          setState({
            ...state,
            btnValue3: "Save Address"
          })
          return results1
        } else if (results1.status === 400 || results1.status === 412) {
          results1 = await results1.json();
          alert(results1.message);
          setState({
            ...state,
            btnValue3: "Save Address"
          })
          return results1
        }
      } else {
        alert("Session expired. Please login to continue.");
        localStorage.clear("All");
        history.push("/Home")
      }
    } else if (results.status === 400 || results.status === 412) {
      results = await results.json();
      alert(results.message);
      setState({
        ...state,
        btnValue3: "Save Address"
      })
      return results
    }
  }

  async function saveAddressasdrop() {
    setState({
      ...state,
      btnValue4: "Saving..."
    })

    const data = {
      address: state.receiverAdd,
      lat: state.destLat,
      lng: state.destLng,
      label: state.saveasdrop,
      number: state.receiverContact,
      buildingName: state.receiverBuilding
    };

    const token = localStorage.getItem("token")

    let results = fetch(`${BaseURL}/users/saved-addresses`, {
      method: "PATCH",
      body: JSON.stringify(data),
      headers: {
        "Content-Type": "application/json",
        Authorization: `bearer ${token}`,
      },
    })
    results = await results;
    if (results.status === 200 || results.status === 201) {
      results = await results.json();
      alert("Address saved successfully.");
      setState({
        ...state,
        btnValue4: "Save Address"
      })
      return results
    } else if (results.status === 401) {
      const refreshToken = localStorage.getItem('refreshToken');
      const data = {
        refreshToken
      };

      var response = await fetch(`${BaseURL}/users/refresh-token`, {
        method: 'POST',
        body: JSON.stringify(data),
        headers: {
          "Content-Type": "application/json",
        }
      })
      response = await response;
      if (response.status === 200 || response.status === 201) {
        response = await response.json();
        localStorage.setItem('refreshToken', response.refreshToken)
        localStorage.setItem('token', response.jwtToken)
        let results1 = fetch(`${BaseURL}/users/saved-addresses`, {
          method: "PATCH",
          body: JSON.stringify(data),
          headers: {
            "Content-Type": "application/json",
            Authorization: `bearer ${response.jwtToken}`,
          },
        })
        results1 = await results1;
        if (results1.status === 200 || results1.status === 201) {
          results1 = await results1.json();
          alert("Address saved successfully.");
          setState({
            ...state,
            btnValue4: "Save Address"
          })
          return results1
        } else if (results1.status === 400 || results1.status === 412) {
          results1 = await results1.json();
          alert(results1.message);
          setState({
            ...state,
            btnValue4: "Save Address"
          })
          return results1
        }
      } else {
        alert("Session expired. Please login to continue.");
        localStorage.clear("All");
        history.push("/Home")
      }
    } else if (results.status === 400 || results.status === 412) {
      results = await results.json();
      alert(results.message);
      setState({
        ...state,
        btnValue4: "Save Address"
      })
      return results
    }
  }

  async function displaySaveaddresspickup() {
    setState({
      ...state,
      loadingpickup: true,
      btnValue5: "Displaying..."
    })
    const token = localStorage.getItem("token");

    let results = fetch(`${BaseURL}/users/saved-addresses`, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: `bearer ${token}`,
      },
    })
    results = await results;
    if (results.status === 200 || results.status === 201) {
      results = await results.json();
      setState({
        ...state,
        savedAddresspickup: results.data,
        loadingpickup: false,
        popup1: true,
        btnValue5: "Display saved addresses"
      })
      return results
    } else if (results.status === 401) {
      const refreshToken = localStorage.getItem('refreshToken');
      const data = {
        refreshToken
      };

      var response = await fetch(`${BaseURL}/users/refresh-token`, {
        method: 'POST',
        body: JSON.stringify(data),
        headers: {
          "Content-Type": "application/json",
        }
      })
      response = await response;
      if (response.status === 200 || response.status === 201) {
        response = await response.json();
        localStorage.setItem('refreshToken', response.refreshToken)
        localStorage.setItem('token', response.jwtToken)
        let results1 = fetch(`${BaseURL}/users/saved-addresses`, {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
            Authorization: `bearer ${response.jwtToken}`,
          },
        })
        results1 = await results1;
        if (results1.status === 200 || results1.status === 201) {
          results1 = await results1.json();
          setState({
            ...state,
            savedAddresspickup: results1.data,
            loadingpickup: false,
            popup1: true,
            btnValue5: "Display saved addresses"
          })
          return results1
        } else {
          alert("Something went worng!");
          setState({
            ...state,
            savedAddresspickup: {},
            loadingpickup: false,
            popup1: false
          })
        }
      } else {
        alert("Session expired. Please login to continue.");
        localStorage.clear("All");
        history.push("/Home")
      }
    } else {
      alert("Something went worng!");
      setState({
        ...state,
        savedAddresspickup: {},
        loadingpickup: false,
        popup1: false,
        btnValue5: "Display saved addresses"
      })
    }
  }

  async function displaySaveaddressdrop() {
    setState({
      ...state,
      loadingdrop: true,
      btnValue6: "Displaying..."
    })
    const token = localStorage.getItem("token");

    let results = fetch(`${BaseURL}/users/saved-addresses`, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: `bearer ${token}`,
      },
    })
    results = await results;
    if (results.status === 200 || results.status === 201) {
      results = await results.json();
      setState({
        ...state,
        savedAddressdrop: results.data,
        loadingdrop: false,
        popup2: true,
        btnValue6: "Display saved addresses"
      })
      return results
    } else if (results.status === 401) {
      const refreshToken = localStorage.getItem('refreshToken');
      const data = {
        refreshToken
      };

      var response = await fetch(`${BaseURL}/users/refresh-token`, {
        method: 'POST',
        body: JSON.stringify(data),
        headers: {
          "Content-Type": "application/json",
        }
      })
      response = await response;
      if (response.status === 200 || response.status === 201) {
        response = await response.json();
        localStorage.setItem('refreshToken', response.refreshToken)
        localStorage.setItem('token', response.jwtToken)
        let results1 = fetch(`${BaseURL}/users/saved-addresses`, {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
            Authorization: `bearer ${response.jwtToken}`,
          },
        })
        results1 = await results1;
        if (results1.status === 200 || results1.status === 201) {
          results1 = await results1.json();
          setState({
            ...state,
            savedAddressdrop: results1.data,
            loadingdrop: false,
            popup2: true,
            btnValue6: "Display saved addresses"
          })
          return results1
        } else {
          alert("Something went worng!");
          setState({
            ...state,
            savedAddressdrop: {},
            loadingdrop: false,
            popup2: false,
            btnValue6: "Display saved addresses"
          })
        }
      } else {
        alert("Session expired. Please login to continue.");
        localStorage.clear("All");
        history.push("/Home")
      }
    } else {
      alert("Something went worng!");
      setState({
        ...state,
        savedAddressdrop: {},
        loadingdrop: false,
        popup2: false,
        btnValue6: "Display saved addresses"
      })
    }
  }

  const deleteSaveAddressPickup = async (data) => {
    const token = localStorage.getItem("token")
    const label = data.data

    let results = fetch(`${BaseURL}/users/saved-addresses?label=${label}`, {
      method: "DELETE",
      headers: {
        "Content-Type": "application/json",
        Authorization: `bearer ${token}`,
      },
    })
    results = await results;
    if (results.status === 200 || results.status === 201) {
      results = await results.json();
      alert("Address deleted successfully.");
      setState({
        ...state,
        popup1: false
      })
      return results
    } else if (results.status === 401) {
      const refreshToken = localStorage.getItem('refreshToken');
      const data = {
        refreshToken
      };

      var response = await fetch(`${BaseURL}/users/refresh-token`, {
        method: 'POST',
        body: JSON.stringify(data),
        headers: {
          "Content-Type": "application/json",
        }
      })
      response = await response;
      if (response.status === 200 || response.status === 201) {
        response = await response.json();
        localStorage.setItem('refreshToken', response.refreshToken)
        localStorage.setItem('token', response.jwtToken)
        let results1 = fetch(`${BaseURL}/users/saved-addresses?label=${label}`, {
          method: "DELETE",
          headers: {
            "Content-Type": "application/json",
            Authorization: `bearer ${response.jwtToken}`,
          },
        })
        results1 = await results1;
        if (results1.status === 200 || results1.status === 201) {
          results1 = await results1.json();
          alert("Address deleted successfully.");
          setState({
            ...state,
            popup1: false
          })
          return results1
        } else if (results1.status === 400 || results1.status === 412) {
          results1 = await results1.json();
          alert(results1.message);
          return results1
        }
      } else {
        alert("Session expired. Please login to continue.");
        localStorage.clear("All");
        history.push("/Home")
      }
    } else if (results.status === 400 || results.status === 412) {
      results = await results.json();
      alert(results.message);
      return results
    }

  }

  const deleteSaveAddressDrop = async (data) => {
    const token = localStorage.getItem("token")
    const label = data.data

    let results = fetch(`${BaseURL}/users/saved-addresses?label=${label}`, {
      method: "DELETE",
      headers: {
        "Content-Type": "application/json",
        Authorization: `bearer ${token}`,
      },
    })
    results = await results;
    if (results.status === 200 || results.status === 201) {
      results = await results.json();
      alert("Address deleted successfully.");
      setState({
        ...state,
        popup2: false
      })
      return results
    } else if (results.status === 401) {
      const refreshToken = localStorage.getItem('refreshToken');
      const data = {
        refreshToken
      };

      var response = await fetch(`${BaseURL}/users/refresh-token`, {
        method: 'POST',
        body: JSON.stringify(data),
        headers: {
          "Content-Type": "application/json",
        }
      })
      response = await response;
      if (response.status === 200 || response.status === 201) {
        response = await response.json();
        localStorage.setItem('refreshToken', response.refreshToken)
        localStorage.setItem('token', response.jwtToken)
        let results1 = fetch(`${BaseURL}/users/saved-addresses?label=${label}`, {
          method: "DELETE",
          headers: {
            "Content-Type": "application/json",
            Authorization: `bearer ${response.jwtToken}`,
          },
        })
        results1 = await results1;
        if (results1.status === 200 || results1.status === 201) {
          results1 = await results1.json();
          alert("Address deleted successfully.");
          setState({
            ...state,
            popup2: false
          })
          return results1
        } else if (results1.status === 400 || results1.status === 412) {
          results1 = await results1.json();
          alert(results1.message);
          return results1
        }
      } else {
        alert("Session expired. Please login to continue.");
        localStorage.clear("All");
        history.push("/Home")
      }
    } else if (results.status === 400 || results.status === 412) {
      results = await results.json();
      alert(results.message);
      return results
    }

  }

  function AddInsurance() {
    history.push("/AddInsurance", {
      isInsured: state.isInsured,
      senderName: state.senderName,
      senderFlat: state.senderFlat,
      senderAdd: state.senderAdd,
      senderAdd1: state.senderAdd1,
      receiverAdd: state.receiverAdd,
      receiverAdd1: state.receiverAdd1,
      senderBuilding: state.senderBuilding,
      receiverBuilding: state.receiverBuilding,
      senderContact: state.senderContact,
      receiverContact: state.receiverContact,
      receiverName: state.receiverName,
      receiverFlat: state.receiverFlat,
      orgLat: state.orgLat,
      orgLng: state.orgLng,
      destLat: state.destLat,
      destLng: state.destLng,
      deliveryMethod: state.deliveryMethod,
      amount: state.amount,
      alreadyInsured: state.alreadyInsured,
      savedAddresspickup: state.savedAddresspickup,
      savedAddressdrop: state.savedAddressdrop,
      popup1: state.popup1,
      popup2: state.popup2
    })
  }

  const displayButton = (bgColor, textColor, text) => {
    return (
      <button className="insurance" style={{ backgroundColor: bgColor, color: textColor }} onClick={AddInsurance} >{text}</button>
    )
  }

  const showSaveaddresspickup = (pickupaddress) => {
    return (
      <div style={{ display: "flex", flexDirection: "row", justifyContent: "space-evenly" }}>
        <Card style={{ width: '100%', height: "250px", borderRadius: "15px", backgroundColor: "#ffffff", border: "none", boxShadow: "5px 5px 11px 5px #0000000D", marginTop: "20px", marginBottom: "10px", cursor: "pointer" }} >
          <Card className="pending-order" style={{ width: '100%', height: "190px", borderRadius: "15px", backgroundColor: "#ffffff", border: "none", cursor: "pointer" }} onClick={() => {
            setState({
              ...state,
              senderAdd: pickupaddress.address,
              senderAdd1: pickupaddress.address,
              senderContact: pickupaddress.number,
              senderBuilding: pickupaddress.buildingName,
              orgLat: pickupaddress.location.lat,
              orgLng: pickupaddress.location.lng,
              popup1: false
            })
          }}>
            <Card.Body>
              <h3 className="sender-address" style={{ fontFamily: "Manrope-Bold", fontSize: "25px", color: "#000000" }}>{pickupaddress.label}</h3>
              <h3 className="sender-address" style={{ fontFamily: "Manrope-Regular", fontSize: "16px", color: "#000000" }}>{pickupaddress.number}</h3>
              <h3 className="sender-address" style={{ fontFamily: "Manrope-Regular", fontSize: "16px", color: "#000000" }}>{pickupaddress.buildingName}</h3>
              <h3 className="receiver-address" style={{ fontFamily: "Manrope-Regular", fontSize: "16px", color: "#000000" }}>{pickupaddress.address}</h3>


            </Card.Body>
          </Card>
          <div style={{ display: "flex", flexDirection: "row", justifyContent: "space-between", alignItems: "space-between", marginTop: 5, marginLeft: 15, marginRight: 15 }}>
            <button style={{ height: "40px", width: "120px", borderRadius: "20px", border: "none", outline: "none", backgroundColor: "#000000", color: "#ffffff", fontSize: "15px", fontFamily: "Manrope-Bold", textAlign: "center" }} onClick={() => { deleteSaveAddressPickup({ data: pickupaddress.label })}}>Delete</button>
            <button style={{ height: "40px", width: "120px", borderRadius: "20px", border: "none", outline: "none", backgroundColor: "#000000", color: "#ffffff", fontSize: "15px", fontFamily: "Manrope-Bold", textAlign: "center" }} onClick={() => {
              setState({
                ...state,
                senderAdd: pickupaddress.address,
                senderAdd1: pickupaddress.address,
                senderContact: pickupaddress.number,
                senderBuilding: pickupaddress.buildingName,
                orgLat: pickupaddress.location.lat,
                orgLng: pickupaddress.location.lng,
                saveaspickup: pickupaddress.label,
                popup1: false
              })
            }}>Update</button>
          </div>
        </Card>
      </div>

    )
  }

  const showSaveaddressdrop = (dropaddress) => {
    return (
      <div style={{ display: "flex", flexDirection: "row", justifyContent: "space-evenly" }}>
        <Card style={{ width: '100%', height: "250px", borderRadius: "15px", backgroundColor: "#ffffff", border: "none", boxShadow: "5px 5px 11px 5px #0000000D", marginTop: "20px", marginBottom: "10px", cursor: "pointer" }} >
          <Card className="pending-order" style={{ width: '100%', height: "190px", borderRadius: "15px", backgroundColor: "#ffffff", border: "none", cursor: "pointer" }} onClick={() => {
            setState({
              ...state,
              receiverAdd: dropaddress.address,
              receiverAdd1: dropaddress.address,
              receiverContact: dropaddress.number,
              receiverBuilding: dropaddress.buildingName,
              destLat: dropaddress.location.lat,
              destLng: dropaddress.location.lng,
              popup2: false
            })
          }}>
            <Card.Body>
              <h3 className="sender-address" style={{ fontFamily: "Manrope-Bold", fontSize: "25px", color: "#000000" }}>{dropaddress.label}</h3>
              <h3 className="sender-address" style={{ fontFamily: "Manrope-Regular", fontSize: "16px", color: "#000000" }}>{dropaddress.number}</h3>
              <h3 className="sender-address" style={{ fontFamily: "Manrope-Regular", fontSize: "16px", color: "#000000" }}>{dropaddress.buildingName}</h3>
              <h3 className="receiver-address" style={{ fontFamily: "Manrope-Regular", fontSize: "16px", color: "#000000" }}>{dropaddress.address}</h3>
            </Card.Body>
          </Card>
          <div style={{ display: "flex", flexDirection: "row", justifyContent: "space-between", alignItems: "space-between", marginTop: 5, marginLeft: 15, marginRight: 15 }}>
            <button style={{ height: "40px", width: "120px", borderRadius: "20px", border: "none", outline: "none", backgroundColor: "#000000", color: "#ffffff", fontSize: "15px", fontFamily: "Manrope-Bold", textAlign: "center" }} onClick={() => { deleteSaveAddressDrop({ data: dropaddress.label })}}>Delete</button>
            <button style={{ height: "40px", width: "120px", borderRadius: "20px", border: "none", outline: "none", backgroundColor: "#000000", color: "#ffffff", fontSize: "15px", fontFamily: "Manrope-Bold", textAlign: "center" }} onClick={() => {
              setState({
                ...state,
                receiverAdd: dropaddress.address,
                receiverAdd1: dropaddress.address,
                receiverContact: dropaddress.number,
                receiverBuilding: dropaddress.buildingName,
                destLat: dropaddress.location.lat,
                destLng: dropaddress.location.lng,
                saveasdrop: dropaddress.label,
                popup2: false
              })
            }}>Update</button>
          </div>
        </Card>
      </div>

    )
  }

  useEffect(() => {
    (async function () {
      const { senderAddressModified, senderAdd, receiverAddressModified, receiverAdd } = state;
      try {
        if (senderAddressModified) {
          const { lat, lng } = await geoCodeAddress(senderAdd);
          setState({
            ...state,
            senderAddressModified: false,
            orgLat: lat,
            orgLng: lng,
            pickupURL: `https://www.google.com/maps/search/?api=1&query=${lat},${lng}`,
          });
        }

        if (receiverAddressModified) {
          const { lat, lng } = await geoCodeAddress(receiverAdd);
          setState({
            ...state,
            receiverAddressModified: false,
            destLat: lat,
            destLng: lng,
            pickupURL: `https://www.google.com/maps/search/?api=1&query=${lat},${lng}`
          })
        };
      } catch (e) {
        console.error("DesktopForm::useEffect::uncaught error", e);
        setState({ senderAddressModified: false, receiverAddressModified: false })
      }
    })()
  }, [state])

  // onChange Function
  const change = (e) => {
    if (e.target.name === "checked") {
      setState({
        ...state,
        checked: e.target.checked,
      });
    } else {
      setState({
        ...state,
        [e.target.name]: e.target.value,
      });
    }
  };

  // Keep Contact Number for Reciever & Sender Same as Primary Number
  const SameAsPrimaryNumber = (e) => {
    setState({
      ...state,
      [e.target.name]: state.contact1,
    });
  };

  // Used for RazorPay
  const loadScript = () => {
    return new Promise((resolve) => {
      const script = document.createElement("script");
      script.src = "https://checkout.razorpay.com/v1/checkout.js";
      document.body.appendChild(script);
      script.onload = () => {
        resolve(true);
      };
      script.onerror = () => {
        resolve(false);
      };
    });
  };

  const displayRazorpay = async () => {
    const role = localStorage.getItem("role");
    if (role === "Bussiness") {
      if (state.insuranceEstimate.length === 0) {
        alert("Please enter invoice amount to place order")
        return true
      }
    }

    setState({
      ...state,
      btnValue2: "Making Payment..."
    })
    const token = localStorage.getItem("token")
    const new_token = token.replace(/"/g, '')
    const res = await loadScript();
    if (!res) {
      alert(
        "Cannot connect to Razorpay. Check your Internet connection and try again later."
      );
      setState({
        ...state,
        btnValue2: "Make Payment"
      })
      return;
    }

    var bodyFormData = new FormData();
    bodyFormData.append('isInsured', state.isInsured)
    bodyFormData.append("files", state.files)
    bodyFormData.append("insuranceAmount", state.insuranceAmount)
    bodyFormData.append('orgLat', state.orgLat)
    bodyFormData.append('orgLng', state.orgLng)
    bodyFormData.append('destLat', state.destLat)
    bodyFormData.append('destLng', state.destLng)
    bodyFormData.append('senderAdd', state.senderAdd1)
    bodyFormData.append('senderBuilding', state.senderBuilding)
    bodyFormData.append('senderContact', state.senderContact)
    bodyFormData.append('receiverAdd', state.receiverAdd1)
    bodyFormData.append('receiverBuilding', state.receiverBuilding)
    bodyFormData.append('receiverContact', state.receiverContact)
    bodyFormData.append('deliveryMethod', state.deliveryMethod)
    bodyFormData.append('alreadyInsured', state.alreadyInsured)
    let data = await fetch(`${BaseURL}/orders`, {
      method: "POST",
      body: bodyFormData,
      headers: {
        Authorization: `bearer ${new_token}`
      },
    })
    data = await data;
    if (data.status === 200 || data.status === 201) {
      data = await data.json();
      setState({
        ...state,
        btnValue2: "Make Payment"
      })
      const { orderId, paymentProcessorOrderId } = data;
      if (typeof orderId === "string") {
        const options = {
          key: Variables.RazorPay,
          currency: data.currency,
          order_id: paymentProcessorOrderId,
          prefill: {
            email: email,
            contact: contact1,
            name: new_name
          },
          name: "Gordian Technologies Pvt. Ltd.",
          description: "Please pay for the delivery.",
          handler: function (response) {
            setState({
              ...state,
              orderId,
            });
            localStorage.setItem('orderId', orderId)
            alert(
              "Thank you for choosing Gordian. Your order has been placed successfully. Please check your E-mail for tracking and delivery executive related details"
            );
            setState(initState);
            document.getElementById("primarynumber").checked = false
            document.getElementById("primarynumber1").checked = false
            history.push("/trackOrder")
          },
        };
        var paymentObject = new window.Razorpay(options);
        paymentObject.open();
      }
      return data;
    } else if (data.status === 401) {
      const refreshToken = localStorage.getItem('refreshToken');
      const data = {
        refreshToken
      };

      var response = await fetch(`${BaseURL}/users/refresh-token`, {
        method: 'POST',
        body: JSON.stringify(data),
        headers: {
          "Content-Type": "application/json",
          // "Accept": 'application/json'
        }
      })
      response = await response;
      if (response.status === 200 || response.status === 201) {
        response = await response.json();
        localStorage.setItem('refreshToken', response.refreshToken)
        localStorage.setItem('token', response.jwtToken)
        let data1 = await fetch(`${BaseURL}/orders`, {
          method: "POST",
          body: bodyFormData,
          headers: {
            Authorization: `bearer ${response.jwtToken}`
          },
        })
        data1 = await data1
        if (data1.status === 200 || data1.status === 201) {
          data1 = await data1.json();
          setState({
            ...state,
            btnValue2: "Make Payment"
          })

          const { orderId, paymentProcessorOrderId } = data1;
          if (typeof orderId === "string") {
            const options = {
              key: Variables.RazorPay,
              currency: data.currency,
              order_id: paymentProcessorOrderId,
              prefill: {
                email: email,
                contact: contact1,
                name: new_name
              },
              name: "Gordian Technologies Pvt. Ltd.",
              description: "Please pay for the delivery.",
              handler: function (response) {
                setState({
                  ...state,
                  orderId,
                });
                localStorage.setItem('orderId', orderId)
                alert(
                  "Thank you for choosing Gordian. Your order has been placed successfully. Please check your E-mail for tracking and delivery executive related details"
                );
                setState(initState);
                document.getElementById("primarynumber").checked = false
                document.getElementById("primarynumber1").checked = false
                history.push("/trackOrder")

              },
            };

            var paymentObject1 = new window.Razorpay(options);
            paymentObject1.open();
          }
          return data1;

        } else if (data1.status === 404 || data1.status === 400) {
          data1 = await data1.json();
          alert("Please select address as available in the dropdown. You can enter more details in the Building/Landmark section for more accuracy.")
          setState({
            ...state,
            btnValue2: "Make Payment"
          })
          return data1;

        } else if (data1.status === 412 || data1.status === 500 || data1.status === 501 || data1.status === 502) {
          data1 = await data1.json();
          alert(data1.message);
          setState({
            ...state,
            btnValue2: "Make Payment"
          })
          return data1;
        }
        return response;
      } else {
        alert("Session expired. Please login to continue.");
        localStorage.clear("All");
        history.push("/Home")
      }

    } else if (data.status === 404 || data.status === 400) {
      data = await data.json();
      alert("Please select address as available in the dropdown. You can enter more details in the Building/Landmark section for more accuracy.");
      setState({
        ...state,
        btnValue2: "Make Payment"
      })
      return data
    } else if (data.status === 412 || data.status === 500 || data.status === 501 || data.status === 502) {
      data = await data.json();
      alert(data.message);
      setState({
        ...state,
        btnValue2: "Make Payment"
      })
      return data;
    }

  };

  // Sender Address Field
  // On address change, update the input field
  const handleSenderChange = (senderAdd) => {
    setState({
      ...state,
      senderAdd,
    });
  };

  // On address select, update the input field with the address selected
  // then fetch the lat, lng
  // update the state
  // --> check side effect ( useEffect )
  const handleSenderSelect = (address) => {
    const senderAddressModified = true;
    setState({
      ...state,
      senderAddressModified,
      senderAdd: address,
      senderAdd1: address
    });
  };

  // Receiver Address Field
  const handleReceiverChange = (receiverAdd) => {
    setState({
      ...state,
      receiverAdd,
    });
  };

  const handleReceiverSelect = (address) => {
    const receiverAddressModified = true;
    setState({
      ...state,
      receiverAddressModified,
      receiverAdd: address,
      receiverAdd1: address
    });
  };

  // Validations
  const isValid = (e) => {
    e.preventDefault();

    if (state.senderAdd.length === 0 || state.receiverAdd.length === 0) {
      alert("Details pertaining to the address is missing. Please enter the same to proceed further.")
      return false;
    } else if (state.senderContact.length === 0 || state.receiverContact.length === 0) {
      alert("Details pertaining to the phone number is missing. Please enter the same to proceed further.")
      return false;
    } else if ((state.senderBuilding === undefined || state.senderBuilding.length === 0) || (state.receiverBuilding === undefined || state.receiverBuilding.length === 0)) {
      alert('Details pertaining to the building number are missing. Please enter the same to proceed further.')
      return false;
    } else
      return true;
  };

  const submit = (e) => {
    e.preventDefault();

    setState({
      ...state,
      contact1Error: "",
      recContactError: "",
      SenContactError: "",
    });

    if (isValid(e)) {
      confirmOrder();
    }
  };

  const confirmOrder = () => {
    confirmAlert({
      title: "Important",
      message: "The delivery partner will reach the pickup location within 60 minutes of placing the order.",
      buttons: [
        {
          label: "Yes",
          onClick: displayRazorpay,
        },
        {
          label: "No",
          onClick: () => { },
        },
      ],
    });
  };


  return (
    <Row className="m-0">
      <Col
        lg={2} md={2} sm={2}
        className="text-center pt-5"
        style={{ backgroundColor: "white" }}
      >
        <Row className="gordian-logo" style={{ justifyContent: "center", alignItem: "center" }}>
          <img src={Logo} alt="Logo" className="logo" />
        </Row>
        <h1 style={{ fontSize: "1.563rem", textAlign: "center", fontFamily: "Manrope-Bold" }}>Gordian</h1>
        <Sidebar />
      </Col>
      <Col lg={7} md={7} sm={7} className="Form">
        <div className="NewOrder" style={{ marginLeft: "23px" }}>Place Order</div>
        <Container>
          <div className="SubHeading row">&emsp; Your Details</div>
          <Row className="row">
            <Col lg={12} md={12} sm={12}>
              <input
                type="text"
                placeholder="Enter your name"
                disabled
                className="field"
                name="name"
                value={state.name}
                onChange={(e) => change(e)}
                required
              />
            </Col>
          </Row>
          <Row className="row">
            <Col lg={6} md={6} sm={6}>
              <input
                type="email"
                placeholder="Enter your email"
                disabled
                className="field"
                name="email"
                value={state.email}
                onChange={(e) => change(e)}
                required
              />
            </Col>
            <Col lg={6} md={6} sm={6}>
              <input
                type="number"
                placeholder="Enter Your Contact No"
                className="field"
                disabled
                name="contact1"
                value={state.contact1}
                onChange={(e) => change(e)}
                required
              />
              <br />
              <div className="error">{state.contact1Error}</div>
            </Col>
          </Row>
          <hr />
          <div className="SubHeading row">&emsp; Pickup Details</div>
          <Row className="row">
            <Col lg={6} md={6} sm={6}>
              <PlacesAutocomplete
                value={state.senderAdd}
                onChange={handleSenderChange}
                onSelect={handleSenderSelect}
              >
                {({
                  getInputProps,
                  suggestions,
                  getSuggestionItemProps,
                  loading,
                }) => (
                  <div className="placesDiv">
                    <input
                      {...getInputProps({
                        placeholder: "Enter Pickup Address",
                        className: "location-search-input fieldAddress field",
                      })}
                    />
                    <div className="autocomplete-dropdown-container">
                      {loading && <div>Loading . . . .</div>}
                      {suggestions.map((suggestion) => {
                        const className = suggestion.active
                          ? "suggestion-item--active"
                          : "suggestion-item";
                        const style = suggestion.active
                          ? {
                            backgroundColor: "#fafafa",
                            cursor: "pointer",
                          }
                          : {
                            backgroundColor: "#ffffff",
                            cursor: "pointer",
                          };
                        return (
                          <div
                            {...getSuggestionItemProps(suggestion, {
                              className,
                              style,
                            })}
                          >
                            <hr />
                            <span>{suggestion.description}</span>
                          </div>
                        );
                      })}
                    </div>
                  </div>
                )}
              </PlacesAutocomplete>
            </Col>
            <Col lg={3} md={3} sm={3}>
              <button style={{ height: "40px", width: "140px", borderRadius: "20px", border: "none", outline: "none", backgroundColor: "#ffffff", color: "#000000", fontSize: "12px", fontFamily: "Manrope-SemiBold", textAlign: "center" }} onClick={getCurrentlocationpickup}><img src={Logout} style={{ height: "25px", width: "25px" }} alt="logout" /> Current location</button>
            </Col>

            <Col lg={3} md={3} sm={3}>
              <button style={{ height: "40px", width: "150px", borderRadius: "20px", border: "none", outline: "none", backgroundColor: "#ffffff", color: "#000000", fontSize: "11px", fontFamily: "Manrope-Bold", textAlign: "center" }} onClick={displaySaveaddresspickup}>{state.btnValue5}</button>
              <Popup open={state.popup1} position="bottom center">
                <div style={{ height: "600px", width: "350px", backgroundColor: "#F6F6FA", marginRight: "150px", marginBottom: "10px", padding: "10px", overflowY: "scroll" }}>
                  <h2 style={{ fontSize: "16px", textAlign: "center", fontFamily: "Manrope-SemiBold" }}>Save Address</h2>
                  <button style={{ height: "44px", width: "32px", fontSize: "17px", fontWeight: "Bold", outline: "none", border: "none", padding: "10px", position: "relative", marginLeft: "285px", marginTop: "-30px" }} onClick={() => { setState({ ...state, popup1: false }) }}>X</button>
                  {state.loadingpickup ? (
                    <Row style={{ justifyContent: "center", alignItem: "center", marginTop: "20px" }}>
                      <Loader
                        type="Oval"
                        color="#000000"
                        height={40}
                        width={40}
                      />
                    </Row>

                  ) : state.savedAddresspickup.length === 0 ? (
                    <h1 style={{ fontSize: "1.5rem", fontFamily: "Manrope-SemiBold", textAlign: "center", marginTop: "30px" }}>No Save Adresses</h1>
                  ) : (
                    // eslint-disable-next-line
                    state.savedAddresspickup.map((pickupaddress) => {
                      return showSaveaddresspickup(pickupaddress);
                    })
                  )}
                </div>
              </Popup>
            </Col>
          </Row>
          <Row className="row">
            <Col lg={6} md={6} sm={6}>
              <input
                type="text"
                placeholder="Building / Landmark"
                className="field"
                name="senderBuilding"
                value={state.senderBuilding}
                onChange={(e) => change(e)}
                required
              />
            </Col>
            <Col lg={6} md={6} sm={6}>
              <input
                type="number"
                placeholder="Enter Pickup Mobile Number"
                className="field"
                name="senderContact"
                value={state.senderContact}
                onChange={(e) => change(e)}
                required
              />
              <br />
              <div
                style={{
                  textAlign: "right",
                  fontSize: "0.75rem",
                }}
              >
                {/* <div className="error">{state.SenContactError}</div> */}
                <input
                  style={{ marginTop: "16px" }}
                  type="checkbox"
                  name="senderContact"
                  onChange={(e) => SameAsPrimaryNumber(e)}
                  id="primarynumber"
                />
                &ensp;
                <label>Same as Primary number</label>
              </div>
            </Col>

            <Col lg={6} md={6} sm={6}>
              <input
                type="text"
                placeholder="Save Address as"
                className="field"
                name="saveaspickup"
                value={state.saveaspickup}
                onChange={(e) => change(e)}
              />
            </Col>

            <Col lg={6} md={6} sm={6}>
              <button style={{ height: "40px", width: "140px", borderRadius: "20px", border: "none", outline: "none", backgroundColor: "#000000", color: "#ffffff", fontSize: "15px", fontFamily: "Manrope-Bold", textAlign: "center" }} onClick={saveAddressaspickup}>{state.btnValue3}</button>
            </Col>
          </Row>
          <hr />
          <div className="SubHeading row">&emsp; Drop Details</div>
          <Row className="row">
            <Col lg={6} md={6} sm={6}>
              <PlacesAutocomplete
                value={state.receiverAdd}
                onChange={handleReceiverChange}
                onSelect={handleReceiverSelect}
              >
                {({
                  getInputProps,
                  suggestions,
                  getSuggestionItemProps,
                  loading,
                }) => (
                  <div className="placesDiv">
                    <input
                      {...getInputProps({
                        placeholder: "Enter Drop Address",
                        className: "location-search-input fieldAddress field",
                      })}
                    />
                    <div className="autocomplete-dropdown-container">
                      {loading && <div>Loading...</div>}
                      {suggestions.map((suggestion) => {
                        const className = suggestion.active
                          ? "suggestion-item--active"
                          : "suggestion-item";

                        const style = suggestion.active
                          ? {
                            backgroundColor: "#fafafa",
                            cursor: "pointer",
                          }
                          : {
                            backgroundColor: "#ffffff",
                            cursor: "pointer",
                          };
                        return (
                          <div
                            {...getSuggestionItemProps(suggestion, {
                              className,
                              style,
                            })}
                          >
                            <hr />
                            <span>{suggestion.description}</span>
                          </div>
                        );
                      })}
                    </div>
                  </div>
                )}
              </PlacesAutocomplete>
            </Col>
            <Col lg={3} md={3} sm={3}>
              <button style={{ height: "40px", width: "140px", borderRadius: "20px", border: "none", outline: "none", backgroundColor: "#ffffff", color: "#000000", fontSize: "12px", fontFamily: "Manrope-SemiBold", textAlign: "center" }} onClick={getCurrentlocationdrop}><img src={Logout} style={{ height: "25px", width: "25px" }} alt="logout" /> Current location</button>
            </Col>
            <Col lg={3} md={3} sm={3}>
              <button style={{ height: "40px", width: "150px", borderRadius: "20px", border: "none", outline: "none", backgroundColor: "#ffffff", color: "#000000", fontSize: "11px", fontFamily: "Manrope-Bold", textAlign: "center" }} onClick={displaySaveaddressdrop}>{state.btnValue6}</button>
              <Popup open={state.popup2} position="bottom center">
                <div style={{ height: "600px", width: "350px", backgroundColor: "#F6F6FA", marginRight: "150px", marginBottom: "10px", padding: "10px", overflowY: "scroll" }}>
                  <h2 style={{ fontSize: "16px", textAlign: "center", fontFamily: "Manrope-SemiBold" }}>Save Address</h2>
                  <button style={{ height: "44px", width: "32px", fontSize: "17px", fontWeight: "Bold", outline: "none", border: "none", padding: "10px", position: "relative", marginLeft: "285px", marginTop: "-30px" }} onClick={() => { setState({ ...state, popup2: false }) }}>X</button>
                  {state.loadingdrop ? (
                    <Row style={{ justifyContent: "center", alignItem: "center", marginTop: "20px" }}>
                      <Loader
                        type="Oval"
                        color="#000000"
                        height={40}
                        width={40}
                      />
                    </Row>

                  ) : state.savedAddressdrop.length === 0 ? (
                    <h1 style={{ fontSize: "1.5rem", fontFamily: "Manrope-SemiBold", textAlign: "center", marginTop: "30px" }}>No Save Adresses</h1>
                  ) : (
                    // eslint-disable-next-line
                    state.savedAddressdrop.map((dropaddress) => {
                      return showSaveaddressdrop(dropaddress);
                    })
                  )}
                </div>
              </Popup>
            </Col>
          </Row>
          <Row className="row">
            <Col lg={6} md={6} sm={6}>
              <input
                type="text"
                placeholder="Building / Landmark"
                className="field"
                name="receiverBuilding"
                value={state.receiverBuilding}
                onChange={(e) => change(e)}
                required
              />
            </Col>
            <Col lg={6} md={6} sm={6}>
              <input
                type="number"
                placeholder="Enter Drop Mobile Number"
                className="field"
                name="receiverContact"
                value={state.receiverContact}
                onChange={(e) => change(e)}
                required
              />
              <br />
              <div
                style={{
                  textAlign: "right",
                  fontSize: "0.75rem",
                }}
              >
                {/* <div className="error">{state.recContactError}</div> */}
                <input
                  style={{ marginTop: "16px" }}
                  type="checkbox"
                  name="receiverContact"
                  onChange={(e) => SameAsPrimaryNumber(e)}
                  id="primarynumber1"
                />
                &ensp;
                <label>Same as Primary number</label>
              </div>
            </Col>
            <Col lg={6} md={6} sm={6}>
              <input
                type="text"
                placeholder="Save Address as"
                className="field"
                name="saveasdrop"
                value={state.saveasdrop}
                onChange={(e) => change(e)}
              />
            </Col>

            <Col lg={6} md={6} sm={6}>
              <button style={{ height: "40px", width: "140px", borderRadius: "20px", border: "none", outline: "none", backgroundColor: "#000000", color: "#ffffff", fontSize: "15px", fontFamily: "Manrope-Bold", textAlign: "center" }} onClick={saveAddressasdrop}>{state.btnValue4}</button>
            </Col>
          </Row>
        </Container>
      </Col>
      <Col lg={3} md={3} sm={3} style={{ backgroundColor: "white", height: '125vh' }} className="right-col">
        <Container>
          <h1 className="name" style={{ fontSize: "1.25rem", fontWeight: "600", marginTop: "20px", fontFamily: "Manrope-SemiBold" }}>Welcome, {new_name}</h1>
          <hr />
          <Instructions />
          {state.role === "Bussiness" ? (

            <Row style={{ justifyContent: "center", alignItem: "center" }}>
              {state.isInsured ? displayButton("white", "black", "Invoice Uploaded") : displayButton("black", "white", "Upload Invoice")}
            </Row>
          ) : null}
          <Row style={{ justifyContent: "center", alignItem: "center" }}>
            <button className="promocode" onClick={getestimate}>{state.btnValue1}</button>
          </Row>

          <div className="makepayment-button">
            <div className="horizontal-line" style={{ marginTop: "15px" }}>
              <hr />
              <Row style={{ justifyContent: "center", alignItem: "center", marginTop: "50px" }}>
                <button className="makepayment" onClick={(e) => submit(e)}>{state.btnValue2}</button>
              </Row>
            </div>
          </div>

          <div style={{ marginTop: "30px" }}>
            <h1 className="goto-text" style={{ color: "#92979D", fontSize: "0.75rem", fontFamily: "Manrope-SemiBold", textAlign: "center" }}>For any difficulties</h1>
            <Link to="Contactus2" style={{ textDecoration: "none" }}>
              <h1 className="contact-text" style={{ color: "#000000", fontSize: "0.875rem", fontFamily: "Manrope-Bold", textAlign: "center" }}>Contact Us</h1>
            </Link>
          </div>
        </Container>
      </Col>
    </Row>
  );
};

export default withRouter(DesktopForm);