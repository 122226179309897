import React, { Fragment, useState, useEffect } from "react";
import { Row, Col, Container } from "reactstrap";
import ProgressBar from "../Components/ProgressBar";
import PlacesAutocomplete, {
  geocodeByAddress,
  getLatLng,
} from "react-places-autocomplete";
import "./styles.mobile.css";
import "./styles.mobile.css";
import Variables from "../Globals/variables";
import { useHistory } from "react-router-dom";
import BackIcon from "./../assets/images/back.png";
import Card from 'react-bootstrap/Card';
import Logout from "./../assets/images/Logout.png";
import Loader from "react-loader-spinner";
import { confirmAlert } from "react-confirm-alert";

const BaseURL = Variables.baseUrl;

const MobileFormPage5 = (props) => {
  const name = localStorage.getItem("name");
  const new_name = name.replace(/"/g, "");
  const email = localStorage.getItem("email");
  const contact1 = localStorage.getItem("contact");
  const role = localStorage.getItem("role");

  const initError = {
    AddError: "",
    BuildingError: "",
    contactError: "",
  };

  var [error] = useState(initError);
  const history = useHistory();

  const [state, setState] = useState({
    contact1: "",
    isInsured: props.location.state.isInsured,
    files: props.location.state.files,
    insuranceAmount: props.location.state.insuranceAmount,
    insuranceEstimate: props.location.state.insuranceEstimate,
    role: role,
    senderAdd: props.location.state.senderAdd,
    senderAdd1: props.location.state.senderAdd1,
    senderBuilding: props.location.state.senderBuilding,
    senderContact: props.location.state.senderContact,
    receiverAdd: props.location.state.receiverAdd,
    receiverAdd1: props.location.state.receiverAdd1,
    receiverBuilding: props.location.state.receiverBuilding,
    receiverContact: props.location.state.receiverContact,
    destLat: props.location.state.destLat,
    destLng: props.location.state.destLng,
    orderId: null,
    promoCode: null,
    orgLat: props.location.state.orgLat,
    orgLng: props.location.state.orgLng,
    buttonColor: "#f6f6fa",
    buttonColor1: "#f6f6fa",
    deliveryMethod: "secure",
    btnValue: "Add Promocode",
    btnValue1: "Continue to Pay",
    savedAddressdrop: [],
    loadingdrop: false,
    btnValue4: "Save Address",
    saveasdrop: "",
    alreadyInsured: props.location.state.alreadyInsured
  });

  console.log("Mobile-5", state)

  useEffect(() => {
    displaySaveaddressdrop();
  }, 
  //eslint-disable-next-line
  [])

  async function getestimate() {
    const role = localStorage.getItem("role");
    if(role === "Bussiness"){
      if (state.insuranceEstimate.length === 0 ) {
        alert("Please enter invoice amount to place order")
        return true
      }
    }
    if ((state.senderBuilding === undefined || state.senderBuilding.length === 0) || (state.receiverBuilding === undefined || state.receiverBuilding.length === 0)) {
      alert('Details pertaining to the building number are missing. Please enter the same to proceed further.')
      return false;
    }
    setState({
      ...state,
      btnValue: "Applying Promocode..."
    })

    let results = await fetch(`${BaseURL}/orders/estimate?origins=${state.orgLat}&origins=${state.orgLng}&destinations=${state.destLat}&destinations=${state.destLng}&deliveryMethod=${state.deliveryMethod}&role=${role}`, {
      method: 'GET',
    })
    results = await results
    if (results.status === 200 || results.status === 201) {
      results = await results.json();
      localStorage.setItem("amount", results.data.amount);
      localStorage.setItem("distance", results.data.distance);
      history.push("/Mobilepromocode", {
        isInsured: state.isInsured,
        senderAdd: state.senderAdd,
        senderAdd1: state.senderAdd1,
        receiverAdd: state.receiverAdd,
        receiverAdd1: state.receiverAdd1,
        senderBuilding: state.senderBuilding,
        receiverBuilding: state.receiverBuilding,
        senderContact: state.senderContact,
        receiverContact: state.receiverContact,
        orgLat: state.orgLat,
        orgLng: state.orgLng,
        destLat: state.destLat,
        destLng: state.destLng,
        deliveryMethod: state.deliveryMethod,
        files: state.files,
        insuranceAmount: state.insuranceAmount,
        insuranceEstimate: state.insuranceEstimate,
        alreadyInsured: state.alreadyInsured
      })
      setState({
        ...state,
        btnValue: "Apply Promocode"
      })
      return results;
    } else if (results.status === 400 || results.status === 404) {
      results = await results.json();
      alert(results.message);
      setState({
        ...state,
        btnValue: "Apply Promocode"
      })
      return results;
    }
  }

  const displayButton = (bgColor, textColor, text) => {
    return (
      <button className="insurance-mobile" style={{ backgroundColor: bgColor, color: textColor }} onClick={AddInsurance} >{text}</button>
    )
  }

  function senderdetails() {
    history.push("/Sender-Details", {
      senderAdd: state.senderAdd,
      senderAdd1: state.senderAdd1,
      senderBuilding: state.senderBuilding,
      senderContact: state.senderContact,
      orgLat: state.orgLat,
      orgLng: state.orgLng
    })
  }

  function AddInsurance() {
    history.push("/MobileAddInsurance", {
      isInsured: state.isInsured,
      senderAdd: state.senderAdd,
      senderAdd1: state.senderAdd1,
      senderBuilding: state.senderBuilding,
      senderContact: state.senderContact,
      orgLat: state.orgLat,
      orgLng: state.orgLng,
      destLat: state.destLat,
      destLng: state.destLng,
      receiverAdd: state.receiverAdd,
      receiverAdd1: state.receiverAdd1,
      receiverBuilding: state.receiverBuilding,
      receiverContact: state.receiverContact,
    })
  }

  // Used for RazorPay
  const loadScript = () => {
    return new Promise((resolve) => {
      const script = document.createElement("script");
      script.src = "https://checkout.razorpay.com/v1/checkout.js";
      document.body.appendChild(script);
      script.onload = () => {
        resolve(true);
      };
      script.onerror = () => {
        resolve(false);
      };
    });
  };

  const displayRazorpay = async () => {
    const role = localStorage.getItem("role");
    if(role === "Bussiness"){
      if (state.insuranceEstimate.length === 0 ) {
        alert("Please enter invoice amount to place order")
        return true
      }
    }

    setState({
      ...state,
      btnValue1: "Making Payment...",
    });
    const token = localStorage.getItem("token");
    const new_token = token.replace(/"/g, "");
    const res = await loadScript();
    if (!res) {
      alert(
        "Cannot connect to Razorpay. Check your Internet connection and try again later."
      );
      setState({
        ...state,
        btnValue1: "Continue to Pay",
      });
      return;
    }

    var bodyFormData = new FormData();
    bodyFormData.append("isInsured", state.isInsured);
    bodyFormData.append("orgLat", state.orgLat);
    bodyFormData.append("orgLng", state.orgLng);
    bodyFormData.append("destLat", state.destLat);
    bodyFormData.append("destLng", state.destLng);
    bodyFormData.append("senderAdd", state.senderAdd1);
    bodyFormData.append("senderBuilding", state.senderBuilding);
    bodyFormData.append("senderContact", state.senderContact);
    bodyFormData.append("receiverAdd", state.receiverAdd1);
    bodyFormData.append("receiverBuilding", state.receiverBuilding);
    bodyFormData.append("receiverContact", state.receiverContact);
    bodyFormData.append("deliveryMethod", state.deliveryMethod);
    bodyFormData.append("alreadyInsured", state.alreadyInsured);
    if (state.role === "Bussiness") {
      bodyFormData.append("files", state.files)
      bodyFormData.append("insuranceAmount", state.insuranceAmount)

    }

    let data = await fetch(`${BaseURL}/orders`, {
      method: "POST",
      body: bodyFormData,
      headers: {
        Authorization: `bearer ${new_token}`,
      },
    });
    data = await data;
    if (data.status === 200 || data.status === 201) {
      data = await data.json();
      setState({
        ...state,
        btnValue1: "Continue to Pay"
      })
      const { orderId, paymentProcessorOrderId } = data;
      if (typeof orderId === "string") {
        const options = {
          key: Variables.RazorPay,
          currency: data.currency,
          order_id: paymentProcessorOrderId,
          prefill: {
            email: email,
            contact: contact1,
            name: new_name
          },
          name: "Gordian Technologies Pvt. Ltd.",
          description: "Please pay for the delivery.",
          handler: function (response) {
            setState({
              ...state,
              orderId,
            });
            localStorage.setItem('orderId', orderId)
            alert(
              "Thank you for choosing Gordian. Your order has been placed successfully. Please check your E-mail for tracking and delivery executive related details"
            );
            setState({
              senderAdd: "",
              senderAdd1: "",
              senderBuilding: "",
              senderContact: "",
              receiverAdd: "",
              receiverAdd1: "",
              receiverBuilding: "",
              receiverContact: "",
              promoCode: null,
              orgLat: null,
              orgLng: null,
              destLat: null,
              destLng: null,
              orderId: new Date().toLocaleString(),
              deliveryMethod: "secure",
              files: [],
              isInsured: false,
              insuranceAmount: null,
              btnValue1: "Continue to Pay",
            });
            history.push("/trackOrder")
          },
        };
        var paymentObject = new window.Razorpay(options);
        paymentObject.open();
      }
      return data;
    } else if (data.status === 401) {
      const refreshToken = localStorage.getItem('refreshToken');
      const data = {
        refreshToken
      };

      var response = await fetch(`${BaseURL}/users/refresh-token`, {
        method: 'POST',
        body: JSON.stringify(data),
        headers: {
          "Content-Type": "application/json",
          // "Accept": 'application/json'
        }
      })
      response = await response;
      if (response.status === 200 || response.status === 201) {
        response = await response.json();
        localStorage.setItem('refreshToken', response.refreshToken)
        localStorage.setItem('token', response.jwtToken)
        let data1 = await fetch(`${BaseURL}/orders`, {
          method: "POST",
          body: bodyFormData,
          headers: {
            Authorization: `bearer ${response.jwtToken}`
          },
        })
        data1 = await data1
        if (data1.status === 200 || data1.status === 201) {
          data1 = await data1.json();
          setState({
            ...state,
            btnValue1: "Continue to Pay"
          })

          const { orderId, paymentProcessorOrderId } = data1;
          if (typeof orderId === "string") {
            const options = {
              key: Variables.RazorPay,
              currency: data.currency,
              order_id: paymentProcessorOrderId,
              prefill: {
                email: email,
                contact: contact1,
                name: new_name
              },
              name: "Gordian Technologies Pvt. Ltd.",
              description: "Please pay for the delivery.",
              handler: function (response) {
                setState({
                  ...state,
                  orderId,
                });
                localStorage.setItem('orderId', orderId)
                alert(
                  "Thank you for choosing Gordian. Your order has been placed successfully. Please check your E-mail for tracking and delivery executive related details"
                );
                setState({
                  senderAdd: "",
                  senderAdd1: "",
                  senderBuilding: "",
                  senderContact: "",
                  receiverAdd: "",
                  receiverAdd1: "",
                  receiverBuilding: "",
                  receiverContact: "",
                  promoCode: null,
                  orgLat: null,
                  orgLng: null,
                  destLat: null,
                  destLng: null,
                  orderId: new Date().toLocaleString(),
                  deliveryMethod: "secure",
                  files: [],
                  isInsured: false,
                  insuranceAmount: null,
                  btnValue1: "Continue to Pay",
                });
                history.push("/trackOrder")

              },
            };

            var paymentObject1 = new window.Razorpay(options);
            paymentObject1.open();
          }
          return data1;

        } else if (data1.status === 412 ||data1.status === 404 || data1.status === 400 || data1.status === 500 || data1.status === 501) {
          data1 = await data1.json();
          alert(data1.message);
          setState({
            ...state,
            btnValue1: "Continue to Pay"
          })
          return data1;

        }
        return response;
      } else {
        alert("Session expired. Please login to continue.");
        localStorage.clear("All");
        history.push("/Home")
      }

    } else if (data.status === 412 || data.status === 404 || data.status === 400 || data.status === 500 || data.status === 501) {
      data = await data.json();
      alert(data.message);
      setState({
        ...state,
        btnValue1: "Continue to Pay"
      })
      return data
    }
  };

  const isValid = () => {
    error = initError;

    if (state.receiverAdd.length === 0) {
      error.AddError = "Address is required, please select from the dropdown.";
    }
    if ( state.receiverBuilding === undefined || state.receiverBuilding.length === 0) {
      error.BuildingError =
        "Landmark is required. If there's no Landmark, just enter N/A.";
    }
    if (state.receiverContact.length !== 10) {
      error.contactError =
        "Invalid Contact Number. Please enter 10 digit number only.";
    }

    if (
      error.AddError.length > 0 ||
      error.BuildingError.length > 0 ||
      error.contactError.length > 0
    ) {
      return false;
    } else {
      return true;
    }
  };

  const ValidSubmission = () => {
    if (isValid()) {
      return (
        <button
          onClick={(e) => confirmOrder(e)}
          type="button"
          className="btn btn-dark rounded-pill button2 mb-3"
        >
          {state.btnValue1}
        </button>
      );
    } else {
      return (
        <button
          disabled
          type="button"
          className="btn btn-dark rounded-pill button2 mb-3"
        >
          {state.btnValue1}
        </button>
      );
    }
  };

  const handleReceiverChange = (receiverAdd) => {
    setState({
      ...state,
      receiverAdd,
    });
  };

  const handleReceiverSelect = (receiverAdd) => {
    geocodeByAddress(receiverAdd)
      .then((results) => getLatLng(results[0]))
      .then((latLng) => {
        setState({
          ...state,
          receiverAdd,
          receiverAdd1: receiverAdd,
          destLat: latLng.lat,
          destLng: latLng.lng,
          dropURL: `https://www.google.com/maps/search/?api=1&query=${state.destLat},${state.destLng}`,
        });
      })
      .catch((error) => console.error("Error", error));
  };

  async function getCurrentlocationdrop() {
    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition((position) => {
        console.log("Drop current location:- ", position.coords)
        fetch(
          `https://maps.googleapis.com/maps/api/geocode/json?latlng=${position.coords.latitude},${position.coords.longitude}&key=${Variables.GOOGLE_API_KEY}`,
          {
            method: "GET",
          }
        ).then((response) => {
          return response.json();
        }).then((responseData) => {
          localStorage.setItem("destLat", position.coords.latitude);
          localStorage.setItem("destLng", position.coords.longitude);
          localStorage.setItem(
            "receiverAdd",
            responseData.results[0].formatted_address
          );
          setState({
            ...state,
            receiverAdd: responseData.results[0].formatted_address,
            receiverAdd1: responseData.results[0].formatted_address,
            destLat: position.coords.latitude,
            destLng: position.coords.longitude
          })
        }).catch((error) => {
          console.error("The error is", error);
        });
      })
    } else {
      alert("Location Permission Denied");
    }
  };

  async function saveAddressasdrop() {
    setState({
      ...state,
      btnValue4: "Saving..."
    })

    const data = {
      address: state.receiverAdd,
      lat: state.destLat,
      lng: state.destLng,
      label: state.saveasdrop,
      number: state.receiverContact,
      buildingName: state.receiverBuilding
    };

    const token = localStorage.getItem("token")

    let results = fetch(`${BaseURL}/users/saved-addresses`, {
      method: "PATCH",
      body: JSON.stringify(data),
      headers: {
        "Content-Type": "application/json",
        Authorization: `bearer ${token}`,
      },
    })
    results = await results;
    if (results.status === 200 || results.status === 201) {
      results = await results.json();
      alert("Address saved successfully.");
      setState({
        ...state,
        btnValue4: "Save Address"
      })
      return results
    } else if (results.status === 401) {
      const refreshToken = localStorage.getItem('refreshToken');
      const data = {
        refreshToken
      };

      var response = await fetch(`${BaseURL}/users/refresh-token`, {
        method: 'POST',
        body: JSON.stringify(data),
        headers: {
          "Content-Type": "application/json",
        }
      })
      response = await response;
      if (response.status === 200 || response.status === 201) {
        response = await response.json();
        localStorage.setItem('refreshToken', response.refreshToken)
        localStorage.setItem('token', response.jwtToken)
        let results1 = fetch(`${BaseURL}/users/saved-addresses`, {
          method: "PATCH",
          body: JSON.stringify(data),
          headers: {
            "Content-Type": "application/json",
            Authorization: `bearer ${response.jwtToken}`,
          },
        })
        results1 = await results1;
        if (results1.status === 200 || results1.status === 201) {
          results1 = await results1.json();
          alert("Address saved successfully.");
          setState({
            ...state,
            btnValue4: "Save Address"
          })
          return results1
        } else if (results1.status === 400 || results1.status === 412) {
          results1 = await results1.json();
          alert(results1.message);
          setState({
            ...state,
            btnValue4: "Save Address"
          })
          return results1
        }
      } else {
        alert("Session expired. Please login to continue.");
        localStorage.clear("All");
        history.push("/Home")
      }
    } else if (results.status === 400 || results.status === 412) {
      results = await results.json();
      alert(results.message);
      setState({
        ...state,
        btnValue4: "Save Address"
      })
      return results
    }
  }

  async function displaySaveaddressdrop() {
    setState({
      ...state,
      loadingdrop: true,
    })
    const token = localStorage.getItem("token");

    let results = fetch(`${BaseURL}/users/saved-addresses`, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: `bearer ${token}`,
      },
    })
    results = await results;
    if (results.status === 200 || results.status === 201) {
      results = await results.json();
      console.log("All Save Address:-", results.data);
      setState({
        ...state,
        savedAddressdrop: results.data,
        loadingdrop: false,
      })
      return results
    } else if (results.status === 401) {
      const refreshToken = localStorage.getItem('refreshToken');
      const data = {
        refreshToken
      };

      var response = await fetch(`${BaseURL}/users/refresh-token`, {
        method: 'POST',
        body: JSON.stringify(data),
        headers: {
          "Content-Type": "application/json",
        }
      })
      response = await response;
      if (response.status === 200 || response.status === 201) {
        response = await response.json();
        localStorage.setItem('refreshToken', response.refreshToken)
        localStorage.setItem('token', response.jwtToken)
        let results1 = fetch(`${BaseURL}/users/saved-addresses`, {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
            Authorization: `bearer ${response.jwtToken}`,
          },
        })
        results1 = await results1;
        if (results1.status === 200 || results1.status === 201) {
          results1 = await results1.json();
          console.log("All Save Address:-", results1.data);
          setState({
            ...state,
            savedAddressdrop: results1.data,
            loadingdrop: false,
          })
          return results1
        } else {
          alert("Something went worng!");
          setState({
            ...state,
            savedAddressdrop: {},
            loadingdrop: false,
          })
        }
      } else {
        alert("Session expired. Please login to continue.");
        localStorage.clear("All");
        history.push("/Home")
      }
    } else {
      alert("Something went worng!");
      setState({
        ...state,
        savedAddressdrop: {},
        loadingdrop: false,
      })
    }
  }

  const showSaveaddressdrop = (dropaddress) => {
    return (
      <div style={{ display: "flex", flexDirection: "row", justifyContent: "space-evenly" }}>
        <Card style={{ width: '100%', height: "230px", borderRadius: "15px", backgroundColor: "#ffffff", border: "none", boxShadow: "5px 5px 11px 5px #0000000D", marginTop: "20px", marginBottom: "10px" }} onClick={() => {
          setState({
            ...state,
            receiverAdd: dropaddress.address,
            receiverAdd1: dropaddress.address,
            receiverContact: dropaddress.number,
            receiverBuilding: dropaddress.buildingName,
            destLat: dropaddress.location.lat,
            destLng: dropaddress.location.lng,
          })
        }}>
          <Card.Body>
            <h3 style={{ fontFamily: "Manrope-Bold", fontSize: "25px", color: "#000000" }}>{dropaddress.label}</h3>
            <h3 style={{ fontFamily: "Manrope-Regular", fontSize: "16px", color: "#000000" }}>{dropaddress.number}</h3>
            <h3 style={{ fontFamily: "Manrope-Regular", fontSize: "16px", color: "#000000" }}>{dropaddress.buildingName}</h3>
            <h3 style={{ fontFamily: "Manrope-Regular", fontSize: "16px", color: "#000000" }}>{dropaddress.address}</h3>
          </Card.Body>
        </Card>
      </div>

    )
  }

  const confirmOrder = () => {
    confirmAlert({
      title: "Important",
      message: "The delivery partner will reach the pickup location within 60 minutes of placing the order.",
      buttons: [
        {
          label: "Yes",
          onClick: displayRazorpay,
        },
        {
          label: "No",
          onClick: () => { },
        },
      ],
    });
  };

  return (
    <Fragment>
      <Container>
        <Row className="m-0 mt-2" style={{ justifyContent: "space-between", alignItem: "space-beween" }}>
          {/* <Col xs={8}></Col> */}
          <Card style={{ width: '50px', height: "50px", borderRadius: "30px", backgroundColor: "#F5F6FA", border: "none", boxShadow: "5px 5px 11px 5px #0000000D" }} onClick={senderdetails}>
            <Card.Body>
              <img style={{ height: "30px", width: "27px", marginTop: "-15px", marginLeft: "-11px" }} src={BackIcon} alt="backicon" />
            </Card.Body>
          </Card>
          <Col xs={4} style={{ marginTop: "10px" }}>
            <ProgressBar width="100%" steps="Step 4/4" />
          </Col>
        </Row>
        <div className="NewOrder">Place Order</div>
        <br />
        <div className="SubHeading">Drop Details</div>
        <Row>
          <Col>
            <PlacesAutocomplete
              value={state.receiverAdd}
              onChange={handleReceiverChange}
              onSelect={handleReceiverSelect}
            >
              {({
                getInputProps,
                suggestions,
                getSuggestionItemProps,
                loading,
              }) => (
                <div className="placesDiv">
                  <input
                    {...getInputProps({
                      placeholder: "Enter Drop Address",
                      className: "location-search-input fieldAddress field",
                    })}
                  />
                  <div className="autocomplete-dropdown-container">
                    {loading && <div>Loading...</div>}
                    {suggestions.map((suggestion) => {
                      const className = suggestion.active
                        ? "suggestion-item--active"
                        : "suggestion-item";

                      const style = suggestion.active
                        ? {
                          backgroundColor: "#fafafa",
                          cursor: "pointer",
                        }
                        : {
                          backgroundColor: "#ffffff",
                          cursor: "pointer",
                        };
                      return (
                        <div
                          {...getSuggestionItemProps(suggestion, {
                            className,
                            style,
                          })}
                        >
                          <hr />
                          <span>{suggestion.description}</span>
                        </div>
                      );
                    })}
                  </div>
                </div>
              )}
            </PlacesAutocomplete>
          </Col>
          <Col>
            <button style={{ height: "35px", width: "120px", borderRadius: "20px", border: "none", outline: "none", backgroundColor: "#ffffff", color: "#000000", fontSize: "10px", fontFamily: "Manrope-SemiBold", textAlign: "center" }} onClick={getCurrentlocationdrop}><img src={Logout} style={{ height: "20px", width: "20px" }} alt="logout" /> Current location</button>
          </Col>
        </Row>
        <Row>
          <Col>
            <input
              type="text"
              placeholder="Enter Building or Landmark"
              className="field"
              value={state.receiverBuilding}
              onChange={(e) =>
                setState({ ...state, receiverBuilding: e.target.value })
              }
            />
            <br />
          </Col>
        </Row>
        <Row>
          <Col>
            <input
              type="number"
              placeholder="Enter Pickup number"
              className="field"
              value={state.receiverContact}
              onChange={(e) =>
                setState({ ...state, receiverContact: e.target.value })
              }
            />
            <br />
            <div
              style={{
                textAlign: "center",
                fontSize: "12px",
              }}
            >
              <input
                style={{ marginTop: "16px" }}
                type="checkbox"
                onChange={() =>
                  setState({
                    ...state,
                    receiverContact: contact1,
                  })
                }
              />
              &ensp;
              <label>Same as Primary number</label>
            </div>
          </Col>
        </Row>
        <Row>
          <Col>
            <input
              type="text"
              placeholder="Save as address"
              className="field"
              value={state.saveasdrop}
              onChange={(e) =>
                setState({ ...state, saveasdrop: e.target.value })
              }
            />
            <br />
          </Col>
          <Col>
            <button style={{ height: "35px", width: "120px", borderRadius: "20px", border: "none", outline: "none", backgroundColor: "#000000", color: "#ffffff", fontSize: "12px", fontFamily: "Manrope-Bold", textAlign: "center" }} onClick={saveAddressasdrop}>{state.btnValue4}</button>
          </Col>
        </Row>
        {/* <Row style={{ justifyContent: "center", alignItem: "center" }}>
          <button
            className="Mobile-secure-button"
            onClick={SecureMethod}
            style={{
              backgroundColor:
                state.deliveryMethod === "secure" ? "#000000" : "#f6f6fa",
              zIndex: state.deliveryMethod === "secure" ? "1" : "0",
            }}
          >
            Secure
          </button>
          <button
            className="Mobile-regular-button"
            onClick={RegularMethod}
            style={{
              backgroundColor:
                state.deliveryMethod === "regular" ? "#000000" : "#f6f6fa",
              zIndex: state.deliveryMethod === "regular" ? "1" : "0",
            }}
          >
            Regular
          </button>
        </Row> */}
        {state.role === "Bussiness" ? (
          <Row style={{ justifyContent: "center", alignItem: "center" }}>
            {/* <button className="insurance-mobile" onClick={AddInsurance} >{state.isInsured === true ? ("Insurance Added") : (" Add Insurance")}</button> */}
            {state.isInsured ? displayButton("white", "black", "Invoice Uploaded") : displayButton("black", "white", "Upload Invoice")}
          </Row>
        ) : null}

        <Row style={{ justifyContent: "center", alignItem: "center" }}>
          <button className="promocode-mobile" onClick={getestimate}>
            {state.btnValue}
          </button>
        </Row>
        <Row style={{ justifyContent: "center", alignItem: "center" }}>
          {ValidSubmission()}
        </Row>
        <div>
          {state.loadingdrop ? (
            <Row style={{ justifyContent: "center", alignItem: "center", marginTop: "20px" }}>
              <Loader
                type="Oval"
                color="#000000"
                height={40}
                width={40}
              />
            </Row>

          ) : state.savedAddressdrop.length === 0 ? (
            <h1 style={{ fontSize: "1.5rem", fontFamily: "Manrope-SemiBold", textAlign: "center", marginTop: "30px" }}>No Save Adresses</h1>
          ) : (
              // eslint-disable-next-line
              state.savedAddressdrop.map((dropaddress) => {
                return showSaveaddressdrop(dropaddress);
              })
            )}
        </div>
      </Container>
    </Fragment>
  );
};

export default MobileFormPage5;
