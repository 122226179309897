import React, { Fragment, useEffect, useState } from "react";
import { Row, Col, Container } from "reactstrap";
import { Link } from "react-router-dom";
import ProgressBar from "../Components/ProgressBar";
import PlacesAutocomplete, {
  geocodeByAddress,
  getLatLng,
} from "react-places-autocomplete";
import BackIcon from "./../assets/images/back.png";
import Card from 'react-bootstrap/Card';
import Variables from "./../Globals/variables";
import { useHistory } from "react-router-dom";
import Logout from "./../assets/images/Logout.png";
import Loader from "react-loader-spinner";

const BaseURL = Variables.baseUrl;

const MobileFormPage4 = (props) => {
  const contact1 = localStorage.getItem("contact")

  const initError = {
    AddError: "",
    BuildingError: "",
    contactError: "",
  };

  var [error] = useState(initError);

  const [state, setState] = useState({
    contact1: contact1,
    senderAdd: props.location.state.senderAdd,
    senderAdd1: props.location.state.senderAdd1,
    senderBuilding: props.location.state.senderBuilding,
    senderContact: props.location.state.senderContact,
    orgLat: props.location.state.orgLat,
    orgLng: props.location.state.orgLng,
    receiverAdd: "",
    receiverAdd1: "",
    receiverBuilding: "",
    receiverContact: "",
    destLat: "",
    destLng: "",
    isInsured: false,
    files: [],
    insuranceAmount: "1",
    savedAddresspickup: [],
    loadingpickup: false,
    saveaspickup: "",
    btnValue3: "Save Address",
    alreadyInsured: false,
    insuranceEstimate: ""
  });
  const history = useHistory();

  console.log(state)

  useEffect (() => {
    displaySaveaddresspickup();
  }, 
  //eslint-disable-next-line
  [])

  const isValid = () => {
    error = initError;

    if (state.senderAdd.length === 0) {
      error.AddError = "Address is required, please select from the dropdown.";
    }
    if ( state.senderBuilding === undefined || state.senderBuilding.length === 0) {
      error.BuildingError =
        "Landmark is reuired. If there's no Landmark, just enter N/A.";
    }
    if (state.senderContact.length !== 10) {
      error.contactError = "Invalid Contact Number";
    }

    if (
      error.AddError.length > 0 ||
      error.BuildingError.length > 0 ||
      error.contactError.length > 0
    ) {
      return false;
    } else {
      return true;
    }
  };

  const ValidSubmission = () => {
    if (isValid()) {
      return (
        <Link
          to={{
            pathname: "/Drop-Details",
            state: {
              senderAdd: state.senderAdd,
              senderAdd1: state.senderAdd1,
              senderBuilding: state.senderBuilding,
              senderContact: state.senderContact,
              orgLat: state.orgLat,
              orgLng: state.orgLng,
              receiverAdd: state.receiverAdd,
              receiverAdd1: state.receiverAdd1,
              receiverBuilding: state.receiverBuilding,
              receiverContact: state.receiverContact,
              destLat: state.destLat,
              destLng: state.destLng,
              isInsured: state.isInsured,
              files: state.files,
              insuranceAmount: state.insuranceAmount,
              alreadyInsured: state.alreadyInsured,
              insuranceEstimate: state.insuranceEstimate
            },
          }}
          type="button"
          className="btn btn-dark rounded-pill button3 mb-3"
        >
          Next
        </Link>
      );
    } else {
      return (
        <button
          disabled
          type="button"
          className="btn btn-dark rounded-pill button3 mb-3"
        >
          Next
        </button>
      );
    }
  };

  const handleSenderChange = (senderAdd) => {
    setState({
      ...state,
      senderAdd,
    });
  };

  const handleSenderSelect = (senderAdd) => {
    geocodeByAddress(senderAdd)
      .then((results) => getLatLng(results[0]))
      .then((latLng) => {
        setState({
          ...state,
          senderAdd,
          senderAdd1: senderAdd,
          orgLat: latLng.lat,
          orgLng: latLng.lng,
          pickupURL: `https://www.google.com/maps/search/?api=1&query=${state.orgLat},${state.orgLng}`,
        });
      })
      .catch((error) => console.error("Error", error));
  };

  async function getCurrentlocationpickup() {
    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition((position) => {
        console.log("Pickup current location:- ", position.coords)
        fetch(
          `https://maps.googleapis.com/maps/api/geocode/json?latlng=${position.coords.latitude},${position.coords.longitude}&key=${Variables.GOOGLE_API_KEY}`,
          {
            method: "GET",
          }
        ).then((response) => {
          return response.json();
        }).then((responseData) => {
          localStorage.setItem("orgLat", position.coords.latitude);
          localStorage.setItem("orgLng", position.coords.longitude);
          localStorage.setItem(
            "senderAdd",
            responseData.results[0].formatted_address,
          );
          setState({
            ...state,
            senderAdd: responseData.results[0].formatted_address,
            senderAdd1: responseData.results[0].formatted_address,
            orgLat: position.coords.latitude,
            orgLng: position.coords.longitude
          })
        }).catch((error) => {
          console.error("The error is", error);
        });
      })
    } else {
      alert("Location Permission Denied");
    }
  };

  async function saveAddressaspickup() {
    setState({
      ...state,
      btnValue3: "Saving..."
    })

    const data = {
      address: state.senderAdd,
      lat: state.orgLat,
      lng: state.orgLng,
      label: state.saveaspickup,
      number: state.senderContact,
      buildingName: state.senderBuilding
    };

    const token = localStorage.getItem("token")

    let results = fetch(`${BaseURL}/users/saved-addresses`, {
      method: "PATCH",
      body: JSON.stringify(data),
      headers: {
        "Content-Type": "application/json",
        Authorization: `bearer ${token}`,
      },
    })
    results = await results;
    if (results.status === 200 || results.status === 201) {
      results = await results.json();
      alert("Address saved successfully");
      setState({
        ...state,
        btnValue3: "Save Address"
      })
      return results
    } else if (results.status === 401) {
      const refreshToken = localStorage.getItem('refreshToken');
      const data = {
        refreshToken
      };

      var response = await fetch(`${BaseURL}/users/refresh-token`, {
        method: 'POST',
        body: JSON.stringify(data),
        headers: {
          "Content-Type": "application/json",
        }
      })
      response = await response;
      if (response.status === 200 || response.status === 201) {
        response = await response.json();
        localStorage.setItem('refreshToken', response.refreshToken)
        localStorage.setItem('token', response.jwtToken)
        let results1 = fetch(`${BaseURL}/users/saved-addresses`, {
          method: "PATCH",
          body: JSON.stringify(data),
          headers: {
            "Content-Type": "application/json",
            Authorization: `bearer ${response.jwtToken}`,
          },
        })
        results1 = await results1;
        if (results1.status === 200 || results1.status === 201) {
          results1 = await results1.json();
          alert("Address saved successfully.");
          setState({
            ...state,
            btnValue3: "Save Address"
          })
          return results1
        } else if (results1.status === 400 || results1.status === 412) {
          results1 = await results1.json();
          alert(results1.message);
          setState({
            ...state,
            btnValue3: "Save Address"
          })
          return results1
        }
      } else {
        alert("Session expired. Please login to continue.");
        localStorage.clear("All");
        history.push("/Home")
      }
    } else if (results.status === 400 || results.status === 412) {
      results = await results.json();
      alert(results.message);
      setState({
        ...state,
        btnValue3: "Save Address"
      })
      return results
    }
  }

  async function displaySaveaddresspickup() {
    setState({
      ...state,
      loadingpickup: true,
    })
    const token = localStorage.getItem("token");

    let results = fetch(`${BaseURL}/users/saved-addresses`, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: `bearer ${token}`,
      },
    })
    results = await results;
    if (results.status === 200 || results.status === 201) {
      results = await results.json();
      console.log("All Save Address:-", results.data);
      setState({
        ...state,
        savedAddresspickup: results.data,
        loadingpickup: false,
      })
      return results
    } else if (results.status === 401) {
      const refreshToken = localStorage.getItem('refreshToken');
      const data = {
        refreshToken
      };

      var response = await fetch(`${BaseURL}/users/refresh-token`, {
        method: 'POST',
        body: JSON.stringify(data),
        headers: {
          "Content-Type": "application/json",
        }
      })
      response = await response;
      if (response.status === 200 || response.status === 201) {
        response = await response.json();
        localStorage.setItem('refreshToken', response.refreshToken)
        localStorage.setItem('token', response.jwtToken)
        let results1 = fetch(`${BaseURL}/users/saved-addresses`, {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
            Authorization: `bearer ${response.jwtToken}`,
          },
        })
        results1 = await results1;
        if (results1.status === 200 || results1.status === 201) {
          results1 = await results1.json();
          console.log("All Save Address:-", results1.data);
          setState({
            ...state,
            savedAddresspickup: results1.data,
            loadingpickup: false,
          })
          return results1
        } else {
          alert("Something went worng!");
          setState({
            ...state,
            savedAddresspickup: {},
            loadingpickup: false,
          })
        }
      } else {
        alert("Session expired. Please login to continue.");
        localStorage.clear("All");
        history.push("/Home")
      }
    } else {
      alert("Something went worng!");
      setState({
        ...state,
        savedAddresspickup: {},
        loadingpickup: false,
      })
    }
  }

  const showSaveaddresspickup = (pickupaddress) => {
    return (
      <div style={{ display: "flex", flexDirection: "row", justifyContent: "space-evenly" }}>
        <Card style={{ width: '100%', height: "230px", borderRadius: "15px", backgroundColor: "#ffffff", border: "none", boxShadow: "5px 5px 11px 5px #0000000D", marginTop: "10px", marginBottom: "10px" }} onClick={() => {
          setState({
            ...state,
            senderAdd: pickupaddress.address,
            senderAdd1: pickupaddress.address,
            senderContact: pickupaddress.number,
            senderBuilding: pickupaddress.buildingName,
            orgLat: pickupaddress.location.lat,
            orgLng: pickupaddress.location.lng,
          })
        }}>
          <Card.Body>
            <h3  style={{ fontFamily: "Manrope-Bold", fontSize: "25px", color: "#000000" }}>{pickupaddress.label}</h3>
            <h3  style={{ fontFamily: "Manrope-Regular", fontSize: "16px", color: "#000000" }}>{pickupaddress.number}</h3>
            <h3  style={{ fontFamily: "Manrope-Regular", fontSize: "16px", color: "#000000" }}>{pickupaddress.buildingName}</h3>
            <h3  style={{ fontFamily: "Manrope-Regular", fontSize: "16px", color: "#000000" }}>{pickupaddress.address}</h3>
          </Card.Body>
        </Card>
      </div>

    )
  }

  return (
    <Fragment>
      <Container>
        <Row className="m-0 mt-2" style={{ justifyContent: "space-between", alignItem: "space-between" }}>
          {/* <Col xs={8}></Col> */}
          <Link to="/YourDetails" style={{ textDecoration: 'none' }} >
            <Card style={{ width: '50px', height: "50px", borderRadius: "30px", backgroundColor: "#F5F6FA", border: "none", boxShadow: "5px 5px 11px 5px #0000000D" }}>
              <Card.Body>
                <img style={{ height: "30px", width: "27px", marginTop: "-15px", marginLeft: "-11px" }} src={BackIcon} alt="backicon" />
              </Card.Body>
            </Card>
          </Link>
          <Col xs={4} style={{ marginTop: "10px" }}>
            <ProgressBar width="75%" steps="Step 3/4" />
          </Col>
        </Row>
        <div className="NewOrder">Place Order</div>
        <br />
        <div className="SubHeading">Pickup Details</div>
        <Row>
          <Col>
            <PlacesAutocomplete
              value={state.senderAdd}
              onChange={handleSenderChange}
              onSelect={handleSenderSelect}
            >
              {({
                getInputProps,
                suggestions,
                getSuggestionItemProps,
                loading,
              }) => (
                <div className="placesDiv">
                  <input
                    {...getInputProps({
                      placeholder: "Enter Pickup Address",
                      className: "location-search-input fieldAddress field",
                    })}
                  />
                  <div className="autocomplete-dropdown-container">
                    {loading && <div>Loading . . . .</div>}
                    {suggestions.map((suggestion) => {
                      const className = suggestion.active
                        ? "suggestion-item--active"
                        : "suggestion-item";
                      const style = suggestion.active
                        ? {
                          backgroundColor: "#fafafa",
                          cursor: "pointer",
                        }
                        : {
                          backgroundColor: "#ffffff",
                          cursor: "pointer",
                        };
                      return (
                        <div
                          {...getSuggestionItemProps(suggestion, {
                            className,
                            style,
                          })}
                        >
                          <hr />
                          <span>{suggestion.description}</span>
                        </div>
                      );
                    })}
                  </div>
                </div>
              )}
            </PlacesAutocomplete>
          </Col>
          <Col>
            <button style={{ height: "35px", width: "120px", borderRadius: "20px", border: "none", outline: "none", backgroundColor: "#ffffff", color: "#000000", fontSize: "10px", fontFamily: "Manrope-SemiBold", textAlign: "center" }} onClick={getCurrentlocationpickup}><img src={Logout} style={{ height: "20px", width: "20px" }} alt="logout" /> Current location</button>
          </Col>
        </Row>
        <Row>
          <Col>
            <input
              type="text"
              placeholder="Enter Building or Landmark"
              className="field"
              value={state.senderBuilding}
              onChange={(e) =>
                setState({ ...state, senderBuilding: e.target.value })
              }
            />
            <br />
          </Col>
        </Row>
        <Row>
          <Col>
            <input
              type="number"
              placeholder="Enter Pickup number"
              className="field"
              value={state.senderContact}
              onChange={(e) =>
                setState({ ...state, senderContact: e.target.value })
              }
            />
            <br />
            <div
              style={{
                textAlign: "center",
                fontSize: "12px",
              }}
            >
              <input
                style={{ marginTop: "16px" }}
                type="checkbox"
                onChange={() =>
                  setState({
                    ...state,
                    senderContact: props.location.state.contact1,
                  })
                }
              />
              &ensp;
              <label>
                Same as Primary number
              </label>
            </div>
          </Col>
        </Row>
        <Row>
          <Col>
            <input
              type="text"
              placeholder="Save as address"
              className="field"
              value={state.saveaspickup}
              onChange={(e) =>
                setState({ ...state, saveaspickup: e.target.value })
              }
            />
            <br />
          </Col>
          <Col>
            <button style={{ height: "35px", width: "120px", borderRadius: "20px", border: "none", outline: "none", backgroundColor: "#000000", color: "#ffffff", fontSize: "12px", fontFamily: "Manrope-Bold", textAlign: "center" }} onClick={saveAddressaspickup}>{state.btnValue3}</button>
          </Col>
        </Row>
        <Row style={{ marginLeft: "-100px" }}>{ValidSubmission()}</Row>
        <div>
          {state.loadingpickup ? (
            <Row style={{ justifyContent: "center", alignItem: "center", marginTop: "20px" }}>
              <Loader
                type="Oval"
                color="#000000"
                height={40}
                width={40}
              />
            </Row>

          ) : state.savedAddresspickup.length === 0 ? (
            <h1 style={{ fontSize: "1.5rem", fontFamily: "Manrope-SemiBold", textAlign: "center", marginTop: "30px" }}>No Save Adresses</h1>
          ) : (
              // eslint-disable-next-line
              state.savedAddresspickup.map((pickupaddress) => {
                return showSaveaddresspickup(pickupaddress);
              })
            )}
        </div>
      </Container>
    </Fragment>
  );
};

export default MobileFormPage4;
