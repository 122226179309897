import React, { useState, useEffect } from 'react'
import { Row, Col, Container } from 'reactstrap'
import Logo from './../assets/images/Logo1.png'
import { useHistory } from 'react-router-dom'
import Sidebar from './../Components/Sidebar/sidebar'
import PlacesAutocomplete from 'react-places-autocomplete'
import './styles.desktop.css';
import { geoCodeAddress } from './../services/GoogleMapsService'
import './../Globals/Fonts.css'
import BackIcon from "./../assets/images/back.png";
import Card from 'react-bootstrap/Card';
import Variables from "./../Globals/variables";
import Loader from "react-loader-spinner";
import { confirmAlert } from "react-confirm-alert";

const BaseURL = Variables.baseUrl;

function AddInsurance(props) {

    const name = localStorage.getItem('name')
    const new_name = name.replace(/"/g, '')
    const email = localStorage.getItem("email");
    const contact1 = localStorage.getItem("contact");

    const initState = {
        name: new_name,
        email: email,
        contact1: contact1,
        isInsured: props.location.state.isInsured,
        senderName: props.location.state.senderName,
        senderFlat: props.location.state.senderFlat,
        senderAdd: props.location.state.senderAdd,
        senderAdd1: props.location.state.senderAdd1,
        senderBuilding: props.location.state.senderBuilding,
        senderContact: props.location.state.senderContact,
        receiverName: props.location.state.receiverName,
        receiverFlat: props.location.state.receiverFlat,
        receiverAdd: props.location.state.receiverAdd,
        receiverAdd1: props.location.state.receiverAdd1,
        receiverBuilding: props.location.state.receiverBuilding,
        receiverContact: props.location.state.receiverContact,
        orgLat: props.location.state.orgLat,
        orgLng: props.location.state.orgLng,
        destLat: props.location.state.destLat,
        destLng: props.location.state.destLng,
        SenderError: '',
        ReceiverError: '',
        senderAddressModified: false,
        receiverAddressModified: false,
        deliveryMethod: props.location.state.deliveryMethod,
        files: [],
        insuranceEstimate: -1,
        totalAmount: 0,
        loading: false,
        insuranceAmount: "",
        amount: props.location.state.amount,
        savedAddresspickup: props.location.state.savedAddresspickup,
        savedAddressdrop: props.location.state.savedAddressdrop,
        popup1: props.location.state.popup1,
        popup2: props.location.state.popup2,
        alreadyInsured: (props.location.state === undefined || props.location.state.alreadyInsured === undefined) ? false : props.location.state.alreadyInsured,
        Check: false
    }

    console.log(initState)

    const [state, setState] = useState(initState)
    const history = useHistory()

    useEffect(() => {
        ; (async function () {
            const {
                senderAddressModified,
                senderAdd,
                receiverAddressModified,
                receiverAdd
            } = state
            try {
                if (senderAddressModified) {
                    const { lat, lng } = await geoCodeAddress(senderAdd)
                    setState({
                        ...state,
                        senderAddressModified: false,
                        orgLat: lat,
                        orgLng: lng,
                        pickupURL: `https://www.google.com/maps/search/?api=1&query=${lat},${lng}`
                    })
                }

                if (receiverAddressModified) {
                    const { lat, lng } = await geoCodeAddress(receiverAdd)
                    setState({
                        ...state,
                        receiverAddressModified: false,
                        destLat: lat,
                        destLng: lng,
                        pickupURL: `https://www.google.com/maps/search/?api=1&query=${lat},${lng}`
                    })
                }
            } catch (e) {
                console.error('DesktopForm::useEffect::uncaught error', e)
                setState({
                    senderAddressModified: false,
                    receiverAddressModified: false
                })
            }
        })()
    }, [state])

    // onChange Function
    const change = e => {
        if (e.target.name === 'checked') {
            setState({
                ...state,
                checked: e.target.checked
            })
        } else {
            setState({
                ...state,
                [e.target.name]: e.target.type === "checkbox" ? e.target.checked : e.target.value
            })
        }
    }

    // Sender Address Field
    // On address change, update the input field
    const handleSenderChange = senderAdd => {
        setState({
            ...state,
            senderAdd
        })
    }

    // On address select, update the input field with the address selected
    // then fetch the lat, lng
    // update the state
    // --> check side effect ( useEffect )
    const handleSenderSelect = address => {
        const senderAddressModified = true
        setState({
            ...state,
            senderAddressModified,
            senderAdd: address
        })
    }

    // Receiver Address Field
    const handleReceiverChange = receiverAdd => {
        setState({
            ...state,
            receiverAdd
        })
    }

    const handleReceiverSelect = address => {
        const receiverAddressModified = true
        setState({
            ...state,
            receiverAddressModified,
            receiverAdd: address
        })
    }

    const SameAsPrimaryNumber = (e) => {
        setState({
            ...state,
            [e.target.name]: state.contact1,
        });
    };


    async function getEstimate() {
        const token = localStorage.getItem("token");
        const new_token = token.replace(/"/g, "");
        if (
            !state.insuranceAmount ||
            parseInt(state.insuranceAmount) < 10000
        ) {
            alert("Insurance is applicable only on invoice value of more than Rs. 10,000");
            return;
        }

        // if (state.insuranceAmount >= 500000) {
        //     alert("Orders can be placed only for invoice value less than Rs. 5 lacs");
        //     return;
        // }

        setState(() => ({
            ...state,
            loading: true
        }));
        var promise = new Promise(function (resolve, reject) {
            // call resolve if the method succeeds
            setTimeout(function () {
                resolve();
            }, 100);

        })

        if (state.alreadyInsured === false)//if already insured then insurance amount is 0
        {
            var insuranceResult = promise.then(() => {
                setState
                    ({
                        ...state,
                        alreadyInsured: true,
                        insuranceEstimate: parseInt(0),
                        totalAmount:
                            parseFloat(state.insuranceAmount),//if already insured then insurance amount and total amount would be same 

                        loading: false
                    })
            }
            )
            console.log(state.insuranceEstimate)
            console.log(state.totalAmount)

            return insuranceResult;
        }

        let result = await fetch(
            `${BaseURL}/bussinessusers/getInsuranceEstimate`,
            {
                method: "POST",
                body: JSON.stringify({
                    amount: state.insuranceAmount,
                    alreadyInsured: false
                }),
                headers: {
                    Authorization: `Bearer ${new_token}`,
                    "Content-Type": "application/json",
                },
            }
        );
        result = await result;
        if (result.status === 200 || result.status === 201) {
            result = await result.json();
            setState({
                ...state,
                insuranceEstimate:
                    result.data[0].insuranceAmount,
                totalAmount:
                    parseFloat(result.data[0].insuranceAmount) +
                    parseFloat(result.data[0].amount),
                loading: false
            });
            return result;
        } else if (result.status === 401) {
            const refreshToken = localStorage.getItem("refreshToken");
            const data = {
                refreshToken,
            };

            var response = await fetch(`${BaseURL}/users/refresh-token`, {
                method: "POST",
                body: JSON.stringify(data),
                headers: {
                    "Content-Type": "application/json"
                },
            });
            response = await response;
            if (response.status === 200 || response.status === 201) {
                response = await response.json();
                localStorage.setItem("refreshToken", response.refreshToken);
                localStorage.setItem("token", response.jwtToken);
                result = await fetch(
                    `${BaseURL}/bussinessusers/getInsuranceEstimate`,
                    {
                        method: "POST",
                        body: JSON.stringify({
                            amount: state.insuranceAmount,
                            alreadyInsured: false
                        }),
                        headers: {
                            Authorization: `Bearer ${response.jwtToken}`,
                            "Content-Type": "application/json",
                        },
                    }
                );
                result = await result;
                if (result.status === 200 || result.status === 201) {
                    result = await result.json();
                    setState({
                        ...state,
                        insuranceEstimate:
                            result.data[0].insuranceAmount,
                        totalAmount:
                            parseFloat(result.data[0].insuranceAmount) +
                            parseFloat(result.data[0].amount),
                        loading: false
                    });
                    return result;
                } else if (result.status === 400 || result.status === 501) {
                    result = await result.json();
                    alert(result.message);
                    setState({
                        ...state,
                        loading: false
                    });
                    return result;
                }
                return response;
            } else {
                alert("Session expired. Please login to continue.");
                localStorage.clear("All");
                history.push("/Home");
            }
        } else if (result.status === 400 || result.status === 501) {
            result = await result.json();
            alert(result.message);
            setState({
                ...state,
                loading: false
            });
            return result;
        }
    };

    async function confirmInsurance() {
        const token = localStorage.getItem("token");
        const new_token = token.replace(/"/g, "");
        if (state.alreadyInsured === false) {
            if (
                !state.insuranceAmount ||
                parseInt(state.insuranceAmount) < 1
            ) {
                alert("Please enter the invoice amount to continue.");
                return;
            }

            // if (
            //     state.files.length === 0
            // ) {
            //     alert("Please upload the Invoice");
            //     return;
            // }

            var promise1 = new Promise(function (resolve, reject) {
                // call resolve if the method succeeds
                setTimeout(function () {
                    resolve();
                }, 100);

            })

            if (state.alreadyInsured === false)//if already insured then insurance amount is 0
            {
                var insuranceResult1 = promise1.then(() => {
                    setState
                        ({
                            ...state,
                            insuranceEstimate: parseInt(0),
                            totalAmount:
                                parseFloat(state.insuranceAmount),//if already insured then insurance amount and total amount would be same 
                        })
                })
                // this api we calling to check amount greater than 5lakh and store the logs on backend
                let result = await fetch(
                    `${BaseURL}/bussinessusers/getInsuranceEstimate`,
                    {
                        method: "POST",
                        body: JSON.stringify({
                            amount: state.insuranceAmount,
                            alreadyInsured: true
                        }),
                        headers: {
                            Authorization: `Bearer ${new_token}`,
                            "Content-Type": "application/json",
                        },
                    }
                );
                result = await result;
                if (result.status === 200 || result.status === 201) {
                    result = await result.json();
                    localStorage.setItem("insuranceEstimate", parseInt(result.data[0].insuranceAmount));
                    setState({
                        ...state,
                        insuranceEstimate:
                            result.data[0].insuranceAmount,
                        totalAmount:
                            parseFloat(result.data[0].insuranceAmount) +
                            parseFloat(result.data[0].amount),
                        loading: false
                    },
                    history.push("/placeOrder", {
                        isInsured: true,
                        files: state.files,
                        insuranceAmount: state.insuranceAmount,
                        insuranceEstimate: parseInt(0),
                        senderName: state.senderName,
                        senderFlat: state.senderFlat,
                        senderAdd: state.senderAdd,
                        senderAdd1: state.senderAdd1,
                        senderBuilding: state.senderBuilding,
                        senderContact: state.senderContact,
                        orgLat: state.orgLat,
                        orgLng: state.orgLng,
                        receiverName: state.receiverName,
                        receiverFlat: state.receiverFlat,
                        receiverAdd: state.receiverAdd,
                        receiverAdd1: state.receiverAdd1,
                        receiverBuilding: state.receiverBuilding,
                        receiverContact: state.receiverContact,
                        destLat: state.destLat,
                        destLng: state.destLng,
                        deliveryMethod: state.deliveryMethod,
                        amount: state.amount,
                        alreadyInsured: true,
                        savedAddresspickup: state.savedAddresspickup,
                        savedAddressdrop: state.savedAddressdrop,
                        popup1: state.popup1,
                        popup2: state.popup2
                    }));
                    return result;
                } else if (result.status === 401) {
                    const refreshToken = localStorage.getItem("refreshToken");
                    const data = {
                        refreshToken,
                    };
    
                    var response3 = await fetch(`${BaseURL}/users/refresh-token`, {
                        method: "POST",
                        body: JSON.stringify(data),
                        headers: {
                            "Content-Type": "application/json"
                        },
                    });
                    response3 = await response3;
                    if (response3.status === 200 || response3.status === 201) {
                        response3 = await response3.json();
                        localStorage.setItem("refreshToken", response3.refreshToken);
                        localStorage.setItem("token", response3.jwtToken);
                        result = await fetch(
                            `${BaseURL}/bussinessusers/getInsuranceEstimate`,
                            {
                                method: "POST",
                                body: JSON.stringify({
                                    amount: state.insuranceAmount,
                                    alreadyInsured: true
                                }),
                                headers: {
                                    Authorization: `Bearer ${response3.jwtToken}`,
                                    "Content-Type": "application/json",
                                },
                            }
                        );
                        result = await result;
                        if (result.status === 200 || result.status === 201) {
                            result = await result.json();
                            localStorage.setItem("insuranceEstimate", parseInt(result.data[0].insuranceAmount));
                            setState({
                                ...state,
                                insuranceEstimate:
                                    result.data[0].insuranceAmount,
                                totalAmount:
                                    parseFloat(result.data[0].insuranceAmount) +
                                    parseFloat(result.data[0].amount),
                                loading: false
                            },
                            history.push("/placeOrder", {
                                isInsured: true,
                                files: state.files,
                                insuranceAmount: state.insuranceAmount,
                                insuranceEstimate: parseInt(0),
                                senderName: state.senderName,
                                senderFlat: state.senderFlat,
                                senderAdd: state.senderAdd,
                                senderAdd1: state.senderAdd1,
                                senderBuilding: state.senderBuilding,
                                senderContact: state.senderContact,
                                orgLat: state.orgLat,
                                orgLng: state.orgLng,
                                receiverName: state.receiverName,
                                receiverFlat: state.receiverFlat,
                                receiverAdd: state.receiverAdd,
                                receiverAdd1: state.receiverAdd1,
                                receiverBuilding: state.receiverBuilding,
                                receiverContact: state.receiverContact,
                                destLat: state.destLat,
                                destLng: state.destLng,
                                deliveryMethod: state.deliveryMethod,
                                amount: state.amount,
                                alreadyInsured: true,
                                savedAddresspickup: state.savedAddresspickup,
                                savedAddressdrop: state.savedAddressdrop,
                                popup1: state.popup1,
                                popup2: state.popup2
                            }));
                            return result;
                        } else if (result.status === 400 || result.status === 501) {
                            result = await result.json();
                            alert(result.message);
                            setState({
                                ...state,
                                loading: false
                            });
                            return result;
                        }
                        return response3;
                    } else {
                        alert("Session expired. Please login to continue.");
                        localStorage.clear("All");
                        history.push("/Home");
                    }
                } else if (result.status === 400 || result.status === 501) {
                    result = await result.json();
                    alert(result.message);
                    setState({
                        ...state,
                        loading: false
                    });
                    return result;
                }
                return insuranceResult1;
            }

            setState(() => ({
                ...state,
                loading: true
            }));

            let result = await fetch(
                `${BaseURL}/bussinessusers/getInsuranceEstimate`,
                {
                    method: "POST",
                    body: JSON.stringify({
                        amount: state.insuranceAmount,
                        alreadyInsured: false
                    }),
                    headers: {
                        Authorization: `Bearer ${new_token}`,
                        "Content-Type": "application/json",
                    },
                }
            );
            result = await result;
            if (result.status === 200 || result.status === 201) {
                result = await result.json();
                localStorage.setItem("insuranceEstimate", parseInt(result.data[0].insuranceAmount));
                setState({
                    ...state,
                    insuranceEstimate:
                        result.data[0].insuranceAmount,
                    totalAmount:
                        parseFloat(result.data[0].insuranceAmount) +
                        parseFloat(result.data[0].amount),
                    loading: false
                },
                    history.push("/placeOrder", {
                        isInsured: true,
                        files: state.files,
                        insuranceAmount: state.insuranceAmount,
                        insuranceEstimate: parseInt(result.data[0].insuranceAmount),
                        senderName: state.senderName,
                        senderFlat: state.senderFlat,
                        senderAdd: state.senderAdd,
                        senderAdd1: state.senderAdd1,
                        senderBuilding: state.senderBuilding,
                        senderContact: state.senderContact,
                        orgLat: state.orgLat,
                        orgLng: state.orgLng,
                        receiverName: state.receiverName,
                        receiverFlat: state.receiverFlat,
                        receiverAdd: state.receiverAdd,
                        receiverAdd1: state.receiverAdd1,
                        receiverBuilding: state.receiverBuilding,
                        receiverContact: state.receiverContact,
                        destLat: state.destLat,
                        destLng: state.destLng,
                        deliveryMethod: state.deliveryMethod,
                        amount: state.amount,
                        alreadyInsured: false,
                        savedAddresspickup: state.savedAddresspickup,
                        savedAddressdrop: state.savedAddressdrop,
                        popup1: state.popup1,
                        popup2: state.popup2
                    }));
                return result;
            } else if (result.status === 401) {
                const refreshToken = localStorage.getItem("refreshToken");
                const data = {
                    refreshToken,
                };

                var response1 = await fetch(`${BaseURL}/users/refresh-token`, {
                    method: "POST",
                    body: JSON.stringify(data),
                    headers: {
                        "Content-Type": "application/json"
                    },
                });
                response1 = await response1;
                if (response1.status === 200 || response1.status === 201) {
                    response1 = await response1.json();
                    localStorage.setItem("refreshToken", response1.refreshToken);
                    localStorage.setItem("token", response1.jwtToken);
                    result = await fetch(
                        `${BaseURL}/bussinessusers/getInsuranceEstimate`,
                        {
                            method: "POST",
                            body: JSON.stringify({
                                amount: state.insuranceAmount,
                                alreadyInsured: false
                            }),
                            headers: {
                                Authorization: `Bearer ${response1.jwtToken}`,
                                "Content-Type": "application/json",
                            },
                        }
                    );
                    result = await result;
                    if (result.status === 200 || result.status === 201) {
                        result = await result.json();
                        localStorage.setItem("insuranceEstimate", parseInt(result.data[0].insuranceAmount));
                        setState({
                            ...state,
                            insuranceEstimate:
                                result.data[0].insuranceAmount,
                            totalAmount:
                                parseFloat(result.data[0].insuranceAmount) +
                                parseFloat(result.data[0].amount),
                            loading: false
                        },
                            history.push("/placeOrder", {
                                isInsured: true,
                                files: state.files,
                                insuranceAmount: state.insuranceAmount,
                                insuranceEstimate: parseInt(result.data[0].insuranceAmount),
                                senderName: state.senderName,
                                senderFlat: state.senderFlat,
                                senderAdd: state.senderAdd,
                                senderAdd1: state.senderAdd1,
                                senderBuilding: state.senderBuilding,
                                senderContact: state.senderContact,
                                orgLat: state.orgLat,
                                orgLng: state.orgLng,
                                receiverName: state.receiverName,
                                receiverFlat: state.receiverFlat,
                                receiverAdd: state.receiverAdd,
                                receiverAdd1: state.receiverAdd1,
                                receiverBuilding: state.receiverBuilding,
                                receiverContact: state.receiverContact,
                                destLat: state.destLat,
                                destLng: state.destLng,
                                deliveryMethod: state.deliveryMethod,
                                amount: state.amount,
                                alreadyInsured: false,
                                savedAddresspickup: state.savedAddresspickup,
                                savedAddressdrop: state.savedAddressdrop,
                                popup1: state.popup1,
                                popup2: state.popup2
                            }));
                        return result;
                    } else if (result.status === 400 || result.status === 501) {
                        result = await result.json();
                        alert(result.message);
                        setState({
                            ...state,
                            loading: false
                        });
                        return result;
                    }
                    return response1;
                } else {
                    alert("Session expired. Please login to continue.");
                    localStorage.clear("All");
                    history.push("/Home");
                }
            } else if (result.status === 400 || result.status === 501) {
                result = await result.json();
                alert(result.message);
                setState({
                    ...state,
                    loading: false
                });
                return result;
            }

        } else {
            if (
                !state.insuranceAmount ||
                parseInt(state.insuranceAmount) < 10000
            ) {
                alert("Insurance is applicable only on invoice value of more than Rs. 10,000");
                return;
            }

            // if (state.insuranceAmount >= 500000) {
            //     alert("Orders can be placed only for invoice value less than Rs. 5 lacs");
            //     return;
            // }

            // if (
            //     state.files.length === 0
            // ) {
            //     alert("Please upload the Invoice");
            //     return;
            // }

            setState(() => ({
                ...state,
                loading: true
            }));

            var promise = new Promise(function (resolve, reject) {
                // call resolve if the method succeeds
                setTimeout(function () {
                    resolve();
                }, 100);

            })

            if (state.alreadyInsured === false)//if already insured then insurance amount is 0
            {
                var insuranceResult = promise.then(() => {
                    setState
                        ({
                            ...state,
                            alreadyInsured: true,
                            insuranceEstimate: parseInt(0),
                            totalAmount:
                                parseFloat(state.insuranceAmount),//if already insured then insurance amount and total amount would be same 

                            loading: false
                        })
                })
                history.push("/placeOrder", {
                    isInsured: true,
                    files: state.files,
                    insuranceAmount: state.insuranceAmount,
                    insuranceEstimate: parseInt(0),
                    senderName: state.senderName,
                    senderFlat: state.senderFlat,
                    senderAdd: state.senderAdd,
                    senderAdd1: state.senderAdd1,
                    senderBuilding: state.senderBuilding,
                    senderContact: state.senderContact,
                    orgLat: state.orgLat,
                    orgLng: state.orgLng,
                    receiverName: state.receiverName,
                    receiverFlat: state.receiverFlat,
                    receiverAdd: state.receiverAdd,
                    receiverAdd1: state.receiverAdd1,
                    receiverBuilding: state.receiverBuilding,
                    receiverContact: state.receiverContact,
                    destLat: state.destLat,
                    destLng: state.destLng,
                    deliveryMethod: state.deliveryMethod,
                    amount: state.amount,
                    alreadyInsured: true,
                    savedAddresspickup: state.savedAddresspickup,
                    savedAddressdrop: state.savedAddressdrop,
                    popup1: state.popup1,
                    popup2: state.popup2
                })

                return insuranceResult;
            }

            let result = await fetch(
                `${BaseURL}/bussinessusers/getInsuranceEstimate`,
                {
                    method: "POST",
                    body: JSON.stringify({
                        amount: state.insuranceAmount,
                        alreadyInsured: false
                    }),
                    headers: {
                        Authorization: `Bearer ${new_token}`,
                        "Content-Type": "application/json",
                    },
                }
            );
            result = await result;
            if (result.status === 200 || result.status === 201) {
                result = await result.json();
                localStorage.setItem("insuranceEstimate", parseInt(result.data[0].insuranceAmount));
                setState({
                    ...state,
                    insuranceEstimate:
                        result.data[0].insuranceAmount,
                    totalAmount:
                        parseFloat(result.data[0].insuranceAmount) +
                        parseFloat(result.data[0].amount),
                    loading: false
                },
                    history.push("/placeOrder", {
                        isInsured: true,
                        files: state.files,
                        insuranceAmount: state.insuranceAmount,
                        insuranceEstimate: parseInt(result.data[0].insuranceAmount),
                        senderName: state.senderName,
                        senderFlat: state.senderFlat,
                        senderAdd: state.senderAdd,
                        senderAdd1: state.senderAdd1,
                        senderBuilding: state.senderBuilding,
                        senderContact: state.senderContact,
                        orgLat: state.orgLat,
                        orgLng: state.orgLng,
                        receiverName: state.receiverName,
                        receiverFlat: state.receiverFlat,
                        receiverAdd: state.receiverAdd,
                        receiverAdd1: state.receiverAdd1,
                        receiverBuilding: state.receiverBuilding,
                        receiverContact: state.receiverContact,
                        destLat: state.destLat,
                        destLng: state.destLng,
                        deliveryMethod: state.deliveryMethod,
                        amount: state.amount,
                        alreadyInsured: false,
                        savedAddresspickup: state.savedAddresspickup,
                        savedAddressdrop: state.savedAddressdrop,
                        popup1: state.popup1,
                        popup2: state.popup2
                    }));
                return result;
            } else if (result.status === 401) {
                const refreshToken = localStorage.getItem("refreshToken");
                const data = {
                    refreshToken,
                };

                var response = await fetch(`${BaseURL}/users/refresh-token`, {
                    method: "POST",
                    body: JSON.stringify(data),
                    headers: {
                        "Content-Type": "application/json"
                    },
                });
                response = await response;
                if (response.status === 200 || response.status === 201) {
                    response = await response.json();
                    localStorage.setItem("refreshToken", response.refreshToken);
                    localStorage.setItem("token", response.jwtToken);
                    result = await fetch(
                        `${BaseURL}/bussinessusers/getInsuranceEstimate`,
                        {
                            method: "POST",
                            body: JSON.stringify({
                                amount: state.insuranceAmount,
                                alreadyInsured: false
                            }),
                            headers: {
                                Authorization: `Bearer ${response.jwtToken}`,
                                "Content-Type": "application/json",
                            },
                        }
                    );
                    result = await result;
                    if (result.status === 200 || result.status === 201) {
                        result = await result.json();
                        localStorage.setItem("insuranceEstimate", parseInt(result.data[0].insuranceAmount));
                        setState({
                            ...state,
                            insuranceEstimate:
                                result.data[0].insuranceAmount,
                            totalAmount:
                                parseFloat(result.data[0].insuranceAmount) +
                                parseFloat(result.data[0].amount),
                            loading: false
                        },
                            history.push("/placeOrder", {
                                isInsured: true,
                                files: state.files,
                                insuranceAmount: state.insuranceAmount,
                                insuranceEstimate: parseInt(result.data[0].insuranceAmount),
                                senderName: state.senderName,
                                senderFlat: state.senderFlat,
                                senderAdd: state.senderAdd,
                                senderAdd1: state.senderAdd1,
                                senderBuilding: state.senderBuilding,
                                senderContact: state.senderContact,
                                orgLat: state.orgLat,
                                orgLng: state.orgLng,
                                receiverName: state.receiverName,
                                receiverFlat: state.receiverFlat,
                                receiverAdd: state.receiverAdd,
                                receiverAdd1: state.receiverAdd1,
                                receiverBuilding: state.receiverBuilding,
                                receiverContact: state.receiverContact,
                                destLat: state.destLat,
                                destLng: state.destLng,
                                deliveryMethod: state.deliveryMethod,
                                amount: state.amount,
                                alreadyInsured: false,
                                savedAddresspickup: state.savedAddresspickup,
                                savedAddressdrop: state.savedAddressdrop,
                                popup1: state.popup1,
                                popup2: state.popup2
                            }));
                        return result;
                    } else if (result.status === 400 || result.status === 501) {
                        result = await result.json();
                        alert(result.message);
                        setState({
                            ...state,
                            loading: false
                        });
                        return result;
                    }
                    return response;
                } else {
                    alert("Session expired. Please login to continue.");
                    localStorage.clear("All");
                    history.push("/Home");
                }
            } else if (result.status === 400 || result.status === 501) {
                result = await result.json();
                alert(result.message);
                setState({
                    ...state,
                    loading: false
                });
                return result;
            }
        }
    };

    const showMessage = () => {
        confirmAlert({
            customUI: ({ onClose }) => {
                return (
                    <div style={{ backgroundColor: "#FEFEFE", padding: "50px", width: "70%", marginLeft: "150px" }}>
                        <p style={{ color: "#000000" }}>Please read carefully. <br />1. Transit Insurance will be applicable only after the package is placed inside the device and door is locked until the door is unlocked by the receiver.<br />2. Premium is calculated at 0.14% of the invoice value declared by you.<br /> 3. Uploading the invoice is mandatory. Maximum invoice value is Rs. 5 lacs.<br />4. Insurance is applicable only on items whose invoice value is more than Rs. 10,000.<br /><br /> Click on the 'T&C' button below to read the detailed terms and conditions for insurance.</p>
                        <button style={{ border: "none", backgroundColor: "#333333", color: "#ffffff", paddingLeft: "20px", paddingRight: "20px", paddingTop: "7px", paddingBottom: "7px", outline: "none", fontFamily: "Manrope-Bold" }}
                            onClick={() => {
                                window.open("https://beta-orders.gordian.in/insurance-terms", "_blank");
                                onClose();
                            }}
                        >
                            T&C
                        </button>
                        <button style={{ border: "none", backgroundColor: "#333333", color: "#ffffff", paddingLeft: "20px", paddingRight: "20px", paddingTop: "7px", paddingBottom: "7px", outline: "none", fontFamily: "Manrope-Bold", marginLeft: "20px" }} onClick={onClose}>OK</button>
                    </div>
                );
            }
        });
        // confirmAlert({
        //   message: "Please read carefully.\u2022 Transit Insurance will be applicable only after the package is placed inside the device and door is locked until the door is unlocked by the receiver.\n\u2022 Premium is calculated at 0.14% of the invoice value declared by you.\n\u2022 Uploading the invoice is mandatory. Maximum invoice value is Rs. 5 lacs.\n\u2022 Insurance is applicable only on items whose invoice value is more than Rs. 10,000.\n\nClick on the 'T&C' button below to read the detailed terms and conditions for insurance.",
        //   buttons: [
        //     {
        //       label: "T&C",
        //       onClick: () => window.open("https://beta-orders.gordian.in/insurance-terms", "_selfx"),
        //     },
        //     {
        //         label: "Ok",
        //         onClick: () => { },
        //       },
        //   ],
        // });
    };

    const changeButtonColor = () => {
        return state.alreadyInsured === false ? "grey" : "black"
    }

    const getEstimateButton = () => {
        return state.alreadyInsured === false ? null : getEstimate()
    }

    return (
        <Row className='m-0'>
            <Col
                lg={2} md={2} sm={2}
                className='text-center pt-5'
                style={{ backgroundColor: 'white' }}
            >
                <Row className="gordian-logo" style={{ justifyContent: "center", alignItem: "center" }}>
                    <img src={Logo} alt='Logo' className='logo' />
                </Row>
                <h1
                    style={{ fontSize: '1.563rem', fontWeight: 'bold', textAlign: 'center' }}
                >
                    Gordian
                </h1>
                <Sidebar />
            </Col>
            <Col lg={7} md={7} sm={7} className='Form'>
                <div className='NewOrder' style={{ marginLeft: "23px" }}>Place Order</div>
                <Container>
                    <div
                        style={{ fontSize: '1.25rem', fontWeight: '600', color: '#000000' }}
                    >
                        &emsp; Pickup Details
                    </div>
                    <Row className='row'>
                        <Col lg={12} md={12} sm={12}>
                            <PlacesAutocomplete
                                value={state.senderAdd}
                                onChange={handleSenderChange}
                                onSelect={handleSenderSelect}
                            >
                                {({
                                    getInputProps,
                                    suggestions,
                                    getSuggestionItemProps,
                                    loading
                                }) => (
                                    <div className='placesDiv'>
                                        <input
                                            {...getInputProps({
                                                placeholder: 'Enter Pickup Address',
                                                className: 'location-search-input fieldAddress field'
                                            })}
                                        />
                                        <div className='autocomplete-dropdown-container'>
                                            {loading && <div>Loading . . . .</div>}
                                            {suggestions.map(suggestion => {
                                                const className = suggestion.active
                                                    ? 'suggestion-item--active'
                                                    : 'suggestion-item'
                                                const style = suggestion.active
                                                    ? {
                                                        backgroundColor: '#fafafa',
                                                        cursor: 'pointer'
                                                    }
                                                    : {
                                                        backgroundColor: '#ffffff',
                                                        cursor: 'pointer'
                                                    }
                                                return (
                                                    <div
                                                        {...getSuggestionItemProps(suggestion, {
                                                            className,
                                                            style
                                                        })}
                                                    >
                                                        <hr />
                                                        <span>{suggestion.description}</span>
                                                    </div>
                                                )
                                            })}
                                        </div>
                                    </div>
                                )}
                            </PlacesAutocomplete>
                        </Col>
                    </Row>
                    <Row className='row'>
                        <Col lg={6} md={6} sm={6}>
                            <input
                                type='text'
                                placeholder='Building / Landmark'
                                className='field'
                                name='senderBuilding'
                                value={state.senderBuilding}
                                onChange={e => change(e)}
                                required
                            />
                        </Col>
                        <Col lg={6} md={6} sm={6}>
                            <input
                                type='number'
                                placeholder='Enter Pickup Mobile Number'
                                className='field'
                                name='senderContact'
                                value={state.senderContact}
                                onChange={e => change(e)}
                                required
                            />
                            <br />
                            <div
                                style={{
                                    textAlign: "right",
                                    fontSize: "0.75rem",
                                }}
                            >
                                {/* <div className="error">{state.SenContactError}</div> */}
                                <input
                                    style={{ marginTop: "16px" }}
                                    type="checkbox"
                                    name="senderContact"
                                    onChange={(e) => SameAsPrimaryNumber(e)}
                                    id="primarynumber"
                                />
                                &ensp;
                                <label>Same as Primary number</label>
                            </div>
                            {/* <br />
              <div
                style={{
                  textAlign: 'right',
                  fontSize: '12px'
                }}
              >
                <div className='error'>{state.SenContactError}</div>
              </div> */}
                        </Col>
                    </Row>
                    <hr />
                    <div
                        style={{ fontSize: '1.25rem', fontWeight: '600', color: '#000000' }}
                    >
                        &emsp; Drop Details
                    </div>
                    <Row className='row'>
                        <Col lg={12} md={12} sm={12}>
                            <PlacesAutocomplete
                                value={state.receiverAdd}
                                onChange={handleReceiverChange}
                                onSelect={handleReceiverSelect}
                            >
                                {({
                                    getInputProps,
                                    suggestions,
                                    getSuggestionItemProps,
                                    loading
                                }) => (
                                    <div className='placesDiv'>
                                        <input
                                            {...getInputProps({
                                                placeholder: 'Enter the Drop Address',
                                                className: 'location-search-input fieldAddress field'
                                            })}
                                        />
                                        <div className='autocomplete-dropdown-container'>
                                            {loading && <div>Loading...</div>}
                                            {suggestions.map(suggestion => {
                                                const className = suggestion.active
                                                    ? 'suggestion-item--active'
                                                    : 'suggestion-item'

                                                const style = suggestion.active
                                                    ? {
                                                        backgroundColor: '#fafafa',
                                                        cursor: 'pointer'
                                                    }
                                                    : {
                                                        backgroundColor: '#ffffff',
                                                        cursor: 'pointer'
                                                    }
                                                return (
                                                    <div
                                                        {...getSuggestionItemProps(suggestion, {
                                                            className,
                                                            style
                                                        })}
                                                    >
                                                        <hr />
                                                        <span>{suggestion.description}</span>
                                                    </div>
                                                )
                                            })}
                                        </div>
                                    </div>
                                )}
                            </PlacesAutocomplete>
                        </Col>
                    </Row>
                    <Row className='row'>
                        <Col lg={6} md={6} sm={6}>
                            <input
                                type='text'
                                placeholder='Building / Landmark'
                                className='field'
                                name='receiverBuilding'
                                value={state.receiverBuilding}
                                onChange={e => change(e)}
                                required
                            />
                        </Col>
                        <Col lg={6} md={6} sm={6}>
                            <input
                                type='number'
                                placeholder='Enter Drop Mobile Number'
                                className='field'
                                name='receiverContact'
                                value={state.receiverContact}
                                onChange={e => change(e)}
                                required
                            />
                            <br />
                            <div
                                style={{
                                    textAlign: "right",
                                    fontSize: "0.75rem",
                                }}
                            >
                                {/* <div className="error">{state.recContactError}</div> */}
                                <input
                                    style={{ marginTop: "16px" }}
                                    type="checkbox"
                                    name="receiverContact"
                                    onChange={(e) => SameAsPrimaryNumber(e)}
                                    id="primarynumber1"
                                />
                                &ensp;
                                <label>Same as Primary number</label>
                            </div>
                        </Col>
                    </Row>
                </Container>
            </Col>
            <Col lg={3} md={3} sm={3} style={{ backgroundColor: 'white', height: '100vh' }}>
                <Container>
                    <Row>
                        <Card style={{ width: '50px', height: "50px", borderRadius: "30px", backgroundColor: "#F5F6FA", border: "none", boxShadow: "5px 5px 11px 5px #0000000D", marginLeft: "7px" }} onClick={() => props.history.goBack()}>
                            <Card.Body>
                                <img style={{ height: "30px", width: "27px", marginTop: "-15px", marginLeft: "-11px" }} src={BackIcon} alt="backicon" />
                            </Card.Body>
                        </Card>
                    </Row>
                    <h1 className="name"
                        style={{
                            fontSize: '1.25rem',
                            fontWeight: '600',
                            marginTop: '20px',
                            fontFamily: 'Manrope-SemiBold'
                        }}
                    >
                        Welcome {new_name}
                    </h1>
                    <hr />

                    <div>
                        <label className="insurance-text"
                            style={{
                                fontSize: '1.25rem',
                                marginLeft: '10px',
                                fontFamily: 'Manrope-Bold'
                            }}
                        >
                            Enter Invoice Value of item
                        </label>
                        <input
                            type='text'
                            placeholder='Amount in Rupees'
                            style={{ backgroundColor: '#F5F6FA' }}
                            className='Insurance-fields'
                            name='insuranceAmount'
                            value={state.insuranceAmount}
                            onChange={e => setState({ ...state, insuranceAmount: e.target.value })}
                        />
                    </div>
                    <div>
                        <label className="insurance-text"
                            style={{
                                fontSize: '1.25rem',
                                marginTop: '20px',
                                marginLeft: '10px',
                                fontFamily: 'Manrope-Bold'
                            }}
                        >
                            Upload Invoice
                        </label>
                        <input
                            type='file'
                            style={{ backgroundColor: '#F5F6FA' }}
                            className='Insurance-fields'
                            name='insurance-invoice'
                            value={state.file}
                            onChange={e => setState({ ...state, files: e.target.files[0] })}
                        />

                        <Row style={{ justifyContent: 'center', marginTop: '45px' }}>
                            <div>
                                <input type="checkbox"
                                    name="alreadyInsured"
                                    checked={state.alreadyInsured}
                                    onChange={(e) => change(e)}
                                    id="IsInsured"
                                />
                                &ensp;
                                <label>I want to insure my order</label>
                            </div>
                        </Row>

                        <Row style={{ justifyContent: 'center', marginTop: '45px' }}>
                            <button className='insurance' style={{ backgroundColor: changeButtonColor() }} onClick={getEstimateButton}>
                                Get Estimate
                            </button>
                        </Row>

                        {state.loading ? (
                            <Row style={{ justifyContent: "center", alignItem: "center", marginTop: "30px" }}>
                                <Loader
                                    type="Oval"
                                    color="#000000"
                                    height={40}
                                    width={40}
                                />
                            </Row>
                        ) : (state.insuranceEstimate >= 0) ? (
                            <Row
                                style={{
                                    marginTop: "40px",
                                    justifyContent: "center",
                                    alignItem: "center",
                                }}
                            >
                                <Card className="insurance-card"
                                    style={{
                                        width: "290px",
                                        height: "80px",
                                        borderRadius: "20px",
                                        backgroundColor: "#F6F6FA",
                                        borderColor: "#F6F6FA",
                                    }}
                                >
                                    <Card.Body>
                                        <Row
                                            style={{
                                                justifyContent: "space-between",
                                                alignItem: "space-between",
                                                marginLeft: "5px",
                                                marginRight: "5px",
                                                marginTop: "-5px",
                                            }}
                                        >
                                            <Card.Title className="insurance-card-text"
                                                style={{
                                                    color: "#000000",
                                                    fontSize: "14px",
                                                    fontFamily: "Manrope-Bold",
                                                }}
                                            >
                                                Insurance Amount
                                            </Card.Title>
                                            <Card.Title className="insurance-card-text"
                                                style={{
                                                    color: "#000000",
                                                    fontSize: "14px",
                                                    fontFamily: "Manrope-Bold",
                                                }}
                                            >
                                                {"\u20B9"} {state.insuranceAmount}
                                            </Card.Title>
                                        </Row>

                                        <Row
                                            style={{
                                                justifyContent: "space-between",
                                                alignItem: "space-between",
                                                marginLeft: "5px",
                                                marginRight: "5px",
                                                marginTop: "-20px",
                                            }}
                                        >
                                            <Card.Title className="insurance-card-text"
                                                style={{
                                                    color: "#000000",
                                                    fontSize: "14px",
                                                    fontFamily: "Manrope-Bold",
                                                }}
                                            >
                                                Insurance Estimate
                                            </Card.Title>
                                            <Card.Title className="insurance-card-text"
                                                style={{
                                                    color: "#000000",
                                                    fontSize: "14px",
                                                    fontFamily: "Manrope-Bold",
                                                }}
                                            >
                                                {"\u20B9"} {state.insuranceEstimate.toFixed(4)}
                                            </Card.Title>
                                        </Row>
                                    </Card.Body>
                                </Card>
                            </Row>
                        ) : null}

                        <Row style={{ justifyContent: 'center', marginTop: '45px' }}>
                            <button className='insurance' style={{ backgroundColor: "#000000" }} onClick={confirmInsurance}>
                                Confirm Invoice Details
                            </button>
                        </Row>
                        <Row style={{ justifyContent: "center", alignItem: "center" }}>
                            <Card className='insurance-text-card' style={{ width: '260px', height: "4.5rem", borderRadius: "1rem", backgroundColor: "#F6F6FA", marginTop: "15px", marginBottom: "20px" }}>
                                <Card.Body>
                                    <Card.Title style={{ color: "#92979D", fontSize: "0.7rem", fontFamily: "Manrope-Regular", marginTop: "-15px", textAlign: "center" }}>Insurance will be applicable only if the item is new and invoice for the same is available. Read<button style={{ border: "none", outline: "none" }} onClick={showMessage}>T&C</button>the before applying for insurance</Card.Title>
                                </Card.Body>
                            </Card>
                        </Row>
                    </div>
                </Container>
            </Col>
        </Row>
    )
}

export default AddInsurance;
