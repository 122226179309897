import React, { useState } from "react";
import { Row, Col } from "reactstrap";

import Variables from "./../Globals/variables";
import Logo from "./../assets/images/Logo1.png";
import Sidebar from "./../Components/Sidebar/sidebar";
import "./../Globals/Fonts.css";
import "react-confirm-alert/src/react-confirm-alert.css";
// import { useHistory } from "react-router-dom";
import { GoogleMap, useJsApiLoader, Marker, DirectionsRenderer } from "@react-google-maps/api";

import LogoRider from "../assets/images/Rider.png";
import LogoDevice from "../assets/images/Device.png";

// const BaseURL = Variables.baseUrl;
const GoogleApiKey = Variables.GOOGLE_API_KEY;

const routes = [{"location": {
    "id": "ID of Source",
    "lat":40.753167,
    "lng":-73.968120
  }},{"location": {
    "id": "ID of Destination",
    "lat":40.756795,
    "lng":-73.954298
  }},
  {"location": {
    "id": "ID of Waypoint1",
    "lat":40.76795,
    "lng":-73.96556
  }},
  {"location": {
    "id": "ID of Waypoint2",
    "lat":40.76473,
    "lng":-73.97239
  }}]

const center = {
  lat: 12.9,
  lng: 77.5,
};
const mapContainerStyle = {
  height: "80vh",
  width: "100%",
};

function TestGps() {
  const { isLoaded } = useJsApiLoader({
    id: "google-map-script",
    googleMapsApiKey: GoogleApiKey,
  });

  const [directionsResponse, setDirectionsResponse] = useState(null)

  // const history = useHistory();

  // Fetching the orders from the backend when component is mounted.
//   useEffect(
//     () => {
//       console.log("getOrderDetails hook called");
//       getOrderDetails();
//     },
//     // eslint-disable-next-line
//     []
//   );

  async function getOrderDetails() {
    // eslint-disable-next-line no-undef
    const directionsService = new google.maps.DirectionsService()
    const results = await directionsService.route({
      origin: { lat: routes[0].location.lat, lng: routes[0].location.lng},
      destination: { lat: routes[1].location.lat, lng: routes[1].location.lng},
      waypoints: [
        { location: routes[2].location, stopover: false },{location: routes[3].location, stopover:false}
      ],
      optimizeWaypoints: false,
      // eslint-disable-next-line no-undef
      travelMode: google.maps.TravelMode.DRIVING,
    })
    setDirectionsResponse(results)
  }

  return (
    <Row className="m-0">
      <Col
        lg={2}
        md={2}
        sm={2}
        className="text-center pt-5"
        style={{ backgroundColor: "white", height: "100vh" }}
      >
        <Row
          className="gordian-logo"
          style={{ justifyContent: "center", alignItem: "center" }}
        >
          <img src={Logo} alt="Logo" className="logo" />
        </Row>
        <h1
          style={{
            fontSize: "1.56rem",
            textAlign: "center",
            fontFamily: "Manrope-Bold",
          }}
        >
          Gordian
        </h1>
        <Sidebar />
      </Col>
      <Col lg={10} md={10} sm={10} style={{ backgroundColor: "white" }}>
        <Col lg={12} md={12} sm={12}>
          <h1
            className="name"
            style={{
              fontSize: "20px",
              fontWeight: "600",
              marginTop: "1.25rem",
              fontFamily: "Manrope-SemiBold",
            }}
          >
            Live Gps Tracking of Orders
          </h1>
          <button colorScheme='pink' type='submit' onClick={getOrderDetails}>
              Calculate Route
            </button>
          <hr />
          <Row>
            {isLoaded ? (
              <GoogleMap
                id="gpsTrackerMap"
                mapContainerStyle={mapContainerStyle}
                center={center}
                zoom={8}
                options={{ streetViewControl: false }}
              >
                <Marker
                  icon={LogoRider}
                  position={routes[0].location}
                  id="markerRider"
                />
                <Marker
                  id="markerDevice"
                  icon={LogoDevice}
                  position={routes[1].location}
                />
                {directionsResponse && (
            <DirectionsRenderer directions={directionsResponse} />
          )}
              </GoogleMap>
            ) : (
              <></>
            )}
          </Row>
          {/* <Row style={{ justifyContent: "center", alignItem: "center" }}>
            <h1
              style={{
                color: "#92979D",
                fontSize: "0.75rem",
                fontFamily: "Manrope-SemiBold",
                position: "fixed",
                bottom: "40px",
              }}
            >
              For any difficulties
            </h1>
            <Link to="/Contactus2">
              <h1
                style={{
                  fontFamily: "Manrope-Bold",
                  color: "#000000",
                  fontSize: "0.938rem",
                  position: "fixed",
                  bottom: "15px",
                  marginLeft: "-40px",
                }}
              >
                Contact Us
              </h1>
            </Link>
          </Row> */}
        </Col>
      </Col>
    </Row>
  );
}

export default TestGps;
