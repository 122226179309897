import React from 'react'

const ProgressBar = (props) => {
    return (
        <div>
            <div className="progress" style={{ height: "4px" }}>
              <div className="progress-bar bg-dark" style={{ width: `${props.width}` }}></div>
            </div>
            {props.steps}
          </div>
    )
}

export default ProgressBar
