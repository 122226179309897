import React, { useState, useEffect } from "react";
import { Row, Col, Container } from "reactstrap";
import Button from 'react-bootstrap/Button';
import Card from 'react-bootstrap/Card';
import Loader from "react-loader-spinner";

import "./styles.desktop.css";
import Variables from "./../Globals/variables";
import Logo from "./../assets/images/Logo1.png";
import { useHistory } from "react-router-dom";
import Sidebar from "./../Components/Sidebar/sidebar";
import "./../Globals/Fonts.css";
import 'react-confirm-alert/src/react-confirm-alert.css';

const BaseURL = Variables.baseUrl

function PastOrders() {

    const name = localStorage.getItem("name");
    const new_name = name.replace(/"/g, "");

    const [state, setState] = useState({
        loading: true,
        filterCategory: ["All"],
        orders: [],
        btnValue: 'Cancel Order',
        btnValue1: 'Track Your Drop Address'

    });
    const history = useHistory();

    // Fetching the orders from the backend when component is mounted.
    useEffect(() => {
        getOrders();
    },
        // eslint-disable-next-line 
        [state.filterCategory]);


    const showCompletedOrder = (order) => {
        return (
            <Card className="completed-order" style={{ width: '100%', height: "250px", borderRadius: "15px", backgroundColor: "#ffffff", border: "none", boxShadow: "5px 5px 11px 5px #0000000D", marginTop: "20px", marginBottom: "10px" }}>
                <Card.Body>
                    <Row style={{ justifyContent: "space-between", alignItem: "space-between", marginTop: "5px" }}>
                        <h3 style={{ fontSize: "0.9rem", color: "#000000", marginLeft: "15px" }}>{(order.created_at.substring(0, 10))}  <span style={{ fontFamily: "Manrope-Bold", color: "grey" }}>{order.created_at.substring(11, 16)}</span></h3>
                        <button style={{ width: "170px", height: "40px", backgroundColor: "#f07173", color: "#ffffff", borderRadius: "30px", border: "none", fontSize: "17px", fontFamily: "Manrope-Bold", marginRight: "15px", marginTop: "-8px", textAlign: "center", outline: "none" }}>Order Completed</button>
                    </Row>
                    <Row style={{ justifyContent: "flex-end", alignItem: "flex-end", marginTop: "-5px" }}>
                        <button style={{ width: "170px", height: "40px", backgroundColor: "#696969", color: "#ffffff", borderRadius: "30px", border: "none", fontSize: "15px", fontFamily: "Manrope-Bold", marginRight: "15px", marginTop: "-8px", textAlign: "center", outline: "none" }}>{order.orderId}</button>
                    </Row>
                    <Row style={{ justifyContent: "space-between", alignItem: "space-between", marginTop: "-15px" }}>
                        <h3 className="complete-pickUp" style={{ fontFamily: "Manrope-SemiBold", fontSize: "0.9rem", color: "grey", marginLeft: "20px", width: "16rem" }}>Pickup</h3>
                        <h3 className="complete-Drop" style={{ fontFamily: "Manrope-SemiBold", fontSize: "0.9rem", color: "grey", marginRight: "20px", width: "27.85rem" }}>Drop</h3>
                    </Row>
                    <Row style={{ justifyContent: "space-between", alignItem: "space-between", marginTop: "-20px", }}>
                        <h3 className="complete-sender-address" style={{ fontFamily: "Manrope-Regular", fontSize: "13px", color: "#000000", marginLeft: "20px", width: "13.75rem" }}>{order.senderAdd}</h3>
                        <h3 className="complete-receiver-address" style={{ fontFamily: "Manrope-Regular", fontSize: "13px", color: "#000000", marginRight: "20px", width: "13.438rem" }}>{order.receiverAdd}</h3>

                        <button style={{ width: "170px", height: "40px", backgroundColor: "#000000", color: "#ffffff", borderRadius: "30px", border: "none", fontSize: "1rem", fontFamily: "Manrope-Bold", textAlign: "center", outline: "none", marginRight: "15px", marginTop: "-10px" }} onClick={() => history.push("/trackOrder", localStorage.setItem("orderId", order.orderId))} >Track Order</button>
                    </Row>
                    {/* <Row style={{ justifyContent: "flex-end", alignItem: "flex-end" }}>
                        <button style={{ width: "140px", height: "40px", backgroundColor: "#000000", color: "#ffffff", borderRadius: "30px", border: "none", fontSize: "1rem", fontFamily: "Manrope-Bold", textAlign: "center", outline: "none", marginRight: "15px", marginTop: "-10px" }} onClick={() => history.push("/trackOrder", localStorage.setItem("orderId", order.orderId))} >Track Order</button>
                    </Row> */}

                </Card.Body>
            </Card>

        )
    }

    const showCancelledOrder = (order) => {
        return (
            <Card className="cancelled-order" style={{ width: '100%', height: "250px", borderRadius: "15px", backgroundColor: "#ffffff", border: "none", boxShadow: "5px 5px 11px 5px #0000000D", marginTop: "20px", marginBottom: "10px" }}>
                <Card.Body>
                    <Row style={{ justifyContent: "space-between", alignItem: "space-between", marginTop: "5px" }}>
                        <h3 style={{ fontSize: "0.9rem", color: "#000000", marginLeft: "15px" }}>{order.created_at.substring(0, 10)} <span style={{ fontFamily: "Manrope-Bold", color: "grey" }}>{order.created_at.substring(11, 16)}</span></h3>
                        <button style={{ width: "170px", height: "40px", backgroundColor: "#696969", color: "#ffffff", borderRadius: "30px", border: "none", fontSize: "17px", fontFamily: "Manrope-Bold", marginRight: "15px", marginTop: "-8px", textAlign: "center", outline: "none" }}>Order Cancelled</button>
                    </Row>
                    <Row style={{ justifyContent: "flex-end", alignItem: "flex-end", marginTop: "-5px" }}>
                        <button style={{ width: "170px", height: "40px", backgroundColor: "#696969", color: "#ffffff", borderRadius: "30px", border: "none", fontSize: "15px", fontFamily: "Manrope-Bold", marginRight: "15px", marginTop: "-8px", textAlign: "center", outline: "none" }}>{order.orderId}</button>
                    </Row>
                    <Row style={{ justifyContent: "space-between", alignItem: "space-between", marginTop: "-15px" }}>
                        <h3 className="pickUp" style={{ fontFamily: "Manrope-SemiBold", fontSize: "0.9rem", color: "grey", marginLeft: "20px", width: "290px" }}>Pickup</h3>
                        <h3 className="Drop" style={{ fontFamily: "Manrope-SemiBold", fontSize: "0.9rem", color: "grey", marginRight: "20px", width: "290px" }}>Drop</h3>

                    </Row>
                    <Row style={{ justifyContent: "space-between", alignItem: "space-between", marginTop: "-20px", }}>
                        <h3 className="sender-address" style={{ fontFamily: "Manrope-Regular", fontSize: "13px", color: "#000000", marginLeft: "20px", width: "290px" }}>{order.senderAdd}</h3>
                        <h3 className="receiver-address" style={{ fontFamily: "Manrope-Regular", fontSize: "13px", color: "#000000", marginRight: "20px", width: "290px" }}>{order.receiverAdd}</h3>
                    </Row>
                </Card.Body>
            </Card>

        )
    }

    async function getOrders() {
        const token = localStorage.getItem("token");
        const new_token = token.replace(/"/g, "");
        var url = `${BaseURL}/orders?page=1&limit=10`;
        if (state.filterCategory[0] === "All")
            url += "&status[]=completed&status[]=cancelled";
        else
            for (var i = 0; i < state.filterCategory.length; ++i) {
                url += `&status[]=${state.filterCategory[i].toLowerCase()}`;
            }
        let result = await fetch(url,
            {
                method: "GET",
                headers: {
                    "Content-Type": "application/json",
                    Authorization: `bearer ${new_token}`,
                },
            }
        );
        result = await result;
        if (result.status === 200 || result.status === 201) {
            result = await result.json();
            if (result.data.length === 0) {
                alert("There are no order to show.");
                setState({
                    ...state,
                    orders: [],
                    loading: false
                })
            } else {
                setState({
                    ...state,
                    orders: result.data,
                    loading: false
                })

            }
            return result;

        } else if (result.status === 401) {
            const refreshToken = localStorage.getItem("refreshToken");
            const data = {
                refreshToken,
            };

            var response = await fetch(`${BaseURL}/users/refresh-token`, {
                method: "POST",
                body: JSON.stringify(data),
                headers: {
                    "Content-Type": "application/json"
                },
            });
            response = await response;
            if (response.status === 200 || response.status === 201) {
                response = await response.json();
                localStorage.setItem("refreshToken", response.refreshToken);
                localStorage.setItem("token", response.jwtToken);
                result = await fetch(
                    url,
                    {
                        method: "GET",
                        headers: {
                            "Content-Type": "application/json",
                            Authorization: `bearer ${response.jwtToken}`,
                        },
                    }
                );
                result = await result;
                if (result.status === 200 || result.status === 201) {
                    result = await result.json();
                    if (result.data.length === 0) {
                        alert("There are no order to show.");
                        setState({
                            ...state,
                            orders: [],
                            loading: false
                        })
                    } else {
                        setState({
                            ...state,
                            orders: result.data,
                            loading: false
                        })
                    }
                    return result;
                }
                return response;
            } else {
                alert("Session expired. Please login to continue.");
                localStorage.clear("All");
                history.push("/Home");
            }

        } else if (result.status === 400) {
            result = await result.json();
            alert(result.message);
            return result
        }
    }

    function All() {
        setState({
            ...state,
            filterCategory: ["All"],
            loading: true,
        });
    }

    function Completed() {
        setState({
            ...state,
            filterCategory: ["Completed"],
            loading: true,
        });
    }

    function Cancelled() {
        setState({
            ...state,
            filterCategory: ["Cancelled"],
            loading: true,
        });
    }



    return (
        <Row className="m-0" >
            <Col
                lg={2} md={2} sm={2}
                className="text-center pt-5 "
                style={{ backgroundColor: "white", height: "100vh" }}
            >
                <Row className="gordian-logo" style={{ justifyContent: "center", alignItem: "center" }}>
                    <img src={Logo} alt="Logo" className="logo" />
                </Row>
                <h1 style={{ fontSize: "1.56rem", textAlign: "center", fontFamily: "Manrope-Bold" }}>Gordian</h1>
                <Sidebar />
            </Col>
            <Col lg={7} md={7} sm={7}  >
                <Row style={{ justifyContent: "evenly", alignItem: "evenly", padding: "10px" }}>
                    <h1 style={{ fontSize: "2rem", fontFamily: "Manrope-Bold", color: "#000000" }}>Your order list</h1>
                    <div className="order-list-buttons" style={{ paddingLeft: "50px" }}>
                        <Button style={{ backgroundColor: "black", borderColor: "black", fontFamily: "Manrope-Bold", fontSize: "17px" }} onClick={All}>All</Button>{' '}
                        <Button style={{ backgroundColor: "black", borderColor: "black", fontFamily: "Manrope-Bold", fontSize: "17px" }} onClick={Completed}>Completed</Button>{' '}
                        <Button style={{ backgroundColor: "black", borderColor: "black", fontFamily: "Manrope-Bold", fontSize: "17px" }} onClick={Cancelled}>Cancelled</Button>{' '}
                    </div>
                </Row>

                <h1 style={{ fontSize: "1rem", fontFamily: "Manrope-SemiBold", color: "#000000" }}>Previous Orders</h1>
                {state.loading ? (
                    <Row style={{ justifyContent: "center", alignItem: "center", marginTop: "100px" }}>
                        <Loader
                            type="Oval"
                            color="#000000"
                            height={40}
                            width={40}
                        />
                    </Row>

                ) :
                    state.orders.length === 0 ? (
                        <h1 style={{ fontSize: "1.5rem", fontFamily: "Manrope-SemiBold", textAlign: "center" }}>No Orders</h1>
                    ) : (
                            // eslint-disable-next-line
                            state.orders.map((order) => {
                                if (order.orderStatus === "completed")
                                    return showCompletedOrder(order);
                                else if (order.orderStatus === "cancelled")
                                    return showCancelledOrder(order);
                                // Add a check if no filter is selected
                            })
                        )}

            </Col>
            <Col lg={3} md={3} sm={3} style={{ backgroundColor: "white" }} className="rightside-content" >
                <Container>
                    <h1 className="name" style={{ fontSize: "20px", fontWeight: "600", marginTop: "1.25rem", fontFamily: "Manrope-SemiBold" }}>Welcome, {new_name} </h1>
                    <hr />

                </Container>
            </Col>
        </Row >
    )
}

export default PastOrders
