import React, { useState } from "react";
import { Row, Col, Container } from "reactstrap";
import "./styles.mobile.css";
import Instructions from "../T&C/Instructions";
import { Link } from 'react-router-dom';
import ProgressBar from "../Components/ProgressBar";
import BackIcon from "./../assets/images/back.png";
import Card from 'react-bootstrap/Card'

const MobileFormPage2 = () => {

  const [state, setState] = useState({
    checked: false,
  })

  const Termschange = (e) => {
    setState({
      ...state,
      checked: e.target.checked,
    })
  }

  const ValidSubmission = () => {
    if (state.checked) {
      return (
        <Link
          to={{
            pathname: "/YourDetails",
            state: {
              checked: state.checked,
            },
          }}
          type="button"
          className="btn btn-dark rounded-pill button3 mb-3"
        >
          Next
        </Link>
      );
    } else {
      return (
        <button
          disabled
          type="button"
          className="btn btn-dark rounded-pill button3 mb-3"
        >
          Next
        </button>
      );
    }
  };

  return (
    <Container>
      <Row className="m-0 mt-2" style={{ justifyContent: "space-between", alignItem: "space-between" }}>
        {/* <Col xs={8}></Col> */}
        <Link to="/placeOrder" style={{ textDecoration: 'none' }} >
          <Card style={{ width: '50px', height: "50px", borderRadius: "30px", backgroundColor: "#F5F6FA", border: "none", boxShadow: "5px 5px 11px 5px #0000000D" }}>
            <Card.Body>
              <img style={{ height: "30px", width: "27px", marginTop: "-15px", marginLeft: "-11px" }} src={BackIcon} alt="backicon" />
            </Card.Body>
          </Card>
        </Link>
        <Col xs={4} style={{ marginTop: "10px" }}>
          <ProgressBar width='25%' steps='Step 1/4' />
        </Col>
      </Row>
      <div className="NewOrder">Place Order</div>
      <Row className="m-0">
        <Instructions />
        <div>
          <input
            value={state.checked}
            onChange={(e) => Termschange(e)}
            type="checkbox"
          />
          &ensp;
          <label style={{ fontSize: "15px" }}>
            I agree to the &nbsp;
            <b>
              <Link
                to="/terms"
                style={{ color: "black", textDecoration: "none" }}
              >
                terms and conditions
              </Link>
            </b>
          </label>
        </div>
        <Row style={{ marginLeft: "-75px", 'width': '100%' }}>
          {ValidSubmission()}
        </Row>
      </Row>
    </Container>
  );
};

export default MobileFormPage2;
