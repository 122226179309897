import React from 'react'
import { Row, Col, Container } from "reactstrap";
import EmailIcon from "../assets/images/emailWhite.png";
import MobileIcon from "../assets/images/mobileWhite.png";
import { Link, useHistory } from "react-router-dom";
import Slider from "./Slider";
import Card from 'react-bootstrap/Card';
import BackIcon from "./../assets/images/back.png";
import ChatIcon from "./../assets/images/chat.png"
import "./../Globals/Fonts.css";

function DesktopBusinessSignup1(props) {

    const history = useHistory();

    function signupwithemail() {
        history.push("/BusinessSignup2")
    }

    return (
        <Row style={{ marginBottom: 0, marginTop: 0, backgroundColor: '#F5F6FA' }}>
            <Col lg={7} md={7} sm={7}>
                <Slider />
            </Col>

            <Col lg={5} md={5} sm={5} >
                <Row style={{ marginTop: "15px", marginLeft: "5px", marginRight: "30px", justifyContent: "space-between", alignItem: "space-between" }}>
                    <Link to="/Signup1" style={{ textDecoration: 'none' }} >
                        <Card style={{ width: '50px', height: "50px", borderRadius: "30px", backgroundColor: "#F5F6FA", border: "none", boxShadow: "5px 5px 11px 5px #0000000D" }}>
                            <Card.Body>
                                <img style={{ height: "30px", width: "27px", marginTop: "-15px", marginLeft: "-11px" }} src={BackIcon} alt="backicon" />
                            </Card.Body>
                        </Card>
                    </Link>

                    <Link to="/ContactUs" style={{ textDecoration: "none" }}>
                        <Card style={{ width: '50px', height: "50px", borderRadius: "30px", backgroundColor: "#F5F6FA", border: "none", boxShadow: "5px 5px 11px 5px #0000000D" }}>
                            <Card.Body>
                                <img style={{ height: "30px", width: "30px", marginTop: "-18px", marginLeft: "-11px" }} src={ChatIcon} alt="chaticon" />
                            </Card.Body>
                        </Card>
                    </Link>
                </Row>
                <Container style={{ marginTop: "100px" }}>
                    <h1 className="signup-text" style={{ fontSize: "24px", fontFamily: "Manrope-Bold", marginLeft: "60px", marginBottom: "20px", color: "#000000" }}>Sign Up</h1>
                    <Row style={{ marginTop: "10px", marginLeft: "5px", justifyContent: "center", alignItem: "center" }}>
                        {/* <Link to="Signup3" style={{ textDecoration: 'none' }} > */}
                        <Card className="mobile-card" style={{ width: '200px', height: "200px", borderRadius: "20px", backgroundColor: "#000000" }} onClick={signupwithemail}>
                            <Card.Body>
                                <img className="icons" style={{ height: "5.5rem", width: "5.5rem" }} src={EmailIcon} alt="emailicon" />
                                <Card.Title className="texts" style={{ color: "#ffffff", fontSize: "1.375rem", fontFamily: "Manrope-Bold", marginTop: "35px" }}>Email Address</Card.Title>
                            </Card.Body>
                        </Card>
                        {/* </Link> */}

                        <Link to="/BusinessSignupMobile" style={{ textDecoration: "none" }}>
                            <Card className="mobile-card ml-4" style={{ width: '200px', height: "200px", borderRadius: "20px", backgroundColor: "#000000" }}>
                                <Card.Body>
                                    <img className="icons" style={{ height: "5.5rem", width: "5.5rem" }} src={MobileIcon} alt="mobileicon" />
                                    <Card.Title className="texts" style={{ color: "#ffffff", fontSize: "1.26rem", fontFamily: "Manrope-Bold", marginTop: "35px" }}>Mobile Number</Card.Title>
                                </Card.Body>
                            </Card>
                        </Link>

                    </Row>
                    <h1 style={{ color: "#92979D", fontSize: "14px", textAlign: "center", fontFamily: "Manrope-Medium" }}>Already have an account?</h1>

                    <Link to="/Home" style={{ textDecoration: 'none' }}>

                        <h1 style={{ textAlign: "center", fontSize: "17px", fontFamily: "Manrope-Bold", color: "#000000" }}>Login</h1>
                    </Link>
                    <Row style={{ alignItem: "center", justifyContent: "center" }}>
                        <h1 className="goto-text" style={{ color: "#92979D", fontSize: "14px", fontFamily: "Manrope-Bold", position: "fixed", bottom: "50px", }}>Facing difficulties?</h1>
                        <Link to="/ContactUs" style={{ textDecoration: "none", marginLeft: "-65px" }}>
                            <h1 className="contact-text" style={{ color: "#000000", fontSize: "14px", fontFamily: "Manrope-Bold", position: "fixed", bottom: "20px", }}>Contact Us</h1>
                        </Link>
                    </Row>
                </Container>
            </Col>
        </Row>
    )
}

export default DesktopBusinessSignup1;