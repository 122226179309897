import React from 'react'
import "./../Globals/Fonts.css"
import Logo from "./../assets/images/Logo1.png"
import "./styles.signup.css"
// import Gordian from "./../assets/images/gordian-logo-white.jpeg";

const Slider = () => {
    return (
        <div style={{ height: "100vh", backgroundColor: "#000000" }}>

            <img className="icon1" style={{ height: "7rem", width: "7rem", marginTop: "5rem", marginLeft: "7.188rem" }} src={Logo} alt="logo" />
            <div className="theme-text" style={{ marginLeft: "6.25rem", marginRight: "7rem", marginTop: "6rem" }}>
                <h1 className="text1" style={{ fontSize: "2.6rem", fontFamily: "Manrope-SemiBold", letterSpacing: "-0.1rem", color: "#ffffff" }}>Now deliver what matters to you securely.</h1>
            </div>

            <div className="theme-text1" style={{ marginLeft: "6.25rem", marginTop: "0.625rem" }}>
                <h1 className="text2" style={{ fontSize: "1.5rem", fontFamily: "Manrope-Bold", letterSpacing: "-0.063rem", color: "#C0C6CD" }}>India's only secure delivery app.</h1>
            </div>

            <div className="gordian-text" style={{ marginLeft: "6.25rem", marginTop: "8rem" }}>
                {/* <img style={{ height: "120px", width: "120px"}} src={Gordian} alt='Gordian-Logo'/> */}
                <h1 className="text3" style={{ fontSize: "2.75rem", fontFamily: "Manrope-Bold", color: "#ffffff", opacity: 1 }}>Gordian</h1>
            </div>

        </div>
    )
}

export default Slider