import React, { Fragment, useState, useEffect } from "react";
import { Row, Container } from "reactstrap";
import { useHistory } from "react-router-dom";
import "./../Signup&login-Mobile/styles.signuplogin.css";
import BackIcon from "./../assets/images/back.png";
import Card from 'react-bootstrap/Card'
import "./../Globals/Fonts.css";
import "./styles.mobile.css";
import Variables from "./../Globals/variables";
import Loader from "react-loader-spinner";
import "react-loader-spinner/dist/loader/css/react-spinner-loader.css";
import "react-confirm-alert/src/react-confirm-alert.css";

const BaseURL = Variables.baseUrl;

function MobileCureentOrders(props) {

    const [state, setState] = useState({
        loading: true,
        orders: [],
        orderId: [],
        btnValue: "Cancel Order",

    });
    useEffect(() => {
        getOrders();
    }, // eslint-disable-next-line
        []);

    const history = useHistory();

    const showPendingOrders = (order) => {
        return (
            <Card style={{ width: '330px', height: "415px", borderRadius: "15px", backgroundColor: "#ffffff", border: "none", boxShadow: "5px 5px 11px 5px #0000000D", marginTop: "20px", marginBottom: "10px" }}>
                <Card.Body>
                    <Row style={{ justifyContent: "space-between", alignItem: "space-between", marginTop: "5px" }}>
                        <h3 style={{ fontSize: "0.7rem", color: "#000000", marginLeft: "15px" }}>{order.created_at.substring(0, 10)} <span style={{ fontFamily: "Manrope-Bold", color: "grey" }}>({order.created_at.substring(11, 16)})</span></h3>
                        <button style={{ width: "145px", height: "40px", backgroundColor: "#f07173", color: "#ffffff", borderRadius: "30px", border: "none", fontSize: "0.9rem", fontFamily: "Manrope-Bold", marginRight: "15px", marginTop: "-8px", textAlign: "center", outline: "none" }}>Order Placed</button>
                    </Row>
                    <Row style={{ justifyContent: "flex-end", alignItem: "flex-end", marginTop: "-5px" }}>
                        <button style={{ width: "170px", height: "40px", backgroundColor: "grey", color: "#ffffff", borderRadius: "30px", border: "none", fontSize: "0.9rem", fontFamily: "Manrope-Bold", marginRight: "15px", marginTop: "-8px", textAlign: "center", outline: "none" }}>{order.orderId}</button>
                    </Row>
                    <Row style={{ justifyContent: "space-between", alignItem: "space-between", marginTop: "30px" }}>
                        <h3 style={{ fontFamily: "Manrope-SemiBold", fontSize: "15px", color: "grey", marginLeft: "10px" }}>Pickup</h3>
                        <h3 style={{ fontFamily: "Manrope-Regular", fontSize: "13px", color: "#000000", width: "235px" }}>{order.senderAdd}</h3>
                    </Row>
                    <Row style={{ justifyContent: "space-between", alignItem: "space-between", marginTop: "-10px", }}>
                        <h3 style={{ fontFamily: "Manrope-SemiBold", fontSize: "15px", color: "grey", marginLeft: "10px" }}>Drop</h3>
                        <h3 style={{ fontFamily: "Manrope-Regular", fontSize: "13px", color: "#000000", width: "235px" }}>{order.receiverAdd}</h3>
                    </Row>
                    <Row style={{ justifyContent: "space-evenly", alignItem: "space-evenly" }}>

                        <button style={{ width: "140px", height: "40px", backgroundColor: "#000000", color: "#ffffff", borderRadius: "30px", border: "none", fontSize: "17px", fontFamily: "Manrope-Bold", textAlign: "center", outline: "none" }} onClick={() => history.push("/trackOrder", localStorage.setItem('orderId', order.orderId))} >Track Order</button>
                        <button style={{ width: "140px", height: "40px", backgroundColor: "#f07173", color: "#ffffff", borderRadius: "30px", border: "none", fontSize: "17px", fontFamily: "Manrope-Bold", textAlign: "center", outline: "none" }} onClick={() => history.push("/cancelOrder", { orderId: order.orderId, senderAdd: order.senderAdd, receiverAdd: order.receiverAdd })}>{state.btnValue}</button>
                    </Row>

                </Card.Body>
            </Card>

        )
    }

    async function getOrders() {
        const token = localStorage.getItem("token");
        const new_token = token.replace(/"/g, "");
        let result = await fetch(
            `${BaseURL}/orders?page=1&limit=10&status[]=pending&status[]=ongoing`,
            {
                method: "GET",
                headers: {
                    "Content-Type": "application/json",
                    Authorization: `bearer ${new_token}`,
                },
            }
        );
        result = await result;
        if (result.status === 200 || result.status === 201) {
            result = await result.json();
            if (result.data.length === 0) {
                alert("There are no order to show. Please proceed to place an order.");
                setState({
                    ...state,
                    orders: [],
                    loading: false
                })
            } else {
                setState({
                    ...state,
                    orders: result.data,
                    loading: false
                })

            }
            return result;

        } else if (result.status === 401) {
            const refreshToken = localStorage.getItem("refreshToken");
            const data = {
                refreshToken,
            };

            var response = await fetch(`${BaseURL}/users/refresh-token`, {
                method: "POST",
                body: JSON.stringify(data),
                headers: {
                    "Content-Type": "application/json"
                },
            });
            response = await response;
            if (response.status === 200 || response.status === 201) {
                response = await response.json();
                localStorage.setItem("refreshToken", response.refreshToken);
                localStorage.setItem("token", response.jwtToken);
                result = await fetch(
                    `${BaseURL}/orders?page=1&limit=10&status[]=pending&status[]=ongoing`,
                    {
                        method: "GET",
                        headers: {
                            "Content-Type": "application/json",
                            Authorization: `bearer ${response.jwtToken}`,
                        },
                    }
                );
                result = await result;
                if (result.status === 200 || result.status === 201) {
                    result = await result.json();
                    if (result.data.length === 0) {
                        alert("There are no order to show. Please proceed to place an order.");
                        setState({
                            ...state,
                            orders: [],
                            loading: false
                        })
                    } else {
                        setState({
                            ...state,
                            orders: result.data,
                            loading: false
                        })
                    }
                    return result;
                }
                return response;
            } else {
                alert("Session expired. Please login to continue.");
                localStorage.clear("All");
                history.push("/Home");
            }
        } else if (result.status === 400) {
            result = await result.json();
            alert(result.message);
            return result
        }
    }

    return (
        <Fragment>
            <Container>
                <Row style={{ marginTop: "15px", marginLeft: "5px", justifyContent: "space-between", alignItem: "space-between", marginRight: "5px" }} onClick={() => props.history.goBack()}>
                    <Card style={{ width: '50px', height: "50px", borderRadius: "30px", backgroundColor: "#F5F6FA", border: "none", boxShadow: "5px 5px 11px 5px #0000000D" }}>
                        <Card.Body>
                            <img style={{ height: "30px", width: "27px", marginTop: "-15px", marginLeft: "-11px" }} src={BackIcon} alt="backicon" />
                        </Card.Body>
                    </Card>
                </Row>
                <div style={{ marginTop: "30px", marginLeft: "5px" }}>
                    <h1 style={{ fontSize: "32px", fontFamily: "Manrope-ExtraBold", color: "#000000", letterSpacing: "-1px" }} >Track Current Orders</h1>
                </div>

                {/* <Card style={{ width: '330px', height: "270px", borderRadius: "15px", backgroundColor: "#ffffff", border: "none", boxShadow: "5px 5px 11px 5px #0000000D", marginTop: "20px" }}>
                    <Card.Body>
                        <Row style={{ justifyContent: "space-between", alignItem: "space-between", marginTop: "5px" }}>
                            <h3 style={{ fontSize: "15px", color: "#000000", marginLeft: "15px" }}>2021-06-05</h3>
                            <button style={{ width: "165px", height: "40px", backgroundColor: "#f07173", color: "#ffffff", borderRadius: "30px", border: "none", fontSize: "17px", fontFamily: "Manrope-Bold", marginRight: "15px", marginTop: "-8px", textAlign: "center", outline: "none" }}>Order Placed</button>
                        </Row>
                        <Row style={{ justifyContent: "space-between", alignItem: "space-between", marginTop: "30px" }}>
                            <h3 style={{ fontFamily: "Manrope-SemiBold", fontSize: "15px", color: "grey" }}>Pickup</h3>
                            <h3 style={{ fontFamily: "Manrope-Regular", fontSize: "15px", color: "#000000", width: "235px" }}>Laxmi NagarLaxmi Nagar, New Delhi, Delhi, India</h3>
                        </Row>
                        <Row style={{ justifyContent: "space-between", alignItem: "space-between", marginTop: "-10px", }}>
                            <h3 style={{ fontFamily: "Manrope-SemiBold", fontSize: "15px", color: "grey" }}>Drop</h3>
                            <h3 style={{ fontFamily: "Manrope-Regular", fontSize: "15px", color: "#000000", width: "235px" }}>Laxmi NagarLaxmi Nagar, New Delhi, Delhi, India</h3>
                        </Row>
                        <Row style={{ justifyContent: "space-evenly", alignItem: "space-evenly" }}>
                            <button style={{ width: "140px", height: "40px", backgroundColor: "#000000", color: "#ffffff", borderRadius: "30px", border: "none", fontSize: "17px", fontFamily: "Manrope-Bold", textAlign: "center", outline: "none" }} >Repeat Order</button>
                            <button style={{ width: "140px", height: "40px", backgroundColor: "#000000", color: "#ffffff", borderRadius: "30px", border: "none", fontSize: "17px", fontFamily: "Manrope-Bold", textAlign: "center", outline: "none" }} >Track Order</button>
                        </Row>

                    </Card.Body>
                </Card> */}

                {state.loading ? (
                    <Row style={{ justifyContent: "center", alignItem: "center", marginTop: "100px" }}>
                        <Loader
                            type="Oval"
                            color="#000000"
                            height={40}
                            width={40}
                        />
                    </Row>

                ) :
                    state.orders.length === 0 ? (
                        <h1 style={{ fontSize: "1.5rem", fontFamily: "Manrope-SemiBold", textAlign: "center", marginTop: "40px" }}>No Orders</h1>
                    ) :
                        (state.orders.map((order) => showPendingOrders(order)
                        )
                        )}

            </Container>
        </Fragment>
    )
}

export default MobileCureentOrders;
