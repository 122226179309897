import React, { Fragment } from "react";
import Navbar from "../Components/Navbar/Navbar";
import { Container } from "reactstrap";

function InsuranceTerms() {
    return (
        <Fragment>
            <Navbar />
            <hr />
            <Container style={{ 'textAlign': 'left' }}>
                <h1>Insurance Terms &amp; Conditions</h1>
                <p>By insuring your Order on the Platform, you agree to the terms and conditions as set out
                    below:</p>
                <ol type="1">
                    <li>You hereby agree and acknowledge that Gordian Technologies Private Limited is
                        facilitating insurance between the insurer and the You (the “insured”). You further agree
                        and acknowledge that the insurance contract is between you and the insurer only and
                        Gordian will only be involved in a facilitating capacity.</li>
                    <h2>Coverage Details:</h2>
                    <li>Conveyance: Road/ Own Vehicle / Personal Carriage</li>
                    <li>Packing: Standard &amp; customary</li>
                    <li>Cargo Covered: Jewellery, luxury rentals, premium gift items, fashion accessories,
                        medicines, cameras, electronic equipment, toys, blood and similar items approved, and
                        legal items being transported by the insured on B2B or B2C basis</li>
                    <h2>Voyage:</h2>
                    <li>From anywhere in Bangalore to anywhere in Bangalore</li>
                    <li>Per Bottom Limit: Rs. 10 lakhs (i.e., Maximum claim value will be Rs. 10 lakhs per order)</li>
                    <li>Per Location Limit: Rs. 20 lakhs (i.e., Maximum claim value will be Rs. 20 lakhs per
                        location or Pick Up Location)</li>
                    <li>Basis of valuation:</li>
                    <ol type="a">
                        <li>For New Commodity – Invoice Value</li>
                        <li>Used / Second Hand items: Depreciated Market Value.</li>
                        <li>Un-invoiced items: excluded</li>
                    </ol>
                    <li>Terms of Cover: Inland Transit (Rail or Road) Clauses-A + SRCC</li>
                    <h2>Claim Conditions:</h2>
                    <li>Intimation: Theft/ Non Delivery claims to be intimated to the insurer by the insured
                        immediately (within 3 hours). Other claims to be intimated within 3 days.</li>
                    <li>Mandatory documents for theft / non-delivery: FIR, C-report, and GPS tracking report</li>
                    <li>Employee (i.e., Delivery Partner) dishonesty or infidelity not covered</li>
                    <li>No claim will be admitted for loss of contents from packages delivered with seals intact</li>
                    <li>No claim for loss /damage will be admitted if proved to be due to incorrect/ambiguous/
                        insufficient address on the package or having been unaccepted by the addressee</li>
                    <li>The items should not be left un-attended at any point of time during the transit</li>
                    <li>The item to be considered as delivered once it is delivered either at the security / other
                        place confirmed by the customer or User</li>
                    <li>Maximum two attempts to be made for delivery and if the losses is intimated post that,
                        no claim to be admissible under the policy</li>
                    <li>Depreciation of 15% per year subject to maximum depreciation of 80% for all total
                        losses excluding Gold items. For Gold the Basis of valuation to be read as Market Value
                        only.</li>
                    <li>Gold and other precious items such as diamonds, platinum, etc will be covered
                        subjected to mandatory GPS Report and OTP based locks. FIR will be part of mandatory
                        documents at the time of claims, if any.</li>
                    <h2>Exclusions:</h2>
                    <li>Excluded Cargo: Bulk Shipment, Bloodstock, livestock and living creature, Deeds,
                        securities, treasury notes, and the like documents, Money (Including bank notes and
                        coin currencies), Stamps, Duty Stamps, Tickets and the like, Motor vehicles, aircraft,
                        watercraft and the like, Arms, ammunitions and explosives, Prototypes and one of a kind
                        interest, Hazardous / Explosives / Flammable / Poisonous /Toxic items, Any Illegal items
                        as per the law of land, Indian Postal Articles</li>
                    <li>Loss or damage due to adulteration, contamination and deterioration of quality.</li>
                    <li>Losses due to breakages unless the loss is proximately caused by perils named under ITC
                        (B) Clause.</li>
                    <li>Rusting, Oxidation and Discoloration losses under the scope of policy unless caused by
                        perils named under ITC (B) Clause.</li>
                    <li>For old or second hand items, non-accident damages not covered</li>
                    <li>Loss or damage due to chipping, denting, scratching, paint / polish damage unless loss is
                        proximately caused by perils named under ITC (B) Clause.</li>
                    <li>Electrical, Mechanical, Electronic, Electro-mechanical and Internal derangement losses
                        unless the loss is proximately caused by perils named under ITC (B) Clause. Incidents
                        where the parcels are lost/stolen by third parties will be covered</li>
                    <h2>Others:</h2>
                    <li>All transits where the customer has taken insurance to be declared in the agreed format
                        to marinedeclaration@icicilombard.com within 5th of the next month</li>
                    <li>CYBER ATTACK EXCLUSION (Replaced by Joint Excess of Loss Cyber Losses Clause JH2020
                        – 007 – wordings incorporated in latter part of this Slip Contract) F.1 Notwithstanding
                        any other term of this contract save for Clause F.2 below, in no case shall this Contract
                        cover loss, damage, liability, or expense directly or indirectly caused by or contributed to
                        or arising from the use or operation of an Information Technology Device as a means for
                        inflicting harm. F.2 Where this clause is endorsed on policies covering risks of war, civil
                        war, revolution, rebellion, insurrection, or civil strife arising therefrom, or any hostile act
                        by or against a belligerent power, or terrorism or any person acting from a political
                        motive, Clause F.1 shall not exclude losses which would otherwise be covered arising
                        from the use of any Information Technology Device in the launch and/or guidance
                        system and/or firing mechanism of any weapon or missile. F.3 For the purposes of this
                        clause, an Information Technology Device shall mean any computer system, hardware,
                        software, programme, code, data, process, virus, information repository, microchip,
                        integrated circuit or similar device in or connected with computer equipment or non-
                        computer equipment, whether the property of a direct insured or not.</li>
                    <li>COMMUNICABLE DISEASE EXCLUSION (Cargo): 1. Notwithstanding any provision to the
                        contrary within this insurance, this insurance does not insure any loss, damage, liability,
                        claim, cost or expense of whatsoever nature caused by, contributed to by, resulting
                        from, arising out of, or in connection with a Communicable Disease or the fear or threat
                        (whether actual or perceived) of a Communicable Disease regardless of any other cause
                        or event contributing concurrently or in any other sequence thereto.2. As used herein, a
                        Communicable Disease means any disease which can be transmitted by means of any
                        substance or agent from any organism to another organism where:1.1 the substance or
                        agent includes, but is not limited to, a virus, bacterium, parasite or other organism or
                        any variation thereof, whether deemed living or not, and 1.2 the method of
                        transmission, whether direct or indirect, includes but is not limited to, airborne
                        transmission, bodily fluid transmission, transmission from or to any surface or object,
                        solid, liquid or gas or between organisms, and 1.3 the disease, substance or agent can
                        cause or threaten bodily injury, illness, damage to human health, human welfare or
                        property. JC2020-011 17 April 2020</li>
                </ol>
            </Container>
        </Fragment>
    )
}
export default InsuranceTerms