import React, { useState, useEffect } from "react";
import { Row, Col } from "reactstrap";

import Variables from "./../Globals/variables";
import Logo from "./../assets/images/Logo1.png";
import Sidebar from "./../Components/Sidebar/sidebar";
import "./../Globals/Fonts.css";
import "react-confirm-alert/src/react-confirm-alert.css";
import { useHistory } from "react-router-dom";
import { GoogleMap, useJsApiLoader, Marker } from "@react-google-maps/api";

import LogoRider from "../assets/images/Rider.png";
import LogoDevice from "../assets/images/Device.png";

const BaseURL = Variables.baseUrl;
const GoogleApiKey = Variables.GOOGLE_API_KEY;

const center = {
  lat: 12.9,
  lng: 77.5,
};
const mapContainerStyle = {
  height: "80vh",
  width: "100%",
};

function TrackGps() {
  const { isLoaded } = useJsApiLoader({
    id: "google-map-script",
    googleMapsApiKey: GoogleApiKey,
  });

  const [orders, setOrders] = useState({
    list: [],
    selectedOrder: undefined,
  });
  const [rider, setRider] = useState({ id: "", position: { lat: 0, lng: 0 } });
  const [device, setDevice] = useState({
    id: "",
    position: { lat: 0, lng: 0 },
  });

  const history = useHistory();

  // Fetching the orders from the backend when component is mounted.
  useEffect(
    () => {
      console.log("getOrderDetails hook called");
      getOrderDetails();
    },
    // eslint-disable-next-line
    []
  );

  useEffect(
    () => {
      console.log("getRiderAndDeviceId hook called");
      getRiderAndDeviceId();
    },
    // eslint-disable-next-line
    [orders.selectedOrder]
  );

  const MINUTE_MS = 10000;

  useEffect(() => {
    const interval = setInterval(() => {
      console.log("Auto exec event - ", new Date());
      updateLocation();
    }, MINUTE_MS);

    return () => clearInterval(interval); // This represents the unmount function, in which you need to clear your interval to prevent memory leaks.
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [rider.id, device.id]);

  async function getOrderDetails() {
    var url = `${BaseURL}/admin/getOrderList`;
    let results = await fetch(url, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: `bearer ${localStorage.getItem("token")}`,
        Accept: "application/json",
      },
    });
    if (results.status === 200 || results.status === 201) {
      results = await results.json();
      setOrders({
        list: results.data,
        selectedOrder: results.data[0].orderId,
      });
      return results;
    } else if (results.status === 401) {
      await refreshToken();
      results = await fetch(url, {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Authorization: `bearer ${localStorage.getItem("token")}`,
        },
      });
      if (results.status === 200 || results.status === 201) {
        results = await results.json();
        if (results.data.length === 0) {
          alert("There are no order to show.");
        } else {
          setOrders({
            list: results.data,
            selectedOrder: results.data[0].orderId,
          });
        }
        return results;
      } else {
        alert("Bad Request");
      }
    } else {
      alert("Bad Request");
    }
  }

  async function getRiderAndDeviceId() {
    if (!orders.selectedOrder) {
      return;
    }
    var url = `${BaseURL}/admin/getRiderAndDeviceId?orderId=${orders.selectedOrder}`;
    let results = await fetch(url, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: `bearer ${localStorage.getItem("token")}`,
        Accept: "application/json",
      },
    });
    if (results.status === 200 || results.status === 201) {
      results = await results.json();
      setDevice({ ...device, id: results.data.boxId });
      setRider({ ...rider, id: results.data.riderId });
      return results;
    } else if (results.status === 401) {
      refreshToken();
      results = await fetch(url, {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Authorization: `bearer ${localStorage.getItem("token")}`,
        },
      });
      if (results.status === 200 || results.status === 201) {
        results = await results.json();
        if (results.data.length === 0) {
          alert("Rider/Device not found.");
        } else {
          setDevice({ ...device, id: results.data.boxId });
          setRider({ ...rider, id: results.data.riderId });
        }
        return results;
      } else {
        alert("Bad request");
      }
    } else if (results.status === 422) {
      results = await results.json();
      alert(results.message);
    } else {
      alert("Bad request");
    }
  }

  async function getRiderGps() {
    const riderId = rider.id;
    if (!riderId) {
      return;
    }
    var url = `${BaseURL}/admin/getRiderLocation?riderId=${riderId}`;
    let results = await fetch(url, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: `bearer ${localStorage.getItem("token")}`,
        Accept: "application/json",
      },
    });
    if (results.status === 200 || results.status === 201) {
      results = await results.json();
      if (results.data[0].length !== 0) {
        setRider({
          id: riderId,
          position: {
            lat: parseFloat(results.data[0].latitude),
            lng: parseFloat(results.data[0].longitude),
          },
        });
        console.log("Rider update", rider);
      }
      return results;
    } else if (results.status === 401) {
      refreshToken();
      results = await fetch(url, {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Authorization: `bearer ${localStorage.getItem("token")}`,
        },
      });
      results = await results;
      if (results.status === 200 || results.status === 201) {
        results = await results.json();
        if (results.data.length === 0) {
          alert("No Rider gps data");
        } else {
          setRider({
            id: riderId,
            position: {
              lat: parseFloat(results.data[0].latitude),
              lng: parseFloat(results.data[0].longitude),
            },
          });
          console.log("Rider update", rider);
        }
        return results;
      } else {
        alert("Bad request");
      }
    } else {
      alert("Bad request");
    }
  }
  async function getDeviceGps() {
    const deviceId = device.id;
    if (!deviceId) {
      return;
    }
    var url = `${BaseURL}/admin/getDeviceLocation?deviceId=${deviceId}`;
    let results = await fetch(url, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: `bearer ${localStorage.getItem("token")}`,
        Accept: "application/json",
      },
    });
    results = await results;
    if (results.status === 200 || results.status === 201) {
      results = await results.json();
      if (results.data) {
        setDevice({
          id: deviceId,
          position: {
            lat: parseFloat(results.data.latitude),
            lng: parseFloat(results.data.longitude),
          },
        });
        console.log("Device update", device);
      }
      return results;
    } else if (results.status === 401) {
      refreshToken();
      results = await fetch(url, {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Authorization: `bearer ${localStorage.getItem("token")}`,
        },
      });
      if (results.status === 200 || results.status === 201) {
        results = await results.json();
        if (results.data.length === 0) {
          alert("No device gps data");
        } else {
          setDevice({
            id: deviceId,
            position: {
              lat: parseFloat(results.data.latitude),
              lng: parseFloat(results.data.longitude),
            },
          });
          console.log("Device update", device);
        }
        return results;
      } else {
        alert("Bad request");
      }
    } else {
      alert("Bad request");
    }
  }
  const updateLocation = async (e) => {
    if (e) {
      console.log("event ", e.type);
    }
    // const gpsDevice = await getDeviceGps();
    // const gpsRider = await getRiderGps();

    await getDeviceGps();
    await getRiderGps();

    // if (gpsDevice && gpsDevice.data.latitude) {
    //   setDevice({
    //     ...device,
    //     position: {
    //       lat: parseFloat(gpsDevice.data.latitude),
    //       lng: parseFloat(gpsDevice.data.longitude),
    //     },
    //   });
    // }
    // if (gpsRider && gpsRider.data[0].latitude) {
    //   setRider({
    //     ...rider,
    //     position: {
    //       lat: parseFloat(gpsRider.data[0].latitude),
    //       lng: parseFloat(gpsRider.data[0].longitude),
    //     },
    //   });
    // }
    //console.log("Location update", device, rider);
  };

  async function refreshToken() {
    const refreshToken = localStorage.getItem("refreshToken");
    const data = {
      refreshToken,
    };

    var response = await fetch(`${BaseURL}/users/refresh-token`, {
      method: "POST",
      body: JSON.stringify(data),
      headers: {
        "Content-Type": "application/json",
      },
    });
    response = await response;
    if (response.status === 200 || response.status === 201) {
      response = await response.json();
      localStorage.setItem("refreshToken", response.refreshToken);
      localStorage.setItem("token", response.jwtToken);
    } else {
      localStorage.clear();
      localStorage.clear("All");
      alert("Session expired. Please login to continue.");
      history.push("/Home");
    }
  }

  const handleChange = (e) => {
    setOrders({ ...orders, selectedOrder: e.target.value });
    console.log("Dropdown change");
    console.log({ orders });
  };
  const riderPositionChanged = (e) => {
    console.log("Rider Position changed event", orders, rider);
  };
  const devicePositionChanged = (e) => {
    console.log("Device Position changed event", orders, device);
  };

  return (
    <Row className="m-0">
      <Col
        lg={2}
        md={2}
        sm={2}
        className="text-center pt-5"
        style={{ backgroundColor: "white", height: "100vh" }}
      >
        <Row
          className="gordian-logo"
          style={{ justifyContent: "center", alignItem: "center" }}
        >
          <img src={Logo} alt="Logo" className="logo" />
        </Row>
        <h1
          style={{
            fontSize: "1.56rem",
            textAlign: "center",
            fontFamily: "Manrope-Bold",
          }}
        >
          Gordian
        </h1>
        <Sidebar />
      </Col>
      <Col lg={10} md={10} sm={10} style={{ backgroundColor: "white" }}>
        <Col lg={12} md={12} sm={12}>
          <h1
            className="name"
            style={{
              fontSize: "20px",
              fontWeight: "600",
              marginTop: "1.25rem",
              fontFamily: "Manrope-SemiBold",
            }}
          >
            Live Gps Tracking of Orders
          </h1>
          <hr />
          <Row>
            {orders.list.length === 0 ? (
              <h1
                style={{
                  fontSize: "1.5rem",
                  fontFamily: "Manrope-SemiBold",
                  textAlign: "center",
                }}
              >
                No Orders
              </h1>
            ) : (
              // eslint-disable-next-line
              <Col lg={12} md={12} sm={12}>
                <select
                  onChange={handleChange}
                  title="Ongoing Orders"
                  style={{
                    height: "40px",
                    backgroundColor: "#696969",
                    color: "#ffffff",
                    borderRadius: "30px",
                    border: "none",
                    fontSize: "17px",
                    fontFamily: "Manrope-Bold",
                    padding: "8px",
                    textAlign: "center",
                    outline: "none",
                  }}
                >
                  {orders.list.map((order) => (
                    <option key={order.orderId} value={order.orderId}>
                      {order.orderId}
                    </option>
                  ))}
                </select>
                <button
                  onClick={updateLocation}
                  style={{
                    width: "170px",
                    height: "40px",
                    backgroundColor: "#696969",
                    color: "#ffffff",
                    borderRadius: "30px",
                    border: "none",
                    fontSize: "17px",
                    fontFamily: "Manrope-Bold",
                    marginLeft: "15px",
                    textAlign: "center",
                    outline: "none",
                  }}
                >
                  Show Gps
                </button>
              </Col>
            )}
          </Row>
          <Row>
            {isLoaded ? (
              <GoogleMap
                id="gpsTrackerMap"
                mapContainerStyle={mapContainerStyle}
                center={center}
                zoom={8}
                options={{ streetViewControl: false }}
              >
                <Marker
                  icon={LogoRider}
                  position={rider.position}
                  id="markerRider"
                  onPositionChanged={riderPositionChanged}
                />
                <Marker
                  id="markerDevice"
                  icon={LogoDevice}
                  position={device.position}
                  onPositionChanged={devicePositionChanged}
                />
              </GoogleMap>
            ) : (
              <></>
            )}
          </Row>
          {/* <Row style={{ justifyContent: "center", alignItem: "center" }}>
            <h1
              style={{
                color: "#92979D",
                fontSize: "0.75rem",
                fontFamily: "Manrope-SemiBold",
                position: "fixed",
                bottom: "40px",
              }}
            >
              For any difficulties
            </h1>
            <Link to="/Contactus2">
              <h1
                style={{
                  fontFamily: "Manrope-Bold",
                  color: "#000000",
                  fontSize: "0.938rem",
                  position: "fixed",
                  bottom: "15px",
                  marginLeft: "-40px",
                }}
              >
                Contact Us
              </h1>
            </Link>
          </Row> */}
        </Col>
      </Col>
    </Row>
  );
}

export default TrackGps;
