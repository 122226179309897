import React, { useState, useEffect } from "react";
import { Container, Row } from "reactstrap";
import "./../Signup&login-Mobile/styles.signuplogin.css";
import Card from "react-bootstrap/Card";
import "./../Globals/Fonts.css";
import Variables from "../Globals/variables";
import { useHistory, Link } from "react-router-dom";
import { confirmAlert } from "react-confirm-alert";

const BaseURL = Variables.baseUrl;

function MobilePromoCodePayment(props) {
    const name = localStorage.getItem("name");
    const new_name = name.replace(/"/g, "");
    const email = localStorage.getItem("email");
    const contact1 = localStorage.getItem("contact");
    const amount = localStorage.getItem("amount");
    const totalDiscount = localStorage.getItem("totalDiscount");
    const totalAmount = localStorage.getItem("totalAmount");
    const role = localStorage.getItem("role");

    const history = useHistory();

    const [state, setState] = useState({
        name: new_name,
        email: email,
        contact1: contact1,
        role: role,
        isInsured: props.location.state.isInsured,
        files: props.location.state.files,
        insuranceAmount: props.location.state.insuranceAmount,
        insuranceEstimate: props.location.state.insuranceEstimate,
        senderAdd: props.location.state.senderAdd,
        senderAdd1: props.location.state.senderAdd1,
        senderBuilding: props.location.state.senderBuilding,
        senderContact: props.location.state.senderContact,
        receiverAdd: props.location.state.receiverAdd,
        receiverAdd1: props.location.state.receiverAdd1,
        receiverBuilding: props.location.state.receiverBuilding,
        receiverContact: props.location.state.receiverContact,
        destLat: props.location.state.destLat,
        destLng: props.location.state.destLng,
        orderId: null,
        promoCode: props.location.state.promoCode,
        orgLat: props.location.state.orgLat,
        orgLng: props.location.state.orgLng,
        totalAmount: parseInt(totalAmount),
        totalDiscount: totalDiscount,
        amount: parseInt(amount),
        deliveryMethod: props.location.state.deliveryMethod,
        btnValue: "Continue to Pay",
        checked: false,
        alreadyInsured: props.location.state.alreadyInsured
    });

    console.log("pp", state)

    useEffect(() => {
        if (state.insuranceAmount === "1") {
            setState({
                ...state,
                insuranceEstimate: 0,
                insuranceAmount: 1,
                // files: [],
                // isInsured: false
            })
        }
    }, [state])

    const Termschange = (e) => {
        setState({
            ...state,
            checked: e.target.checked,
        });
    };

    function changepromocode() {
        history.push("/Mobilepromocode", {
            isInsured: state.isInsured,
            insuranceAmount: state.insuranceAmount,
            insuranceEstimate: state.insuranceEstimate,
            files: state.files,
            amount: state.amount,
            senderAdd: state.senderAdd,
            senderAdd1: state.senderAdd1,
            senderBuilding: state.senderBuilding,
            senderContact: state.senderContact,
            orgLat: state.orgLat,
            orgLng: state.orgLng,
            receiverAdd: state.receiverAdd,
            receiverAdd1: state.receiverAdd1,
            receiverBuilding: state.receiverBuilding,
            receiverContact: state.receiverContact,
            destLat: state.destLat,
            destLng: state.destLng,
            deliveryMethod: state.deliveryMethod,
            alreadyInsured: state.alreadyInsured
        });
    }

    // Used for RazorPay
    const loadScript = () => {
        return new Promise((resolve) => {
            const script = document.createElement("script");
            script.src = "https://checkout.razorpay.com/v1/checkout.js";
            document.body.appendChild(script);
            script.onload = () => {
                resolve(true);
            };
            script.onerror = () => {
                resolve(false);
            };
        });
    };

    const displayRazorpay = async () => {
        const token = localStorage.getItem("token");
        const new_token = token.replace(/"/g, "");
        const res = await loadScript();
        if (!res) {
            alert(
                "Cannot connect to Razorpay. Check your Internet connection and try again later."
            );
            return;
        } else if (!state.checked) {
            alert('Please agree to the Terms and Conditions.');
            return;
        }

        setState({
            ...state,
            btnValue: "Making Payment...",
        });

        var bodyFormData = new FormData();
        bodyFormData.append("isInsured", state.isInsured);
        // bodyFormData.append("files", state.files);
        bodyFormData.append("orgLat", state.orgLat);
        bodyFormData.append("orgLng", state.orgLng);
        bodyFormData.append("destLat", state.destLat);
        bodyFormData.append("destLng", state.destLng);
        bodyFormData.append("senderAdd", state.senderAdd1);
        bodyFormData.append("senderBuilding", state.senderBuilding);
        bodyFormData.append("senderContact", state.senderContact);
        bodyFormData.append("receiverAdd", state.receiverAdd1);
        bodyFormData.append("receiverBuilding", state.receiverBuilding);
        bodyFormData.append("receiverContact", state.receiverContact);
        bodyFormData.append("deliveryMethod", state.deliveryMethod);
        bodyFormData.append("promoCode", state.promoCode);
        bodyFormData.append("alreadyInsured", state.alreadyInsured);
        if (state.role === "Bussiness") {
            bodyFormData.append("files", state.files)
            bodyFormData.append("insuranceAmount", state.insuranceAmount)

        }

        let data = await fetch(`${BaseURL}/orders`, {
            method: "POST",
            body: bodyFormData,
            headers: {
                Authorization: `bearer ${new_token}`,
            },
        });
        data = await data;
        if (data.status === 200 || data.status === 201) {
            data = await data.json();
            setState({
                ...state,
                btnValue: "Continue to Pay"
            })
            const { orderId, paymentProcessorOrderId } = data;
            if (typeof orderId === "string") {
                const options = {
                    key: Variables.RazorPay,
                    currency: data.currency,
                    order_id: paymentProcessorOrderId,
                    prefill: {
                        email: email,
                        contact: contact1,
                        name: new_name
                    },
                    name: "Gordian Technologies Pvt. Ltd.",
                    description: "Please pay for the delivery.",
                    handler: function (response) {
                        setState({
                            ...state,
                            orderId,
                        });
                        localStorage.setItem('orderId', orderId)
                        alert(
                            "Thank you for choosing Gordian. Your order has been placed successfully. Please check your E-mail for tracking and delivery executive related details"
                        );
                        setState({
                            senderAdd: "",
                            senderAdd1: "",
                            senderBuilding: "",
                            senderContact: "",
                            receiverAdd: "",
                            receiverAdd1: "",
                            receiverBuilding: "",
                            receiverContact: "",
                            promoCode: null,
                            orgLat: null,
                            orgLng: null,
                            destLat: null,
                            destLng: null,
                            orderId: new Date().toLocaleString(),
                            deliveryMethod: "secure",
                            files: [],
                            isInsured: false,
                            insuranceAmount: null,
                            btnValue: "Continue to Pay",
                        });
                        history.push("/trackOrder")
                    },
                };
                var paymentObject = new window.Razorpay(options);
                paymentObject.open();
            }
            return data;
        } else if (data.status === 401) {
            const refreshToken = localStorage.getItem('refreshToken');
            const data = {
                refreshToken
            };

            var response = await fetch(`${BaseURL}/users/refresh-token`, {
                method: 'POST',
                body: JSON.stringify(data),
                headers: {
                    "Content-Type": "application/json",
                    // "Accept": 'application/json'
                }
            })
            response = await response;
            if (response.status === 200 || response.status === 201) {
                response = await response.json();
                localStorage.setItem('refreshToken', response.refreshToken)
                localStorage.setItem('token', response.jwtToken)
                let data1 = await fetch(`${BaseURL}/orders`, {
                    method: "POST",
                    body: bodyFormData,
                    headers: {
                        Authorization: `bearer ${response.jwtToken}`
                    },
                })
                data1 = await data1
                if (data1.status === 200 || data1.status === 201) {
                    data1 = await data1.json();
                    setState({
                        ...state,
                        btnValue: "Continue to Pay"
                    })

                    const { orderId, paymentProcessorOrderId } = data1;
                    if (typeof orderId === "string") {
                        const options = {
                            key: Variables.RazorPay,
                            currency: data.currency,
                            order_id: paymentProcessorOrderId,
                            prefill: {
                                email: email,
                                contact: contact1,
                                name: new_name
                            },
                            name: "Gordian Technologies Pvt. Ltd.",
                            description: "Please pay for the delivery.",
                            handler: function (response) {
                                setState({
                                    ...state,
                                    orderId,
                                });
                                localStorage.setItem('orderId', orderId)
                                alert(
                                    "Thank you for choosing Gordian. Your order has been placed successfully. Please check your E-mail for tracking and delivery executive related details"
                                );
                                setState({
                                    senderAdd: "",
                                    senderAdd1: "",
                                    senderBuilding: "",
                                    senderContact: "",
                                    receiverAdd: "",
                                    receiverAdd1: "",
                                    receiverBuilding: "",
                                    receiverContact: "",
                                    promoCode: null,
                                    orgLat: null,
                                    orgLng: null,
                                    destLat: null,
                                    destLng: null,
                                    orderId: new Date().toLocaleString(),
                                    deliveryMethod: "secure",
                                    files: [],
                                    isInsured: false,
                                    insuranceAmount: null,
                                    btnValue: "Continue to Pay",
                                });
                                history.push("/trackOrder")

                            },
                        };

                        var paymentObject1 = new window.Razorpay(options);
                        paymentObject1.open();
                    }
                    return data1;

                } else if (data1.status === 412 || data1.status === 404 || data1.status === 400 || data1.status === 500 || data1.status === 501) {
                    data1 = await data1.json();
                    alert(data1.message);
                    setState({
                        ...state,
                        btnValue: "Continue to Pay"
                    })
                    return data1;

                }
                return response;
            } else {
                alert("Session expired. Please login to continue.");
                localStorage.clear("All");
                history.push("/Home")
            }

        } else if (data.status === 412 || data.status === 404 || data.status === 400 || data.status === 500 || data.status === 501) {
            data = await data.json();
            alert(data.message);
            setState({
                ...state,
                btnValue: "Continue to Pay"
            })
            return data
        }
    };

    const confirmOrder = () => {
        confirmAlert({
          title: "Important",
          message: "The delivery partner will reach the pickup location within 60 minutes of placing the order.",
          buttons: [
            {
              label: "Yes",
              onClick: displayRazorpay,
            },
            {
              label: "No",
              onClick: () => { },
            },
          ],
        });
      };

    return (
        <Container>
            <div className="NewOrder">Place Order</div>
            <Row
                style={{
                    marginTop: "40px",
                    justifyContent: "center",
                    alignItem: "center",
                }}
            >
                <Card
                    style={{
                        width: "290px",
                        height: "145px",
                        borderRadius: "20px",
                        backgroundColor: "#ffffff",
                        borderColor: "#ffffff",
                    }}
                >
                    <Card.Body>
                        <Row
                            style={{
                                justifyContent: "space-between",
                                alignItem: "space-between",
                                marginLeft: "5px",
                                marginRight: "5px",
                                marginTop: "-5px",
                            }}
                        >
                            <Card.Title
                                style={{
                                    color: "#000000",
                                    fontSize: "16px",
                                    fontFamily: "Manrope-Bold",
                                }}
                            >
                                Total Amount
                            </Card.Title>
                            <Card.Title
                                style={{
                                    color: "#000000",
                                    fontSize: "16px",
                                    fontFamily: "Manrope-Bold",
                                }}
                            >
                                Rs. {state.amount}
                            </Card.Title>
                        </Row>

                        <Row
                            style={{
                                justifyContent: "space-between",
                                alignItem: "space-between",
                                marginLeft: "5px",
                                marginRight: "5px",
                                marginTop: "-20px",
                            }}
                        >
                            <Card.Title
                                style={{
                                    color: "#000000",
                                    fontSize: "16px",
                                    fontFamily: "Manrope-Bold",
                                }}
                            >
                                Discount Amount
                            </Card.Title>
                            <Card.Title
                                style={{
                                    color: "#000000",
                                    fontSize: "16px",
                                    fontFamily: "Manrope-Bold",
                                }}
                            >
                                Rs. {state.totalDiscount}
                            </Card.Title>
                        </Row>

                        {state.role === "Bussiness" ? (
                            <Row
                                style={{
                                    justifyContent: "space-between",
                                    alignItem: "space-between",
                                    marginLeft: "5px",
                                    marginRight: "5px",
                                    marginTop: "-20px",
                                }}
                            >
                                <Card.Title
                                    style={{
                                        color: "#000000",
                                        fontSize: "16px",
                                        fontFamily: "Manrope-Bold",
                                    }}
                                >
                                    Insurance Amount
                                </Card.Title>
                                <Card.Title
                                    style={{
                                        color: "#000000",
                                        fontSize: "16px",
                                        fontFamily: "Manrope-Bold",
                                    }}
                                >
                                    Rs. {state.insuranceEstimate}
                                </Card.Title>
                            </Row>) : null}

                        <Row
                            style={{
                                justifyContent: "space-between",
                                alignItem: "space-between",
                                marginLeft: "5px",
                                marginRight: "5px",
                                marginTop: "-20px",
                            }}
                        >
                            <Card.Title
                                style={{
                                    color: "#000000",
                                    fontSize: "16px",
                                    fontFamily: "Manrope-Bold",
                                }}
                            >
                                Total Price
                            </Card.Title>
                            <Card.Title
                                style={{
                                    color: "#000000",
                                    fontSize: "16px",
                                    fontFamily: "Manrope-Bold",
                                }}
                            >
                                Rs. {state.totalAmount + state.insuranceEstimate}
                            </Card.Title>
                        </Row>
                    </Card.Body>
                </Card>
            </Row>

            <div style={{ marginLeft: "15px" }}>
                <input
                    value={state.checked}
                    onChange={(e) => Termschange(e)}
                    type="checkbox"
                />
                &ensp;
                <label style={{ fontSize: "15px" }}>
                    I agree to the &nbsp;
                    <b>
                        <Link
                            to="/terms"
                            target="_blank"
                            style={{ color: "black", textDecoration: "none" }}
                        >
                            {" "}
                            Terms and Conditions{" "}
                        </Link>
                    </b>
                </label>
            </div>
            <Row style={{ justifyContent: "center", alignItem: "center" }}>
                <button className="promocodepayment-mobile" onClick={confirmOrder}>
                    {state.btnValue}
                </button>
            </Row>

            <Row style={{ justifyContent: "center", alignItem: "center" }}>
                <button className="changepromocode-mobile" onClick={changepromocode}>
                    Change Promo Code
                </button>
            </Row>
        </Container>
    );
}

export default MobilePromoCodePayment;