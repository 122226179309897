import React, { useState } from 'react'
import { Row, Col, Container } from "reactstrap";
import { Link } from "react-router-dom"
import Slider from "./Slider";
import BackIcon from "./../assets/images/back.png";
import Card from 'react-bootstrap/Card';
import ProgressBar from "./../Components/ProgressBar";
// import Variables from "./../Globals/variables";
import "./../Globals/Fonts.css";

// const BaseURL = Variables.baseUrl;

function DesktopBusinessSignup3(props) {
    const role = localStorage.getItem("role");

    const initError = {
        fullnameError: "",
        emailError: "",
        phonenumberError: "",
    };

    const [state, setState] = useState({
        fullname: "",
        email: props.location.state.email,
        password: props.location.state.password,
        confirmpassword: props.location.state.confirmpassword,
        phonenumber: "",
        role: role,
        checked: false,
        btnValue: "Sign Up"
    });
    // const history = useHistory();

    // async function signUp() {
    //     const item = { fullname: state.fullname, email: state.email, password: state.password, confirmpassword: state.confirmpassword, phonenumber: state.phonenumber, role: state.role }
    //     setState({
    //         ...state,
    //         btnValue: "Signing Up..."
    //     })

    //     let result = await fetch(`${BaseURL}/users/signup-customer`, {
    //         method: 'POST',
    //         body: JSON.stringify(item),
    //         headers: {
    //             "Content-Type": 'application/json',
    //             "Accept": 'application/json'
    //         }
    //     })
    //     result = await result.json()
    //     // console.warn("result", result)
    //     if (result.message === "You are registering with existing credentials") {
    //         alert("These credentials already exist. Please try Logging In.");
    //         setState({
    //             ...state,
    //             btnValue: "Sign Up"
    //         })
    //     } else if (result.message === "Phone number is already exist!") {
    //         //eslint-disable-next-line
    //         alert("Phone Number alredy Exist," + "/n" + "Please try another Phone Number.");
    //         setState({
    //             ...state,
    //             btnValue: "Sign Up"
    //         })
    //     } else if (result.message === "passwords don't match") {
    //         //eslint-disable-next-line
    //         alert("Passwords don’t match," + "/n" + " Please recheck your password.");
    //         setState({
    //             ...state,
    //             btnValue: "Sign Up"
    //         })
    //     } else {
    //         localStorage.setItem('refreshToken', result.refreshToken)
    //         localStorage.setItem("token", JSON.stringify(result.jwtToken));
    //         localStorage.setItem("name", JSON.stringify(result.fullName));
    //         localStorage.setItem("email", result.email);
    //         localStorage.setItem("contact", result.phonenumber);
    //         history.push("/placeOrder")
    //     }

    // }

    var [error] = useState(initError);

    const isValid = () => {
        error = initError;
        const re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

        if (state.fullname.length === 0) {
            error.nameError = "Name is required";
            return false;
        }
        if (!re.test(state.email)) {
            error.emailError = "Invalid Email address";
            return false
        }
        if (state.phonenumber.length !== 10) {
            error.contactError = "Invalid Phone Number";
            return false
        } else {
            return true;
        }
    };

    const Termschange = (e) => {
        setState({
            ...state,
            checked: e.target.checked,
        })
    }

    const ValidSubmission = () => {
        if (isValid() && state.checked) {
            return (
                <Link to={{
                    pathname: "/BusinessSignup4",
                    state,
                }}
                    type="button"
                    className="btn btn-dark rounded-pill button mb-3"
                >
                    {state.btnValue}
                </Link>
            );
        } else {
            return (
                <button
                    disabled
                    type="button"
                    className="btn btn-dark rounded-pill button mb-3"
                >
                    {state.btnValue}
                </button>
            );
        }
    };

    return (
        <Row style={{ marginBottom: 0, marginTop: 0, backgroundColor: '#F5F6FA' }}>
            <Col lg={7} md={7} sm={7}>
                <Slider />
            </Col>

            <Col lg={5} md={5} sm={7}>
                <Row style={{ marginTop: "15px", marginLeft: "5px", marginRight: "30px", justifyContent: "space-between", alignItem: "space-between" }}>
                    <Link to="/BusinessSignup2" style={{ textDecoration: 'none' }} >
                        <Card style={{ width: '50px', height: "50px", borderRadius: "30px", backgroundColor: "#F5F6FA", border: "none", boxShadow: "5px 5px 11px 5px #0000000D" }}>
                            <Card.Body>
                                <img style={{ height: "30px", width: "27px", marginTop: "-15px", marginLeft: "-11px" }} src={BackIcon} alt="backicon" />
                            </Card.Body>
                        </Card>
                    </Link>
                    <div style={{ marginTop: "10px" }}>
                        <ProgressBar width="100%" steps="Step 2/2" />
                    </div>
                </Row>
                <Container style={{ marginTop: "45px" }}>
                    <h1 className="enter-details" style={{ fontSize: "25px", fontFamily: "Manrope-Bold", marginLeft: "75px", marginBottom: "20px" }}>Enter Details</h1>
                    <Row style={{ justifyContent: "center", alignItem: "space-between" }}>
                        <Col lg={9} >
                            <label style={{ fontSize: "16px", fontFamily: "Manrope-Bold", marginLeft: "15px" }}>Full Name</label>
                            <input
                                type="text"
                                className="field"
                                value={state.fullname}
                                onChange={(e) => setState({ ...state, fullname: e.target.value })}
                                required
                            />
                        </Col>

                        <Col lg={9} >
                            <label style={{ marginTop: "20px", fontSize: "16px", fontFamily: "Manrope-Bold", marginLeft: "15px" }}>Mobile Number</label>
                            <input
                                type="number"
                                className="field"
                                value={state.phonenumber}
                                onChange={(e) => setState({ ...state, phonenumber: e.target.value })}
                                required
                            />
                        </Col>

                        <Col lg={9} >
                            <label style={{ marginTop: "20px", fontSize: "16px", fontFamily: "Manrope-Bold", marginLeft: "15px" }}>Email Address</label>
                            <input
                                type="email"
                                className="field"
                                disabled
                                value={state.email}
                                onChange={(e) => setState({ ...state, email: e.target.value })}
                                required
                            />
                        </Col>
                    </Row>


                    <Row style={{ justifyContent: "center", alignItem: "center" }}>
                        {/* <Col lg={12} > */}

                        <input
                            className="details-checkbox"
                            style={{ marginTop: "2px" }}
                            type="checkbox"
                            name="remember me"
                            value={state.checked}
                            onChange={(e) => Termschange(e)}
                        />
                &ensp;
                <label className="details-label" style={{ fontSize: "11px", fontFamily: "Manrope-Regular" }}>By creating an account, you agree to our <Link to="/terms" target="_blank" style={{ color: "#000000", textDecoration: "none" }}><b style={{ fontFamily: "Manrope-Bold" }}>Terms & Conditions</b></Link> and <Link to="/privacy-policy" target="_blank" style={{ color: "#000000", textDecoration: "none" }}><b style={{ fontFamily: "Manrope-Bold" }}>Privacy Policy</b></Link></label>
                        {/* </Col> */}
                    </Row>

                    <Row style={{ justifyContent: "center", alignItem: "center" }} className="buttons">
                        <Col lg={9} md={12} sm={12} >
                            {ValidSubmission()}
                        </Col>
                    </Row>

                    <Row style={{ alignItem: "center", justifyContent: "center" }}>
                        <h1 className="goto-text" style={{ color: "#92979D", fontSize: "14px", fontFamily: "Manrope-Bold", position: "fixed", bottom: "40px", }}>Facing difficulties?</h1>
                        <Link to="/ContactUs" style={{ textDecoration: "none", marginLeft: "-65px" }}>
                            <h1 className="contact-text" style={{ color: "#000000", fontSize: "14px", fontFamily: "Manrope-Bold", position: "fixed", bottom: "15px", }}>Contact Us</h1>
                        </Link>
                    </Row>
                </Container>
            </Col>
        </Row>
    )
}

export default DesktopBusinessSignup3;