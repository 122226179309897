import React, { Fragment, useState } from "react";
import { Row, Col, Container } from "reactstrap";
import { Link, useHistory } from "react-router-dom";
import "./styles.signuplogin.css";
import Variables from "../Globals/variables";
import BackIcon from "./../assets/images/back.png";
import ProgressBar from "../Components/ProgressBar";
import Card from 'react-bootstrap/Card'
import "./../Globals/Fonts.css"

const BaseURL = Variables.baseUrl;

const MobileBusinessOtpSignup1 = () => {
    const initError = {
        phonenumberError: "",
    };

    const [state, setState] = useState({
        phonenumber: "",
        btnValue: "Send OTP"

    });
    const history = useHistory();

    async function signupotp() {
        const item = { phonenumber: state.phonenumber }
        setState({
            ...state,
            btnValue: "Sending OTP..."
        })

        let result = await fetch(`${BaseURL}/users/otp-sender`, {
            method: 'POST',
            body: JSON.stringify(item),
            headers: {
                "Content-Type": 'application/json',
                "Accept": 'application/json'
            }
        })
        result = await result;
        if (result.status === 200 || result.status === 201) {
            result = await result.json();
            localStorage.setItem("contact", result.phonenumber);
            history.push("/MobileBusinessSignupOtp2", {
                phonenumber: state.phonenumber
            })
            return result;
        } else if (result.status === 401) {
            result = await result.json();
            alert(result.message);
            setState({
                ...state,
                btnValue: "Send OTP"
            })
            return result;
        }

    }

    var [error] = useState(initError);

    const isValid = () => {
        error = initError;

        if (state.phonenumber.length !== 10) {
            error.phonenumberError = "Invalid Phone Number";
        }

        if (
            error.phonenumberError.length > 0
        ) {
            return false;
        } else {
            return true;
        }
    };

    const ValidSubmission = () => {
        if (isValid()) {
            return (
                <button
                    type="button"
                    onClick={signupotp}
                    className="btn btn-dark rounded-pill button1 mb-3"
                >
                    {state.btnValue}
                </button>
            );
        } else {
            return (
                <button
                    disabled
                    type="button"
                    className="btn btn-dark rounded-pill button1 mb-3"
                >
                    {state.btnValue}
                </button>
            );
        }
    };

    return (
        <Fragment>
            <Container>
                <Row style={{ marginTop: "15px", marginLeft: "5px", justifyContent: "space-between", alignItem: "space-between", marginRight: "5px" }}>
                    <Link to="/MobileBusinessSignup1" style={{ textDecoration: 'none' }} >
                        <Card style={{ width: '50px', height: "50px", borderRadius: "30px", backgroundColor: "#F5F6FA", border: "none", boxShadow: "5px 5px 11px 5px #0000000D" }}>
                            <Card.Body>
                                <img style={{ height: "30px", width: "27px", marginTop: "-15px", marginLeft: "-11px" }} src={BackIcon} alt="backicon" />
                            </Card.Body>
                        </Card>
                    </Link>
                    <div style={{ marginTop: "12px" }}>
                        <ProgressBar width="33%" steps="Step 1/3" />
                    </div>
                </Row>
                <div style={{ marginTop: "30px", marginLeft: "20px" }}>
                    <h1 style={{ fontSize: "32px", fontFamily: "Manrope-ExtraBold", color: "#000000", letterSpacing: "-1px" }} >Sign Up with</h1>
                    <h1 style={{ fontSize: "32px", fontFamily: "Manrope-ExtraBold", color: "#000000", letterSpacing: "-1px" }}>Mobile Number</h1>
                </div>

                <Row style={{ marginTop: "1px" }}>
                    <Col>
                        <label style={{ marginTop: "10px", fontSize: "16px", fontFamily: "Manrope-Bold", marginLeft: "15px" }}>Enter Mobile Number</label>
                        <input
                            type="number"
                            className="field"
                            value={state.phonenumber}
                            onChange={(e) => setState({ ...state, phonenumber: e.target.value })}
                            required
                        />
                        <br />
                    </Col>
                </Row>

                <Row style={{ justifyContent: "center", alignItem: "center" }}>{ValidSubmission()}</Row>

                <div>
                    <h1
                        style={{
                            textAlign: "center",
                            color: "#92979D",
                            fontSize: "12px",
                            fontFamily: "Manrope-SemiBold",
                        }}
                    >
                        Already have an Account?
            </h1>
                    <Link to="/mobilesignin">
                        <h1
                            style={{
                                textAlign: "center",
                                fontFamily: "Manrope-Bold",
                                color: "#000000",
                                fontSize: "17px",
                            }}
                        >
                            Sign In
              </h1>
                    </Link>
                </div>

                <h1 style={{ color: "#92979D", fontSize: "12px", fontFamily: "Manrope-SemiBold", textAlign: "center", marginTop: "160px" }}>For any difficulties</h1>
                <Link to="/ContactUs">
                    <h1 style={{ fontFamily: "Manrope-Bold", color: "#000000", fontSize: "15px", textAlign: "center" }}>Contact Us</h1>
                </Link>

            </Container>
        </Fragment>
    );
};

export default MobileBusinessOtpSignup1;
