import React, { Fragment, useState } from "react";
import { Row, Col, Container } from "reactstrap";
import { useHistory } from "react-router-dom";
import "./../Signup&login-Mobile/styles.signuplogin.css";
import BackIcon from "./../assets/images/back.png";
import Card from 'react-bootstrap/Card';
import "./../Globals/Fonts.css";
import Variables from "./../Globals/variables";
import Loader from "react-loader-spinner";
import { confirmAlert } from "react-confirm-alert";

const BaseURL = Variables.baseUrl;

function AddInsuranceMobile(props) {

    const initError = {
        insuranceAmountError: "",
        insuranceInvoiceError: ""
    };

    const [state, setState] = useState({
        isInsured: props.location.state.isInsured,
        senderAdd: props.location.state.senderAdd,
        senderAdd1: props.location.state.senderAdd1,
        senderBuilding: props.location.state.senderBuilding,
        senderContact: props.location.state.senderContact,
        receiverAdd: props.location.state.receiverAdd,
        receiverAdd1: props.location.state.receiverAdd1,
        receiverBuilding: props.location.state.receiverBuilding,
        receiverContact: props.location.state.receiverContact,
        destLat: props.location.state.destLat,
        destLng: props.location.state.destLng,
        orderId: null,
        orgLat: props.location.state.orgLat,
        orgLng: props.location.state.orgLng,
        deliveryMethod: props.location.state.deliveryMethod,
        files: [],
        insuranceAmount: "",
        insuranceEstimate: -1,
        totalAmount: 0,
        loading: false,
        btnValue: "Confirm Invoice Details",
        alreadyInsured: (props.location.state === undefined || props.location.state.alreadyInsured === undefined) ? false : props.location.state.alreadyInsured

    });
    const history = useHistory();

    function dropdetails() {
        history.push("/Drop-Details",
            {
                isInsured: state.isInsured,
                senderAdd: state.senderAdd,
                senderAdd1: state.senderAdd1,
                receiverAdd: state.receiverAdd,
                receiverAdd1: state.receiverAdd1,
                senderBuilding: state.senderBuilding,
                receiverBuilding: state.receiverBuilding,
                senderContact: state.senderContact,
                receiverContact: state.receiverContact,
                orgLat: state.orgLat,
                orgLng: state.orgLng,
                destLat: state.destLat,
                destLng: state.destLng,
                amount: state.amount,
                deliveryMethod: state.deliveryMethod,
                insuranceEstimate: "",
                insuranceAmount: "1",
                files: state.files
            }
        )
    }

    async function getEstimate() {
        const token = localStorage.getItem("token");
        const new_token = token.replace(/"/g, "");
        if (
            !state.insuranceAmount ||
            parseInt(state.insuranceAmount) < 10000
        ) {
            alert("Insurance is applicable only on invoice value of more than Rs. 10,000");
            return;
        }

        // if (state.insuranceAmount >= 500000) {
        //     alert("Orders can be placed only for invoice value less than Rs. 5 lacs");
        //     return;
        // }

        setState(() => ({
            ...state,
            loading: true
        }));
        var promise = new Promise(function (resolve, reject) {
            // call resolve if the method succeeds
            setTimeout(function () {
                resolve();
            }, 100);

        })

        if (state.alreadyInsured === false)//if already insured then insurance amount is 0
        {
            var insuranceResult = promise.then(() => {
                setState
                    ({
                        ...state,
                        alreadyInsured: true,
                        insuranceEstimate: parseInt(0),
                        totalAmount:
                            parseFloat(state.insuranceAmount),//if already insured then insurance amount and total amount would be same 

                        loading: false
                    })
            }
            )
            console.log(state.insuranceEstimate)
            console.log(state.totalAmount)

            return insuranceResult;
        }

        let result = await fetch(
            `${BaseURL}/bussinessusers/getInsuranceEstimate`,
            {
                method: "POST",
                body: JSON.stringify({
                    amount: state.insuranceAmount,
                    alreadyInsured: false
                }),
                headers: {
                    Authorization: `Bearer ${new_token}`,
                    "Content-Type": "application/json",
                },
            }
        );
        result = await result;
        if (result.status === 200 || result.status === 201) {
            result = await result.json();
            setState({
                ...state,
                insuranceEstimate:
                    result.data[0].insuranceAmount,
                totalAmount:
                    parseFloat(result.data[0].insuranceAmount) +
                    parseFloat(result.data[0].amount),
                loading: false
            });
            return result;
        } else if (result.status === 401) {
            const refreshToken = localStorage.getItem("refreshToken");
            const data = {
                refreshToken,
            };

            var response = await fetch(`${BaseURL}/users/refresh-token`, {
                method: "POST",
                body: JSON.stringify(data),
                headers: {
                    "Content-Type": "application/json"
                },
            });
            response = await response;
            if (response.status === 200 || response.status === 201) {
                response = await response.json();
                localStorage.setItem("refreshToken", response.refreshToken);
                localStorage.setItem("token", response.jwtToken);
                result = await fetch(
                    `${BaseURL}/bussinessusers/getInsuranceEstimate`,
                    {
                        method: "POST",
                        body: JSON.stringify({
                            amount: state.insuranceAmount,
                            alreadyInsured: false
                        }),
                        headers: {
                            Authorization: `Bearer ${response.jwtToken}`,
                            "Content-Type": "application/json",
                        },
                    }
                );
                result = await result;
                if (result.status === 200 || result.status === 201) {
                    result = await result.json();
                    setState({
                        ...state,
                        insuranceEstimate:
                            result.data[0].insuranceAmount,
                        totalAmount:
                            parseFloat(result.data[0].insuranceAmount) +
                            parseFloat(result.data[0].amount),
                        loading: false
                    });
                    return result;
                } else if (result.status === 400 || result.status === 501) {
                    result = await result.json();
                    alert(result.message);
                    setState({
                        ...state,
                        loading: false
                    });
                    return result;
                }
                return response;
            } else {
                alert("Session expired. Please login to continue.");
                localStorage.clear("All");
                history.push("/Home");
            }
        } else if (result.status === 400 || result.status === 501) {
            result = await result.json();
            alert(result.message);
            setState({
                ...state,
                loading: false
            });
            return result;
        }
    };

    async function confirmInsurance() {
        const token = localStorage.getItem("token");
        const new_token = token.replace(/"/g, "");
        if (state.alreadyInsured === false) {
            if (
                !state.insuranceAmount ||
                parseInt(state.insuranceAmount) < 1
            ) {
                alert("Please enter the invoice amount to continue.");
                return;
            }

            // if (
            //     state.files.length === 0
            // ) {
            //     alert("Please upload the Invoice");
            //     return;
            // }

            var promise1 = new Promise(function (resolve, reject) {
                // call resolve if the method succeeds
                setTimeout(function () {
                    resolve();
                }, 100);

            })

            if (state.alreadyInsured === false)//if already insured then insurance amount is 0
            {
                var insuranceResult1 = promise1.then(() => {
                    setState
                        ({
                            ...state,
                            insuranceEstimate: parseInt(0),
                            totalAmount:
                                parseFloat(state.insuranceAmount),//if already insured then insurance amount and total amount would be same 

                            loading: false
                        })
                })

                // this api we calling to check amount greater than 5lakh and store the logs on backend
                let result = await fetch(
                    `${BaseURL}/bussinessusers/getInsuranceEstimate`,
                    {
                        method: "POST",
                        body: JSON.stringify({
                            amount: state.insuranceAmount,
                            alreadyInsured: true
                        }),
                        headers: {
                            Authorization: `Bearer ${new_token}`,
                            "Content-Type": "application/json",
                        },
                    }
                );
                result = await result;
                if (result.status === 200 || result.status === 201) {
                    result = await result.json();
                    setState({
                        ...state,
                        insuranceEstimate:
                            result.data[0].insuranceAmount,
                        totalAmount:
                            parseFloat(result.data[0].insuranceAmount) +
                            parseFloat(result.data[0].amount),
                        loading: false
                    },
                        history.push("/Drop-Details", {
                            isInsured: true,
                            senderAdd: state.senderAdd,
                            senderAdd1: state.senderAdd1,
                            receiverAdd: state.receiverAdd,
                            receiverAdd1: state.receiverAdd1,
                            senderBuilding: state.senderBuilding,
                            receiverBuilding: state.receiverBuilding,
                            senderContact: state.senderContact,
                            receiverContact: state.receiverContact,
                            orgLat: state.orgLat,
                            orgLng: state.orgLng,
                            destLat: state.destLat,
                            destLng: state.destLng,
                            amount: state.amount,
                            deliveryMethod: state.deliveryMethod,
                            files: state.files,
                            insuranceAmount: state.insuranceAmount,
                            insuranceEstimate: parseInt(0),
                            totalAmount: state.totalAmount,
                            alreadyInsured: true
                        }));
                    return result;
                } else if (result.status === 401) {
                    const refreshToken = localStorage.getItem("refreshToken");
                    const data = {
                        refreshToken,
                    };

                    var response2 = await fetch(`${BaseURL}/users/refresh-token`, {
                        method: "POST",
                        body: JSON.stringify(data),
                        headers: {
                            "Content-Type": "application/json"
                        },
                    });
                    response2 = await response2;
                    if (response2.status === 200 || response2.status === 201) {
                        response2 = await response2.json();
                        localStorage.setItem("refreshToken", response2.refreshToken);
                        localStorage.setItem("token", response2.jwtToken);
                        result = await fetch(
                            `${BaseURL}/bussinessusers/getInsuranceEstimate`,
                            {
                                method: "POST",
                                body: JSON.stringify({
                                    amount: state.insuranceAmount,
                                    alreadyInsured: true
                                }),
                                headers: {
                                    Authorization: `Bearer ${response2.jwtToken}`,
                                    "Content-Type": "application/json",
                                },
                            }
                        );
                        result = await result;
                        if (result.status === 200 || result.status === 201) {
                            result = await result.json();
                            setState({
                                ...state,
                                insuranceEstimate:
                                    result.data[0].insuranceAmount,
                                totalAmount:
                                    parseFloat(result.data[0].insuranceAmount) +
                                    parseFloat(result.data[0].amount),
                                loading: false
                            },
                                history.push("/Drop-Details", {
                                    isInsured: true,
                                    senderAdd: state.senderAdd,
                                    senderAdd1: state.senderAdd1,
                                    receiverAdd: state.receiverAdd,
                                    receiverAdd1: state.receiverAdd1,
                                    senderBuilding: state.senderBuilding,
                                    receiverBuilding: state.receiverBuilding,
                                    senderContact: state.senderContact,
                                    receiverContact: state.receiverContact,
                                    orgLat: state.orgLat,
                                    orgLng: state.orgLng,
                                    destLat: state.destLat,
                                    destLng: state.destLng,
                                    amount: state.amount,
                                    deliveryMethod: state.deliveryMethod,
                                    files: state.files,
                                    insuranceAmount: state.insuranceAmount,
                                    insuranceEstimate: parseInt(0),
                                    totalAmount: state.totalAmount,
                                    alreadyInsured: true
                                }));
                            return result;
                        } else if (result.status === 400 || result.status === 501) {
                            result = await result.json();
                            alert(result.message);
                            setState({
                                ...state,
                                loading: false
                            });
                            return result;
                        }
                        return response2;
                    } else {
                        alert("Session expired. Please login to continue.");
                        localStorage.clear("All");
                        history.push("/Home");
                    }
                } else if (result.status === 400 || result.status === 501) {
                    result = await result.json();
                    alert(result.message);
                    setState({
                        ...state,
                        loading: false
                    });
                    return result;
                }

                return insuranceResult1;
            }

            setState(() => ({
                ...state,
                loading: true
            }));

            let result = await fetch(
                `${BaseURL}/bussinessusers/getInsuranceEstimate`,
                {
                    method: "POST",
                    body: JSON.stringify({
                        amount: state.insuranceAmount,
                        alreadyInsured: false
                    }),
                    headers: {
                        Authorization: `Bearer ${new_token}`,
                        "Content-Type": "application/json",
                    },
                }
            );
            result = await result;
            if (result.status === 200 || result.status === 201) {
                result = await result.json();
                localStorage.setItem("insuranceEstimate", parseInt(result.data[0].insuranceAmount));
                setState({
                    ...state,
                    insuranceEstimate:
                        result.data[0].insuranceAmount,
                    totalAmount:
                        parseFloat(result.data[0].insuranceAmount) +
                        parseFloat(result.data[0].amount),
                    loading: false
                },
                    history.push("/Drop-Details", {
                        isInsured: true,
                        senderAdd: state.senderAdd,
                        senderAdd1: state.senderAdd1,
                        receiverAdd: state.receiverAdd,
                        receiverAdd1: state.receiverAdd1,
                        senderBuilding: state.senderBuilding,
                        receiverBuilding: state.receiverBuilding,
                        senderContact: state.senderContact,
                        receiverContact: state.receiverContact,
                        orgLat: state.orgLat,
                        orgLng: state.orgLng,
                        destLat: state.destLat,
                        destLng: state.destLng,
                        amount: state.amount,
                        deliveryMethod: state.deliveryMethod,
                        files: state.files,
                        insuranceAmount: state.insuranceAmount,
                        insuranceEstimate: parseInt(result.data[0].insuranceAmount),
                        totalAmount: state.totalAmount,
                        alreadyInsured: false
                    }));
                return result;
            } else if (result.status === 401) {
                const refreshToken = localStorage.getItem("refreshToken");
                const data = {
                    refreshToken,
                };

                var response1 = await fetch(`${BaseURL}/users/refresh-token`, {
                    method: "POST",
                    body: JSON.stringify(data),
                    headers: {
                        "Content-Type": "application/json"
                    },
                });
                response1 = await response1;
                if (response1.status === 200 || response1.status === 201) {
                    response1 = await response1.json();
                    localStorage.setItem("refreshToken", response1.refreshToken);
                    localStorage.setItem("token", response1.jwtToken);
                    result = await fetch(
                        `${BaseURL}/bussinessusers/getInsuranceEstimate`,
                        {
                            method: "POST",
                            body: JSON.stringify({
                                amount: state.insuranceAmount,
                                alreadyInsured: false
                            }),
                            headers: {
                                Authorization: `Bearer ${response1.jwtToken}`,
                                "Content-Type": "application/json",
                            },
                        }
                    );
                    result = await result;
                    if (result.status === 200 || result.status === 201) {
                        result = await result.json();
                        localStorage.setItem("insuranceEstimate", parseInt(result.data[0].insuranceAmount));
                        setState({
                            ...state,
                            insuranceEstimate:
                                result.data[0].insuranceAmount,
                            totalAmount:
                                parseFloat(result.data[0].insuranceAmount) +
                                parseFloat(result.data[0].amount),
                            loading: false
                        },
                            history.push("/Drop-Details", {
                                isInsured: true,
                                senderAdd: state.senderAdd,
                                senderAdd1: state.senderAdd1,
                                receiverAdd: state.receiverAdd,
                                receiverAdd1: state.receiverAdd1,
                                senderBuilding: state.senderBuilding,
                                receiverBuilding: state.receiverBuilding,
                                senderContact: state.senderContact,
                                receiverContact: state.receiverContact,
                                orgLat: state.orgLat,
                                orgLng: state.orgLng,
                                destLat: state.destLat,
                                destLng: state.destLng,
                                amount: state.amount,
                                deliveryMethod: state.deliveryMethod,
                                files: state.files,
                                insuranceAmount: state.insuranceAmount,
                                insuranceEstimate: parseInt(result.data[0].insuranceAmount),
                                totalAmount: state.totalAmount,
                                alreadyInsured: false
                            }));
                        return result;
                    } else if (result.status === 400 || result.status === 501) {
                        result = await result.json();
                        alert(result.message);
                        setState({
                            ...state,
                            loading: false
                        });
                        return result;
                    }
                    return response1;
                } else {
                    alert("Session expired. Please login to continue.");
                    localStorage.clear("All");
                    history.push("/Home");
                }
            } else if (result.status === 400 || result.status === 501) {
                result = await result.json();
                alert(result.message);
                setState({
                    ...state,
                    loading: false
                });
                return result;
            }

        } else {
            // if (
            //     state.files.length === 0
            // ) {
            //     alert("Please upload the Invoice");
            //     return;
            // }
            if (
                !state.insuranceAmount ||
                parseInt(state.insuranceAmount) < 10000
            ) {
                alert("Insurance is applicable only on invoice value of more than Rs. 10,000");
                return;
            }

            setState(() => ({
                ...state,
                loading: true
            }));

            var promise = new Promise(function (resolve, reject) {
                // call resolve if the method succeeds
                setTimeout(function () {
                    resolve();
                }, 100);

            })

            if (state.alreadyInsured === false)//if already insured then insurance amount is 0
            {
                var insuranceResult = promise.then(() => {
                    setState
                        ({
                            ...state,
                            alreadyInsured: true,
                            insuranceEstimate: parseInt(0),
                            totalAmount:
                                parseFloat(state.insuranceAmount),//if already insured then insurance amount and total amount would be same 

                            loading: false
                        })
                })

                history.push("/Drop-Details", {
                    isInsured: true,
                    senderAdd: state.senderAdd,
                    senderAdd1: state.senderAdd1,
                    receiverAdd: state.receiverAdd,
                    receiverAdd1: state.receiverAdd1,
                    senderBuilding: state.senderBuilding,
                    receiverBuilding: state.receiverBuilding,
                    senderContact: state.senderContact,
                    receiverContact: state.receiverContact,
                    orgLat: state.orgLat,
                    orgLng: state.orgLng,
                    destLat: state.destLat,
                    destLng: state.destLng,
                    amount: state.amount,
                    deliveryMethod: state.deliveryMethod,
                    files: state.files,
                    insuranceAmount: state.insuranceAmount,
                    insuranceEstimate: parseInt(0),
                    totalAmount: state.totalAmount,
                    alreadyInsured: true
                })
                return insuranceResult;
            }

            let result = await fetch(
                `${BaseURL}/bussinessusers/getInsuranceEstimate`,
                {
                    method: "POST",
                    body: JSON.stringify({
                        amount: state.insuranceAmount,
                        alreadyInsured: false
                    }),
                    headers: {
                        Authorization: `Bearer ${new_token}`,
                        "Content-Type": "application/json",
                    },
                }
            );
            result = await result;
            if (result.status === 200 || result.status === 201) {
                result = await result.json();
                setState({
                    ...state,
                    insuranceEstimate:
                        result.data[0].insuranceAmount,
                    totalAmount:
                        parseFloat(result.data[0].insuranceAmount) +
                        parseFloat(result.data[0].amount),
                    loading: false
                },
                    history.push("/Drop-Details", {
                        isInsured: true,
                        senderAdd: state.senderAdd,
                        senderAdd1: state.senderAdd1,
                        receiverAdd: state.receiverAdd,
                        receiverAdd1: state.receiverAdd1,
                        senderBuilding: state.senderBuilding,
                        receiverBuilding: state.receiverBuilding,
                        senderContact: state.senderContact,
                        receiverContact: state.receiverContact,
                        orgLat: state.orgLat,
                        orgLng: state.orgLng,
                        destLat: state.destLat,
                        destLng: state.destLng,
                        amount: state.amount,
                        deliveryMethod: state.deliveryMethod,
                        files: state.files,
                        insuranceAmount: state.insuranceAmount,
                        insuranceEstimate: parseInt(result.data[0].insuranceAmount),
                        totalAmount: state.totalAmount,
                        alreadyInsured: false
                    }));
                return result;
            } else if (result.status === 401) {
                const refreshToken = localStorage.getItem("refreshToken");
                const data = {
                    refreshToken,
                };

                var response = await fetch(`${BaseURL}/users/refresh-token`, {
                    method: "POST",
                    body: JSON.stringify(data),
                    headers: {
                        "Content-Type": "application/json"
                    },
                });
                response = await response;
                if (response.status === 200 || response.status === 201) {
                    response = await response.json();
                    localStorage.setItem("refreshToken", response.refreshToken);
                    localStorage.setItem("token", response.jwtToken);
                    result = await fetch(
                        `${BaseURL}/bussinessusers/getInsuranceEstimate`,
                        {
                            method: "POST",
                            body: JSON.stringify({
                                amount: state.insuranceAmount,
                                alreadyInsured: false
                            }),
                            headers: {
                                Authorization: `Bearer ${response.jwtToken}`,
                                "Content-Type": "application/json",
                            },
                        }
                    );
                    result = await result;
                    if (result.status === 200 || result.status === 201) {
                        result = await result.json();
                        setState({
                            ...state,
                            insuranceEstimate:
                                result.data[0].insuranceAmount,
                            totalAmount:
                                parseFloat(result.data[0].insuranceAmount) +
                                parseFloat(result.data[0].amount),
                            loading: false
                        },
                            history.push("/Drop-Details", {
                                isInsured: true,
                                senderAdd: state.senderAdd,
                                senderAdd1: state.senderAdd1,
                                receiverAdd: state.receiverAdd,
                                receiverAdd1: state.receiverAdd1,
                                senderBuilding: state.senderBuilding,
                                receiverBuilding: state.receiverBuilding,
                                senderContact: state.senderContact,
                                receiverContact: state.receiverContact,
                                orgLat: state.orgLat,
                                orgLng: state.orgLng,
                                destLat: state.destLat,
                                destLng: state.destLng,
                                amount: state.amount,
                                deliveryMethod: state.deliveryMethod,
                                files: state.files,
                                insuranceAmount: state.insuranceAmount,
                                insuranceEstimate: parseInt(result.data[0].insuranceAmount),
                                totalAmount: state.totalAmount,
                                alreadyInsured: false
                            }));
                        return result;
                    } else if (result.status === 400 || result.status === 501) {
                        result = await result.json();
                        alert(result.message);
                        setState({
                            ...state,
                            loading: false
                        });
                        return result;
                    }
                    return response;
                } else {
                    alert("Session expired. Please login to continue.");
                    localStorage.clear("All");
                    history.push("/Home");
                }
            } else if (result.status === 400 || result.status === 501) {
                result = await result.json();
                alert(result.message);
                setState({
                    ...state,
                    loading: false
                });
                return result;
            }
        }
    };

    // onChange Function
    const change = e => {
        if (e.target.name === 'checked') {
            setState({
                ...state,
                checked: e.target.checked
            })
        } else {
            setState({
                ...state,
                [e.target.name]: e.target.type === "checkbox" ? e.target.checked : e.target.value
            })
        }
    }

    var [error] = useState(initError);

    const isValid = () => {
        error = initError;

        if (state.insuranceAmount.length === 0) {
            error.insuranceAmountError = "Invalid Insurance Amount";
        }
        // if (state.files.length === 0) {
        //     error.insuranceInvoiceError = "Invalid Insurance Invoice";
        // }

        if (
            error.insuranceAmountError.length > 0 
            // ||
            // error.insuranceInvoiceError.length > 0
        ) {
            return false;
        } else {
            return true;
        }
    };

    const ValidSubmission = () => {
        if (isValid()) {
            return (
                <button
                    type="button"
                    onClick={confirmInsurance}
                    className="btn btn-dark rounded-pill button3 mb-3"
                >
                    {state.btnValue}
                </button>
            );
        } else {
            return (
                <button
                    disabled
                    type="button"
                    className="btn btn-dark rounded-pill button3 mb-3"
                >
                    {state.btnValue}
                </button>
            );
        }
    };

    const showMessage = () => {
        confirmAlert({
            customUI: ({ onClose }) => {
                return (
                    <div style={{ backgroundColor: "#FEFEFE", padding: "50px", width: "100%" }}>
                        <p style={{ color: "#000000" }}>Please read carefully. <br />1. Transit Insurance will be applicable only after the package is placed inside the device and door is locked until the door is unlocked by the receiver.<br />2. Premium is calculated at 0.14% of the invoice value declared by you.<br /> 3. Uploading the invoice is mandatory. Maximum invoice value is Rs. 5 lacs.<br />4. Insurance is applicable only on items whose invoice value is more than Rs. 10,000.<br /><br /> Click on the 'T&C' button below to read the detailed terms and conditions for insurance.</p>
                        <button style={{ border: "none", backgroundColor: "#333333", color: "#ffffff", paddingLeft: "20px", paddingRight: "20px", paddingTop: "7px", paddingBottom: "7px", outline: "none", fontFamily: "Manrope-Bold" }}
                            onClick={() => {
                                window.open("https://beta-orders.gordian.in/insurance-terms", "_self");
                                onClose();
                            }}
                        >
                            T&C
                        </button>
                        <button style={{ border: "none", backgroundColor: "#333333", color: "#ffffff", paddingLeft: "20px", paddingRight: "20px", paddingTop: "7px", paddingBottom: "7px", outline: "none", fontFamily: "Manrope-Bold", marginLeft: "20px" }} onClick={onClose}>OK</button>
                    </div>
                );
            }
        });

        // confirmAlert({
        //   message: "Please read carefully.\u2022 Transit Insurance will be applicable only after the package is placed inside the device and door is locked until the door is unlocked by the receiver.\n\u2022 Premium is calculated at 0.14% of the invoice value declared by you.\n\u2022 Uploading the invoice is mandatory. Maximum invoice value is Rs. 5 lacs.\n\u2022 Insurance is applicable only on items whose invoice value is more than Rs. 10,000.\n\nClick on the 'T&C' button below to read the detailed terms and conditions for insurance.",
        //   buttons: [
        //     {
        //       label: "T&C",
        //       onClick: () => window.open("https://beta-orders.gordian.in/insurance-terms", "_self"),
        //     },
        //     {
        //         label: "Ok",
        //         onClick: () => { },
        //       },
        //   ],
        // });
    };

    const changeButtonColor = () => {
        return state.alreadyInsured === false ? "grey" : "black"
    }

    const getEstimateButton = () => {
        return state.alreadyInsured === false ? null : getEstimate()
    }

    return (
        <Fragment>
            <Container>
                <Row style={{ marginTop: "15px", marginLeft: "5px", justifyContent: "space-between", alignItem: "space-between", marginRight: "5px" }}>
                    {/* <Link style={{ textDecoration: 'none' }} > */}
                    <Card style={{ width: '50px', height: "50px", borderRadius: "30px", backgroundColor: "#F5F6FA", border: "none", boxShadow: "5px 5px 11px 5px #0000000D" }} onClick={dropdetails}>
                        <Card.Body>
                            <img style={{ height: "30px", width: "27px", marginTop: "-15px", marginLeft: "-11px" }} src={BackIcon} alt="backicon" />
                        </Card.Body>
                    </Card>
                    {/* </Link> */}
                    {/* <div style={{ marginTop: "12px" }}>
                        <ProgressBar width="33%" steps="Step 1/3" />
                    </div> */}
                </Row>
                <div style={{ marginTop: "30px", marginLeft: "20px" }}>
                    <h1 style={{ fontSize: "32px", fontFamily: "Manrope-ExtraBold", color: "#000000", letterSpacing: "-1px" }} >Upload Invoice</h1>
                </div>

                <Row style={{ marginTop: "1px" }}>
                    <Col>
                        <label style={{ marginTop: "10px", fontSize: "16px", fontFamily: "Manrope-Bold", marginLeft: "15px" }}>Enter Insurance Amount</label>
                        <input
                            type="text"
                            className="field"
                            value={state.insuranceAmount}
                            onChange={(e) => setState({ ...state, insuranceAmount: e.target.value })}
                            required
                        />
                        <br />
                    </Col>
                </Row>

                <Row style={{ marginTop: "1px" }}>
                    <Col>
                        <label style={{ marginTop: "10px", fontSize: "16px", fontFamily: "Manrope-Bold", marginLeft: "15px" }}>Upload Insurance Invoice</label>
                        <input
                            type="file"
                            className="field"
                            value={state.file}
                            onChange={(e) => setState({ ...state, files: e.target.files[0] })}
                            required
                        />
                        <br />
                    </Col>
                </Row>

                <Row style={{ justifyContent: 'center', marginTop: '45px' }}>
                    <div>
                        <input type="checkbox"
                            name="alreadyInsured"
                            checked={state.alreadyInsured}
                            onChange={(e) => change(e)}
                            id="IsInsured"
                        />
                        &ensp;
                        <label>I want to insure my order</label>
                    </div>
                </Row>

                <Row style={{ justifyContent: 'center', marginTop: '15px' }}>
                    <button style={{ height: "35px", width: "270px", backgroundColor: changeButtonColor(), color: "white", border: "none", borderRadius: "30px", textAlign: "center", fontSize: "17px", fontFamily: "Manrope-Bold", outline: "none" }} onClick={getEstimateButton}>
                        Get Estimate
                    </button>
                </Row>
                {state.loading ? (
                    <Row style={{ justifyContent: "center", alignItem: "center", marginTop: "30px" }}>
                        <Loader
                            type="Oval"
                            color="#000000"
                            height={40}
                            width={40}
                        />
                    </Row>
                ) : state.insuranceEstimate >= 0 ? (
                    <Row
                        style={{
                            marginTop: "20px",
                            justifyContent: "center",
                            alignItem: "center",
                        }}
                    >
                        <Card
                            style={{
                                width: "290px",
                                height: "75px",
                                borderRadius: "20px",
                                backgroundColor: "#ffffff",
                                borderColor: "#ffffff",
                            }}
                        >
                            <Card.Body>
                                <Row
                                    style={{
                                        justifyContent: "space-between",
                                        alignItem: "space-between",
                                        marginLeft: "5px",
                                        marginRight: "5px",
                                        marginTop: "-5px",
                                    }}
                                >
                                    <Card.Title
                                        style={{
                                            color: "#000000",
                                            fontSize: "14px",
                                            fontFamily: "Manrope-Bold",
                                        }}
                                    >
                                        Insurance Amount
                                    </Card.Title>
                                    <Card.Title
                                        style={{
                                            color: "#000000",
                                            fontSize: "14px",
                                            fontFamily: "Manrope-Bold",
                                        }}
                                    >
                                        {"\u20B9"} {state.insuranceAmount}
                                    </Card.Title>
                                </Row>

                                <Row
                                    style={{
                                        justifyContent: "space-between",
                                        alignItem: "space-between",
                                        marginLeft: "5px",
                                        marginRight: "5px",
                                        marginTop: "-20px",
                                    }}
                                >
                                    <Card.Title
                                        style={{
                                            color: "#000000",
                                            fontSize: "14px",
                                            fontFamily: "Manrope-Bold",
                                        }}
                                    >
                                        Insurance Estimate
                                    </Card.Title>
                                    <Card.Title className="insurance-card-text"
                                        style={{
                                            color: "#000000",
                                            fontSize: "14px",
                                            fontFamily: "Manrope-Bold",
                                        }}
                                    >
                                        {"\u20B9"} {state.insuranceEstimate.toFixed(4)}
                                    </Card.Title>
                                </Row>
                            </Card.Body>
                        </Card>
                    </Row>
                ) : null}

                <Row style={{ marginLeft: "-100px" }}>{ValidSubmission()}</Row>

                <Row style={{ justifyContent: "center", alignItem: "center" }}>
                    <Card style={{ width: '260px', height: "4.5rem", borderRadius: "1rem", backgroundColor: "#F6F6FA", marginBottom: "15px" }}>
                        <Card.Body>
                            <Card.Title style={{ color: "#92979D", fontSize: "0.7rem", fontFamily: "Manrope-Regular", marginTop: "-15px", textAlign: "center" }}>Insurance will be applicable only if the item is new and invoice for the same is available. Read<button style={{ border: "none", outline: "none" }} onClick={showMessage}>T&C</button>the before applying for insurance</Card.Title>
                        </Card.Body>
                    </Card>
                </Row>

            </Container>
        </Fragment>
    )
}

export default AddInsuranceMobile
