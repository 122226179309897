import React from 'react';
import { Row, Container } from "reactstrap";
import Card from 'react-bootstrap/Card';
import { Link, useHistory } from "react-router-dom";
import BackIcon from "./../assets/images/back.png";
import ChatIcon from "./../assets/images/chat.png";
import CustomerIcon from "./../assets/images/customerWhite.png";
import BussinessIcon from "./../assets/images/businessWhite.png"
import "./../Globals/Fonts.css";
import "./styles.signuplogin.css";
import { confirmAlert } from "react-confirm-alert";

function MobileSignupAs() {
    const history = useHistory();

    function Customerrole() {
        localStorage.setItem("role", "Customer")
        history.push("/Signup2")
    }

    function Bussinessrole() {
        localStorage.setItem("role", "Bussiness")
        history.push("/MobileBusinessSignup1")
    }

    const showMessage = () => {
        confirmAlert({
            customUI: ({ onClose }) => {
              return (
                <div style={{ backgroundColor: "#FEFEFE", padding: "50px", width: "100%"}}>
                  <p style={{ color: "#000000"}}>If you’re a business, you get the following benefits: <br /> 1. Option to insure your items at 0.14% of invoice value. Maximum claim value of Rs. 5 lacs. <br /> 2. Monthly billing for a committed order volume. Get in touch at support@gordian.in to know more.</p>
                  <button style={{border: "none", backgroundColor: "#333333", color: "#ffffff", paddingLeft: "20px", paddingRight: "20px", paddingTop: "7px", paddingBottom: "7px", outline: "none", fontFamily: "Manrope-Bold"}} onClick={onClose}>OK</button>
                </div>
              );
            }
          });

        // confirmAlert({
        //   message: "If you’re a business, you get the following benefits: \n 1. Option to insure your items at 0.14% of invoice value. Maximum claim value of Rs. 5 lacs.\n2. Monthly billing for a committed order volume. Get in touch at support@gordian.in to know more.",
        //   buttons: [
        //     {
        //       label: "Ok",
        //       onClick: () => { },
        //     },
        //   ],
        // });
      };

    return (
        <Container >
            <Row style={{ marginTop: "15px", marginLeft: "5px", justifyContent: "space-between", alignItem: "space-between", marginRight: "5px" }}>
                <Link to="/mobilesignin" style={{ textDecoration: 'none' }} >
                    <Card style={{ width: '50px', height: "50px", borderRadius: "30px", backgroundColor: "#F5F6FA", border: "none", boxShadow: "5px 5px 11px 5px #0000000D" }}>
                        <Card.Body>
                            <img style={{ height: "30px", width: "27px", marginTop: "-15px", marginLeft: "-11px" }} src={BackIcon} alt="backicon" />
                        </Card.Body>
                    </Card>
                </Link>

                <Card style={{ width: '50px', height: "50px", borderRadius: "30px", backgroundColor: "#F5F6FA", border: "none", boxShadow: "5px 5px 11px 5px #0000000D" }}>
                    <Card.Body>
                        <img style={{ height: "30px", width: "30px", marginTop: "-18px", marginLeft: "-11px" }} src={ChatIcon} alt="chaticon" />
                    </Card.Body>
                </Card>
            </Row>
            <h1 style={{ fontSize: "28px", fontFamily: "Manrope-ExtraBold", marginLeft: "20px", color: "#000000", letterSpacing: "-1px" }}>Who do you want </h1>
            <h1 style={{ fontSize: "28px", fontFamily: "Manrope-ExtraBold", marginLeft: "20px", marginBottom: "20px", color: "#000000", letterSpacing: "-1px" }}>Sign up as? </h1>
            <Row style={{ justifyContent: "center", alignItem: "center" }}>
                {/* <Link to="/mobilesignup" style={{ textDecoration: 'none' }} > */}
                <div className="Customer-icon-mobile" onClick={Customerrole}>
                    <div style={{ flexDirection: "row" }}>
                        <img style={{ height: "40px", width: "40px", marginTop: "60px", marginLeft: "25px" }} src={CustomerIcon} alt="customericon" />
                        <h1 style={{ fontSize: "20px", fontFamily: "Manrope-ExtraBold", textAlign: 'center', color: "#ffffff", marginTop: "-32px", marginLeft: "30px" }}>Gordian for Customer</h1>
                    </div>
                </div>
                {/* </Link> */}
                {/* <Link style={{ textDecoration: 'none' }}> */}
                <div className="Bussiness-icon-mobile" onClick={Bussinessrole}>
                    <div style={{ flexDirection: "row" }}>
                        <img style={{ height: "40px", width: "40px", marginTop: "60px", marginLeft: "25px" }} src={BussinessIcon} alt="customericon" />
                        <h1 style={{ fontSize: "20px", fontFamily: "Manrope-ExtraBold", textAlign: 'center', color: "#ffffff", marginTop: "-32px", marginLeft: "30px" }}>Gordian for Business</h1>
                    </div>

                </div>
                {/* </Link> */}

            </Row>
            <Row style={{ justifyContent: "center", alignItem: "center" }}>
                <Card style={{ width: '310px', height: "3.5rem", borderRadius: "1rem", backgroundColor: "#F6F6FA" }}>
                    <Card.Body>
                        <Card.Title style={{ color: "#92979D", fontSize: "0.7rem", fontFamily: "Manrope-Regular", marginTop: "-15px", textAlign: "center" }}>Gordian for Business allows you to insure your package in transit at only 0.14% of the invoice value.<button style={{ border: "none", outline: "none" }} onClick={showMessage}>Click here</button>to read more.</Card.Title>
                    </Card.Body>
                </Card>
            </Row>

            <Row style={{ justifyContent: "center", alignItem: "center" }}>
                <h1 style={{ color: "#92979D", fontSize: "12px", fontFamily: "Manrope-SemiBold", position: "fixed", bottom: "40px" }}>For any difficulties</h1>
                <Link to="/ContactUs">
                    <h1 style={{ fontFamily: "Manrope-Bold", color: "#000000", fontSize: "15px", position: "fixed", bottom: "15px", marginLeft: "-40px" }}>Contact Us</h1>
                </Link>
            </Row>
        </Container>

    )
}

export default MobileSignupAs
