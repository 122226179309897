import React, { Fragment } from "react";
import "./styles.css";
import "../Globals/Fonts.css";

function Instructions() {
  return (
    <Fragment>
      <p className="Heading" style={{ fontFamily: "Manrope-Bold" }}>General Instructions</p>
      <div className="Instructions">
        <p>
          <b style={{ fontFamily: "Manrope-Bold" }}>Weight</b>: Parcel should weigh less than 10 Kgs.
        </p>
        <p>
          <b style={{ fontFamily: "Manrope-Bold" }}>Dimensions </b>: Length - 11 inches, Breadth - 7 inches, Height -
          11 inches.
        </p>
        <p >The keypad is santized before every use!</p>
        <p >
          Please select the accurate landmark in the order form in order to
          facilitate a smoother pickup/drop experience.
        </p>
        <p >
          We are currently operational from 10 in the morning till 9 in the
          night.
        </p>
        <p>
          If you face any issues during your delivery or if you'd like to get
          more information on placing the order, call us on{" "}
          <a className="contactNumber" href="tel:+91 9980094933">
            +91 8762918529.
          </a>
        </p>
      </div>
    </Fragment>
  );
}

export default Instructions;