import React from 'react';
import { Row, Col, Container } from "reactstrap";
import Card from 'react-bootstrap/Card';
import { Link, useHistory } from "react-router-dom";
import Slider from "./Slider";
import BackIcon from "./../assets/images/back.png";
import ChatIcon from "./../assets/images/chat.png";
import CustomerIcon from "./../assets/images/customerWhite.png";
import BussinessIcon from "./../assets/images/businessWhite.png"
import "./styles.signup.css";
import "./../Globals/Fonts.css";
import { confirmAlert } from "react-confirm-alert";

function DesktopSignup1(props) {

    const history = useHistory();

    function Customerrole() {
        localStorage.setItem("role", "Customer")
        history.push("/Signup2")
    }

    function Bussinessrole() {
        localStorage.setItem("role", "Bussiness")
        history.push("/BusinessSignup1")
    }

    const showMessage = () => {
        confirmAlert({
            customUI: ({ onClose }) => {
              return (
                <div style={{ backgroundColor: "#FEFEFE", padding: "50px", width: "50%", marginLeft: "300px"}}>
                  <p style={{ color: "#000000"}}>If you’re a business, you get the following benefits: <br /> 1. Option to insure your items at 0.14% of invoice value. Maximum claim value of Rs. 5 lacs. <br /> 2. Monthly billing for a committed order volume. Get in touch at support@gordian.in to know more.</p>
                  <button style={{border: "none", backgroundColor: "#333333", color: "#ffffff", paddingLeft: "20px", paddingRight: "20px", paddingTop: "7px", paddingBottom: "7px", outline: "none", fontFamily: "Manrope-Bold"}} onClick={onClose}>OK</button>
                </div>
              );
            }
          });
        // confirmAlert({
        //   message: "If you’re a business, you get the following benefits: \r\n 1. Option to insure your items at 0.14% of invoice value. Maximum claim value of Rs. 5 lacs.\n2. Monthly billing for a committed order volume. Get in touch at support@gordian.in to know more.",
        //   buttons: [
        //     {
        //       label: "Ok",
        //       onClick: () => { },
        //     },
        //   ],
        // });
      };

    return (
        <Row style={{ marginBottom: 0, marginTop: 0, backgroundColor: '#F5F6FA' }}>
            <Col lg={7} md={7} sm={7} >
                <Slider />
            </Col>

            <Col lg={5} md={5} sm={5}>
                <Row style={{ marginTop: "10px", marginLeft: "5px", marginRight: "30px", justifyContent: "space-between", alignItem: "space-between" }}>
                    <Link to="/Home" style={{ textDecoration: 'none' }} >
                        <Card style={{ width: '50px', height: "50px", borderRadius: "30px", backgroundColor: "#F5F6FA", border: "none", boxShadow: "5px 5px 11px 5px #0000000D" }} >
                            <Card.Body>
                                <img style={{ height: "30px", width: "27px", marginTop: "-15px", marginLeft: "-11px" }} src={BackIcon} alt="backicon" />
                            </Card.Body>
                        </Card>
                    </Link>

                    <Link to="/ContactUs" style={{ textDecoration: "none" }}>
                        <Card style={{ width: '50px', height: "50px", borderRadius: "30px", backgroundColor: "#F5F6FA", border: "none", boxShadow: "5px 5px 11px 5px #0000000D" }}>
                            <Card.Body>
                                <img style={{ height: "30px", width: "30px", marginTop: "-18px", marginLeft: "-11px" }} src={ChatIcon} alt="chaticon" />
                            </Card.Body>
                        </Card>
                    </Link>
                </Row>
                <Container style={{ marginTop: "10px" }}>
                    <h1 className="signupas-name" style={{ fontSize: "1.25rem", fontFamily: "Manrope-Bold", marginLeft: "87px", marginBottom: "20px", color: "#000000" }}>Who do you want to Sign Up as?</h1>
                    <Row style={{ marginTop: "10px", justifyContent: "center", alignItem: "center" }}>
                        {/* <Link to="/Signup2" style={{ textDecoration: 'none' }} > */}
                        <div className="Customer-icon" onClick={Customerrole}>
                            <div style={{ flexDirection: "row" }}>
                                <img className="customer-img" style={{ height: "40px", width: "40px", marginTop: "60px", marginLeft: "50px" }} src={CustomerIcon} alt="customericon" />
                                <h1 className="customer-text" style={{ fontSize: "1.2rem", fontFamily: "Manrope-ExtraBold", textAlign: 'center', color: "#ffffff", marginTop: "-32px", marginLeft: "30px" }}>Gordian for Customer</h1>
                            </div>
                        </div>
                        {/* </Link> */}
                        {/* <Link style={{ textDecoration: 'none' }}> */}
                    </Row>
                    <Row style={{ justifyContent: "center", alignItem: "center", marginTop: "-20px" }}>
                        <div className="Bussiness-icon" onClick={Bussinessrole}>
                            <div style={{ flexDirection: "row" }}>
                                <img className="business-img" style={{ height: "40px", width: "40px", marginTop: "60px", marginLeft: "50px" }} src={BussinessIcon} alt="businessicon" />
                                <h1 className="business-text" style={{ fontSize: "1.2rem", fontFamily: "Manrope-ExtraBold", textAlign: 'center', color: "#ffffff", marginTop: "-32px", marginLeft: "30px" }}>Gordian for Business</h1>
                            </div>

                        </div>
                        {/* </Link> */}
                    </Row>
                    <Row style={{ justifyContent: "center", alignItem: "center" }}>
                    <Card style={{ width: '340px', height: "3.5rem", borderRadius: "1rem", backgroundColor: "#F6F6FA" }}>
                        <Card.Body>
                            <Card.Title style={{ color: "#92979D", fontSize: "0.7rem", fontFamily: "Manrope-Regular",marginTop: "-15px", textAlign: "center" }}>Gordian for Business allows you to insure your package in transit at only 0.14% of the invoice value.<button style={{ border: "none", outline: "none"}} onClick={showMessage}>Click here</button>to read more.</Card.Title>
                        </Card.Body>
                    </Card>
                    </Row>


                    {/* <h1 style={{ color: "#92979D", fontSize: "14px", textAlign: "center", fontFamily: "Manrope-Medium" }}>Already have an account?</h1>

                    <Link to="/Home" style={{ textDecoration: 'none' }}>

                        <h1 style={{ textAlign: "center", fontSize: "17px", fontFamily: "Manrope-Bold", color: "#000000" }}>Sign In</h1>
                    </Link> */}
                    <Row style={{ alignItem: "center", justifyContent: "center" }}>
                        <h1 style={{ color: "#92979D", fontSize: "14px", fontFamily: "Manrope-Bold", position: "fixed", bottom: "40px", }}>Facing difficulties?</h1>
                        <Link to="/ContactUs" style={{ textDecoration: "none", marginLeft: "-65px" }}>
                            <h1 style={{ color: "#000000", fontSize: "14px", fontFamily: "Manrope-Bold", position: "fixed", bottom: "15px", }}>Contact Us</h1>
                        </Link>
                    </Row>
                </Container>
            </Col>
        </Row>
    )
}

export default DesktopSignup1