import React from "react";
import { Navbar, NavbarBrand } from "reactstrap";
import Logo from "../../assets/images/Logo-black.png";
import IIMB from "../../assets/images/IIMB.png";
import Nasscom from '../../assets/images/Nasscom.jpg';

import "./styles.css";

const NavbarMenu = () => {
  return (
    <Navbar color="light" expand="md" className="Navbar py-0">
      <NavbarBrand href='https://www.gordian.in/' className="NavLink text-left">
        <img src={Logo} alt="Gordian Logo" className="Logo" />
        {/* <span className="text">GORDIAN TECHNOLOGIES PVT. LTD.</span> */}
      </NavbarBrand>
      <img src={IIMB} alt="IIMB" className="LogoRight" />{' '}
      <img src={Nasscom} alt="Nasscom" className="LogoRight" />
    </Navbar>
  );
};

export default NavbarMenu;
