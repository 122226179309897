import React from "react";
import Logo from "../assets/images/Logo1.png";
import { Container, Row } from "reactstrap";
import { Link } from "react-router-dom";
import "./styles.mobile.css";
import Image1 from "../assets/images/Mobile/NewOrder1.png";
import Image2 from "../assets/images/Mobile/Pickup & Drop@3x.png";
import "./../Globals/Fonts.css"
// import Variables from "./../Globals/variables";
import DownIcon from "./../assets/images/rotate.png";
import Card from 'react-bootstrap/Card';
// import { confirmAlert } from "react-confirm-alert";
import "react-confirm-alert/src/react-confirm-alert.css";


// const BaseURL = Variables.baseUrl;

const MobileFormPage1 = () => {

  const logout = () => {
    localStorage.clear('name');
    localStorage.clear('email');
    localStorage.clear('contact');
    localStorage.clear('token');
  }

  // const [state, setState] = useState({
  //   btnValue: "Cancel Order"
  // });
  // const history = useHistory();

  const scrollToBottom = () => window.scrollTo({
    top: document.documentElement.scrollHeight,
    behavior: "smooth"
  })

  const name = localStorage.getItem('name');
  const new_name = name.replace(/"/g, '')

  // async function cancleorder() {
  //   const token = localStorage.getItem("token");
  //   const new_token = token.replace(/"/g, "");
  //   setState({
  //     ...state,
  //     btnValue: "Fetching Order...",
  //   });
  //   let result = await fetch(
  //     `${BaseURL}/orders?status[]=pending&status=ongoing&limit=1`,
  //     {
  //       method: "GET",
  //       headers: {
  //         "Content-Type": "application/json",
  //         Authorization: `bearer ${new_token}`,
  //       },
  //     }
  //   );
  //   result = await result
  //   if (result.status === 200 || result.status === 201) {
  //     result = await result.json();
  //     if (result.data.length === 0) {
  //       alert("There are no ongoing orders that can be cancelled. Please proceed to place an order.");
  //       setState({
  //         ...state,
  //         btnValue: "Cancel Order",
  //       });
  //     } else {
  //       const orderId = await result.data[0].orderId;
  //       let results = await fetch(`${BaseURL}/orders/${orderId}/cancel`, {
  //         method: "POST",
  //         headers: {
  //           "Content-Type": "application/json",
  //           Authorization: `bearer ${new_token}`,
  //         },
  //       });
  //       results = await results;
  //       if (results.status === 200 || results.status === 201) {
  //         results = await results.json();
  //         alert("Your order has been successfully cancelled");
  //         setState({
  //           ...state,
  //           btnValue: "Cancel Order",
  //         });
  //         return results;

  //       } else if (results.status === 401 || results.status === 412 || results.status === 400 || results.status === 500) {
  //         results = await results.json();
  //         alert(results.message);
  //         setState({
  //           ...state,
  //           btnValue: "Cancel Order",
  //         });
  //         return results;
  //       }
  //     }
  //     return result;

  //   } else if (result.status === 401) {
  //     const refreshToken = localStorage.getItem("refreshToken");
  //     const data = {
  //       refreshToken,
  //     };

  //     var response = await fetch(`${BaseURL}/users/refresh-token`, {
  //       method: "POST",
  //       body: JSON.stringify(data),
  //       headers: {
  //         "Content-Type": "application/json"
  //       },
  //     });
  //     response = await response;
  //     if (response.status === 200 || response.status === 201) {
  //       response = await response.json();
  //       localStorage.setItem("refreshToken", response.refreshToken);
  //       localStorage.setItem("token", response.jwtToken);
  //       result = await fetch(
  //         `${BaseURL}/orders?status[]=pending&status=ongoing&limit=1`,
  //         {
  //           method: "GET",
  //           headers: {
  //             "Content-Type": "application/json",
  //             Authorization: `bearer ${response.jwtToken}`,
  //           },
  //         }
  //       );
  //       result = await result
  //       if (result.status === 200 || result.status === 201) {
  //         result = await result.json();
  //         if (result.data.length === 0) {
  //           alert("There are no ongoing orders that can be cancelled. Please proceed to place an order.");
  //           setState({
  //             ...state,
  //             btnValue: "Cancel Order",
  //           });
  //         } else {
  //           const orderId = await result.data[0].orderId;
  //           let results = await fetch(`${BaseURL}/orders/${orderId}/cancel`, {
  //             method: "POST",
  //             headers: {
  //               "Content-Type": "application/json",
  //               Authorization: `bearer ${response.jwtToken}`,
  //             },
  //           });
  //           results = await results;
  //           if (results.status === 200 || results.status === 201) {
  //             results = await results.json();
  //             alert("Your order has been successfully cancelled");
  //             setState({
  //               ...state,
  //               btnValue: "Cancel Order",
  //             });
  //             return results;

  //           } else if (results.status === 401 || results.status === 412 || results.status === 400 || results.status === 500) {
  //             results = await results.json();
  //             alert(results.message);
  //             setState({
  //               ...state,
  //               btnValue: "Cancel Order",
  //             });
  //             return results;
  //           }
  //         }
  //         return result;
  //       }
  //       return response;

  //     }
  //     else {
  //       alert("Session expired. Please login to continue.");
  //       localStorage.clear("All");
  //       history.push("/Home")
  //     }

  //   }
  // }

  // const confirmCancelOrder = () => {
  //   confirmAlert({
  //     title: "Confirm to submit",
  //     message: "Are you sure to do this.",
  //     buttons: [
  //       {
  //         label: "Yes",
  //         onClick: cancleorder,
  //       },
  //       {
  //         label: "No",
  //         onClick: () => { },
  //       },
  //     ],
  //   });
  // };

  return (
    <Container>
      <Row >
        <Card style={{ width: '50px', height: "50px", borderRadius: "30px", backgroundColor: "#F5F6FA", border: "none", boxShadow: "5px 5px 11px 5px #0000000D", position: "fixed", bottom: "10px", right: "15px" }} onClick={scrollToBottom}>
          <Card.Body>
            <img className="rotateimg90" style={{ height: "30px", width: "27px", marginTop: "-11px", marginLeft: "-10px" }} src={DownIcon} alt="backicon" />
          </Card.Body>
        </Card>
      </Row>
      <Row className="justify-content-center mt-5 mr-5">
        <img src={Logo} className="logo" alt='Logo' />
      </Row>
      <h1 style={{ fontSize: "32px", fontFamily: "Manrope-Bold", marginLeft: "10px" }}>Welcome, {new_name}</h1>
      <Row>
        <Container>
          <img src={Image1} className="image" alt='Image1' />
        </Container>
      </Row>
      <Row>
        <Container>
          <Link to="/Instructions">
            <img src={Image2} className="image" alt='Image2' />
            <h1 className='text'>Place Order</h1>
          </Link>
        </Container>
        <Container>
          <Link to="/Mobile-Estimate">
            <img src={Image2} className="image" alt='Image2' />
            <div className='text'>Get Estimate</div>
          </Link>
        </Container>
      </Row>
      {/* <Row style={{ marginTop: "-40px", marginBottom: "10px", justifyContent: "center", alignItem: "center" }}>
        <button className="cancleorderbutton" onClick={confirmCancelOrder} >{state.btnValue}</button>
      </Row> */}
      <Row style={{ marginTop: "-25px", marginBottom: "10px", justifyContent: "center", alignItem: "center" }}>
        <Link to="/TrackCurrentOrder">
          <button className="trackorderbutton" >Track your Orders</button>
        </Link>
      </Row>

      <Row style={{ marginTop: "-10px", marginBottom: "10px", justifyContent: "center", alignItem: "center" }}>
        <Link to="/PastOrders">
          <button className="trackorderbutton" >Previous Orders</button>
        </Link>
      </Row>

      <Row style={{ marginTop: "-10px", marginBottom: "10px", justifyContent: "center", alignItem: "centers" }}>
        <Link to="/mobilesignin">
          <button className="logout-mobile" onClick={logout}>Logout</button>
        </Link>
      </Row>
      <div >
        <h1 style={{ color: "#92979D", fontSize: "12px", fontFamily: "Manrope-SemiBold", textAlign: "center" }}>For any difficulties</h1>
        <Link to="ContactUs1" style={{ textDecoration: "none" }}>
          <h1 style={{ color: "#000000", fontSize: "14px", fontFamily: "Manrope-Bold", textAlign: "center" }}>Contact Us</h1>
        </Link>
      </div>
    </Container>
  );
};

export default MobileFormPage1;
