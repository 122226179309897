import React from 'react';
import { Row, Col, Container } from "reactstrap";
import { Link } from "react-router-dom"
import Slider from "./Slider";
import "./styles.signup.css";
import ChatIcon from "./../assets/images/chat.png";
import Card from 'react-bootstrap/Card';
import ResetLogo from './../assets/images/customerBlack.png';

function ResetPassword1() {
    return (<Row style={{ marginBottom: 0, marginTop: 0, backgroundColor: '#F5F6FA' }}>
        <Col lg={7} md={7} sm={7} >
            <Slider />
        </Col>

        <Col lg={5} md={5} sm={5} >
            <Row style={{ marginTop: "15px", marginRight: "30px", alignItem: "flex-end", justifyContent: "flex-end" }}>

                <Link to="/ContactUs" style={{ textDecoration: "none" }}>
                    <Card style={{ width: '50px', height: "50px", borderRadius: "30px", backgroundColor: "#F5F6FA", border: "none", boxShadow: "5px 5px 11px 5px #0000000D" }}>
                        <Card.Body>
                            <img style={{ height: "30px", width: "30px", marginTop: "-18px", marginLeft: "-11px" }} src={ChatIcon} alt="chaticon" />
                        </Card.Body>
                    </Card>
                </Link>
            </Row>
            <Container style={{ marginTop: "80px" }}>
                <img style={{ height: "90px", width: "90px", marginLeft: "25px" }} src={ResetLogo} alt="reset-logo" />
                <h1 style={{ fontSize: "25px", fontWeight: "bold", marginLeft: "46px", marginBottom: "10px" }}>Reset Password</h1>
                <h1 style={{ fontSize: "15px", marginLeft: "46px", marginBottom: "20px", marginRight: "40px" }}>An email has been sent to your registered email address with a link to reset your password.</h1>

                <Link to="/Home">
                    <Col lg={9} >
                        <button className="button" >Sign In</button>
                    </Col>
                </Link>

                <Row style={{ alignItem: "center", justifyContent: "center" }}>
                    <h1 className="goto-text" style={{ color: "#92979D", fontSize: "14px", fontFamily: "Manrope-Bold", position: "fixed", bottom: "40px", }}>Go to www.Gordian.in</h1>
                    <Link to="/ContactUs" style={{ textDecoration: "none", marginLeft: "-65px" }}>
                        <h1 className="contact-text" style={{ color: "#000000", fontSize: "14px", fontFamily: "Manrope-Bold", position: "fixed", bottom: "15px", }}>Contact Us</h1>
                    </Link>
                </Row>
            </Container>
        </Col>
    </Row >
    )
}

export default ResetPassword1;