import React, { useState } from 'react';
import { Row, Col, Container } from "reactstrap";
import { Link } from "react-router-dom"
import Slider from "./Slider";
import BackIcon from "./../assets/images/back.png";
import Card from 'react-bootstrap/Card';
import ProgressBar from "../Components/ProgressBar";
import "./../Globals/Fonts.css";
// import Variables from "../Globals/variables";

// const BaseURL = Variables.baseUrl;

function BusinessSignupMobile1(props) {

    const phonenumber = localStorage.getItem("contact");
    const role = localStorage.getItem("role");

    const initError = {
        fullnameError: "",
        phonenumberError: "",
        emailError: ""
    };

    const [state, setState] = useState({
        fullname: "",
        phonenumber: phonenumber,
        email: "",
        role: role,
        checked: false,
        btnValue: "Sign Up",
        otp: props.location.state.otp
    });

    var [error] = useState(initError);

    const isValid = () => {
        error = initError;
        const re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

        if (state.fullname.length === 0) {
            error.fullnameError = "Name is required";
        }
        if (!re.test(state.email)) {
            error.emailError = "Invalid Email address";
        }
        if (state.phonenumber.length !== 10) {
            error.phonenumberError = "Invalid Phone Number";
        }

        if (
            error.fullnameError.length > 0 ||
            error.emailError.length > 0 ||
            error.phonenumberError.length > 0
        ) {
            return false;
        } else {
            return true;
        }
    };

    const Termschange = (e) => {
        setState({
            ...state,
            checked: e.target.checked,
        })
    }


    const ValidSubmission = () => {
        if (isValid() && state.checked) {
            return (
                <Link to={{
                    pathname: "/BusinessSignupMobile2",
                    state,
                }}
                    type="button"
                    className="btn btn-dark rounded-pill button mb-3"
                >

                    {state.btnValue}

                </Link>
            );
        } else {
            return (
                <button
                    disabled
                    type="button"
                    className="btn btn-dark rounded-pill button mb-3"
                >
                    {state.btnValue}
                </button>
            );
        }
    };


    return (
        <Row style={{ marginBottom: 0, marginTop: 0, backgroundColor: '#F5F6FA' }}>
            <Col lg={7} md={7} sm={7} >
                <Slider />
            </Col>

            <Col lg={5} md={5} sm={5} >
                <Row style={{ marginTop: "15px", marginLeft: "5px", marginRight: "30px", justifyContent: "space-between", alignItem: "space-between" }}>
                    <Link to="/BusinessSignupMobile" style={{ textDecoration: 'none' }} >
                        <Card style={{ width: '50px', height: "50px", borderRadius: "30px", backgroundColor: "#F5F6FA", border: "none", boxShadow: "5px 5px 11px 5px #0000000D" }}>
                            <Card.Body>
                                <img style={{ height: "30px", width: "27px", marginTop: "-15px", marginLeft: "-11px" }} src={BackIcon} alt="backicon" />
                            </Card.Body>
                        </Card>
                    </Link>
                    <div style={{ marginTop: "10px" }}>
                        <ProgressBar width="100%" steps="Step 3/3" />
                    </div>
                </Row>
                <Container style={{ marginTop: "45px" }}>
                    <h1 className="enter-details" style={{ fontSize: "25px", fontFamily: "Manrope-Bold", marginLeft: "70px", marginBottom: "20px" }}>Enter Details</h1>
                    <Row style={{ justifyContent: "center", alignItem: "center" }}>
                        <Col lg={9} >
                            <label style={{ fontSize: "16px", fontFamily: "Manrope-Bold", marginLeft: "15px" }}>Full Name</label>
                            <input
                                type="text"
                                className="field"
                                value={state.fullname}
                                onChange={(e) => setState({ ...state, fullname: e.target.value })}
                                required
                            />
                        </Col>

                        <Col lg={9} >
                            <label style={{ marginTop: "20px", fontSize: "16px", fontFamily: "Manrope-Bold", marginLeft: "15px" }}>Mobile Number</label>
                            <input
                                type="number"
                                className="field"
                                disabled
                                value={state.phonenumber}
                                onChange={(e) => setState({ ...state, phonenumber: e.target.value })}
                                required
                            />
                        </Col>

                        <Col lg={9} >
                            <label style={{ marginTop: "20px", fontSize: "16px", fontWeight: "bold", marginLeft: "15px", fontFamily: "Manrope-Bold" }}>Email Address</label>
                            <input
                                type="email"
                                className="field"
                                value={state.email}
                                onChange={(e) => setState({ ...state, email: e.target.value })}
                                required
                            />
                        </Col>
                    </Row>


                    <Row style={{ justifyContent: "center", alignItem: "center" }}>
                        <input
                            className="details-checkbox"
                            style={{ marginTop: "5px" }}
                            type="checkbox"
                            value={state.checked}
                            onChange={(e) => Termschange(e)}
                        />
                &ensp;
                <label className="details-label" style={{ fontSize: "14px", fontFamily: "Manrope-Regular" }}>I have read and accepted the <Link to="/terms" target="_blank" style={{ color: "#000000", textDecoration: "none" }}><b style={{ fontFamily: "Manrope-Bold" }}>Terms and Conditions</b></Link></label>
                    </Row>
                    <Row style={{ justifyContent: "center", alignItem: "center" }} className="buttons">
                        <Col lg={9} md={12} sm={12} >
                            {ValidSubmission()}
                        </Col>
                    </Row>

                    <Row style={{ alignItem: "center", justifyContent: "center" }}>
                        <h1 className="goto-text" style={{ color: "#92979D", fontSize: "14px", fontFamily: "Manrope-Bold", position: "fixed", bottom: "40px", }}>Go to www.Gordian.in</h1>
                        <Link to="/ContactUs" style={{ textDecoration: "none", marginLeft: "-65px" }}>
                            <h1 className="contact-text" style={{ color: "#000000", fontSize: "14px", fontFamily: "Manrope-Bold", position: "fixed", bottom: "15px", }}>Contact Us</h1>
                        </Link>
                    </Row>
                </Container>
            </Col>
        </Row>

    )
}

export default BusinessSignupMobile1