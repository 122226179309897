import React from 'react';
import { Row, Col, Container } from "reactstrap";
import EmailIcon from "./../assets/images/emailWhite.png";
import MobileIcon from "./../assets/images/mobileWhite.png";
import { Link } from "react-router-dom";
import Slider from "./Slider";
import Card from 'react-bootstrap/Card';
import "./../Globals/Fonts.css";

function Home() {
    return (
        <Row style={{ marginBottom: 0, marginTop: 0, backgroundColor: '#F5F6FA' }}>
            <Col lg={7} md={7} sm={7} >
                <Slider />
            </Col>

            <Col lg={5} md={5} sm={5} style={{ marginTop: "11.25rem" }}>
                <Container >
                    <h1 className="signin-text" style={{ fontSize: "1.5rem", fontFamily: "Manrope-Bold", marginLeft: "4.375rem", marginBottom: "1.25rem", color: "#000000" }}>Login</h1>
                    <Row style={{ marginTop: "0.625rem", marginLeft: "0.313rem", justifyContent: "center", alignItem: "center" }}>
                        <Link to="/Signin" style={{ textDecoration: 'none' }} >
                            <Card className="mobile-card" style={{ width: '12.5rem', height: "12.5rem", borderRadius: "1.25rem", backgroundColor: "#000000" }}>
                                <Card.Body>
                                    <img className="icons" style={{ height: "4rem", width: "4rem" }} src={EmailIcon} alt="emailicon" />
                                    <Card.Title className="texts" style={{ color: "#ffffff", fontSize: "1.375rem", fontFamily: "Manrope-Bold", marginTop: "2.188rem" }}>Login with Email Address</Card.Title>
                                </Card.Body>
                            </Card>
                        </Link>

                        <Link to="/Signinmobile" style={{ textDecoration: "none" }}>
                            <Card className="mobile-card ml-4" style={{ width: '12.5rem', height: "12.5rem", borderRadius: "1.25rem", backgroundColor: "#000000" }}>
                                <Card.Body>
                                    <img className="icons" style={{ height: "4rem", width: "4rem" }} src={MobileIcon} alt="mobileicon" />
                                    <Card.Title className="texts" style={{ color: "#ffffff", fontSize: "1.26rem", fontFamily: "Manrope-Bold", marginTop: "2.188rem" }}>Login with Mobile Number</Card.Title>
                                </Card.Body>
                            </Card>
                        </Link>

                    </Row>
                    <h1 style={{ color: "#92979D", fontSize: "0.875rem", textAlign: "center", fontFamily: "Manrope-Medium", marginTop: "2.5rem" }}>New to Gordian?</h1>

                    <Link to="/Signup1" style={{ textDecoration: 'none' }}>

                        <h1 style={{ textAlign: "center", fontSize: "1.063rem", fontFamily: "Manrope-Bold", color: "#000000" }}>Sign Up</h1>
                    </Link>
                    <Row style={{ alignItem: "center", justifyContent: "center" }}>
                        <h1 className="goto-text" style={{ color: "#92979D", fontSize: "0.875rem", fontFamily: "Manrope-Bold", position: "fixed", bottom: "3.125rem", }}>Facing difficulties?</h1>
                        <Link to="/ContactUs" style={{ textDecoration: "none", marginLeft: "-65px" }}>
                            <h1 className="contact-text" style={{ color: "#000000", fontSize: "0.875rem", fontFamily: "Manrope-Bold", position: "fixed", bottom: "1.25rem", }}>Contact Us</h1>
                        </Link>
                    </Row>
                </Container>
            </Col>
        </Row>
    )
}

export default Home
