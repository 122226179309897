import React, { useState, useEffect } from 'react'
import { Row, Col, Container } from 'reactstrap'
import Logo from './../assets/images/Logo1.png'
import { useHistory } from 'react-router-dom'
import Sidebar from './../Components/Sidebar/sidebar'
import PlacesAutocomplete from 'react-places-autocomplete'
import './styles.desktop.css'
import Variables from './../Globals/variables'
import { geoCodeAddress } from './../services/GoogleMapsService'
import './../Globals/Fonts.css'
import BackIcon from "./../assets/images/back.png";
import Card from 'react-bootstrap/Card';

const BaseURL = Variables.baseUrl

const PromoCode = props => {

  const name = localStorage.getItem('name')
  const new_name = name.replace(/"/g, '')
  const email = localStorage.getItem("email");
  const contact1 = localStorage.getItem("contact");
  const amount = localStorage.getItem("amount");
  const role = localStorage.getItem("role");

  const initState = {
    name: new_name,
    email: email,
    contact1: contact1,
    role: role,
    isInsured: props.location.state.isInsured,
    insuranceEstimate: props.location.state.insuranceEstimate,
    files: props.location.state.files,
    insuranceAmount: props.location.state.insuranceAmount,
    senderAdd: props.location.state.senderAdd,
    senderAdd1: props.location.state.senderAdd1,
    senderBuilding: props.location.state.senderBuilding,
    senderContact: props.location.state.senderContact,
    receiverAdd: props.location.state.receiverAdd,
    receiverAdd1: props.location.state.receiverAdd1,
    receiverBuilding: props.location.state.receiverBuilding,
    receiverContact: props.location.state.receiverContact,
    promoCode: '',
    orgLat: props.location.state.orgLat,
    orgLng: props.location.state.orgLng,
    destLat: props.location.state.destLat,
    destLng: props.location.state.destLng,
    SenderError: '',
    ReceiverError: '',
    senderAddressModified: false,
    receiverAddressModified: false,
    amount: amount,
    totalAmount: '',
    totalDiscount: '',
    deliveryMethod: props.location.state.deliveryMethod,
    btnValue: "Apply",
    savedAddresspickup: props.location.state.savedAddresspickup,
    savedAddressdrop: props.location.state.savedAddressdrop,
    popup1: props.location.state.popup1,
    popup2: props.location.state.popup2,
    alreadyInsured: props.location.state.alreadyInsured
  }

  const [state, setState] = useState(initState)
  const history = useHistory()

  async function promcode() {
    const role = localStorage.getItem("role");
    const insuranceestimate = role === "Customer" ? 0 : state.insuranceEstimate
    var token = localStorage.getItem('token')
    var new_token = token.replace(/"/g, '')
    setState({
      ...state,
      btnValue: "Appying..."
    })

    let result = await fetch(
      `${BaseURL}/orders/coupons/validate?coupon=${state.promoCode}&amount=${state.amount}&insuranceEstimate=${insuranceestimate}`,
      {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
          Accept: 'application/json',
          Authorization: `bearer ${new_token}`
        }
      }
    )
    result = await result
    if (result.status === 200 || result.status === 201) {
      result = await result.json();
      localStorage.setItem('totalAmount', result.data.totalAmount)
      localStorage.setItem('totalDiscount', result.data.totalDiscount)
      setState({
        ...state,
        totalAmount: result.data.totalAmount,
        totalDiscount: result.data.totalDiscount
      })
      alert(
        // eslint-disable-next-line
        "Promo code successfully applied," + "\n" + "Please proceed to make your payment."
      )
      history.push('/Promocodepayment', {
        isInsured: state.isInsured,
        senderAdd: state.senderAdd,
        senderAdd1: state.senderAdd1,
        receiverAdd: state.receiverAdd,
        receiverAdd1: state.receiverAdd1,
        senderBuilding: state.senderBuilding,
        receiverBuilding: state.receiverBuilding,
        senderContact: state.senderContact,
        receiverContact: state.receiverContact,
        orgLat: state.orgLat,
        orgLng: state.orgLng,
        destLat: state.destLat,
        destLng: state.destLng,
        totalAmount: state.totalAmount,
        totalDiscount: state.totalDiscount,
        amount: state.amount,
        promoCode: state.promoCode,
        deliveryMethod: state.deliveryMethod,
        files: state.files,
        insuranceAmount: state.insuranceAmount,
        insuranceEstimate: state.insuranceEstimate,
        alreadyInsured: state.alreadyInsured,
        savedAddresspickup: state.savedAddresspickup,
        savedAddressdrop: state.savedAddressdrop,
        popup1: state.popup1,
        popup2: state.popup2
      })
      setState({
        ...state,
        btnValue: "Apply"
      })
      return result;
    } else if (result.status === 401) {
      const refreshToken = localStorage.getItem('refreshToken');
      const data = {
        refreshToken
      };

      setState({
        ...state,
        btnValue: "Appying..."
      })

      var response = await fetch(`${BaseURL}/users/refresh-token`, {
        method: 'POST',
        body: JSON.stringify(data),
        headers: {
          "Content-Type": "application/json",
          // "Accept": 'application/json'
        }
      })
      response = await response;
      if (response.status === 200 || response.status === 201) {
        response = await response.json();
        localStorage.setItem('refreshToken', response.refreshToken)
        localStorage.setItem('token', response.jwtToken)

        result = await fetch(
          `${BaseURL}/orders/coupons/validate?coupon=${state.promoCode}&amount=${state.amount}&insuranceEstimate=${insuranceestimate}`,
          {
            method: 'GET',
            headers: {
              'Content-Type': 'application/json',
              Accept: 'application/json',
              Authorization: `bearer ${response.jwtToken}`
            }
          }
        )
        result = await result
        if (result.status === 200 || result.status === 201) {
          result = await result.json();
          localStorage.setItem('totalAmount', result.data.totalAmount)
          localStorage.setItem('totalDiscount', result.data.totalDiscount)
          setState({
            ...state,
            totalAmount: result.data.totalAmount,
            totalDiscount: result.data.totalDiscount
          })
          alert(
            // eslint-disable-next-line
            "Promo code successfully applied," + "\n" + "Please proceed to make your payment."
          )
          history.push('/Promocodepayment', {
            isInsured: state.isInsured,
            senderAdd: state.senderAdd,
            senderAdd1: state.senderAdd1,
            receiverAdd: state.receiverAdd,
            receiverAdd1: state.receiverAdd1,
            senderBuilding: state.senderBuilding,
            receiverBuilding: state.receiverBuilding,
            senderContact: state.senderContact,
            receiverContact: state.receiverContact,
            orgLat: state.orgLat,
            orgLng: state.orgLng,
            destLat: state.destLat,
            destLng: state.destLng,
            totalAmount: state.totalAmount,
            totalDiscount: state.totalDiscount,
            amount: state.amount,
            promoCode: state.promoCode,
            deliveryMethod: state.deliveryMethod,
            files: state.files,
            insuranceAmount: state.insuranceAmount,
            insuranceEstimate: state.insuranceEstimate,
            alreadyInsured: state.alreadyInsured,
            savedAddresspickup: state.savedAddresspickup,
            savedAddressdrop: state.savedAddressdrop,
            popup1: state.popup1,
            popup2: state.popup2
          })
          setState({
            ...state,
            btnValue: "Apply"
          })
          return result;
        } else if (result.status === 400 || result.status === 412 || result.status === 404) {
          result = await result.json();
          alert(result.message);
          setState({
            ...state,
            btnValue: "Apply"
          })
          return result;
        }
        return response;
      } else {
        alert("Session expired. Please login to continue.");
        localStorage.clear("All");
        history.push("/Home")
      }

    } else if (result.status === 400 || result.status === 404 || result.status === 412) {
      result = await result.json();
      alert(result.message)
      setState({
        ...state,
        btnValue: "Apply"
      })
      return result;
    }
  }

  function placeorder() {
    history.push("/placeOrder", {
      isInsured: state.isInsured,
      alreadyInsured: state.alreadyInsured,
      insuranceAmount: state.insuranceAmount,
      insuranceEstimate: state.insuranceEstimate,
      files: state.files,
      amount: state.amount,
      senderAdd: state.senderAdd,
      senderAdd1: state.senderAdd1,
      senderBuilding: state.senderBuilding,
      senderContact: state.senderContact,
      orgLat: state.orgLat,
      orgLng: state.orgLng,
      receiverAdd: state.receiverAdd,
      receiverAdd1: state.receiverAdd1,
      receiverBuilding: state.receiverBuilding,
      receiverContact: state.receiverContact,
      destLat: state.destLat,
      destLng: state.destLng,
      deliveryMethod: state.deliveryMethod,
      savedAddressdrop: state.savedAddressdrop,
      savedAddresspickup: state.savedAddresspickup,
      popup1: state.popup1,
      popup2: state.popup2
    })

  }

  useEffect(() => {
    ; (async function () {
      const {
        senderAddressModified,
        senderAdd,
        receiverAddressModified,
        receiverAdd
      } = state
      try {
        if (senderAddressModified) {
          const { lat, lng } = await geoCodeAddress(senderAdd)
          setState({
            ...state,
            senderAddressModified: false,
            orgLat: lat,
            orgLng: lng,
            pickupURL: `https://www.google.com/maps/search/?api=1&query=${lat},${lng}`
          })
        }

        if (receiverAddressModified) {
          const { lat, lng } = await geoCodeAddress(receiverAdd)
          setState({
            ...state,
            receiverAddressModified: false,
            destLat: lat,
            destLng: lng,
            pickupURL: `https://www.google.com/maps/search/?api=1&query=${lat},${lng}`
          })
        }
      } catch (e) {
        console.error('DesktopForm::useEffect::uncaught error', e)
        setState({
          senderAddressModified: false,
          receiverAddressModified: false
        })
      }
    })()
  }, [state])

  // onChange Function
  const change = e => {
    if (e.target.name === 'checked') {
      setState({
        ...state,
        checked: e.target.checked
      })
    } else {
      setState({
        ...state,
        [e.target.name]: e.target.value
      })
    }
  }

  // Sender Address Field
  // On address change, update the input field
  const handleSenderChange = senderAdd => {
    setState({
      ...state,
      senderAdd
    })
  }

  // On address select, update the input field with the address selected
  // then fetch the lat, lng
  // update the state
  // --> check side effect ( useEffect )
  const handleSenderSelect = address => {
    const senderAddressModified = true
    setState({
      ...state,
      senderAddressModified,
      senderAdd: address
    })
  }

  // Receiver Address Field
  const handleReceiverChange = receiverAdd => {
    setState({
      ...state,
      receiverAdd
    })
  }

  const handleReceiverSelect = address => {
    const receiverAddressModified = true
    setState({
      ...state,
      receiverAddressModified,
      receiverAdd: address
    })
  }

  const SameAsPrimaryNumber = (e) => {
    setState({
      ...state,
      [e.target.name]: state.contact1,
    });
  };

  return (
    <Row className='m-0'>
      <Col
        lg={2} md={2} sm={2}
        className='text-center pt-5'
        style={{ backgroundColor: 'white' }}
      >
        <Row className="gordian-logo" style={{ justifyContent: "center", alignItem: "center" }}>
          <img src={Logo} alt='Logo' className='logo' />
        </Row>
        <h1
          style={{ fontSize: '25px', fontWeight: 'bold', textAlign: 'center' }}
        >
          Gordian
        </h1>
        <Sidebar />
      </Col>
      <Col lg={7} md={7} sm={7} className='Form'>
        <div className='NewOrder' style={{ marginLeft: "23px" }}>Place Order</div>
        <Container>
          <div
            style={{ fontSize: '20px', fontWeight: '600', color: '#000000' }}
          >
            &emsp; Pickup Details
          </div>
          <Row className='row'>
            <Col lg={12} md={12} sm={12}>
              <PlacesAutocomplete
                value={state.senderAdd}
                onChange={handleSenderChange}
                onSelect={handleSenderSelect}
              >
                {({
                  getInputProps,
                  suggestions,
                  getSuggestionItemProps,
                  loading
                }) => (
                  <div className='placesDiv'>
                    <input
                      {...getInputProps({
                        placeholder: 'Enter Pickup Address',
                        className: 'location-search-input fieldAddress field'
                      })}
                    />
                    <div className='autocomplete-dropdown-container'>
                      {loading && <div>Loading . . . .</div>}
                      {suggestions.map(suggestion => {
                        const className = suggestion.active
                          ? 'suggestion-item--active'
                          : 'suggestion-item'
                        const style = suggestion.active
                          ? {
                            backgroundColor: '#fafafa',
                            cursor: 'pointer'
                          }
                          : {
                            backgroundColor: '#ffffff',
                            cursor: 'pointer'
                          }
                        return (
                          <div
                            {...getSuggestionItemProps(suggestion, {
                              className,
                              style
                            })}
                          >
                            <hr />
                            <span>{suggestion.description}</span>
                          </div>
                        )
                      })}
                    </div>
                  </div>
                )}
              </PlacesAutocomplete>
            </Col>
          </Row>
          <Row className='row'>
            <Col lg={6} md={6} sm={6}>
              <input
                type='text'
                placeholder='Building / Landmark'
                className='field'
                name='senderBuilding'
                value={state.senderBuilding}
                onChange={e => change(e)}
                required
              />
            </Col>
            <Col lg={6} md={6} sm={6}>
              <input
                type='number'
                placeholder='Enter Pickup Mobile Number'
                className='field'
                name='senderContact'
                value={state.senderContact}
                onChange={e => change(e)}
                required
              />
              <br />
              <div
                style={{
                  textAlign: "right",
                  fontSize: "12px",
                }}
              >
                {/* <div className="error">{state.SenContactError}</div> */}
                <input
                  style={{ marginTop: "16px" }}
                  type="checkbox"
                  name="senderContact"
                  onChange={(e) => SameAsPrimaryNumber(e)}
                  id="primarynumber"
                />
                &ensp;
                <label>Same as Primary number</label>
              </div>
              {/* <br />
              <div
                style={{
                  textAlign: 'right',
                  fontSize: '12px'
                }}
              >
                <div className='error'>{state.SenContactError}</div>
              </div> */}
            </Col>
          </Row>
          <hr />
          <div
            style={{ fontSize: '20px', fontWeight: '600', color: '#000000' }}
          >
            &emsp; Drop Details
          </div>
          <Row className='row'>
            <Col lg={12} md={12} sm={12}>
              <PlacesAutocomplete
                value={state.receiverAdd}
                onChange={handleReceiverChange}
                onSelect={handleReceiverSelect}
              >
                {({
                  getInputProps,
                  suggestions,
                  getSuggestionItemProps,
                  loading
                }) => (
                  <div className='placesDiv'>
                    <input
                      {...getInputProps({
                        placeholder: 'Enter the Drop Address',
                        className: 'location-search-input fieldAddress field'
                      })}
                    />
                    <div className='autocomplete-dropdown-container'>
                      {loading && <div>Loading...</div>}
                      {suggestions.map(suggestion => {
                        const className = suggestion.active
                          ? 'suggestion-item--active'
                          : 'suggestion-item'

                        const style = suggestion.active
                          ? {
                            backgroundColor: '#fafafa',
                            cursor: 'pointer'
                          }
                          : {
                            backgroundColor: '#ffffff',
                            cursor: 'pointer'
                          }
                        return (
                          <div
                            {...getSuggestionItemProps(suggestion, {
                              className,
                              style
                            })}
                          >
                            <hr />
                            <span>{suggestion.description}</span>
                          </div>
                        )
                      })}
                    </div>
                  </div>
                )}
              </PlacesAutocomplete>
            </Col>
          </Row>
          <Row className='row'>
            <Col lg={6} md={6} sm={6}>
              <input
                type='text'
                placeholder='Building / Landmark'
                className='field'
                name='receiverBuilding'
                value={state.receiverBuilding}
                onChange={e => change(e)}
                required
              />
            </Col>
            <Col lg={6} md={6} sm={6}>
              <input
                type='number'
                placeholder='Enter Drop Mobile Number'
                className='field'
                name='receiverContact'
                value={state.receiverContact}
                onChange={e => change(e)}
                required
              />
              <br />
              <div
                style={{
                  textAlign: "right",
                  fontSize: "12px",
                }}
              >
                {/* <div className="error">{state.recContactError}</div> */}
                <input
                  style={{ marginTop: "16px" }}
                  type="checkbox"
                  name="receiverContact"
                  onChange={(e) => SameAsPrimaryNumber(e)}
                  id="primarynumber1"
                />
                &ensp;
                <label>Same as Primary number</label>
              </div>
              {/* <br />
              <div
                style={{
                  textAlign: 'right',
                  fontSize: '12px'
                }}
              >
                <div className='error'>{state.recContactError}</div>
              </div> */}
            </Col>
          </Row>
        </Container>
      </Col>
      <Col lg={3} md={3} sm={3} style={{ backgroundColor: 'white', height: '100vh' }}>
        <Container>
          <Row>
            {/* <Link to="/placeOrder" style={{ textDecoration: 'none' }} > */}
            <Card style={{ width: '50px', height: "50px", borderRadius: "30px", backgroundColor: "#F5F6FA", border: "none", boxShadow: "5px 5px 11px 5px #0000000D", marginLeft: "7px" }} onClick={placeorder}>
              <Card.Body>
                <img style={{ height: "30px", width: "27px", marginTop: "-15px", marginLeft: "-11px" }} src={BackIcon} alt="backicon" />
              </Card.Body>
            </Card>
            {/* </Link> */}
          </Row>
          <h1 className="name"
            style={{
              fontSize: '20px',
              fontWeight: '600',
              marginTop: '20px',
              fontFamily: 'Manrope-SemiBold'
            }}
          >
            Welcome {new_name}
          </h1>
          <hr />

          <button className='makepayment' >
            Total Amount Rs.{amount}
          </button>

          <div>
            <label
              style={{
                fontSize: '1.25rem',
                marginTop: '10px',
                marginLeft: '10px',
                fontFamily: 'Manrope-Bold'
              }}
            >
              Promo Code
            </label>
            <input
              type='text'
              style={{ backgroundColor: '#F5F6FA' }}
              className='field'
              name='promocode'
              value={state.promoCode}
              onChange={e => setState({ ...state, promoCode: e.target.value })}
            />

            <Row style={{ justifyContent: 'center', marginTop: '15px' }}>
              <button onClick={promcode} className='promocode'>
                {state.btnValue}
              </button>
            </Row>
          </div>
        </Container>
      </Col>
    </Row>
  )
}

export default PromoCode