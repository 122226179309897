import React, { Fragment, useState } from "react";
import { Row, Col, Container } from "reactstrap";
import { Link, useHistory } from "react-router-dom";
import "./styles.signuplogin.css";
import Variables from "../Globals/variables";
import BackIcon from "./../assets/images/back.png";
import ChatIcon from "./../assets/images/chat.png";
import Card from 'react-bootstrap/Card';
import "./../Globals/Fonts.css";
import usePasswordToggle from "./../Components/usePasswordToggle";

const BaseURL = Variables.baseUrl;

const MobileLoginPage2 = (props) => {
    const initError = {
        emailError: "",
        passwordError: "",
    };

    const [state, setState] = useState({
        email: "",
        password: "",
        checked: false,
        btnValue: "Sign In"
    });
    const history = useHistory();

    const [PasswordInputType, ToggleIcon] = usePasswordToggle();

    async function Login() {

        const item = { email: state.email, password: state.password };
        setState({
            ...state,
            btnValue: "Signing In..."
        })

        let result = await fetch(`${BaseURL}/users/authenticate`, {
            method: 'POST',
            headers: {
                "Content-Type": 'application/json',
                "Accept": 'application/json'
            },
            body: JSON.stringify(item)
        });
        result = await result;
        if (result.status === 200 || result.status === 201) {
            result = await result.json();
            localStorage.setItem('refreshToken', (result.refreshToken));
            localStorage.setItem("name", JSON.stringify(result.fullName));
            localStorage.setItem("token", JSON.stringify(result.jwtToken));
            localStorage.setItem("email", result.email);
            localStorage.setItem("contact", result.phonenumber);
            localStorage.setItem("role", result.role);
            history.push("/placeOrder", {
                senderName: state.senderName,
                senderAdd: state.senderAdd,
                senderBuilding: state.senderBuilding,
                senderContact: state.senderContact,
                senderFlat: state.senderFlat,
                orgLat: state.orgLat,
                orgLng: state.orgLng,
                receiverName: state.receiverName,
                receiverAdd: state.receiverAdd,
                receiverBuilding: state.receiverBuilding,
                receiverContact: state.receiverContact,
                receiverFlat: state.receiverFlat,
                destLat: state.destLat,
                destLng: state.destLng,
                deliveryMethod: state.deliveryMethod,
                isInsured: state.isInsured,
                files: state.files,
                insuranceAmount: state.insuranceAmount,
                amount: ""
            }
            )
            return result;
        } else if (result.status === 401 || result.status === 412) {
            result = await result.json();
            // alert("Email ID/Password is incorrect," + "\n" + "Please try again.");
            alert(result.message)
            setState({
                ...state,
                btnValue: "Login"
            })
            return result;

        }
    }

    var [error] = useState(initError);

    const isValid = () => {
        error = initError;
        const re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

        if (!re.test(state.email)) {
            error.emailError = "Invalid Email address";
        }
        if (state.password.length === 0) {
            error.passwordError = "Password Required.";
        }

        if (
            error.emailError.length > 0 ||
            error.passwordError.length > 0
        ) {
            return false;
        } else {
            return true;
        }
    };

    const Termschange = (e) => {
        setState({
            ...state,
            checked: e.target.checked,
        })
    }

    const ValidSubmission = () => {
        if (isValid() && state.checked) {
            return (
                <button
                    type="button"
                    onClick={Login}
                    className="btn btn-dark rounded-pill button1 mb-3"
                >
                    {state.btnValue}
                </button>
            );
        } else {
            return (
                <button
                    disabled
                    type="button"
                    className="btn btn-dark rounded-pill button1 mb-3"
                >
                    {state.btnValue}
                </button>
            );
        }
    };

    return (
        <Fragment>
            <Container>
                <Row style={{ marginTop: "15px", marginLeft: "5px", justifyContent: "space-between", alignItem: "space-between", marginRight: "5px" }}>
                    <Link to="/mobilesignin" style={{ textDecoration: 'none' }} >
                        <Card style={{ width: '50px', height: "50px", borderRadius: "30px", backgroundColor: "#F5F6FA", border: "none", boxShadow: "5px 5px 11px 5px #0000000D" }}>
                            <Card.Body>
                                <img style={{ height: "30px", width: "27px", marginTop: "-15px", marginLeft: "-11px" }} src={BackIcon} alt="backicon" />
                            </Card.Body>
                        </Card>
                    </Link>

                    <Link to="/ContactUs">
                        <Card style={{ width: '50px', height: "50px", borderRadius: "30px", backgroundColor: "#F5F6FA", border: "none", boxShadow: "5px 5px 11px 5px #0000000D" }}>
                            <Card.Body>
                                <img style={{ height: "30px", width: "30px", marginTop: "-18px", marginLeft: "-11px" }} src={ChatIcon} alt="chaticon" />
                            </Card.Body>
                        </Card>
                    </Link>
                </Row>
                <div style={{ marginTop: "30px", marginLeft: "20px" }}>
                    <h1 style={{ fontSize: "32px", fontFamily: "Manrope-ExtraBold", color: "#000000", letterSpacing: "-1px" }} >Sign in with</h1>
                    <h1 style={{ fontSize: "32px", fontFamily: "Manrope-ExtraBold", color: "#000000", letterSpacing: "-1px" }}>Email Address</h1>
                </div>

                <Row style={{ marginTop: "1px" }}>
                    <Col>
                        <label style={{ marginTop: "10px", fontSize: "16px", fontFamily: "Manrope-Bold", marginLeft: "15px" }}>Email Address</label>
                        <input
                            type="email"
                            className="field"
                            value={state.email}
                            onChange={(e) => setState({ ...state, email: e.target.value })}
                            required
                        />
                        <br />
                    </Col>
                </Row>
                <Row>
                    <Col>
                        <label style={{ fontSize: "16px", fontFamily: "Manrope-Bold", marginLeft: "15px" }}>Password</label>
                        <input
                            type={PasswordInputType}
                            className="field"
                            value={state.password}
                            onChange={(e) => setState({ ...state, password: e.target.value })}
                            required
                        />
                        <span style={{ position: "absolute", cursor: "pointer", right: "20px" }}>{ToggleIcon}</span>
                        <br />
                    </Col>
                </Row>
                <div style={{ marginLeft: "15px" }}>
                    <input
                        value={state.checked}
                        onChange={(e) => Termschange(e)}
                        type="checkbox"
                    />
          &ensp;
          <label style={{ fontSize: "15px" }}>
                        I agree to the &nbsp;
            <b>
                            <Link to="/terms" target="_blank" style={{ color: "black", textDecoration: "none" }}> Terms and Conditions </Link>
                        </b>
                    </label>
                </div>
                <Row style={{ marginTop: "10px", justifyContent: "center", alignItem: "center" }}>{ValidSubmission()}</Row>

                <Link to="/Forgotpassword">
                    <h1 style={{ fontSize: "17px", fontFamily: "Manrope-Bold", color: "#000000", textAlign: "center", marginTop: "-25px" }}>Forgot Password</h1>
                </Link>
                <div style={{ marginTop: "10px" }}>
                    <h1 style={{ fontSize: "12px", fontFamily: "Manrope-SemiBold", textAlign: "center", color: "#92979D" }}>Do not have an account?</h1>
                    <Link to="/mobilesignupas">
                        <h1 style={{ fontSize: "17px", fontFamily: "Manrope-Bold", textAlign: "center", color: "#000000" }}>Sign Up</h1>
                    </Link>
                </div>

                <h1 style={{ color: "#92979D", fontSize: "12px", fontFamily: "Manrope-SemiBold", textAlign: "center", marginTop: "45px" }}>For any difficulties</h1>
                <Link to="/ContactUs">
                    <h1 style={{ fontFamily: "Manrope-Bold", color: "#000000", fontSize: "15px", textAlign: "center" }}>Contact Us</h1>
                </Link>

            </Container>
        </Fragment>
    );
};

export default MobileLoginPage2;
