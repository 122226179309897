import React, { useState, useEffect } from "react";
import { Row, Col, Container } from "reactstrap";
import { Link, useHistory } from "react-router-dom"

import "./styles.signup.css";
import Variables from "./../Globals/variables";
import Slider from "./Slider";
import BackIcon from "./../assets/images/back.png";
import ChatIcon from "./../assets/images/chat.png";
import Card from 'react-bootstrap/Card';
import "./../Globals/Fonts.css";
import usePasswordToggle from "./../Components/usePasswordToggle";

const BaseURL = Variables.baseUrl;

const DesktopLogin = () => {

    const initError = {
        emailError: "",
        passwordError: "",
    };

    const [state, setState] = useState({
        email: "",
        password: "",
        btnValue: "Login",
        senderAdd: "",
        senderAdd1: "",
        senderBuilding: "",
        senderContact: "",
        senderName: "",
        senderFlat: "",
        orgLat: "",
        orgLng: "",
        saveaspickup: "",
        receiverName: "",
        receiverFlat: "",
        receiverAdd: "",
        receiverAdd1: "",
        receiverBuilding: "",
        receiverContact: "",
        destLat: "",
        destLng: "",
        saveasdrop: "",
        deliveryMethod: "secure",
        isInsured: false,
        files: [],
        insuranceAmount: "1",
        insuranceEstimate: "",
        amount: "",
        savedAddresspickup: [],
        savedAddressdrop: [],
        popup1: false,
        popup2: false

    });

    const [PasswordInputType, ToggleIcon] = usePasswordToggle();

    const history = useHistory();

    useEffect(() => {
        if (localStorage.getItem('name && token')) {
            history.push("/placeOrder")
        }
        // eslint-disable-next-line
    }, [])

    async function Login() {

        let item = { email: state.email, password: state.password };
        setState({
            ...state,
            btnValue: "Logging in..."
        })

        let result = await fetch(`${BaseURL}/users/authenticate`, {
            method: 'POST',
            headers: {
                "Content-Type": 'application/json',
                "Accept": 'application/json'
            },
            body: JSON.stringify(item)
        });
        result = await result;
        if (result.status === 200 || result.status === 201) {
            result = await result.json();
            localStorage.setItem('refreshToken', (result.refreshToken));
            localStorage.setItem("name", JSON.stringify(result.fullName));
            localStorage.setItem("token", JSON.stringify(result.jwtToken));
            localStorage.setItem("email", result.email);
            localStorage.setItem("contact", result.phonenumber);
            localStorage.setItem("role", result.role);
            history.push("/placeOrder", {
                senderName: state.senderName,
                senderAdd: state.senderAdd,
                senderAdd1: state.senderAdd1,
                senderBuilding: state.senderBuilding,
                senderContact: state.senderContact,
                senderFlat: state.senderFlat,
                orgLat: state.orgLat,
                orgLng: state.orgLng,
                receiverName: state.receiverName,
                receiverAdd: state.receiverAdd,
                receiverAdd1: state.receiverAdd1,
                receiverBuilding: state.receiverBuilding,
                receiverContact: state.receiverContact,
                receiverFlat: state.receiverFlat,
                destLat: state.destLat,
                destLng: state.destLng,
                deliveryMethod: state.deliveryMethod,
                isInsured: state.isInsured,
                files: state.files,
                insuranceAmount: state.insuranceAmount,
                amount: "",
                insuranceEstimate: state.insuranceEstimate,
                saveaspickup: state.saveaspickup,
                saveasdrop: state.saveasdrop,
                savedAddresspickup: state.savedAddresspickup,
                savedAddressdrop: state.savedAddressdrop,
                popup1: state.popup1,
                popup2: state.popup2
            }
            )
            return result;
        } else if (result.status === 401 || result.status === 412) {
            result = await result.json();
            // alert("Email ID/Password is incorrect," + "\n" + "Please try again.");
            alert(result.message)
            setState({
                ...state,
                btnValue: "Login"
            })
            return result;

        }

    }

    var [error] = useState(initError);

    const isValid = () => {
        error = initError;
        const re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

        if (!re.test(state.email)) {
            error.emailError = "Invalid Email address";
        }
        if (state.password.length === 0) {
            error.passwordError = "Password Required.";
        }

        if (
            error.emailError.length > 0 ||
            error.passwordError.length > 0
        ) {
            return false;
        } else {
            return true;
        }
    };

    const ValidSubmission = () => {
        if (isValid()) {
            return (
                <button
                    type="button"
                    onClick={Login}
                    className="btn btn-dark rounded-pill button mb-3"
                >
                    {state.btnValue}
                </button>
            );
        } else {
            return (
                <button
                    disabled
                    type="button"
                    className="btn btn-dark rounded-pill button mb-3"
                >
                    {state.btnValue}
                </button>
            );
        }
    };

    return (
        <Row style={{ marginBottom: 0, marginTop: 0, backgroundColor: '#F5F6FA' }}>
            <Col lg={7} md={7} sm={7} >
                <Slider />
            </Col>

            <Col lg={5} md={5} sm={5} >
                <Row style={{ marginTop: "15px", marginLeft: "5px", marginRight: "30px", justifyContent: "space-between", alignItem: "space-between" }}>
                    <Link to="/Home" style={{ textDecoration: 'none' }} >
                        <Card style={{ width: '50px', height: "50px", borderRadius: "30px", backgroundColor: "#F5F6FA", border: "none", boxShadow: "5px 5px 11px 5px #0000000D", }}>
                            <Card.Body>
                                <img style={{ height: "30px", width: "27px", marginTop: "-15px", marginLeft: "-11px" }} src={BackIcon} alt="backicon" />
                            </Card.Body>
                        </Card>
                    </Link>

                    <Link to="/ContactUs" style={{ textDecoration: 'none' }}>
                        <Card style={{ width: '50px', height: "50px", borderRadius: "30px", backgroundColor: "#F5F6FA", border: "none", boxShadow: "5px 5px 11px 5px #0000000D" }}>
                            <Card.Body>
                                <img style={{ height: "30px", width: "30px", marginTop: "-18px", marginLeft: "-11px" }} src={ChatIcon} alt="chaticon" />
                            </Card.Body>
                        </Card>
                    </Link>
                </Row>
                <Container style={{ marginTop: "80px" }}>
                    <h1 className="signinemail-text" style={{ fontSize: "25px", fontFamily: "Manrope-Bold", marginLeft: "70px", marginBottom: "20px" }}>Login with Email Address</h1>
                    <Row style={{ justifyContent: "center", alignItem: "center" }}>
                        <Col lg={9} >
                            <label style={{ fontSize: "16px", fontFamily: "Manrope-Bold", marginLeft: "15px" }}>Email Address</label>
                            <input
                                type="email"
                                className="fields"
                                name="email"
                                value={state.email}
                                onChange={(e) => setState({ ...state, email: e.target.value })}
                                required
                            />
                        </Col>

                        <Col lg={9} >
                            <label style={{ marginTop: "20px", fontSize: "16px", fontFamily: "Manrope-Bold", marginLeft: "15px" }}>Password</label>
                            <input
                                type={PasswordInputType}
                                className="fields"
                                value={state.password}
                                onChange={(e) => setState({ ...state, password: e.target.value })}
                                name="password"
                                required
                            />
                            <span style={{ position: "absolute", cursor: "pointer", right: "20px" }}>{ToggleIcon}</span>
                        </Col>
                    </Row>
                    <Row style={{ justifyContent: "center", alignItem: "center" }} className="buttons">
                        <Col lg={9} md={12} sm={12} >
                            {ValidSubmission()}
                        </Col>
                    </Row>



                    <Link to="/Forgotpassword" style={{ textDecoration: "none" }}>
                        <h1 style={{ fontSize: "17px", fontFamily: "Manrope-Regular", color: "#000000", textAlign: "center", marginTop: "20px" }}>Forgot Password?</h1>
                    </Link>

                    <Row style={{ justifyContent: "center", alignItem: "center" }}>
                        <h1 style={{ color: "#92979D", fontSize: "14px", fontFamily: "Manrope-Medium" }}>Don’t have an account yet?</h1>
                    </Row>

                    <Link to="/Signup1" style={{ textDecoration: "none" }}>
                        <h1 style={{ textAlign: "center", fontSize: "17px", fontFamily: "Manrope-Bold", color: "#000000", marginTop: "-20px" }}>Sign Up</h1>
                    </Link>
                    <Row style={{ alignItem: "center", justifyContent: "center" }}>
                        <h1 className="goto-text" style={{ color: "#92979D", fontSize: "14px", fontFamily: "Manrope-Bold", position: "fixed", bottom: "40px", }}>Facing difficulties?</h1>
                        <Link to="/ContactUs" style={{ textDecoration: "none", marginLeft: "-65px" }}>
                            <h1 className="contact-text" style={{ color: "#000000", fontSize: "14px", fontFamily: "Manrope-Bold", position: "fixed", bottom: "15px", }}>Contact Us</h1>
                        </Link>
                    </Row>
                </Container>
            </Col>
        </Row>
    );
};

export default DesktopLogin;