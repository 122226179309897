import React, { useState, useEffect } from "react";
import { Row, Container } from "reactstrap";
import Card from "react-bootstrap/Card";
import { Link, useHistory } from "react-router-dom";
import BackIcon from "./../assets/images/back.png";
import ChatIcon from "./../assets/images/chat.png";
import "./../Globals/Fonts.css";
import "./MobileTrackOrder.css";
import Variables from "./../Globals/variables";
import "react-confirm-alert/src/react-confirm-alert.css";
import Loader from "react-loader-spinner";

const BaseURL = Variables.baseUrl;

function MobileTrackOrder(props) {
  const orderId = localStorage.getItem("orderId");

  const initState = {
    orderId: orderId,
    dropTrackLink: "",
    pickUpTrackLink: "",
    btnValue: 'Track Your Pickup Address',
    btnValue1: 'Track Your Drop Address',
    message: "",
    loading: true
  };

  const [state, setState] = useState(initState);

  // Fetching the orders from the backend when component is mounted.
  useEffect(() => {
    getOrderDetails();
  },
    // eslint-disable-next-line 
    []);
  const history = useHistory();
  
  async function getOrderDetails() {
    const orderId = localStorage.getItem("orderId");
    let results = await fetch(`${BaseURL}/getOrderDetails?orderId=${orderId}`, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        Accept: 'application/json'
      }
    })
    results = await results;
    if (results.status === 200 || results.status === 201) {
      results = await results.json();
      setState({
        dropTrackLink: results.data.dropTrackLink,
        pickUpTrackLink: results.data.pickUpTrackLink,
        btnValue1: "Track Your Drop Address",
        btnValue: "Track Your Pickup Address",
        loading: false
      })
      // alert("Click on the above links to track your order.")
      // window.open(results.data.dropTrackLink, "_blank")
      // setState({
      //     ...state,
      //     btnValue1: "Track Your Drop Address",
      //     btnValue: "Track Your Pickup Address",
      // })
      return results;
    } else if (results.status === 404) {
      alert("OrderId is Invalid");
      setState({
        ...state,
        loading: false
      })
    }
    else {
      setState({
        ...state,
        message: results.message,
        loading: false
      })

    }

  }

  const GetDropTrack = () => {
    if (state.dropTrackLink) {
      window.open(state.dropTrackLink, "_self");
    }
    // else {
    //   if (state.message.length === 0)
    //     alert("No order to track.");
    //   else
    //     alert(state.message);
    // }
  };

  const GetPickupTrack = () => {
    if (state.pickUpTrackLink) {
      window.open(state.pickUpTrackLink, "_self");
    }
    // else {
    //   if (state.message.length === 0)
    //     alert("No order to track.");
    //   else
    //     alert(state.message);
    // }
  };

  return (
    <Container>
      <Row
        style={{
          marginTop: "15px",
          marginLeft: "5px",
          justifyContent: "space-between",
          alignItem: "space-between",
          marginRight: "5px",
        }}
      >
        <Link to="/placeOrder" style={{ textDecoration: "none" }}>
          <Card
            style={{
              width: "50px",
              height: "50px",
              borderRadius: "30px",
              backgroundColor: "#F5F6FA",
              border: "none",
              boxShadow: "5px 5px 11px 5px #0000000D",
            }} onClick={() => history.push("/home")}
          >
            <Card.Body>
              <img
                style={{
                  height: "30px",
                  width: "27px",
                  marginTop: "-15px",
                  marginLeft: "-11px",
                }}
                src={BackIcon}
                alt="backicon"
              />
            </Card.Body>
          </Card>
        </Link>

        <Link to="/ContactUs1">
          <Card
            style={{
              width: "50px",
              height: "50px",
              borderRadius: "30px",
              backgroundColor: "#F5F6FA",
              border: "none",
              boxShadow: "5px 5px 11px 5px #0000000D",
            }}
          >
            <Card.Body>
              <img
                style={{
                  height: "30px",
                  width: "30px",
                  marginTop: "-18px",
                  marginLeft: "-11px",
                }}
                src={ChatIcon}
                alt="chaticon"
              />
            </Card.Body>
          </Card>
        </Link>
      </Row>
      <h1
        style={{
          fontSize: "28px",
          fontFamily: "Manrope-ExtraBold",
          marginLeft: "20px",
          color: "#000000",
          letterSpacing: "-1px",
        }}
      >
        Track your{" "}
      </h1>
      <h1
        style={{
          fontSize: "28px",
          fontFamily: "Manrope-ExtraBold",
          marginLeft: "20px",
          color: "#000000",
          letterSpacing: "-1px",
        }}
      >
        PickUp & Drop{" "}
      </h1>
      {state.loading ? (
        <Row style={{ justifyContent: "center", alignItem: "center", marginTop: "100px" }}>
          <Loader
            type="Oval"
            color="#000000"
            height={40}
            width={40}
          />
        </Row>
      ) : (
        <Row style={{ justifyContent: "center", alignItem: "center" }}>
          <Link style={{ textDecoration: "none" }}>
            <div className="pickup-icon" onClick={GetPickupTrack}>
              <div style={{ flexDirection: "row" }}></div>
              <h1
                style={{
                  fontSize: "20px",
                  fontFamily: "Manrope-Bold",
                  textAlign: "center",
                  color: "#ffffff",
                  paddingTop: "65px",
                }}
              >
                {state.btnValue}
              </h1>
            </div>
          </Link>
          <Link style={{ textDecoration: "none" }}>
            <div className="drop-icon" onClick={GetDropTrack}>
              <div style={{ flexDirection: "row" }}></div>
              <h1
                style={{
                  fontSize: "20px",
                  fontFamily: "Manrope-Bold",
                  textAlign: "center",
                  color: "#ffffff",
                  paddingTop: "65px",
                }}
              >
                {state.btnValue1}
              </h1>
            </div>
          </Link>
        </Row>
      )}

      <Row style={{ justifyContent: "center", alignItem: "center" }}>
        <h1
          style={{
            color: "#92979D",
            fontSize: "12px",
            fontFamily: "Manrope-SemiBold",
            position: "fixed",
            bottom: "40px",
          }}
        >
          For any difficulties
        </h1>
        <Link to="/ContactUs1">
          <h1
            style={{
              fontFamily: "Manrope-Bold",
              color: "#000000",
              fontSize: "15px",
              position: "fixed",
              bottom: "15px",
              marginLeft: "-40px",
            }}
          >
            Contact Us
          </h1>
        </Link>
      </Row>
    </Container>
  );
}

export default MobileTrackOrder;
