import React, { useState } from "react";
import { Row, Col, Container } from "reactstrap";
import PlacesAutocomplete from "react-places-autocomplete";
import { Link } from "react-router-dom";
import Variables from "./../Globals/variables";
import Sidebar from "./../Components/Sidebar/sidebar"
import Logo from './../assets/images/Logo1.png';
import { geoCodeAddress } from "./../services/GoogleMapsService";
import "./../Globals/Fonts.css";

const BaseUrl = Variables.baseUrl;

const Estimate = () => {

  var [state, setState] = useState({
    senderAdd: "",
    orgLat: null,
    orgLng: null,
    receiverAdd: "",
    destLat: null,
    destLng: null,
    amount: null,
    distance: null,
    deliveryMethod: 'secure',
    btnValue: "Get Estimate"
  });

  const name = localStorage.getItem("name");
  const new_name = name.replace(/"/g, "");

  // function SecureMethod() {
  //   setState(() => ({
  //     ...state,
  //     deliveryMethod: "secure"
  //   }))
  // }

  // function RegularMethod() {
  //   setState(() => ({
  //     ...state,
  //     deliveryMethod: "regular",
  //   }))
  // }

  // const submit = (e) => {
  //   e.preventDefault();

  //   axios({
  //     url: `${BaseUrl}/orders/estimate?origins=${state.orgLat}&origins=${state.orgLng}&destinations=${state.destLat}&destinations=${state.destLng}&deliveryMethod=${state.deliveryMethod}`,
  //     method: "GET",
  //   })
  //     .then((res) => {
  //       setState({
  //         ...state,
  //         amount: res.data[0].amount,
  //         distance: res.data[0].distance,
  //       });
  //       console.log(res)
  //     })
  //     .catch((error) => {
  //       alert("Error. Please try again later.");
  //     });
  // };

  async function getestimate() {
    const role = localStorage.getItem("role");
    setState({
      ...state,
      btnValue: "Fetching Details..."
    })
    let results = await fetch(`${BaseUrl}/orders/estimate?origins=${state.orgLat}&origins=${state.orgLng}&destinations=${state.destLat}&destinations=${state.destLng}&deliveryMethod=${state.deliveryMethod}&role=${role}`, {
      method: 'GET',
    })
    results = await results;
    if (results.status === 200 || results.status === 201) {
      results = await results.json();
      setState({
        ...state,
        amount: results.data.amount,
        distance: results.data.distance,
        btnValue: "Get Estimate"
      });
      return results;

    } else if (results.status === 400 || results.status === 404) {
      results = await results.json();
      alert(results.message)
      setState({
        ...state,
        btnValue: "Get Estimate"
      })
      return results;

    }
  }

  const handleSenderChange = (senderAdd) => {
    setState({
      ...state,
      senderAdd,
    });
  };

  const handleSenderSelect = (senderAdd) => {
    setState({
      ...state,
      senderAdd
    });

    geoCodeAddress(senderAdd)
      .then(({ lat, lng }) => {
        setState({
          ...state,
          senderAdd,
          orgLat: lat,
          orgLng: lng
        })
      })
  };

  const handleReceiverChange = (receiverAdd) => {
    setState({
      ...state,
      receiverAdd,
    });
  };

  const handleReceiverSelect = (receiverAdd) => {
    setState({
      ...state,
      receiverAdd
    });

    geoCodeAddress(receiverAdd)
      .then(({ lat, lng }) => {
        setState({
          ...state,
          receiverAdd,
          destLat: lat,
          destLng: lng
        })
      })
  };

  const ValidSubmission = () => {
    if (state.senderAdd && state.receiverAdd) {
      return (
        <button
          onClick={getestimate}
          type="button"
          className="estimatebutoon"
        >
          {state.btnValue}
        </button>
      );
    } else {
      return (
        <button
          disabled
          type="button"
          className="estimatebutoon"
        >
          {state.btnValue}
        </button>
      );
    }
  };

  const calc = () => {
    if (state.amount != null && state.distance != null) {
      return (
        <Row style={{ marginLeft: "7px" }}>
          <button className="estimatebutoon">{state.distance}km</button>
          <button className="estimatebutoon">Rs. {state.amount}</button>
        </Row>
      );
    } else {
      return null;
    }
  };

  return (
    <Row className="m-0">
      <Col
        lg={2} md={2} sm={2}
        className="text-center pt-5"
        style={{ backgroundColor: "white", 'height': '100vh' }}
      >
        <Row className="gordian-logo" style={{ justifyContent: "center", alignItem: "center" }}>
          <img src={Logo} alt="Logo" className="logo" />
        </Row>
        <h1 style={{ fontSize: "25px", textAlign: "center", fontFamily: "Manrope-Bold" }}>Gordian</h1>
        <Sidebar />
      </Col>
      <Col lg={7} md={7} sm={7} className="Form">
        <Container>
          <div className="NewOrder" style={{ marginLeft: "25px", fontSize: "27px" }}>Get Estimate</div>
          <form onSubmit={getestimate} className="main">
            <br />
            <h1 style={{ fontSize: "20px", fontWeight: "600", color: "#000000", fontFamily: "Manrope-Bold" }}>Pickup Details</h1>
            <Row className="row">
              <Col lg="12" md="12" sm="12">
                <PlacesAutocomplete
                  value={state.senderAdd}
                  onChange={handleSenderChange}
                  onSelect={handleSenderSelect}
                >
                  {({
                    getInputProps,
                    suggestions,
                    getSuggestionItemProps,
                    loading,
                  }) => (
                    <div className="placesDiv">
                      <input
                        {...getInputProps({
                          placeholder: "Enter Pickup Address",
                          className: "location-search-input field",
                        })}
                      />
                      <div className="autocomplete-dropdown-container">
                        {loading && <div>Loading . . . .</div>}
                        {suggestions.map((suggestion) => {
                          const className = suggestion.active
                            ? "suggestion-item--active"
                            : "suggestion-item";
                          const style = suggestion.active
                            ? {
                              backgroundColor: "#fafafa",
                              cursor: "pointer",
                            }
                            : {
                              backgroundColor: "#ffffff",
                              cursor: "pointer",
                            };
                          return (
                            <div
                              {...getSuggestionItemProps(suggestion, {
                                className,
                                style,
                              })}
                            >
                              <hr />
                              <span>{suggestion.description}</span>
                            </div>
                          );
                        })}
                      </div>
                    </div>
                  )}
                </PlacesAutocomplete>
              </Col>
            </Row>
            <hr />
            <h1 style={{ fontSize: "20px", fontWeight: "600", color: "#000000", fontFamily: "Manrope-Bold" }}>Drop Details</h1>
            <Row className="row mb-5">
              <Col lg="12" md="12" sm="12">
                <PlacesAutocomplete
                  value={state.receiverAdd}
                  onChange={handleReceiverChange}
                  onSelect={handleReceiverSelect}
                >
                  {({
                    getInputProps,
                    suggestions,
                    getSuggestionItemProps,
                    loading,
                  }) => (
                    <div className="placesDiv">
                      <input
                        {...getInputProps({
                          placeholder: "Enter Drop Address",
                          className: "location-search-input field",
                        })}
                      />
                      <div className="autocomplete-dropdown-container">
                        {loading && <div>Loading...</div>}
                        {suggestions.map((suggestion) => {
                          const className = suggestion.active
                            ? "suggestion-item--active"
                            : "suggestion-item";

                          const style = suggestion.active
                            ? {
                              backgroundColor: "#fafafa",
                              cursor: "pointer",
                            }
                            : {
                              backgroundColor: "#ffffff",
                              cursor: "pointer",
                            };
                          return (
                            <div
                              {...getSuggestionItemProps(suggestion, {
                                className,
                                style,
                              })}
                            >
                              <hr />
                              <span>{suggestion.description}</span>
                            </div>
                          );
                        })}
                      </div>
                    </div>
                  )}
                </PlacesAutocomplete>
              </Col>
            </Row>
            {/* {calc()} */}
            {/* <Row style={{ justifyContent: "center" }}>{ValidSubmission()}</Row> */}
          </form>
        </Container>
      </Col>
      <Col lg={3} md={3} sm={3} style={{ backgroundColor: "white" }}>
        <Container>
          <h1 className="name" style={{ fontSize: "20px", marginTop: "20px", fontFamily: "Manrope-SemiBold" }}>Welcome, {new_name}</h1>
          <hr />
          {/* <Row style={{ justifyContent: "center", alignItem: "center" }}>
            <button className="Secure-button" onClick={SecureMethod} style={{ backgroundColor: state.deliveryMethod === "secure" ? "#000000" : "#ffffff", zIndex: state.deliveryMethod === "secure" ? "1" : "0" }} >Secure</button>
            <button className="Regular-button" onClick={RegularMethod} style={{ backgroundColor: state.deliveryMethod === "regular" ? "#000000" : "#ffffff", zIndex: state.deliveryMethod === "regular" ? "1" : "0" }} >Regular</button>
          </Row> */}
          {calc()}
          <div style={{ position: "fixed", bottom: "10px", marginLeft: "25px" }}>
            <hr />
            <Row style={{ justifyContent: "center" }}>{ValidSubmission()}</Row>
            <div style={{ marginTop: "10px" }}>
              <h1 className="goto-text" style={{ color: "#92979D", fontSize: "12px", fontFamily: "Manrope-SemiBold", textAlign: "center" }}>For any difficulties</h1>
              <Link to="Contactus2" style={{ textDecoration: "none" }}>
                <h1 className="contact-text" style={{ color: "#000000", fontSize: "14px", fontFamily: "Manrope-Bold", textAlign: "center" }}>Contact Us</h1>
              </Link>
            </div>
          </div>
        </Container>
      </Col>

    </Row>
  );
};

export default Estimate;