import React, { useState } from 'react';
import { Row, Col, Container } from "reactstrap";
import { Link, useHistory } from "react-router-dom";
import Slider from "./Slider";
import BackIcon from "./../assets/images/back.png";
import Card from 'react-bootstrap/Card';
import ProgressBar from "./../Components/ProgressBar";
import Variables from "../Globals/variables";
import "./../Globals/Fonts.css";

const BaseURL = Variables.baseUrl;

function BusinessSignupMobile() {

    const initError = {
        phonenumberError: "",
    };

    const [state, setState] = useState({
        phonenumber: "",
        btnValue: "Send OTP"
    });

    const history = useHistory();

    async function signupotp() {
        const item = { phonenumber: state.phonenumber }
        setState({
            ...state,
            btnValue: "Sending OTP..."
        })

        let result = await fetch(`${BaseURL}/users/otp-sender`, {
            method: 'POST',
            body: JSON.stringify(item),
            headers: {
                "Content-Type": 'application/json',
                "Accept": 'application/json'
            }
        })
        result = await result;
        if (result.status === 200 || result.status === 201) {
            result = await result.json();
            localStorage.setItem("contact", result.phonenumber);
            history.push("/BusinessSignupOtp", {
                phonenumber: state.phonenumber
            })
            return result;
        } else if (result.status === 401) {
            result = await result.json();
            alert(result.message);
            setState({
                ...state,
                btnValue: "Send OTP"
            })
            return result;
        }
    }

    var [error] = useState(initError);

    const isValid = () => {
        error = initError;

        if (state.phonenumber.length !== 10) {
            error.phonenumberError = "number is not valid!!";
        }

        if (
            error.phonenumberError.length > 0
        ) {
            return false;
        } else {
            return true;
        }
    };

    const ValidSubmission = () => {
        if (isValid()) {
            return (
                <Link >
                    <button
                        onClick={signupotp}
                        type="button"
                        className="btn btn-dark rounded-pill button mb-3"
                    >
                        {state.btnValue}
                    </button>
                </Link>
            );
        } else {
            return (
                <button
                    disabled
                    type="button"
                    className="btn btn-dark rounded-pill button mb-3"
                >
                    {state.btnValue}
                </button>
            );
        }
    };

    return (
        <Row style={{ marginBottom: 0, marginTop: 0, backgroundColor: '#F5F6FA' }}>
            <Col lg={7} md={7} sm={7} >
                <Slider />
            </Col>

            <Col lg={5} md={5} sm={7} >
                <Row style={{ marginTop: "15px", marginLeft: "5px", marginRight: "30px", justifyContent: "space-between", alignItem: "space-between" }}>
                    <Link to="/BusinessSignup1" style={{ textDecoration: 'none' }} >
                        <Card style={{ width: '50px', height: "50px", borderRadius: "30px", backgroundColor: "#F5F6FA", border: "none", boxShadow: "5px 5px 11px 5px #0000000D" }}>
                            <Card.Body>
                                <img style={{ height: "30px", width: "27px", marginTop: "-15px", marginLeft: "-11px" }} src={BackIcon} alt="backicon" />
                            </Card.Body>
                        </Card>
                    </Link>

                    <div style={{ marginTop: "10px" }}>
                        <ProgressBar width="33%" steps="Step 1/3" />
                    </div>
                </Row>
                <Container style={{ marginTop: "80px" }}>
                    <h1 className="signupmobile-text" style={{ fontSize: "25px", fontFamily: "Manrope-Bold", marginLeft: "70px", marginBottom: "20px" }}>Sign Up with mobile number</h1>
                    <Row style={{ justifyContent: "center", alignItem: "space-between" }}>
                        <Col lg={9} >
                            <label style={{ fontSize: "16px", fontFamily: "Manrope-Bold", marginLeft: "15px" }}>Enter Mobile Number</label>
                            <input
                                type="number"
                                className="fields"
                                name="number"
                                value={state.phonenumber}
                                onChange={(e) => setState({ ...state, phonenumber: e.target.value })}
                                required
                            />
                        </Col>
                    </Row>
                    <Row style={{ justifyContent: "center", alignItem: "center" }} className="buttons">
                        <Col lg={9} md={12} sm={12} >
                            {ValidSubmission()}
                        </Col>
                    </Row>

                    <Row style={{ alignItem: "center", justifyContent: "center" }}>
                        <h1 className="goto-text" style={{ color: "#92979D", fontSize: "14px", fontFamily: "Manrope-Bold", position: "fixed", bottom: "40px", }}>Go to www.Gordian.in</h1>
                        <Link to="/ContactUs" style={{ textDecoration: "none", marginLeft: "-65px" }}>
                            <h1 className="contact-text" style={{ color: "#000000", fontSize: "14px", fontFamily: "Manrope-Bold", position: "fixed", bottom: "15px", }}>Contact Us</h1>
                        </Link>
                    </Row>
                </Container>
            </Col>
        </Row>
    )
}

export default BusinessSignupMobile