import React from 'react';
import { Row, Container } from "reactstrap";
import Card from 'react-bootstrap/Card';
import { Link } from "react-router-dom";
import BackIcon from "./../assets/images/back.png";
import "./../Globals/Fonts.css";
import "./styles.signuplogin.css";

function MobileContactUs1(props) {
    return (
        <Container >
            <Row style={{ marginTop: "15px", marginLeft: "5px", justifyContent: "space-between", alignItem: "space-between", marginRight: "5px" }}>
                {/* <Link to="/PlaceOrder" style={{ textDecoration: 'none' }} > */}
                <Card style={{ width: '50px', height: "50px", borderRadius: "30px", backgroundColor: "#F5F6FA", border: "none", boxShadow: "5px 5px 11px 5px #0000000D" }} onClick={() => props.history.goBack()}>
                    <Card.Body>
                        <img style={{ height: "30px", width: "27px", marginTop: "-15px", marginLeft: "-11px" }} src={BackIcon} alt="backicon" />
                    </Card.Body>
                </Card>
                {/* </Link> */}

            </Row>
            <h1 style={{ fontSize: "28px", fontFamily: "Manrope-ExtraBold", marginLeft: "20px", color: "#000000", letterSpacing: "-1px" }}>ContactUs </h1>
            <Row style={{ justifyContent: "center", alignItem: "center" }}>
                <Link style={{ textDecoration: 'none' }} >
                    <div className="Contact-icon-mobile">
                        <div style={{ flexDirection: "row", }}>
                            <h1 style={{ fontSize: "20px", fontFamily: "Manrope-Bold", textAlign: 'center', color: "#ffffff", paddingTop: "45px" }}>Phone Number</h1>
                            <h1 style={{ fontSize: "20px", fontFamily: "Manrope-Bold", textAlign: 'center', color: "#ffffff" }}>+91 8762918529</h1>
                        </div>
                    </div>
                </Link>
                <div className="Contact-icon-mobile" style={{ marginTop: "30px" }}>
                    <div style={{ flexDirection: "row" }}>
                        <a href='mailto:support@gordian.in' target="blank" style={{ textDecoration: "none" }}>
                            <h1 style={{ fontSize: "20px", fontFamily: "Manrope-ExtraBold", textAlign: 'center', color: "#ffffff", paddingTop: "45px" }}>Email</h1>
                            <h1 style={{ fontSize: "20px", fontFamily: "Manrope-ExtraBold", textAlign: 'center', color: "#ffffff" }}>support@gordian.in</h1>
                        </a>
                    </div>

                </div>

            </Row>
        </Container>

    )
}

export default MobileContactUs1
