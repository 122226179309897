import React, { useState } from 'react';
import { Row, Col, Container } from "reactstrap";
import { Link } from "react-router-dom"
import Slider from "./Slider";
import BackIcon from "./../assets/images/back.png";
import Card from 'react-bootstrap/Card';
import ProgressBar from "../Components/ProgressBar";
import "./../Globals/Fonts.css";
import usePasswordToggle from "./../Components/usePasswordToggle";
import useConfirmpasswordToggle from "./../Components/useConfirmpassToggle";

function DesktopBusinessSignup2(props) {

    const initError = {
        emailError: "",
        passwordError: "",
        confirmpasswordError: "",
    };

    const [state, setState] = useState({
        email: "",
        password: "",
        confirmpassword: "",
    });

    const [PasswordInputType, ToggleIcon] = usePasswordToggle();
    const [PasswordInputType1, ToggleIcon1] = useConfirmpasswordToggle();

    var [error] = useState(initError);

    const isValid = () => {
        error = initError;
        const re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

        if (!re.test(state.email)) {
            error.emailError = "Invalid Email address";
        }
        if (state.password.length === 0) {
            error.passwordError = "Password is required";
        }
        if (state.confirmpassword.length < state.password.length) {
            error.confirmpasswordError = "Confirm Password is required";
        }

        if (
            error.emailError.length > 0 ||
            error.passwordError.length > 0 ||
            error.confirmpasswordError.length > 0 ||
            error.passwordError !== error.confirmpasswordError
        ) {
            return false;
        } else {
            return true;
        }
    };

    const ValidSubmission = () => {
        if (isValid()) {
            if (state.password !== state.confirmpassword) {
                alert("Passwords don't match.")
                return (
                    <button
                        disabled
                        type="button"
                        className="btn btn-dark rounded-pill button mb-3"
                    >
                        Next
                    </button>
                );
            } else
                return (
                    <Link
                        to={{
                            pathname: "/BusinessSignup3",
                            state,
                        }}
                        type="button"
                        className="btn btn-dark rounded-pill button mb-3"
                    >
                        Sign Up
                    </Link>
                );
        } else {
            return (
                <button
                    disabled
                    type="button"
                    className="btn btn-dark rounded-pill button mb-3"
                >
                    Next
                </button>
            );
        }
    };


    return (
        <Row style={{ marginBottom: 0, marginTop: 0, backgroundColor: '#F5F6FA' }}>
            <Col lg={7} md={7} sm={7}>
                <Slider />
            </Col>

            <Col lg={5} md={5} sm={5}>
                <Row style={{ marginTop: "15px", marginLeft: "5px", marginRight: "30px", justifyContent: "space-between", alignItem: "space-between" }}>
                    <Link to="/BusinessSignup1" style={{ textDecoration: 'none' }} >
                        <Card style={{ width: '50px', height: "50px", borderRadius: "30px", backgroundColor: "#F5F6FA", border: "none", boxShadow: "5px 5px 11px 5px #0000000D" }}>
                            <Card.Body>
                                <img style={{ height: "30px", width: "27px", marginTop: "-15px", marginLeft: "-11px" }} src={BackIcon} alt="backicon" />
                            </Card.Body>
                        </Card>
                    </Link>
                    <div style={{ marginTop: "10px" }}>
                        <ProgressBar width="50%" steps="Step 1/2" />
                    </div>
                </Row>
                <Container style={{ marginTop: "60px" }}>
                    <h1 className="signupemail-text" style={{ fontSize: "25px", fontFamily: "Manrope-Bold", marginLeft: "80px", marginBottom: "20px" }}>Sign Up with Email Address</h1>
                    <Row style={{ justifyContent: "center", alignItem: "center" }}>
                        <Col lg={9} >
                            <label style={{ fontSize: "16px", fontFamily: "Manrope-Bold", marginLeft: "15px" }}>Email Address</label>
                            <input
                                type="email"
                                className="fields"
                                name="email"
                                value={state.email}
                                onChange={(e) => setState({ ...state, email: e.target.value })}
                                required
                            />
                        </Col>

                        <Col lg={9} >
                            <label style={{ marginTop: "20px", fontSize: "16px", fontFamily: "Manrope-Bold", marginLeft: "15px" }}>Password</label>
                            <input
                                type={PasswordInputType}
                                className="fields"
                                value={state.password}
                                onChange={(e) => setState({ ...state, password: e.target.value })}
                                name="password"
                                required
                            />
                            <span style={{ position: "absolute", cursor: "pointer", right: "20px" }}>{ToggleIcon}</span>
                        </Col>

                        <Col lg={9} >
                            <label style={{ marginTop: "20px", fontSize: "16px", fontFamily: "Manrope-Bold", marginLeft: "15px" }}>Confirm Password</label>
                            <input
                                type={PasswordInputType1}
                                className="fields"
                                value={state.confirmpassword}
                                onChange={(e) => setState({ ...state, confirmpassword: e.target.value })}
                                name="confirm password"
                                required
                            />
                            <span style={{ position: "absolute", cursor: "pointer", right: "20px" }}>{ToggleIcon1}</span>
                        </Col>
                    </Row>
                    <Row style={{ justifyContent: "center", alignItem: "center" }} className="buttons">
                        <Col lg={9} md={12} sm={12} >
                            {ValidSubmission()}
                        </Col>
                    </Row>

                    <Row style={{ alignItem: "center", justifyContent: "center" }}>
                        <h1 className="goto-text" style={{ color: "#92979D", fontSize: "14px", fontFamily: "Manrope-Bold", position: "fixed", bottom: "40px", }}>Facing difficulties?</h1>
                        <Link to="/ContactUs" style={{ textDecoration: "none", marginLeft: "-65px" }}>
                            <h1 className="contact-text" style={{ color: "#000000", fontSize: "14px", fontFamily: "Manrope-Bold", position: "fixed", bottom: "15px", }}>Contact Us</h1>
                        </Link>
                    </Row>
                </Container>
            </Col>
        </Row>
    )
}

export default DesktopBusinessSignup2