import React, { Fragment } from "react";
import Navbar from "../Components/Navbar/Navbar";
import { Container } from "reactstrap";

function Terms() {
  return (
    <Fragment>
      <Navbar />
      <hr />
      <Container style={{ 'textAlign': 'left' }}>
        <h1>Terms and Conditions</h1>
        <p>These terms of use (&quot;Terms of Use&quot;) mandate the terms on which users (&quot;You&quot; or &quot;Your&quot; or &quot;Yourself&quot; or
          “User” or &quot;Users&quot;) interested in browsing or availing Gordian Services (defined below), and accessing the
          platform www.orders.gordian.in and the mobile applications owned and operated by Gordian Technologies
          Private Limited (&quot;Gordian&quot; or the “Company”), collectively referred to as the &quot;Platform&quot; which connects Users
          with delivery partners (&quot;Delivery Partners&quot;) to avail the Gordian Services.</p>
        <p>Please read the Terms of Use carefully before availing Gordian Services, using the Platform, registering on the
          Platform, or accessing any material or information through the Platform. By signing up, You accept this Terms
          of Use and agree to be legally bound by the same.</p>
        <p>Use of and access to the Platform is offered to You upon the condition of acceptance of all the terms,
          conditions and notices contained in this Terms of Use and Privacy Policy, along with any amendments made
          by Gordian at its sole discretion and posted on the Platform from time to time.</p>
        <p>For the purposes of these Terms of Use, the term “Gordian” or “Us” or “We” or “Company” refers to Gordian
          Technologies Private Limited. The term “You” refers to the user or visitor of the Website and/or App. When
          You use our services, You will be subject to the terms, guidelines and policies applicable to such service and
          as set forth in these Terms of Use. As long as you comply with these Terms of Use, We grant You a personal,
          non-exclusive, non-transferable, limited privilege to enter and use our Platforms and services.</p>
        <ol type="1">
          <h2>Registration</h2>
          <li>
            You shall be permitted to access the Platform, avail the Gordian Services and connect with Delivery
            Partners on the Platform only upon creating an Account (as defined below) and obtaining a registration
            on the Platform. Your ability to continue using the Platform, Gordian Services is subject to Your
            continued registration on the Platform. You will be required to enter Your personal information
            including your name, contact details, valid phone number, valid e-mail address while registering on the
            Platform.
          </li>
          <li>
            As a part of the registration, You may be required to undertake a verification process to verify Your
            personal information and setting up the Account.
          </li>
          <li>
            Gordian shall have the right to display the information, feedback, ratings, reviews etc. provided by You
            on the Platform. You agree and accept that as on the date of Your registration on the Platform, the
            information provided by You is complete, accurate and up-to-date. In the event of any change to such
            information, You shall be required to promptly inform Gordian of the same, in writing, at least 1 (one)
            week prior to the date on which such change shall take effect. You acknowledge and accept that Gordian
            has not independently verified the information provided by You. Gordian shall in no way be responsible
            or liable for the accuracy or completeness of any information provided by You. If You provide any
            information that is untrue, inaccurate, not current or incomplete, or Gordian has reasonable grounds to
            suspect that such information is untrue, inaccurate, not current or incomplete, Gordian reserves the
            right to suspend or terminate Your Account (defined below) and refuse any and all current or future use
            of the Platform (or any portion thereof) at any time.
          </li>
          <h2>Services</h2>
          <li>
            The Platform provides You with the following services (“Gordian Services”):
          </li>
          <ol type="a">
            <li>
              It allows you to pick up-and drop off packages from 1 (one) location to the other through the
              Delivery Partner (“Pick Up &amp; Drop Off Services”), whether or not through the usage of our Internet-
              of-Things-enabled devices (“IoT Devices”)

            </li>
            <li>
              It allows you to place your packages, for transit, in our IoT Devices, which offer security through
              OTP-based unlocking
            </li>
            <li>
              It allows You to give ratings, write comments and reviews about Delivery Partners and the Platform
            </li>
            <li>
              It facilitates improvement in the quality of the services provided by Gordian on the Platform based
              on User ratings, reviews and feedbacks provided on the Platform;
            </li>
            <li>
              It facilitates payments from You to the Delivery Partners.
            </li>
            <li>
              It facilitates transit insurance (“Transit Insurance”) between You and the insurer with which
              Gordian has entered into an agreement with for the provision of insurance to the Users.
            </li>
          </ol>
          <li>
            It is hereby clarified that availing of Gordian Services through placing Orders via call, e-mail, text
            messages, WhatsApp messages, or any other medium, shall be construed as availing Gordian Services
            through or on the Platform.
          </li>
          <li>
            Upon initiation of a request for Pick Up &amp; Drop Off Services on the Platform, depending upon the
            availability of Delivery Partner around Your area, Gordian will assign a Delivery Partner to You. The
            Delivery Partner shall pick up the item(s) from a location designated by You on the Platform and drop off
            the item(s) at a particular location designated by You. While performing the Pick Up &amp; Drop off Services,
            the Delivery Partner shall act as an agent of You and shall act in accordance with Your instructions. You
            agree and acknowledge that the pick-up location and the drop off location has been added by You
            voluntarily and such information will be used for the Gordian Services and shall be handled by Gordian
            in accordance with the terms of its Privacy Policy.
          </li>
          <li>
            Gordian may, at its absolute sole discretion, add, modify, upgrade, extend, withdraw or alienate any of
            the Gordian Services listed above from time to time. Gordian does not provide any guarantee to You that
            the Gordian Services will be made available to You at all times
          </li>
          <li>
            You hereby agree and acknowledge that:
          </li>
          <ol type="a">
            <li>
              The Pick Up &amp; Drop Off Services are provided directly by the Delivery Partner
            </li>
            <li>
              Gordian is only a facilitator between You and the Delivery Partners
            </li>
            <li>
              Gordian merely acts as a technology platform to facilitate transactions initiated on the Platform
            </li>
            <li>
              Gordian only provides You with access to the Platform to connect with Delivery Partners for You to
              initiate transactions on the Platform
            </li>
            <li>
              Gordian will not be a party to any of the transactions that are initiated by You through the Platform
            </li>
            <li>
              Except as otherwise provided under the Insurance section under these Terms of Use, Gordian does
              not assume any responsibility and shall not be liable in any manner or incur any liability with
              respect to the services performed by the Delivery Partners.
            </li>
            <li>
              Except as otherwise provided under the Insurance section under these Terms of Use, Gordian shall
              not be liable for the conduct, acts and omissions of the Delivery Partners in the course of providing
              their services to You, or for any loss or damage to the packages or otherwise caused to You as a
              consequence of or in relation to the services being provided to You by the Delivery Partner.
            </li>
          </ol>
          <li>
            You shall be eligible to avail the Gordian Services only as per applicable laws. If You are transporting any
            medicinal product, using the Platform, for which You are required to have a valid prescription from a
            medical practitioner, You shall, ensure that such prescription is available with You and You agree to
            share the same, upon request, with Gordian, the Delivery Partner, or law enforcement authorities.
            Failure to do the same, shall result in cancellation of the transaction.
          </li>
          <li>
            You agree that before requesting a Pick Up &amp; Drop Off Service on the Platform, you are well aware of the
            contents of the package to be sent by you through the Delivery Partner assigned to you, and that such
            contents are legal and within limits of transportation under any applicable laws. You shall not initiate
            any transaction for any item on the Platform that is illegal, immoral, unethical, unlawful, unsafe, contains
            harmful substance, or is in violation of this Terms of Use and applicable laws. Such contents/item(s)
            shall not be restricted and/or banned and/or dangerous and/or prohibited for carriage (such items
            include, but are not limited to, radio-active, incendiary, corrosive or flammable substances, hazardous
            chemicals, explosives, firearms or parts thereof and ammunition, firecrackers, cyanides, precipitates,
            currency (paper or coin) of any nationality, negotiable instruments in bearer form, cashier&#39;s cheques,
            travellers’ cheques, money orders, lottery tickets and gambling devices, pornographic materials,
            contraband, bottled alcoholic beverages or any intoxicant or narcotics and psychotropic substances or
            any other prohibited material or material for the transportation of which specific authorisation/license
            is required under applicable laws. Further, You hereby acknowledge and agree that Gordian shall not be
            liable for any indirect, direct damage or loss, cost, expense incurred by You in relation to the
            transactions initiated by You on the Platform.
          </li>
          <li>
            You agree that before requesting a Pick Up &amp; Drop Off Service on the Platform, you will:
            <ol type="a">
              <li>Declare, on the Platform, the monetary value of the contents of the package.</li>
              <li>Ensure that the contents of the package are backed and evidenced by appropriate and valid
                documentation that accurately represents the monetary value of the contents of the package so
                declared.</li>
              <li>Either upload such documentation on the Platform or accompany such documentation along with
                the package being sent.</li>
            </ol>
          </li>
          <li>
            Gordian does not check or verify the packages that are being picked up and dropped off on behalf of You
            or the items that are being delivered to You by the Delivery Partner, and therefore Gordian shall have no
            liability with respect to the same. However, if it comes to the knowledge of Gordian that You have
            packaged any illegal or dangerous substance or availed the Pick Up &amp; Drop Off Services using the
            Platform to deliver any illegal or dangerous substance, Gordian shall have the right to report You to the
            government authorities and take other appropriate legal actions against You.
          </li>
          <li>
            You hereby acknowledge that Gordian shall not be liable for any damages of any kind arising from Your
            use of the Gordian Services, including, but not limited to direct, indirect, incidental, punitive, and
            consequential damages.
          </li>
          <li>
            You hereby agree and acknowledge that Gordian shall not be liable for any losses due to malfunction of
            the IoT Devices or due to our Platform and/or website / mobile application being down due to any
            downtime of IT infrastructure or hacks on any of Gordian’s assets.
          </li>
          <li>
            Gordian shall be entitled, at any time, without giving any reason, to terminate Your request for any
            Gordian Service.
          </li>
          <li>
            You hereby agree that Gordian shall not be liable for any conduct or misbehaviour or actions of the
            Delivery Partner with respect to any transactions initiated on the Platform.
          </li>
          <li>
            You hereby agree that scheduling and rescheduling a transaction on the Platform depends upon the
            availability of Delivery Partners around the area provided by You for pick up at the time of such
            scheduling and re-scheduling a transaction. Should You choose to reschedule a transaction on the
            Platform at a later point in time, You shall cancel the current transaction on the Platform (if initiated)
            and initiate a new transaction on the Platform, as per Your convenient time.
          </li>
          <li>
            You agree to provide as much information as possible on the Platform with respect to the services You
            wish to avail, using the Platform.
          </li>
          <li>
            Gordian shall use Your location-based information that is captured by Gordian through global
            positioning system when You are using Your mobile device to request a Gordian Service on its mobile or
            website applications. Such location-based information shall be used by Gordian only to facilitate and
            improve the Gordian Services being offered to You.
          </li>
          <li>
            We can’t fulfil any tasks which are immoral or unlawful in nature. Gordian reserves the right to refuse to
            perform any tasks on the grounds of such tasks being immoral/ unethical/ unlawful/ banned either by
            Gordian’s internal policies, government authorities, or as per the independent discretion of Gordian.
            Gordian may also refuse to perform any task on the grounds that such task is prohibited under any
            contract to which we are party.
          </li>
          <li>
            You hereby acknowledge that if You have any complaint with respect to the Gordian Services, You will
            first inform Gordian in writing within 24 (twenty four) hours of using such Gordian Services.
          </li>
          <li>
            You also agree that, upon becoming aware of the commission any offence by You or Your intention to
            commit any offence upon initiating a Pick-up &amp; Drop-off Service or during a Pick Up &amp; Drop Off service of
            any Item(s) restricted under applicable law, the Delivery Partner may report such information to
            Gordian or to the law enforcement authorities.
          </li>
          <li>
            You hereby agree and acknowledge that any offers/promotions/discounts (collectively referred to as
            “Offers”), if any, are being extended by Gordian at its sole independent discretion and nothing shall
            entitle You to any of the Offers. You shall read the terms and conditions of the Offers carefully before
            availing them.
          </li>
          <h2>User Information</h2>
          <li>
            You are solely responsible for and in control of the information You provide to us. You consent to
            collection, storage, disclosure or transfer of details pertaining to your User Accounts such as your
            contact number and e-mail addresses, pursuant to You availing services on our platform/application.
            Further, You understand and agree that certain information will be case sensitive and must be handled
            with prudent care.
          </li>
          <li>
            In the case where the Platform is unable to establish unique identity of the User against a valid mobile
            number or e-mail address, the Account shall be indefinitely suspended. Gordian reserves the full
            discretion to suspend a User&#39;s Account in the above event and does not have the liability to share any
            Account information whatsoever.
          </li>
          <h2>Payment Terms</h2>
          <li>
            Pick Up &amp; Drop Off Services: While initiating a request for a Pick Up &amp; Drop Off Service, You shall pay the
            service fees for availing the Pick Up &amp; Drop Off Service, as may be displayed to You on the Platform. Only
            upon making such payment will the Delivery Partner initiate the Pick Up &amp; Drop Off Service.
          </li>
          <li>You agree that Gordian may use certain third-party vendors and service providers, including payment
            gateways, to process the payments made by You on the Platform.</li>
          <h2>Rating</h2>
          <li>
            You agree that: (i) after completion of a transaction on the Platform, the Platform will prompt the User
            with an option to provide a rating and comments about the Delivery Partner (with respect to the
            services performed by the Delivery Partner); and (ii) the Delivery Partner may also be prompted to rate
            You on the Platform. Based upon such Delivery Partner rating, Your rating score on the Platform will be
            determined and displayed.
          </li>
          <li>
            Gordian and its affiliates reserve the right to use, share and display such ratings and comments in any
            manner in connection with the business of Gordian and its affiliates without attribution to or approval of
            Users and You hereby consent to the same. Gordian and its affiliates reserve the right to edit or remove
            comments in the event such comments include obscenities or other objectionable content, include an
            individual’s name or other personal information, or violate any privacy laws, other applicable laws or
            Gordian’s or its affiliates’ content policies.
          </li>
          <li>
            Location: You acknowledge and agree that Your geo-location information may be required for You to
            avail the Gordian Services and initiate transactions on the Platform. You acknowledge and hereby
            consent to the monitoring and tracking of Your geo-location information. In addition, Gordian may share
            Your geo-location information with Delivery Partners.
          </li>
          <h2>Cancellation and Refund</h2>
          <li>
            Gordian shall confirm and communicate to its payment partner to process the transaction initiated by
            You upon receiving confirmation from You for the same. If You wish to cancel a transaction on the
            Platform, You shall select the cancel option on the Platform. It is to be noted that You may have to pay a
            cancellation fee for transactions initiated on the Platform for which work has already been commenced
            by the Delivery Partner, as the case may be.
          </li>
          <li>
            Gordian may cancel the transaction initiated by You on the Platform, if:
          </li>
          <ol type="a">
            <li>
              The designated address to avail the Gordian Services provided by You is outside the service zone
              of Gordian.
            </li>
            <li>
              Failure to get your response via phone or any other communication channel at the time of
              confirmation of the order booking.
            </li>
            <li>
              The transaction involves pick up / delivery of any item that is illegal, offensive or violative of the
              Terms of Use.
            </li>
            <li>
              Information, instructions and authorisations provided by You is not complete or sufficient to
              execute the transaction initiated by You on the Platform.
            </li>
            <li>
              If a Delivery Partner is not available to perform the services, as may be requested.
            </li>
            <li>
              If the transaction cannot be completed for reasons not in control of Gordian.
            </li>
          </ol>
          <li>
            You shall only be able to claim refunds for transactions initiated by You only if You have already pre-paid
            the fees with respect to such transaction. You shall be eligible to get the refund in the following
            circumstances:
          </li>
          <ol type="a">
            <li>
              Gordian has cancelled the order because of any reason mentioned under Para 26 above.
            </li>
            <li>
              All decisions with respect to refunds will be at the sole discretion of Gordian and in accordance
              with applicable law and the same shall be final and binding. All refunds initiated by Gordian shall
              be refunded to the financial source account from which, You have initiated the transaction on the
              Platform.
            </li>
          </ol>
          <h2>Eligibility to Use</h2>
          <li>
            The Gordian Services are not available to minors i.e. persons under the age of 18 (eighteen) years or to
            any Users suspended or removed by Gordian from accessing the Platform for any reason whatsoever.
            You represent that You are of legal age to form a binding contract and are not a person barred from
            receiving using or availing Gordian Services under the applicable laws.
          </li>
          <li>
            Gordian reserves the right to refuse access to the Platform, at any time to new Users or to terminate or
            suspend access granted to existing Users at any time without according any reasons for doing so.
          </li>
          <li>
            You shall not have more than 1 (one) active Account (as defined below) on the Platform. Additionally,
            You are prohibited from selling, trading, or otherwise transferring Your Account to another party or
            impersonating any other person for the purposing of creating an account with the Platform.
          </li>
          <h2>User Account, Password, and Security</h2>
          <li>
            In order to use the Platform and avail the Gordian Services, You will have to register on the Platform and
            create an account with a unique user identity and password (&quot;Account&quot;). If You are using the Platform on
            a compatible mobile or tablet, You will have to install the application and then proceed with registration.
          </li>
          <li>
            At the time of registration, You will have the option on the application to register your Account as a
            business account (“Business Account”).
          </li>
          <li>
            You will be responsible for maintaining the confidentiality of the Account information, and are fully
            responsible for all activities that occur under Your Account. You agree to (i) immediately notify Gordian
            of any unauthorized use of Your Account information or any other breach of security, and (ii) [ensure
            that You exit from Your Account at the end of each session.] Gordian cannot and will not be liable for any
            loss or damage arising from Your failure to comply with this provision. You may be held liable for losses
            incurred by Gordian or any other User of or visitor to the Platform due to authorized or unauthorized
            use of Your Account as a result of Your failure in keeping Your Account information secure and
            confidential. Use of another User’s Account information for using the Platform is expressly prohibited.
          </li>
          <h2>Insurance</h2>
          <li>
            You agree and acknowledge that it is your responsibility to procure and maintain insurance to protect
            Your packages during the performance of the Gordian Services. You free Gordian from any and all
            responsibility for any incident or misfortune, in respect of Pick Up &amp; Drop Off Services, caused to you
            due to theft, damage, infidelity of the Delivery Partners, or otherwise. You further agree and
            acknowledge that Gordian shall not be liable to Your insurer for any loss or damage cause to Your
            packages in respect of Pick Up &amp; Drop Off Services, caused to you due to theft, damage, infidelity of the
            Delivery Partners, or otherwise.
          </li>
          <li>
            For any genuine loss or damages suffered and evidenced by you due to infidelity or theft on part of the
            Delivery Partner in respect of Your order on the Platform for Pick Up &amp; Drop Off Services, Gordian may
            be liable to you only up to the extent of actual claim amount received by Gordian from Gordian’s insurer
            in respect of Your transaction, subject to the following conditions:
          </li>
          <ol type="a">
            <li>
              Your due cooperation is received in respect of Gordian’s claims filing efforts and the insurer’s
              investigation process.
            </li>
            <li>
              You provide all such documents, clarifications, and information that are requested from you for filing
              the claim with the insurer and are required by the insurer for its investigation.
            </li>
            <li>
              You agree and acknowledge that it is your responsibility to procure and maintain insurance to
              protect Your packages during the performance of the Gordian Services. You free Gordian from any
              and all responsibility for any incident or misfortune, in respect of Pick Up &amp; Drop Off Services, caused
              to you due to infidelity of the Delivery Partners or otherwise.
            </li>
          </ol>
          <li>
            For any genuine loss or damages suffered by you due to damage or loss of goods in course of transit in
            respect of Your order on the Platform for Pick Up &amp; Drop Off Services, you may be eligible to receive
            claim amount from the insurer under Transit Insurance, subject to the following conditions:
          </li>
          <ol type="a">
            <li>
              Your Account is registered as a Business Account.
            </li>
            <li>
              You have specifically opted-in on Platform to be insured and have paid the appropriate premium
              amount charged by the insurer through our Platform.
            </li>
            <li>
              You agree and acknowledge that Gordian is a mere facilitator between You and the insurer for the
              Transit Insurance and that Gordian does not assume any responsibility and shall not be liable in any
              manner or incur any liability with respect the conduct, acts and omissions of the Delivery Partners in
              the course of providing their services to You, or for any loss or damage to the packages or otherwise
              caused to You as a consequence of or in relation to the services being provided to You by the Delivery
              Partner.
            </li>
            <li>
              Your due cooperation is received in respect of Gordian’s claims filing efforts and the insurer’s
              investigation process.
            </li>
            <li>
              You provide all such documents, clarifications, and information that are requested from you for filing
              the claim with the insurer and are required by the insurer for its investigation.
            </li>
          </ol>
          <li>
            Subject to applicable laws and except as otherwise provided in this “Insurance” section, in no event will
            Gordian or its employees’ aggregate liability, arising from or related to the Gordian Services or the use of
            the Platform exceed INR 100 for any and all causes of actions brought by You or on behalf of You.
          </li>
          <h2>Representations and Warranties</h2>
          <li>
            Subject to compliance with the Terms of Use, Gordian grants You a non-exclusive, limited privilege to
            access and use this Platform and the Gordian Services.
          </li>
          <li>
            You agree to use the Platform only: (i) for purposes that are permitted by this Terms of Use; and (ii) in
            accordance with any applicable law, regulation or generally accepted practices or guidelines. You agree
            not to engage in activities that may adversely affect the use of the Platform by Gordian or Delivery
            Partner(s) or other Users.
          </li>
          <li>
            You represent and warrant that You have not received any notice from any third party or any
            governmental authority and no litigation is pending against You in any court of law, which prevents You
            from accessing the Platform and/or availing the Gordian Services.
          </li>
          <li>
            You represent and warrant that You are legally authorised to view and access the Platform and avail the
            Gordian Services.
          </li>
          <li>
            You agree not to access (or attempt to access) the Platform by any means other than through the
            interface that is provided by Gordian. You shall not use any deep-link, robot, spider or other automatic
            device, program, algorithm or methodology, or any similar or equivalent manual process, to access,
            acquire, copy or monitor any portion of the Platform, or in any way reproduce or circumvent the
            navigational structure or presentation of the Platform, materials or any Gordian Property, to obtain or
            attempt to obtain any materials, documents or information through any means not specifically made
            available through the Platform, or otherwise expressly allowed in writing by Gordian.
          </li>
          <li>
            You acknowledge and agree that by accessing or using the Platform, You may be exposed to content from
            others that You may consider offensive, indecent or otherwise objectionable. Gordian disclaims all
            liabilities arising in relation to such offensive content on the Platform.
          </li>
          <li>
            Further, You undertake not to:
          </li>
          <ol type="a">
            <li>
              defame, abuse, harass, threaten or otherwise violate the legal rights of others;
            </li>
            <li>
              publish, post, upload, distribute or disseminate any inappropriate, profane, defamatory, infringing,
              disparaging, ethnically objectionable, obscene, indecent or unlawful topic, name, material or
              information;
            </li>
            <li>
              do any such thing that may harms minors in any way;
            </li>
            <li>
              copy, republish, post, display, translate, transmit, reproduce or distribute any Gordian property
              through any medium without obtaining the necessary authorization from Gordian;
            </li>
            <li>
              conduct or forward surveys, contests, pyramid schemes or chain letters;
            </li>
            <li>
              upload or distribute files that contain software or other material protected by applicable intellectual
              property laws unless You own or control the rights thereto or have received all necessary consents;
            </li>
            <li>
              upload or distribute files or documents or videos (whether live or pre-recorded) that contain viruses,
              corrupted files, or any other similar software or programs that may damage the operation of the
              Platform or another&#39;s computer;
            </li>
            <li>
              engage in any activity that interferes with or disrupts access to the Platform (or the servers and
              networks which are connected to the Platform);
            </li>
            <li>
              attempt to gain unauthorized access to any portion or feature of the Platform, any other systems or
              networks connected to the Platform, to any Gordian server, or through the Platform, by hacking,
              password mining or any other illegitimate means;
            </li>
            <li>
              probe, scan or test the vulnerability of the Platform or any network connected to the Platform, nor
              breach the security or authentication measures on the Platform or any network connected to the
              Platform. You may not reverse look-up, trace or seek to trace any information on any other User, of
              or visitor to, the Platform, to its source, or exploit the Platform or information made available or
              offered by or through the Platform, in any way whether or not the purpose is to reveal any
              information, including but not limited to personal identification information, other than Your own
              information, as provided on the Platform;
            </li>
            <li>
              disrupt or interfere with the security of, or otherwise cause harm to, the Platform, systems resources,
              accounts, passwords, servers or networks connected to or accessible through the Platform or any
              affiliated or linked sites;
            </li>
            <li>
              collect or store data about other Users or Delivery Partners in connection with the prohibited
              conduct and activities set forth herein;
            </li>
            <li>
              use any device or software to interfere or attempt to interfere with the proper working of the
              Platform or any transaction being conducted on the Platform, or with any other person’s use of the
              Platform;
            </li>
            <li>
              use the Platform or any material or Gordian property for any purpose that is unlawful or prohibited
              by these Terms of Use, or to solicit the performance of any illegal activity or other activity which
              infringes the rights of the Company or other third parties;
            </li>
            <li>
              falsify or delete any author attributions, legal or other proper notices or proprietary designations or
              labels of the origin or source of software or other material contained in a file that is uploaded;
            </li>
            <li>
              impersonate any other User, Delivery Partner, or person;
            </li>
            <li>
              violate any applicable laws or regulations for the time being in force within or outside India or
              anyone’s right to privacy or personality;
            </li>
            <li>
              violate the Terms of Use contained herein or elsewhere;
            </li>
            <li>
              threatens the unity, integrity, defence, security or sovereignty of India, friendly relation with foreign
              states, or public order or causes incitement to the commission of any cognisable offence or prevents
              investigation of any offence or is insulting for any other nation; and
            </li>
            <li>
              reverse engineer, modify, copy, distribute, transmit, display, perform, reproduce, publish, license,
              create derivative works from, transfer, or sell any information or software obtained from the
              Platform.
            </li>
          </ol>
          <li>
            You agree and acknowledge that the use of the Gordian Services offered by Gordian is at Your sole risk
            and that Gordian disclaims all representations and warranties of any kind, whether express or implied
            as to condition, suitability, quality, merchantability and fitness for any purposes are excluded to the
            fullest extent permitted by law.
          </li>
          <li>
            Without prejudice to the above, Gordian makes no representation or warranty that the Gordian Services
            will meet Your requirements.
          </li>
          <li>
            All materials/content on our Platform (except any third party content available on the Platform),
            including, without limitation, names, logos, trademarks, images, text, columns, graphics, videos,
            photographs, illustrations, artwork, software and other elements (collectively, &quot;Material&quot;) are protected
            by copyrights, trademarks and/or other intellectual property rights owned and controlled by Gordian.
            You acknowledge and agree that the Material is made available for limited, non-commercial, personal
            use only. Except as specifically provided herein or elsewhere in our Platform, no Material may be copied,
            reproduced, republished, sold, downloaded, posted, transmitted, or distributed in any way, or otherwise
            used for any purpose other than the purposes stated under this Terms of Use, by any person or entity,
            without Gordian’s prior express written permission. You may not add, delete, distort, or otherwise
            modify the Material. Any unauthorized attempt to modify any Material, to defeat or circumvent any
            security features, or to utilize our Platform or any part of the Material for any purpose other than its
            intended purposes is strictly prohibited. Subject to the above restrictions under this Clause, Gordian
            hereby grants You a non-exclusive, freely revocable (upon notice from Gordian), non-transferable access
            to view the Material on the Platform.
          </li>
          <h2>Intellectual Property Rights</h2>
          <li>
            The Platform and process, and their selection and arrangement, including but not limited to, all text,
            videos, graphics, user interfaces, visual interfaces, sounds and music (if any), artwork, algorithm and
            computer code (and any combination thereof), except any third party software available on the Platform,
            is owned by Gordian (&quot;Gordian Property&quot;) and the design, structure, selection, co-ordination, expression,
            look and feel and arrangement of such Gordian Property is protected by copyright, patent and trademark
            laws, and various other intellectual property rights. You are not permitted to use Gordian Property
            without the prior written consent of Gordian.
          </li>
          <li>
            You hereby agree and acknowledge that you shall not, directly or indirectly, reverse engineer or aid or
            assist in the reverse engineering of all or any part of the IoT Devices or any associated hardware and
            software. It is hereby clarified that, reverse engineering includes, without limitation, decompiling,
            disassembly, sniffing, peeling any electronic components, modifying, changing, altering, deleting or
            adding to the IoT Devices or the intellectual property rights associated with such IoT Devices, or
            otherwise isolating, separating or deriving the source code of the IoT Devices or the intellectual property
            rights associated with such IoT Devices, or attempt to undertake the aforementioned actions.
          </li>
          <li>
            The trademarks, logos, and service marks displayed on the Platform (&quot;Marks&quot;) are the property of
            Gordian, except any trademark, logos and service marks of third parties available on the Platform. You
            are not permitted to use the Marks without the prior consent of Gordian or such third party as may be
            applicable.
          </li>
          <h2>Disclaimer of Warranties &amp; Liabilities</h2>
          <li>
            You expressly understand and agree that, to the maximum extent permitted by applicable law:
          </li>
          <ol type="a">
            <li>
              The Platform and Gordian Property, Gordian Services are provided by Gordian on an &quot;as is&quot; basis
              without warranty of any kind, express, implied, statutory or otherwise, including the implied
              warranties of title, non-infringement, merchantability or fitness for a particular purpose. Without
              limiting the foregoing, Gordian makes no warranty that (i) the Platform, Gordian Services will
              meet Your requirements or Your use of the Platform will be uninterrupted, timely, secure or
              error-free; (ii) the quality of the Platform will meet Your expectations; or (iii) any errors or
              defects in the Platform will be corrected. No advice or information, whether oral or written,
              obtained by You from Gordian shall create any warranty not expressly stated in the Terms of Use.

            </li>
            <li>
              Gordian will have no liability related to any User content arising under intellectual property
              rights, libel, privacy, publicity, obscenity or other laws. Gordian also disclaims all liability with
              respect to the misuse, loss, modification or unavailability of any User content.
            </li>
            <li>
              Gordian will not be liable for any loss that You may incur as a consequence of unauthorized use of
              Your Account or Account information in connection with the Platform either with or without Your
              knowledge.
            </li>
            <li>
              Gordian shall not be responsible for the delay or inability to use the Platform, Gordian Services or
              related functionalities, the provision of or failure to provide functionalities, or for any
              information, software, functionalities and related graphics obtained through the Platform, or
              otherwise arising out of the use of the Platform, whether based on contract, tort, negligence, strict
              liability or otherwise. Further, Gordian shall not be held responsible for non-availability of the
              Platform during periodic maintenance operations or any unplanned suspension of access to the
              Platform that may occur due to technical reasons or for any reason beyond Gordian&#39;s control. You
              understand and agree that any material or data downloaded or otherwise obtained through the
              Platform is done entirely at Your own discretion and risk, and that You will be solely responsible
              for any damage to Your computer systems or loss of data that results from the download of such
              material or data.
            </li>

          </ol>
          <h2>Indemnification and Limitation of Liability</h2>
          <li>
            You agree to indemnify, defend and hold harmless Gordian and its affiliates including but not limited to
            its officers, directors, consultants, agents and employees (&quot;Indemnitees&quot;) from and against any and all
            losses, liabilities, claims, damages, demands, costs and expenses (including legal fees and disbursements
            in connection therewith and interest chargeable thereon) asserted against or incurred by the
            Indemnitees that arise out of, result from, or may be payable by virtue of, any breach or non-
            performance of any obligation, covenant, representation or warranty by You pursuant to these Terms of
            Use. Further, You agree to hold the Indemnitees harmless against any claims made by any third party
            due to, or arising out of, or in connection with, Your use of the Platform, Gordian Services, any
            misrepresentation with respect to the data or information provided by You in relation to the Account,
            Your violation of the Terms of Use, or Your violation of any rights of another, including any intellectual
            property rights.
          </li>
          <li>
            In no event shall the Indemnitees, be liable to You or any third party for any special, incidental, indirect,
            consequential or punitive damages whatsoever, arising out of or in connection with Your use of or access
            to the Platform or Gordian Property on the Platform.
          </li>
          <li>
            Your indemnification obligation under the Terms of Use will survive the termination of Your Account or
            use of the Platform or Gordian Services.
          </li>
          <li>
            You agree and acknowledge that it is your responsibility to procure and maintain insurance to protect
            Your packages during the performance of the Gordian Services. You free Gordian from any and all
            responsibility for any incident or misfortune, in respect of Pick Up &amp; Drop Off Services, caused to you
            due to theft, damage, infidelity of the Delivery Partners, or otherwise. You further agree and
            acknowledge that Gordian shall not be liable to Your insurer for any loss or damage cause to Your
            packages in respect of Pick Up &amp; Drop Off Services, caused to you due to theft, damage, infidelity of the
            Delivery Partners, or otherwise.
          </li>
          <li>
            Subject to applicable laws and except as otherwise provided in the “Insurance” section above, in no
            event will Gordian or its employees’ aggregate liability, arising from or related to the Gordian Services or
            the use of the Platform exceed INR 100 for any and all causes of actions brought by You or on behalf of
            You.
          </li>
          <li>
            The Platform and the Gordian Services are only available to Users located in India. Users shall not access
            or use the Platform from any other jurisdiction except for India. If a User accesses or uses the Platform
            from any other jurisdiction except for India, the User shall be liable to comply with all applicable laws
            and Gordian shall not be liable for the same, whatsoever.
          </li>
          <h2>Violation of the Terms of Use</h2>
          <li>
            You agree that any violation by You of these Terms of Use will constitute an unlawful and unfair business
            practice, and will cause irreparable harm to the Company, as the case may be, for which monetary
            damages would be inadequate, and You consent to the Company obtaining any injunctive or equitable
            relief that they deem necessary or appropriate in such circumstances. These remedies are in addition to
            any other remedies that the Company may have at law or in equity. If Company takes any legal action
            against You as a result of Your violation of these Terms of Use, they will be entitled to recover from You,
            and You agree to pay, all reasonable attorneys’ fees and costs of such action, in addition to any other
            relief that may be granted.
          </li>
          <h2>Additional Terms</h2>
          <li>
            We may also require You to follow additional rules, guidelines or other conditions in order to participate
            in certain promotions or activities available through the Platform. These additional terms shall form a
            part of this Terms of Use, and You agree to comply with them when You participate in those promotions,
            or otherwise engage in activities governed by such additional terms.
          </li>
          <h2>Link to Third Parties</h2>
          <li>
            The Platform may contain links to other sites owned by third parties (i.e., advertisers, affiliate partners,
            strategic partners, or others). Gordian shall not be responsible for examining or evaluating such third-
            party websites, and Gordian does not warrant the products or offerings of, any of these businesses or
            individuals, or the accuracy of the content of such third-party websites. Gordian does not assume any
            responsibility or liability for the actions, product, and content of any such third-party websites. Before
            You use/access any such third-party websites, You should review the applicable terms of use and
            policies for such third party websites. If You decide to access any such linked third party website, You do
            so at Your own risk.
          </li>
          <h2>Term and Termination</h2>
          <li>
            The Terms of Use will continue to apply until terminated by either You or Gordian as set forth below. If
            You object to the Terms of Use or are dissatisfied with the Platform, Gordian Services, Your only
            recourse is to close Your Account on the Platform; and/or (ii) stop accessing the Platform. Gordian will
            make Your account dormant upon receipt of request in writing.
          </li>
          <li>
            Gordian may, in its sole discretion, bar your use of the Gordian Services at any time, for any or no reason.
            Even after your account with Gordian is disabled, dormant or made inactive, the terms agreed by You at
            the time of registration will remain in effect. This termination shall be effective only once You have
            cleared all Your dues that You are liable to pay as per the provisions of this Terms of Use.
          </li>
          <li>
            The Company may delist You or block Your future access to the Platform or suspend or terminate Your
            Account if it believes, in its sole and absolute discretion that You have infringed, breached, violated,
            abused, or unethically manipulated or exploited any term of these Terms of Use or anyway otherwise
            acted unethically.
          </li>
          <li>
            Notwithstanding anything in this clause, these Terms of Use will survive indefinitely unless and until
            Gordian chooses to terminate them.
          </li>
          <li>
            You hereby agree and acknowledge, upon termination, Gordian shall have the right to retain all
            information pertaining to the transactions initiated by You on the Platform.
          </li>
          <h2>Governing Law</h2>
          <li>
            This Terms of Use shall be governed by and constructed in accordance with the laws of India without
            reference to conflict of laws principles and disputes arising in relation hereto shall be subject to the
            exclusive jurisdiction of courts, tribunals, fora, applicable authorities at Bangalore.
          </li>
          <h2>Report Abuse</h2>
          <li>
            In the event You come across any abuse or violation of these Terms of Use or if You become aware of any
            objectionable content on the Platform, please report the same to Gordian. In case You have any queries
            with respect to the Terms or the Gordian Services, please write to Us via mail to our registered address.
          </li>
          <h2>Communications</h2>
          <li>
            You hereby expressly agree to receive communications by way of SMSs and/or e-mails and/or Whatsapp
            from Gordian, or other third parties. You can unsubscribe/ opt-out from receiving communications
            through SMS, Whatsapp and email anytime by contacting us for the same. However, You may still receive
            communications from Your end with respect to Your use of the Gordian Service.
          </li>
          <h2>General</h2>
          <li>
            Amendments: Gordian reserves the unconditional right to modify or amend this Terms of Use without
            any requirement to notify You of the same. You can determine when this Terms of Use was last modified
            by referring to the &quot;Last Updated&quot; legend above. It shall be Your responsibility to check this Terms of
            Use periodically for changes. Your acceptance of the amended Terms of Use shall signify Your consent to
            such changes and agreement to be legally bound by the same.
          </li>
          <li>
            Notice: All notices from Gordian will be served by email to Your registered email address or by general
            notification on the Platform.
          </li>
          <li>
            Assignment: You cannot assign or otherwise transfer the Terms of Use, or any rights granted hereunder
            to any third party. Gordian’s rights under the Terms of Use are freely transferable by Gordian to any
            third party without the requirement of informing You or seeking Your consent.
          </li>
          <li>
            Severability: If, for any reason, a court of competent jurisdiction finds any provision of the Terms of Use,
            or any portion thereof, to be unenforceable, that provision shall be enforced to the maximum extent
            permissible so as to give effect to the intent of the parties as reflected by that provision, and the
            remainder of the Terms of Use shall continue in full force and effect.
          </li>
          <li>
            Waiver: Any failure by Gordian to enforce or exercise any provision of the Terms of Use, or any related
            right, shall not constitute a waiver by Gordian of that provision or right.
          </li>
          <li>
            Integration: These Terms of Use together with Gordian’s Privacy Policy and any other legal notices,
            communications published by Gordian on its Platform, and any other agreements executed between You
            and Gordian shall constitute the entire agreement between you and Gordian concerning its Platform,
            Gordian Services and governs Your use of the Platform and Gordian Service, superseding any prior
            agreements between You and Gordian with respect to the Platform and Gordian Service
          </li>
          <li>
            IP Infringement If You believe the Platform violates Your intellectual property, You must promptly notify
            Gordian in writing. These notifications should only be submitted by the owner of the intellectual
            property or an agent duly authorized to act on his/her behalf. However, any false claim by You may
            result in the termination of Your access to the Platform. You are required to provide the following details
            in Your notice. You consent to the collection, storage, disclosure or transfer of any personal data or
            sensitive personal data (including the data collected below) provided by You to Gordian pursuant to the
            below and in relation to the use of the platform/ app:
          </li>
          <ol type="a">
            <li>
              the intellectual property that You believe is being infringed;
            </li>
            <li>
              the item that You think is infringing and include sufficient information about where the material is
              located on the Platform;
            </li>
            <li>
              a statement that You believe in good faith that the item You have identified as infringing is not
              authorized by the intellectual property owner, its agent, or the law to be used in connection with
              the Platform;
            </li>
            <li>
              Your contact details, such as Your address, telephone number, and/or email;
            </li>
            <li>
              a statement that the information You provided in Your notice is accurate, and that You are the
              intellectual property owner or an agent authorized to act on behalf of the intellectual property
              owner whose intellectual property is being infringed; and
            </li>
            <li>
              Your physical or electronic signature.
            </li>
          </ol>

        </ol>
      </Container>
    </Fragment>
  );
}

export default Terms;