import React from 'react';
import { Row, Col, Container } from "reactstrap";
import Card from 'react-bootstrap/Card';
import { Link } from "react-router-dom";
import Slider from "./Slider";
import BackIcon from "./../assets/images/back.png";
import "./styles.signup.css";
import "./../Globals/Fonts.css";

function ContactUs(props) {
    return (
        <Row style={{ marginBottom: 0, marginTop: 0, backgroundColor: '#F5F6FA' }}>
            <Col lg={7} md={7} sm={7} >
                <Slider />
            </Col>

            <Col lg={5} md={5} sm={5}>
                <Row style={{ marginTop: "15px", marginLeft: "5px", }}>
                    {/* <Link to="/Home" style={{ textDecoration: 'none' }} > */}
                    <Card style={{ width: '50px', height: "50px", borderRadius: "30px", backgroundColor: "#F5F6FA", marginLeft: "12px", border: "none", boxShadow: "5px 5px 11px 5px #0000000D" }} onClick={() => props.history.goBack()}>
                        <Card.Body>
                            <img style={{ height: "30px", width: "27px", marginTop: "-15px", marginLeft: "-11px" }} src={BackIcon} alt="backicon" />
                        </Card.Body>
                    </Card>
                    {/* </Link> */}
                </Row>
                <Container style={{ marginTop: "40px" }}>
                    <h1 className="contactus-heading" style={{ fontSize: "1.5rem", fontFamily: "Manrope-Bold", marginLeft: "60px", marginBottom: "20px", color: "#000000" }}>Contact Us</h1>
                    <Row style={{ justifyContent: "center", alignItem: "center" }}>
                        <Link style={{ textDecoration: 'none' }} >
                            <div className="Contactus-icon">
                                <div style={{ flexDirection: "row" }}>
                                    <h1 style={{ fontSize: "20px", fontFamily: "Manrope-Bold", textAlign: 'center', color: "#ffffff", paddingTop: "45px" }}>Phone Number</h1>
                                    <h1 style={{ fontSize: "20px", fontFamily: "Manrope-Bold", textAlign: 'center', color: "#ffffff" }}>+91 9980094933</h1>
                                </div>
                            </div>
                        </Link>
                        {/* <Link style={{ textDecoration: 'none' }}> */}
                        <div className="Contactus-icon">
                            <div style={{ flexDirection: "row" }}>
                                <a href='mailto:support@gordian.in' target="blank" style={{ textDecoration: "none" }}>
                                    <h1 style={{ fontSize: "20px", fontFamily: "Manrope-ExtraBold", textAlign: 'center', color: "#ffffff", paddingTop: "45px" }}>Email</h1>
                                    <h1 style={{ fontSize: "20px", fontFamily: "Manrope-ExtraBold", textAlign: 'center', color: "#ffffff" }}>support@gordian.in</h1>
                                </a>
                            </div>
                        </div>
                        {/* </Link> */}

                    </Row>
                </Container>
            </Col>
        </Row>
    )
}

export default ContactUs;