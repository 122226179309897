import React, { Fragment, useState } from "react";
import { Row, Col, Container } from "reactstrap";
import { Link, useHistory } from "react-router-dom";
import "./styles.signuplogin.css";
import Variables from "../Globals/variables";
import BackIcon from "./../assets/images/back.png";
import ProgressBar from "../Components/ProgressBar";
import Card from 'react-bootstrap/Card'
import "./../Globals/Fonts.css"

const BaseURL = Variables.baseUrl;

const MobileOtpLogin = () => {
    const initError = {
        phonenumberError: "",
    };

    const [state, setState] = useState({
        phonenumber: "",
        btnValue: "Send OTP"

    });
    const history = useHistory();


    async function signinotp() {
        const item = { phonenumber: state.phonenumber }
        setState({
            ...state,
            btnValue: "Sending OTP..."
        })

        let result = await fetch(`${BaseURL}/users/otp-sender-login`, {
            method: 'POST',
            body: JSON.stringify(item),
            headers: {
                "Content-Type": 'application/json',
                "Accept": 'application/json'
            }
        })
        result = await result;
        if (result.status === 200 || result.status === 201) {
            result = await result.json();
            localStorage.setItem("contact", result.phonenumber);
            history.push("/mobilesigninotp1", {
                phonenumber: state.phonenumber
            })
            return result;
        } else if (result.status === 401) {
            //eslint-disable-next-line
            alert("Phone Number is not registered," + "\n" + " Please sign up.");
            setState({
                ...state,
                btnValue: "Send OTP"
            })
        }

    }

    var [error] = useState(initError);

    const isValid = () => {
        error = initError;

        if (state.phonenumber.length !== 10) {
            error.phonenumberError = "Invalid Phone Number";
        }

        if (
            error.phonenumberError.length > 0
        ) {
            return false;
        } else {
            return true;
        }
    };

    const ValidSubmission = () => {
        if (isValid()) {
            return (
                <button
                    type="button"
                    onClick={signinotp}
                    className="btn btn-dark rounded-pill button1 mb-3"
                >
                    {state.btnValue}
                </button>
            );
        } else {
            return (
                <button
                    disabled
                    type="button"
                    className="btn btn-dark rounded-pill button1 mb-3"
                >
                    {state.btnValue}
                </button>
            );
        }
    };

    return (
        <Fragment>
            <Container>
                <Row style={{ marginTop: "15px", marginLeft: "5px", justifyContent: "space-between", alignItem: "space-between", marginRight: "5px" }}>
                    <Link to="/mobilesignin" style={{ textDecoration: 'none' }} >
                        <Card style={{ width: '50px', height: "50px", borderRadius: "30px", backgroundColor: "#F5F6FA", border: "none", boxShadow: "5px 5px 11px 5px #0000000D" }}>
                            <Card.Body>
                                <img style={{ height: "30px", width: "27px", marginTop: "-15px", marginLeft: "-11px" }} src={BackIcon} alt="backicon" />
                            </Card.Body>
                        </Card>
                    </Link>
                    <div style={{ marginTop: "12px" }}>
                        <ProgressBar width="50%" steps="Step 1/2" />
                    </div>
                </Row>
                <div style={{ marginTop: "30px", marginLeft: "20px" }}>
                    <h1 style={{ fontSize: "32px", fontFamily: "Manrope-ExtraBold", color: "#000000", letterSpacing: "-1px" }} >Sign In with</h1>
                    <h1 style={{ fontSize: "32px", fontFamily: "Manrope-ExtraBold", color: "#000000", letterSpacing: "-1px" }}>Mobile Number</h1>
                </div>

                <Row style={{ marginTop: "1px" }}>
                    <Col>
                        <label style={{ marginTop: "10px", fontSize: "16px", fontFamily: "Manrope-Bold", marginLeft: "15px" }}>Enter Mobile Number</label>
                        <input
                            type="number"
                            className="field"
                            value={state.phonenumber}
                            onChange={(e) => setState({ ...state, phonenumber: e.target.value })}
                            required
                        />
                        <br />
                    </Col>
                </Row>

                <Row style={{ justifyContent: "center", alignItem: "center" }}>{ValidSubmission()}</Row>

                <div style={{ marginTop: "10px" }}>
                    <h1 style={{ fontSize: "12px", fontFamily: "Manrope-SemiBold", textAlign: "center", color: "#92979D" }}>Do not have an account?</h1>
                    <Link to="mobilesignupas">
                        <h1 style={{ fontSize: "17px", fontFamily: "Manrope-Bold", textAlign: "center", color: "#000000" }}>Sign Up</h1>
                    </Link>
                </div>

                <h1 style={{ color: "#92979D", fontSize: "12px", fontFamily: "Manrope-SemiBold", textAlign: "center", marginTop: "180px" }}>For any difficulties</h1>
                <Link to="/ContactUs">
                    <h1 style={{ fontFamily: "Manrope-Bold", color: "#000000", fontSize: "15px", textAlign: "center" }}>Contact Us</h1>
                </Link>

            </Container>
        </Fragment>
    );
};

export default MobileOtpLogin;
