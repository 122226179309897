import React, { useState, useEffect } from "react";
import { Row, Col, Container } from "reactstrap";
import TrackSidebar from "./TrackSiderbar";

import "./TrackOrder.css";
import Variables from "./../Globals/variables";
import Logo from "./../assets/images/Logo1.png";
import { Link } from "react-router-dom";
import Sidebar from "./../Components/Sidebar/sidebar";
import "./../Globals/Fonts.css";
import 'react-confirm-alert/src/react-confirm-alert.css';
import Loader from "react-loader-spinner";
import Card from 'react-bootstrap/Card';
import BackIcon from "./../assets/images/back.png";

const BaseURL = Variables.baseUrl

function TrackOrder(props) {

    const name = localStorage.getItem("name");
    const new_name = name.replace(/"/g, "");
    // const orderId = localStorage.getItem("orderId");

    const initState = {
        // orderId: orderId,
        dropTrackLink: "",
        pickUpTrackLink: "",
        btnValue: 'Track Your Pickup',
        btnValue1: 'Track Your Drop',
        message: "",
        loading: true

    }

    const [state, setState] = useState(initState)

    // Fetching the orders from the backend when component is mounted.
    useEffect(() => {
        getOrderDetails();
    },
        // eslint-disable-next-line 
        []);

    async function getOrderDetails() {
        const orderId = localStorage.getItem("orderId");
        let results = await fetch(`${BaseURL}/getOrderDetails?orderId=${orderId}`, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                Accept: 'application/json'
            }
        })
        results = await results;
        if (results.status === 200 || results.status === 201) {
            results = await results.json();
            setState({
                dropTrackLink: results.data.dropTrackLink,
                pickUpTrackLink: results.data.pickUpTrackLink,
                btnValue1: "Track Your Drop",
                btnValue: "Track Your Pickup",
                loading: false
            })
            return results;
        } else if (results.status === 404) {
            alert("OrderId is Invalid");
            setState({
                btnValue1: "Track Your Drop",
                btnValue: "Track Your Pickup",
                loading: false,
                message: "OrderId is Invalid",
            })
        }
        else {
            setState({
                ...state,
                message: results.message,
                loading: false,
                btnValue1: "Track Your Drop",
                btnValue: "Track Your Pickup",
            })

        }

    }

    const GetDropTrack = () => {
        if (state.dropTrackLink) {
            window.open(state.dropTrackLink, "_blank");
        } 
        // else {
        //     if (state.message.length === 0)
        //         alert("No order to track.");
        //     else
        //         alert(state.message);
        // }
    };

    const GetPickupTrack = () => {
        if (state.pickUpTrackLink) {
            window.open(state.pickUpTrackLink, "_blank");
        } 
        // else {
        //     if (state.message.length === 0)
        //         alert("No order to track.");
        //     else
        //         alert(state.message);
        // }
    };

    // async function getPickupLink() {
    //     setState({
    //         ...state,
    //         btnValue: "Tracking..."
    //     })
    //     // const orderId = props.location.state.orderId
    //     let results = await fetch(`${BaseURL}/getOrderDetails?orderId=${state.orderId}`, {
    //         method: 'GET',
    //         headers: {
    //             'Content-Type': 'application/json',
    //             Accept: 'application/json'
    //         }
    //     })
    //     results = await results;
    //     if (results.status === 200 || results.status === 201) {
    //         results = await results.json();
    //         setState({
    //             ...state,
    //             dropTrackLink: results.data.dropTrackLink,
    //             pickUpTrackLink: results.data.pickUpTrackLink
    //         })
    //         // alert("Click on the above links to track your order.")
    //         window.open(results.data.pickUpTrackLink, "_blank")
    //         setState({
    //             ...state,
    //             btnValue1: "Track Your Drop Address",
    //             btnValue: 'Track Your Pickup Address',
    //         })
    //         return results;
    //     }
    // }

    // async function getDropLink() {
    //     const token = localStorage.getItem("token")
    //     const new_token = token.replace(/"/g, '')
    //     setState({
    //         ...state,
    //         btnValue1: "Tracking..."
    //     })

    //     let result = await fetch(`${BaseURL}/orders?status[]=pending&status=ongoing&limit=1`, {
    //         method: 'GET',
    //         headers: {
    //             'Content-Type': 'application/json',
    //             Authorization: `bearer ${new_token}`
    //         }
    //     })
    //     result = await result;
    //     if (result.status === 200 || result.status === 201) {
    //         result = await result.json();
    //         if (result.data.length === 0) {
    //             alert('There is no ongoing order to track.')
    //             setState({
    //                 ...state,
    //                 btnValue1: "Track Your Drop Address",
    //                 btnValue: "Track Your Pickup Address"
    //             })
    //         } else {
    //             const orderId = await result.data[0].orderId
    //             let results = await fetch(`${BaseURL}/getOrderDetails?orderId=${orderId}`, {
    //                 method: 'GET',
    //                 headers: {
    //                     'Content-Type': 'application/json',
    //                     Accept: 'application/json'
    //                 }
    //             })
    //             results = await results;
    //             if (results.status === 200 || results.status === 201) {
    //                 results = await results.json();
    //                 setState({
    //                     ...state,
    //                     dropTrackLink: results.data.dropTrackLink,
    //                     pickUpTrackLink: results.data.pickUpTrackLink
    //                 })
    //                 // alert("Click on the above links to track your order.")
    //                 window.open(results.data.dropTrackLink, "_blank")
    //                 setState({
    //                     ...state,
    //                     btnValue1: "Track Your Drop Address",
    //                     btnValue: "Track Your Pickup Address",
    //                 })
    //                 return results;

    //             } else if (results.status === 404) {
    //                 results = await results.json();
    //                 alert(results.message);
    //                 setState({
    //                     ...state,
    //                     btnValue1: "Track Your Drop Address",
    //                     btnValue: "Track Your Pickup Address",
    //                 })
    //                 return results;
    //             }
    //         }
    //         return result;
    //     } else if (result.status === 401) {
    //         const refreshToken = localStorage.getItem('refreshToken');
    //         const data = {
    //             refreshToken
    //         };

    //         var response = await fetch(`${BaseURL}/users/refresh-token`, {
    //             method: 'POST',
    //             body: JSON.stringify(data),
    //             headers: {
    //                 "Content-Type": "application/json"
    //             }
    //         })
    //         response = await response;
    //         if (response.status === 200 || response.status === 201) {
    //             response = await response.json();
    //             localStorage.setItem('refreshToken', response.refreshToken)
    //             localStorage.setItem('token', response.jwtToken)

    //             result = await fetch(`${BaseURL}/orders?status[]=pending&status=ongoing&limit=1`, {
    //                 method: 'GET',
    //                 headers: {
    //                     'Content-Type': 'application/json',
    //                     Authorization: `bearer ${response.jwtToken}`
    //                 }
    //             })
    //             result = await result;
    //             if (result.status === 200 || result.status === 201) {
    //                 result = await result.json();
    //                 if (result.data.length === 0) {
    //                     alert('There is no ongoing order to track.')
    //                     setState({
    //                         ...state,
    //                         btnValue1: "Track Your Drop Address",
    //                         btnValue: "Track Your Pickup Address",
    //                     })
    //                 } else {
    //                     const orderId = await result.data[0].orderId
    //                     let results = await fetch(`${BaseURL}/getOrderDetails?orderId=${orderId}`, {
    //                         method: 'GET',
    //                         headers: {
    //                             'Content-Type': 'application/json',
    //                             Accept: 'application/json'
    //                         }
    //                     })
    //                     results = await results;
    //                     if (results.status === 200 || results.status === 201) {
    //                         results = await results.json();
    //                         setState({
    //                             ...state,
    //                             dropTrackLink: results.data.dropTrackLink,
    //                             pickUpTrackLink: results.data.pickUpTrackLink
    //                         })
    //                         // alert("Click on the above links to track your order.")
    //                         window.open(results.data.dropTrackLink, "_blank")
    //                         setState({
    //                             ...state,
    //                             btnValue1: "Track Your Drop Address",
    //                             btnValue: 'Track Your Pickup Address',
    //                         })
    //                         return results;
    //                     } else if (results.status === 404) {
    //                         results = await results.json();
    //                         alert(results.message);
    //                         setState({
    //                             ...state,
    //                             btnValue1: "Track Your Drop Address",
    //                             btnValue: "Track Your Pickup Address",
    //                         })
    //                         return results;
    //                     }
    //                     return result;

    //                 }

    //             }
    //             return response;
    //         } else {
    //             alert("Session expired. Please login to continue.");
    //             localStorage.clear("All");
    //             history.push("/Home")
    //         }
    //     } else if (result.status === 404) {
    //         result = await result.json();
    //         alert(result.message);
    //         setState({
    //             ...state,
    //             btnValue1: "Track Your Drop Address",
    //             btnValue: "Track Your Pickup Address",
    //         })
    //         return result;

    //     }

    // }

    // async function getPickupLink() {
    //     const token = localStorage.getItem("token")
    //     const new_token = token.replace(/"/g, '')
    //     setState({
    //         ...state,
    //         btnValue: "Tracking..."
    //     })

    //     let result = await fetch(`${BaseURL}/orders?status[]=pending&status=ongoing&limit=1`, {
    //         method: 'GET',
    //         headers: {
    //             'Content-Type': 'application/json',
    //             Authorization: `bearer ${new_token}`
    //         }
    //     })
    //     result = await result;
    //     if (result.status === 200 || result.status === 201) {
    //         result = await result.json();
    //         if (result.data.length === 0) {
    //             alert('There is no ongoing order to track.')
    //             setState({
    //                 ...state,
    //                 btnValue1: "Track Your Drop Address",
    //                 btnValue: "Track Your Pickup Address"
    //             })
    //         } else {
    //             const orderId = await result.data[0].orderId
    //             let results = await fetch(`${BaseURL}/getOrderDetails?orderId=${orderId}`, {
    //                 method: 'GET',
    //                 headers: {
    //                     'Content-Type': 'application/json',
    //                     Accept: 'application/json'
    //                 }
    //             })
    //             results = await results;
    //             if (results.status === 200 || results.status === 201) {
    //                 results = await results.json();
    //                 setState({
    //                     ...state,
    //                     dropTrackLink: results.data.dropTrackLink,
    //                     pickUpTrackLink: results.data.pickUpTrackLink
    //                 })
    //                 window.open(results.data.pickUpTrackLink, "_blank")
    //                 setState({
    //                     ...state,
    //                     btnValue1: "Track Your Drop Address",
    //                     btnValue: "Track Your Pickup Address",
    //                 })
    //                 return results;

    //             } else if (results.status === 404) {
    //                 results = await results.json();
    //                 alert(results.message);
    //                 setState({
    //                     ...state,
    //                     btnValue1: "Track Your Drop Address",
    //                     btnValue: "Track Your Pickup Address",
    //                 })
    //                 return results;
    //             }
    //         }
    //         return result;
    //     } else if (result.status === 401) {
    //         const refreshToken = localStorage.getItem('refreshToken');
    //         const data = {
    //             refreshToken
    //         };

    //         var response = await fetch(`${BaseURL}/users/refresh-token`, {
    //             method: 'POST',
    //             body: JSON.stringify(data),
    //             headers: {
    //                 "Content-Type": "application/json"
    //             }
    //         })
    //         response = await response;
    //         if (response.status === 200 || response.status === 201) {
    //             response = await response.json();
    //             localStorage.setItem('refreshToken', response.refreshToken)
    //             localStorage.setItem('token', response.jwtToken)

    //             result = await fetch(`${BaseURL}/orders?status[]=pending&status=ongoing&limit=1`, {
    //                 method: 'GET',
    //                 headers: {
    //                     'Content-Type': 'application/json',
    //                     Authorization: `bearer ${response.jwtToken}`
    //                 }
    //             })
    //             result = await result;
    //             if (result.status === 200 || result.status === 201) {
    //                 result = await result.json();
    //                 if (result.data.length === 0) {
    //                     alert('There is no ongoing order to track.')
    //                     setState({
    //                         ...state,
    //                         btnValue1: "Track Your Drop Address",
    //                         btnValue: "Track Your Pickup Address",
    //                     })
    //                 } else {
    //                     const orderId = await result.data[0].orderId
    //                     let results = await fetch(`${BaseURL}/getOrderDetails?orderId=${orderId}`, {
    //                         method: 'GET',
    //                         headers: {
    //                             'Content-Type': 'application/json',
    //                             Accept: 'application/json'
    //                         }
    //                     })
    //                     results = await results;
    //                     if (results.status === 200 || results.status === 201) {
    //                         results = await results.json();
    //                         setState({
    //                             ...state,
    //                             dropTrackLink: results.data.dropTrackLink,
    //                             pickUpTrackLink: results.data.pickUpTrackLink
    //                         })
    //                         // alert("Click on the above links to track your order.")
    //                         window.open(results.data.pickUpTrackLink, "_blank")
    //                         setState({
    //                             ...state,
    //                             btnValue1: "Track Your Drop Address",
    //                             btnValue: 'Track Your Pickup Address',
    //                         })
    //                         return results;
    //                     } else if (results.status === 404) {
    //                         results = await results.json();
    //                         alert(results.message);
    //                         setState({
    //                             ...state,
    //                             btnValue1: "Track Your Drop Address",
    //                             btnValue: "Track Your Pickup Address",
    //                         })
    //                         return results;
    //                     }
    //                     return result;
    //                 }

    //             }
    //             return response;
    //         } else {
    //             alert("Session expired. Please login to continue.");
    //             localStorage.clear("All");
    //             history.push("/Home")
    //         }
    //     } else if (result.status === 404) {
    //         result = await result.json();
    //         alert(result.message);
    //         setState({
    //             ...state,
    //             btnValue1: "Track Your Drop Address",
    //             btnValue: "Track Your Pickup Address",
    //         })
    //         return result;
    //     }
    // }

    return (
        <Row className="m-0">
            <Col
                lg={2} md={2} sm={2}
                className="text-center pt-5"
                style={{ backgroundColor: "white", height: "100vh" }}
            >
                <Row className="gordian-logo" style={{ justifyContent: "center", alignItem: "center" }}>
                    <img src={Logo} alt="Logo" className="logo" />
                </Row>
                <h1 style={{ fontSize: "1.56rem", textAlign: "center", fontFamily: "Manrope-Bold" }}>Gordian</h1>
                <Sidebar />
            </Col>
            <Col lg={7} md={7} sm={7} >
                <h1 style={{ fontSize: "2rem", fontFamily: "Manrope-Bold", marginTop: "20px", color: "#000000" }}>Tracking</h1>
                <TrackSidebar />

            </Col>
            <Col lg={3} md={3} sm={3} style={{ backgroundColor: "white" }}>
                <Container>
                    <h1 className="name" style={{ fontSize: "20px", fontWeight: "600", marginTop: "1.25rem", fontFamily: "Manrope-SemiBold" }}>Welcome, {new_name} </h1>
                    <hr />
                    <Row style={{ marginTop: "15px", justifyContent: "space-between", alignItem: "space-between", marginRight: "5px" }} onClick={() => props.history.goBack()}>
                        <Card style={{ width: '50px', height: "50px", borderRadius: "30px", backgroundColor: "#F5F6FA", border: "none", boxShadow: "5px 5px 11px 5px #0000000D" }}>
                            <Card.Body>
                                <img style={{ height: "30px", width: "27px", marginTop: "-15px", marginLeft: "-11px" }} src={BackIcon} alt="backicon" />
                            </Card.Body>
                        </Card>
                    </Row>
                    {state.loading ? (
                        <Row style={{ justifyContent: "center", alignItem: "center", marginTop: "100px" }}>
                            <Loader
                                type="Oval"
                                color="#000000"
                                height={40}
                                width={40}
                            />
                        </Row>
                    ) : (

                            <Row style={{ marginTop: "5px", justifyContent: "center", alignItem: "center" }}>
                                <Link style={{ textDecoration: 'none' }} >
                                    <div className="TrackPickup" onClick={GetPickupTrack}>
                                        <div style={{ flexDirection: "row" }}>
                                            <h1 className="pickup-text" style={{ fontSize: "1.063rem", fontFamily: "Manrope-ExtraBold", textAlign: 'center', color: "#ffffff", paddingTop: "60px" }}>{state.btnValue}</h1>
                                        </div>
                                    </div>
                                </Link>
                                <Link style={{ textDecoration: 'none' }}>
                                    <div className="TrackDrop" onClick={GetDropTrack}>
                                        <div style={{ flexDirection: "row" }}>
                                            <h1 className="drop-text" style={{ fontSize: "1.063rem", fontFamily: "Manrope-ExtraBold", textAlign: 'center', color: "#ffffff", paddingTop: "60px" }}>{state.btnValue1}</h1>
                                        </div>
                                    </div>
                                </Link>
                            </Row>
                        )}

                    <Row style={{ justifyContent: "center", alignItem: "center" }}>
                        <h1 style={{ color: "#92979D", fontSize: "0.75rem", fontFamily: "Manrope-SemiBold", position: "fixed", bottom: "40px" }}>For any difficulties</h1>
                        <Link to="/Contactus2">
                            <h1 style={{ fontFamily: "Manrope-Bold", color: "#000000", fontSize: "0.938rem", position: "fixed", bottom: "15px", marginLeft: "-40px" }}>Contact Us</h1>
                        </Link>
                    </Row>

                </Container>
            </Col>
        </Row>
    )
}

export default TrackOrder
