import React from "react";
import EmailIcon from "../assets/images/emailWhite.png";
import MobileIcon from "../assets/images/mobileWhite.png";
import BackIcon from "./../assets/images/back.png";
import ChatIcon from "./../assets/images/chat.png";
import { Container, Row } from "reactstrap";
import { Link } from "react-router-dom";
import "./styles.signuplogin.css";
import "./../Globals/Fonts.css"
import Card from 'react-bootstrap/Card'

const MobileBusinessSignup1 = () => {
    return (
        <Container>
            <Row style={{ marginTop: "15px", marginLeft: "5px", justifyContent: "space-between", alignItem: "space-between", marginRight: "5px" }}>
                <Link to="/mobilesignupas" style={{ textDecoration: 'none' }} >
                    <Card style={{ width: '50px', height: "50px", borderRadius: "30px", backgroundColor: "#F5F6FA", border: "none", boxShadow: "5px 5px 11px 5px #0000000D" }}>
                        <Card.Body>
                            <img style={{ height: "30px", width: "27px", marginTop: "-15px", marginLeft: "-11px" }} src={BackIcon} alt="backicon" />
                        </Card.Body>
                    </Card>
                </Link>

                <Link to="/ContactUs">
                    <Card style={{ width: '50px', height: "50px", borderRadius: "30px", backgroundColor: "#F5F6FA", border: "none", boxShadow: "5px 5px 11px 5px #0000000D" }}>
                        <Card.Body>
                            <img style={{ height: "30px", width: "30px", marginTop: "-18px", marginLeft: "-11px" }} src={ChatIcon} alt="chaticon" />
                        </Card.Body>
                    </Card>
                </Link>
            </Row>
            <h1 style={{ marginTop: "50px", marginLeft: "20px", fontSize: "32px", fontFamily: "Manrope-ExtraBold", color: "#000000" }}>Sign Up</h1>
            <Row style={{ marginTop: "40px", justifyContent: "center", alignItem: "center" }}>
                <Link to="/MobileBusinessSignup2" style={{ textDecoration: "none" }}>
                    <Card style={{ width: '150px', height: "150px", borderRadius: "20px", backgroundColor: "#000000" }}>
                        <Card.Body>
                            <img style={{ height: "30px", width: "30px" }} src={EmailIcon} alt="emailicon" />
                            <Card.Title style={{ color: "#ffffff", fontSize: "17px", marginTop: "15px", fontFamily: "Manrope-Bold" }}>Sign up with Email Address</Card.Title>
                        </Card.Body>
                    </Card>
                </Link>

                <Link to="/MobileBusinessSignupOtp1" style={{ textDecoration: "none" }}>

                    <Card style={{ width: '150px', height: "150px", borderRadius: "20px", backgroundColor: "#000000", marginLeft: "15px" }}>
                        <Card.Body>
                            <img style={{ height: "30px", width: "30px" }} src={MobileIcon} alt="mobileicon" />
                            <Card.Title style={{ color: "#ffffff", fontSize: "17px", marginTop: "15px", fontFamily: "Manrope-Bold" }}>Sign up with Mobile Number</Card.Title>
                        </Card.Body>
                    </Card>
                </Link>

            </Row>

            {/* <Row>
                <Card style={{ width: '150px', height: "150px", borderRadius: "20px", backgroundColor: "#F27070", marginLeft: "25px" }}>
                    <Card.Body>
                        <img style={{ height: "30px", width: "30px" }} src={GoogleIcon} alt="googleicon" />
                        <Card.Title style={{ color: "#ffffff", fontSize: "17px", marginTop: "15px" }}>Sign up with Google</Card.Title>
                    </Card.Body>
                </Card>

                <Card style={{ width: '150px', height: "150px", borderRadius: "20px", backgroundColor: "#5A8FE5", marginLeft: "15px" }}>
                    <Card.Body>
                        <img style={{ height: "30px", width: "30px" }} src={FacebookIcon} alt="facebokicon" />
                        <Card.Title style={{ color: "#ffffff", fontSize: "17px", marginTop: "15px" }}>Sign up with Facebook</Card.Title>
                    </Card.Body>
                </Card>

            </Row> */}
            <div>
                <h1 style={{ textAlign: "center", color: "#92979D", fontSize: "12px", fontFamily: "Manrope-SemiBold" }}>Already have an Account?</h1>
                <Link to="/mobilesignin">
                    <h1 style={{ textAlign: "center", fontFamily: "Manrope-Bold", color: "#000000", fontSize: "17px" }}>Sign In</h1>
                </Link>
            </div>

            <Row style={{ justifyContent: "center", alignItem: "center" }}>
                <h1 style={{ color: "#92979D", fontSize: "12px", fontFamily: "Manrope-SemiBold", position: "fixed", bottom: "40px" }}>For any difficulties</h1>
                <Link to="/ContactUs">
                    <h1 style={{ fontFamily: "Manrope-Bold", color: "#000000", fontSize: "15px", position: "fixed", bottom: "15px", marginLeft: "-40px" }}>Contact Us</h1>
                </Link>
            </Row>
        </Container>
    );
};

export default MobileBusinessSignup1;
