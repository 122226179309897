import React, { useState, useEffect } from 'react';
import { Row, Col, Button, Card } from "react-bootstrap";
import Variables from "./../Globals/variables";
import Logo from "./../assets/images/Logo1.png";
import Sidebar from "./../Components/Sidebar/sidebar";
import "./../Globals/Fonts.css";
import "./OrderStatus.css";
import "react-confirm-alert/src/react-confirm-alert.css";

import axios from 'axios';

const BaseURL = Variables.baseUrl;

async function refreshToken() {
    const refreshToken = localStorage.getItem("refreshToken");
    const data = {
        refreshToken,
    };

    var response = await fetch(`${BaseURL}/users/refresh-token`, {
        method: "POST",
        body: JSON.stringify(data),
        headers: {
            "Content-Type": "application/json",
        },
    });
    response = await response;
    if (response.status === 200 || response.status === 201) {
        response = await response.json();
        localStorage.setItem("refreshToken", response.refreshToken);
        localStorage.setItem("token", response.jwtToken);
    } else {
        localStorage.clear();
        localStorage.clear("All");
        console.log("Session expired. Please login to continue.");
    }
}




function ServiceabilityToggle() {
    const [deliveryStatus, setDeliveryStatus] = useState(false);
    const [bullionStatus, setBullionStatus] = useState(false);
    const [loading, setLoading] = useState(true);

    useEffect(() => {
        fetchServiceabilityStatus('bullion', setBullionStatus)
        fetchServiceabilityStatus('delivery', setDeliveryStatus)
    }, []);

    const fetchServiceabilityStatus = async (serviceName, updateStatus) => {
        try {
            const response = await axios.get(BaseURL + '/api/v1/serviceable/' + serviceName)
            if (response.status === 401) {
                await refreshToken()
            }
            updateStatus(response.data.enabled)
            setLoading(false);
        } catch (error) {
            console.error('Error fetching serviceability status:', error);
            setLoading(false);
        }
    };

    const toggleServiceability = async (serviceName, status, updateStatus) => {
        try {
            const token = 'Bearer ' + localStorage.getItem('token')
            await axios.patch(BaseURL + '/admin/serviceability/' +
                serviceName,
                {
                    enabled: !status
                },
                {
                    headers: {
                        Authorization: token
                    }
                }
            );
            updateStatus(!status);
        } catch (error) {
            console.error('Error toggling serviceability status:', error);
        }
    };

    const handleBullionServiceUpdate = async () => {
        toggleServiceability('bullion', bullionStatus, setBullionStatus)
    }

    const handleDeliveryServiceUpdate = async () => {
        toggleServiceability('delivery', deliveryStatus, setDeliveryStatus)
    }

    return (
        <Row className="m-0">
            <Col
                lg={2}
                md={2}
                sm={2}
                className="text-center pt-5"
                style={{ backgroundColor: "white", height: "100vh" }}
            >
                <Row
                    className="gordian-logo"
                    style={{ justifyContent: "center", alignItem: "center" }}
                >
                    <img src={Logo} alt="Logo" className="logo" />
                </Row>
                <h1
                    style={{
                        fontSize: "1.56rem",
                        textAlign: "center",
                        fontFamily: "Manrope-Bold",
                    }}
                >
                    Gordian
                </h1>
                <Sidebar />
            </Col>
            <Col lg={10} md={10} sm={10} style={{ backgroundColor: "white" }}>
                <Col lg={12} md={12} sm={12}>
                    <h1
                        className="name"
                        style={{
                            fontSize: "20px",
                            fontWeight: "600",
                            marginTop: "1.25rem",
                            fontFamily: "Manrope-SemiBold",
                        }}
                    >
                        Toggle Serviceability
                    </h1>
                    <hr />
                    <div style={{ display: 'flex', justifyContent: 'space-around', flexDirection: 'row' }}>
                        <Card >
                            <Card.Body >
                                <Card.Title>Bullion Serviceability Toggle</Card.Title>
                                {loading ? (
                                    <Card.Text>Loading...</Card.Text>
                                ) : (
                                    <>
                                        <Card.Text>Bullion Service is currently: {bullionStatus ? 'Enabled' : 'Disabled'}</Card.Text>
                                        <Button variant={bullionStatus ? 'danger' : 'primary'} onClick={handleBullionServiceUpdate}>
                                            {bullionStatus ? 'Disable Service' : 'Enable Service'}
                                        </Button>
                                    </>
                                )}
                            </Card.Body>
                        </Card>
                        <Card >
                            <Card.Body>
                                <Card.Title>Delivery Serviceability Toggle</Card.Title>
                                {loading ? (
                                    <Card.Text>Loading...</Card.Text>
                                ) : (
                                    <>
                                        <Card.Text>Delivery Service is currently: {deliveryStatus ? 'Enabled' : 'Disabled'}</Card.Text>
                                        <Button variant={deliveryStatus ? 'danger' : 'primary'} onClick={handleDeliveryServiceUpdate}>
                                            {deliveryStatus ? 'Disable Service' : 'Enable Service'}
                                        </Button>
                                    </>
                                )}
                            </Card.Body>
                        </Card>
                    </div>
                </Col>
            </Col>
        </Row>
    );

}

export default ServiceabilityToggle;
