import React from 'react'
import "./../Globals/Fonts.css"
import "./TrackOrder.css"
import Logo from "./../assets/images/Logo1.png"

const TrackSidebar = () => {
    return (
        <div className="tracksidebar" style={{ height: "87vh", backgroundColor: "#000000" }}>

            <img className="logo" style={{ height: "6.25rem", width: "6.25rem", marginTop: "3.75rem", marginLeft: "5rem" }} src={Logo} alt="logo" />
            <div className="text1" style={{ marginLeft: "80px", marginRight: "115px", marginTop: "70px" }}>
                <h1 className="themetext" style={{ fontSize: "2.6rem", fontFamily: "Manrope-SemiBold", letterSpacing: "-2px", color: "#ffffff" }}>Now deliver what matters to you securely.</h1>
            </div>

            <div className="text2" style={{ marginLeft: "80px", marginTop: "10px" }}>
                <h1 className="themetext1" style={{ fontSize: "1.5rem", fontFamily: "Manrope-Bold", letterSpacing: "-1px", color: "#C0C6CD" }}>India's only secure delivery app.</h1>

            </div>

            <div className="text3" style={{ marginLeft: "80px", marginTop: "90px" }}>
                <h1 className="gordiantext" style={{ fontSize: "2.7rem", fontFamily: "Manrope-Bold", color: "#ffffff", opacity: 1 }}>Gordian</h1>
            </div>

        </div>
    )
}

export default TrackSidebar