import React, { useState } from "react";
import { Row, Col, Container } from "reactstrap";
import PlacesAutocomplete, {
  geocodeByAddress,
  getLatLng,
} from "react-places-autocomplete";
import Variables from "../Globals/variables";
import "./styles.mobile.css";
import ProgressBar from "../Components/ProgressBar";
import "./../Globals/Fonts.css";
import BackIcon from "./../assets/images/back.png";
import Card from 'react-bootstrap/Card';
import { Link } from "react-router-dom";


const BaseUrl = Variables.baseUrl;

const Mobile_Estimate = () => {
  var [state, setState] = useState({
    senderAdd: "",
    orgLat: null,
    orgLng: null,
    receiverAdd: "",
    destLat: null,
    destLng: null,
    amount: null,
    distance: null,
    deliveryMethod: 'secure',
    btnValue: "Get Estimate"
  });

  async function getestimate() {
    const role = localStorage.getItem("role");
    setState({
      ...state,
      btnValue: "Fetching Details..."
    })
    let results = await fetch(`${BaseUrl}/orders/estimate?origins=${state.orgLat}&origins=${state.orgLng}&destinations=${state.destLat}&destinations=${state.destLng}&deliveryMethod=${state.deliveryMethod}&role=${role}`, {
      method: 'GET',
    })
    results = await results;
    if (results.status === 200 || results.status === 201) {
      results = await results.json();
      setState({
        ...state,
        amount: results.data.amount,
        distance: results.data.distance,
        btnValue: "Get Estimate"
      });
      return results;

    } else if (results.status === 400 || results.status === 404) {
      results = await results.json();
      alert(results.message)
      setState({
        ...state,
        btnValue: "Get Estimate"
      })
      return results;

    }
  }

  // function SecureMethod() {
  //   setState(() => ({
  //     ...state,
  //     deliveryMethod: "secure"
  //   }))
  // }

  // function RegularMethod() {
  //   setState(() => ({
  //     ...state,
  //     deliveryMethod: "regular",
  //   }))
  // }

  const handleSenderChange = (senderAdd) => {
    setState({
      ...state,
      senderAdd,
    });
  };

  const handleSenderSelect = (address) => {
    geocodeByAddress(address)
      .then((results) => getLatLng(results[0]))
      .then((latLng) => {
        setState({
          ...state,
          senderAdd: address,
          orgLat: latLng.lat,
          orgLng: latLng.lng,
        });
      })
      .catch((error) => {
        alert("Something Wrong. Please try again later.");
      });
  }

  const handleReceiverChange = (receiverAdd) => {
    setState({
      ...state,
      receiverAdd,
    });
  };

  const handleReceiverSelect = (address) => {
    geocodeByAddress(address)
      .then((results) => getLatLng(results[0]))
      .then((latLng) => {
        setState({
          ...state,
          receiverAdd: address,
          destLat: latLng.lat,
          destLng: latLng.lng,
        });
      })
      .catch((error) => {
        alert("Something went wrong. Please try again later.");
      });
  }

  const ValidSubmission = () => {
    if (state.senderAdd && state.receiverAdd) {
      return (
        <button
          onClick={getestimate}
          type="button"
          className="btn btn-dark rounded-pill button1 mb-3"
        >
          {state.btnValue}
        </button>
      );
    } else {
      return (
        <button
          disabled
          type="button"
          className="btn btn-dark rounded-pill button1 mb-3"
        >
          {state.btnValue}
        </button>
      );
    }
  };

  // css for the cards is present in estimate.css in another folder. 
  const calc = () => {
    if (state.amount != null && state.distance != null) {
      return (
        <Row style={{ justifyContent: "center", alignItem: "center" }}>
          <button className="estimatebutoon1">{state.distance}km</button>
          <button className="estimatebutoon1">Rs. {state.amount}</button>
        </Row>
      );
    } else {
      return null;
    }
  }

  return (
    <Container>
      <Row style={{ marginTop: "15px", marginLeft: "5px", justifyContent: "space-between", alignItem: "space-between", marginRight: "5px" }}>
        <Link to="/placeOrder" style={{ textDecoration: 'none' }} >
          <Card style={{ width: '50px', height: "50px", borderRadius: "30px", backgroundColor: "#F5F6FA", border: "none", boxShadow: "5px 5px 11px 5px #0000000D" }}>
            <Card.Body>
              <img style={{ height: "30px", width: "27px", marginTop: "-15px", marginLeft: "-11px" }} src={BackIcon} alt="backicon" />
            </Card.Body>
          </Card>
        </Link>
        <div style={{ marginTop: "12px" }}>
          <ProgressBar width="100%" steps="Step 1/1" />
        </div>
      </Row>
      <div className="NewOrder">Get Estimate</div>
      <form onSubmit={getestimate} className="main">
        <Row className="row">
          <Col lg="12">
            <h1 style={{ fontSize: "20px", fontWeight: "600", color: "#000000", fontFamily: "Manrope-Bold", marginLeft: "15px" }}>Pickup Details</h1>
            <PlacesAutocomplete
              value={state.senderAdd}
              onChange={handleSenderChange}
              onSelect={handleSenderSelect}
            >
              {({
                getInputProps,
                suggestions,
                getSuggestionItemProps,
                loading,
              }) => (
                <div className="placesDiv">
                  <input
                    {...getInputProps({
                      className: "location-search-input field",
                    })}
                  />
                  <div className="autocomplete-dropdown-container">
                    {loading && <div>Loading . . . .</div>}
                    {suggestions.map((suggestion) => {
                      const className = suggestion.active
                        ? "suggestion-item--active"
                        : "suggestion-item";
                      const style = suggestion.active
                        ? {
                          backgroundColor: "#fafafa",
                          cursor: "pointer",
                        }
                        : {
                          backgroundColor: "#ffffff",
                          cursor: "pointer",
                        };
                      return (
                        <div
                          {...getSuggestionItemProps(suggestion, {
                            className,
                            style,
                          })}
                        >
                          <hr />
                          <span>{suggestion.description}</span>
                        </div>
                      );
                    })}
                  </div>
                </div>
              )}
            </PlacesAutocomplete>
          </Col>
        </Row>
        <Row className="row mb-5">
          <Col lg="12">
            <h1 style={{ fontSize: "20px", fontWeight: "600", color: "#000000", fontFamily: "Manrope-Bold", marginLeft: "15px" }}>Drop Details</h1>
            <PlacesAutocomplete
              value={state.receiverAdd}
              onChange={handleReceiverChange}
              onSelect={handleReceiverSelect}
            >
              {({
                getInputProps,
                suggestions,
                getSuggestionItemProps,
                loading,
              }) => (
                <div className="placesDiv">
                  <input
                    {...getInputProps({
                      className: "location-search-input field",
                    })}
                  />
                  <div className="autocomplete-dropdown-container">
                    {loading && <div>Loading...</div>}
                    {suggestions.map((suggestion) => {
                      const className = suggestion.active
                        ? "suggestion-item--active"
                        : "suggestion-item";

                      const style = suggestion.active
                        ? {
                          backgroundColor: "#fafafa",
                          cursor: "pointer",
                        }
                        : {
                          backgroundColor: "#ffffff",
                          cursor: "pointer",
                        };
                      return (
                        <div
                          {...getSuggestionItemProps(suggestion, {
                            className,
                            style,
                          })}
                        >
                          <hr />
                          <span>{suggestion.description}</span>
                        </div>
                      );
                    })}
                  </div>
                </div>
              )}
            </PlacesAutocomplete>
          </Col>
        </Row>
      </form>
      {/* <Row style={{ justifyContent: "center", alignItem: "center" }}>
        <button className="Mobile-secure-button" onClick={SecureMethod} style={{ backgroundColor: state.deliveryMethod === "secure" ? "#000000" : "#ffffff", zIndex: state.deliveryMethod === "secure" ? "1" : "0" }}>Secure</button>
        <button className="Mobile-regular-button" onClick={RegularMethod} style={{ backgroundColor: state.deliveryMethod === "regular" ? "#000000" : "#ffffff", zIndex: state.deliveryMethod === "regular" ? "1" : "0" }}>Regular</button>
      </Row> */}
      {calc()}
      <Row style={{ justifyContent: "center", alignItem: "center" }}>{ValidSubmission()}</Row>
      <div >
        <h1 className="goto-text" style={{ color: "#92979D", fontSize: "12px", fontFamily: "Manrope-SemiBold", textAlign: "center" }}>For any difficulties</h1>
        <Link to="ContactUs1" style={{ textDecoration: "none" }}>
          <h1 style={{ color: "#000000", fontSize: "14px", fontFamily: "Manrope-Bold", textAlign: "center", marginTop: "-25px" }}>Contact Us</h1>
        </Link>
      </div>
    </Container>
  );
};

export default Mobile_Estimate;
