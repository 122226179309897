import React, { Fragment, useState } from "react";
import { Row, Col, Container } from "reactstrap";
import { useHistory } from "react-router-dom";
import "./../Signup&login-Mobile/styles.signuplogin.css";
import Variables from "../Globals/variables";
import BackIcon from "./../assets/images/back.png";
import Card from 'react-bootstrap/Card'
import "./../Globals/Fonts.css"

const BaseURL = Variables.baseUrl;

function MobilePromoCode(props) {

    const totalamount = localStorage.getItem("amount")

    const initError = {
        promocodeError: "",
    };

    const [state, setState] = useState({
        isInsured: props.location.state.isInsured,
        files: props.location.state.files,
        insuranceAmount: props.location.state.insuranceAmount,
        insuranceEstimate: props.location.state.insuranceEstimate,
        senderAdd: props.location.state.senderAdd,
        senderAdd1: props.location.state.senderAdd1,
        senderBuilding: props.location.state.senderBuilding,
        senderContact: props.location.state.senderContact,
        receiverAdd: props.location.state.receiverAdd,
        receiverAdd1: props.location.state.receiverAdd1,
        receiverBuilding: props.location.state.receiverBuilding,
        receiverContact: props.location.state.receiverContact,
        destLat: props.location.state.destLat,
        destLng: props.location.state.destLng,
        orderId: null,
        orgLat: props.location.state.orgLat,
        orgLng: props.location.state.orgLng,
        amount: totalamount,
        promoCode: "",
        totalAmount: "",
        totalDiscount: "",
        deliveryMethod: props.location.state.deliveryMethod,
        btnValue: "Add Promocode",
        alreadyInsured: props.location.state.alreadyInsured

    });
    const history = useHistory();
    console.log("prrpr", state)

    function dropdetails() {
        history.push("/Drop-Details", {
            isInsured: state.isInsured,
            files: state.files,
            insuranceAmount: state.insuranceAmount,
            insuranceEstimate: state.insuranceEstimate,
            senderAdd: state.senderAdd,
            senderAdd1: state.senderAdd1,
            receiverAdd: state.receiverAdd,
            receiverAdd1: state.receiverAdd1,
            senderBuilding: state.senderBuilding,
            receiverBuilding: state.receiverBuilding,
            senderContact: state.senderContact,
            receiverContact: state.receiverContact,
            orgLat: state.orgLat,
            orgLng: state.orgLng,
            destLat: state.destLat,
            destLng: state.destLng,
            amount: state.amount,
            deliveryMethod: state.deliveryMethod,
            alreadyInsured: state.alreadyInsured
        })
    }

    async function promocode() {
        var token = localStorage.getItem('token')
        var new_token = token.replace(/"/g, '')
        const role = localStorage.getItem("role");
        const insuranceestimate = role === "Customer" ? 0 : state.insuranceEstimate
        setState({
            ...state,
            btnValue: "Adding PromoCode..."
        })

        let result = await fetch(
            `${BaseURL}/orders/coupons/validate?coupon=${state.promoCode}&amount=${state.amount}&insuranceEstimate=${insuranceestimate}`,
            {
                method: 'GET',
                headers: {
                    'Content-Type': 'application/json',
                    Accept: 'application/json',
                    Authorization: `bearer ${new_token}`
                }
            }
        )
        result = await result
        if (result.status === 200 || result.status === 201) {
            result = await result.json();
            localStorage.setItem('totalAmount', result.data.totalAmount)
            localStorage.setItem('totalDiscount', result.data.totalDiscount)
            setState({
                ...state,
                totalAmount: result.data.totalAmount,
                totalDiscount: result.data.totalDiscount
            })
            alert(
                // eslint-disable-next-line
                "Promo code successfully applied," + "\n" + "Please proceed to make your payment."
            )
            history.push('/Mobilepromocodepayment', {
                isInsured: state.isInsured,
                senderAdd: state.senderAdd,
                senderAdd1: state.senderAdd1,
                receiverAdd: state.receiverAdd,
                receiverAdd1: state.receiverAdd1,
                senderBuilding: state.senderBuilding,
                receiverBuilding: state.receiverBuilding,
                senderContact: state.senderContact,
                receiverContact: state.receiverContact,
                orgLat: state.orgLat,
                orgLng: state.orgLng,
                destLat: state.destLat,
                destLng: state.destLng,
                totalAmount: state.totalAmount,
                totalDiscount: state.totalDiscount,
                amount: state.amount,
                promoCode: state.promoCode,
                deliveryMethod: state.deliveryMethod,
                files: state.files,
                insuranceAmount: state.insuranceAmount,
                insuranceEstimate: state.insuranceEstimate,
                alreadyInsured: state.alreadyInsured
            })
            setState({
                ...state,
                btnValue: "Add PromoCode"
            })
            return result;
        } else if (result.status === 401) {
            const refreshToken = localStorage.getItem('refreshToken');
            const data = {
                refreshToken
            };

            setState({
                ...state,
                btnValue: "Adding PromoCode..."
            })

            var response = await fetch(`${BaseURL}/users/refresh-token`, {
                method: 'POST',
                body: JSON.stringify(data),
                headers: {
                    "Content-Type": "application/json",
                    // "Accept": 'application/json'
                }
            })
            response = await response;
            if (response.status === 200 || response.status === 201) {
                response = await response.json();
                localStorage.setItem('refreshToken', response.refreshToken)
                localStorage.setItem('token', response.jwtToken)

                result = await fetch(
                    `${BaseURL}/orders/coupons/validate?coupon=${state.promoCode}&amount=${state.amount}&insuranceEstimate=${insuranceestimate}`,
                    {
                        method: 'GET',
                        headers: {
                            'Content-Type': 'application/json',
                            Accept: 'application/json',
                            Authorization: `bearer ${response.jwtToken}`
                        }
                    }
                )
                result = await result
                if (result.status === 200 || result.status === 201) {
                    result = await result.json();
                    localStorage.setItem('totalAmount', result.data.totalAmount)
                    localStorage.setItem('totalDiscount', result.data.totalDiscount)
                    setState({
                        ...state,
                        totalAmount: result.data.totalAmount,
                        totalDiscount: result.data.totalDiscount
                    })
                    alert(
                        // eslint-disable-next-line
                        "Promo code successfully applied," + "\n" + "Please proceed to make your payment."
                    )
                    history.push('/Mobilepromocodepayment', {
                        isInsured: state.isInsured,
                        senderAdd: state.senderAdd,
                        senderAdd1: state.senderAdd1,
                        receiverAdd: state.receiverAdd,
                        receiverAdd1: state.receiverAdd1,
                        senderBuilding: state.senderBuilding,
                        receiverBuilding: state.receiverBuilding,
                        senderContact: state.senderContact,
                        receiverContact: state.receiverContact,
                        orgLat: state.orgLat,
                        orgLng: state.orgLng,
                        destLat: state.destLat,
                        destLng: state.destLng,
                        totalAmount: state.totalAmount,
                        totalDiscount: state.totalDiscount,
                        amount: state.amount,
                        promoCode: state.promoCode,
                        deliveryMethod: state.deliveryMethod,
                        files: state.files,
                        insuranceAmount: state.insuranceAmount,
                        insuranceEstimate: state.insuranceEstimate,
                        alreadyInsured: state.alreadyInsured
                    })
                    setState({
                        ...state,
                        btnValue: "Add PromoCode"
                    })
                    return result;
                } else if (result.status === 400 || result.status === 412 || result.status === 404) {
                    result = await result.json();
                    alert(result.message);
                    setState({
                        ...state,
                        btnValue: "Add PromoCode"
                    })
                    return result;
                }
                return response;
            } else {
                alert("Session expired. Please login to continue.");
                localStorage.clear("All");
                history.push("/Home")
            }

        } else if (result.status === 400 || result.status === 404 || result.status === 412) {
            result = await result.json();
            alert(result.message)
            setState({
                ...state,
                btnValue: "Add PromoCode"
            })
            return result;
        }

    }

    var [error] = useState(initError);

    const isValid = () => {
        error = initError;

        if (state.promoCode.length === 0) {
            error.promocodeError = "Invalid Promo Code";
        }

        if (
            error.promocodeError.length > 0
        ) {
            return false;
        } else {
            return true;
        }
    };

    const ValidSubmission = () => {
        if (isValid()) {
            return (
                <button
                    type="button"
                    onClick={promocode}
                    className="btn btn-dark rounded-pill button3 mb-3"
                >
                    {state.btnValue}
                </button>
            );
        } else {
            return (
                <button
                    disabled
                    type="button"
                    className="btn btn-dark rounded-pill button3 mb-3"
                >
                    {state.btnValue}
                </button>
            );
        }
    };

    return (
        <Fragment>
            <Container>
                <Row style={{ marginTop: "15px", marginLeft: "5px", justifyContent: "space-between", alignItem: "space-between", marginRight: "5px" }}>
                    {/* <Link style={{ textDecoration: 'none' }} > */}
                    <Card style={{ width: '50px', height: "50px", borderRadius: "30px", backgroundColor: "#F5F6FA", border: "none", boxShadow: "5px 5px 11px 5px #0000000D" }} onClick={dropdetails}>
                        <Card.Body>
                            <img style={{ height: "30px", width: "27px", marginTop: "-15px", marginLeft: "-11px" }} src={BackIcon} alt="backicon" />
                        </Card.Body>
                    </Card>
                    {/* </Link> */}
                    {/* <div style={{ marginTop: "12px" }}>
                        <ProgressBar width="33%" steps="Step 1/3" />
                    </div> */}
                </Row>
                <div style={{ marginTop: "30px", marginLeft: "20px" }}>
                    <h1 style={{ fontSize: "32px", fontFamily: "Manrope-ExtraBold", color: "#000000", letterSpacing: "-1px" }} >Add Promo Code</h1>
                </div>
                <Row style={{ justifyContent: "center", alignItem: "center" }}>
                    <button className="price-button">Total Price Rs. {totalamount}</button>
                </Row>

                <Row style={{ marginTop: "1px" }}>
                    <Col>
                        <label style={{ marginTop: "10px", fontSize: "16px", fontFamily: "Manrope-Bold", marginLeft: "15px" }}>Enter Promo Code</label>
                        <input
                            type="text"
                            className="field"
                            value={state.promoCode}
                            onChange={(e) => setState({ ...state, promoCode: e.target.value })}
                            required
                        />
                        <br />
                    </Col>
                </Row>

                <Row style={{ marginLeft: "-100px" }}>{ValidSubmission()}</Row>

            </Container>
        </Fragment>
    )
}

export default MobilePromoCode
