import { geocodeByAddress, getLatLng } from "react-places-autocomplete";
  
export function geoCodeAddress(address) {
    return new Promise((resolve, reject) => {
        geocodeByAddress(address)
        .then((results) => getLatLng(results[0]))
        .then((latLng) => {
            const { lat, lng } = latLng;
            resolve({lat, lng});
        }).catch((err) => {
            console.error("GoogleMapsService::geoCodeAddress::Unhandled error", err);
            reject(err);
        });
    });
};