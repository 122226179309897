import React, { useState } from 'react'
import { Row, Col, Container } from "reactstrap";
import { Link, useHistory } from "react-router-dom"
import Slider from "./Slider";
import BackIcon from "./../assets/images/back.png";
import Card from 'react-bootstrap/Card';
import ProgressBar from "./../Components/ProgressBar";
import Variables from "./../Globals/variables";
import "./../Globals/Fonts.css";

const BaseURL = Variables.baseUrl;

function BusinessSignupMobile2(props) {

    const initError = {
        gstNumberError: "",
        gstCertificateError: ""
    };

    const [state, setState] = useState({
        fullname: props.location.state.fullname,
        email: props.location.state.email,
        otp: props.location.state.otp,
        phonenumber: props.location.state.phonenumber,
        role: props.location.state.role,
        GSTNumber: "",
        files: [],
        checked: false,
        btnValue: "Sign Up",
        senderAdd: "",
        senderAdd1: "",
        senderBuilding: "",
        senderContact: "",
        senderName: "",
        senderFlat: "",
        orgLat: "",
        orgLng: "",
        receiverName: "",
        receiverFlat: "",
        receiverAdd: "",
        receiverAdd1: "",
        receiverBuilding: "",
        receiverContact: "",
        destLat: "",
        destLng: "",
        deliveryMethod: "secure",
        isInsured: false,
        insuranceAmount: "1",
        amount: "",
        saveaspickup: "",
        saveasdrop: "",
        insuranceEstimate: "",
        savedAddresspickup: [],
        savedAddressdrop: [],
        popup1: false,
        popup2: false
    });
    const history = useHistory();


    async function signUp() {
        setState({
            ...state,
            btnValue: "Signing Up..."
        })

        var bodyFormData = new FormData();
        bodyFormData.append('fullname', state.fullname);
        bodyFormData.append('email', state.email);
        bodyFormData.append('phonenumber', state.phonenumber);
        bodyFormData.append('otp', state.otp);
        bodyFormData.append('role', state.role);
        bodyFormData.append('gstNumber', state.GSTNumber);
        bodyFormData.append('files', state.files);
        console.log(state)

        let result = await fetch(`${BaseURL}/users/signup-customer-with-opt`, {
            method: 'POST',
            body: bodyFormData,
        })
        result = await result;
        if (result.status === 200 || result.status === 201) {
            result = await result.json();
            localStorage.setItem('refreshToken', result.refreshToken)
            localStorage.setItem("token", JSON.stringify(result.jwtToken));
            localStorage.setItem("name", JSON.stringify(result.fullName));
            localStorage.setItem("email", result.email);
            history.push("/placeOrder", {
                senderName: state.senderName,
                senderAdd: state.senderAdd,
                senderAdd1: state.senderAdd1,
                senderBuilding: state.senderBuilding,
                senderContact: state.senderContact,
                senderFlat: state.senderFlat,
                orgLat: state.orgLat,
                orgLng: state.orgLng,
                receiverName: state.receiverName,
                receiverAdd: state.receiverAdd,
                receiverAdd1: state.receiverAdd1,
                receiverBuilding: state.receiverBuilding,
                receiverContact: state.receiverContact,
                receiverFlat: state.receiverFlat,
                destLat: state.destLat,
                destLng: state.destLng,
                deliveryMethod: state.deliveryMethod,
                isInsured: state.isInsured,
                files: state.files,
                insuranceAmount: state.insuranceAmount,
                amount: "",
                insuranceEstimate: state.insuranceEstimate,
                saveaspickup: state.saveaspickup,
                saveasdrop: state.saveasdrop,
                savedAddresspickup: state.savedAddresspickup,
                savedAddressdrop: state.savedAddressdrop,
                popup1: state.popup1,
                popup2: state.popup2
            })
            return result;
        } else if (result.status === 401 || result.status === 409 || result.status === 400) {
            result = await result.json();
            alert(result.message)
            setState({
                ...state,
                btnValue: "Sign Up"
            })
            return result;
        } else if (result.status === 412) {
            result = await result.json();
            alert(result.message);
            setState({
                ...state,
                btnValue: "Sign Up"
            })
            history.push("/Signinmobile")
            return result;
        }
    }

    var [error] = useState(initError);

    const isValid = () => {
        error = initError;

        if (state.GSTNumber.length !== 15) {
            error.gstNumberError = "Invalid GST Number address";
            return false;
        }
        if (state.files.length === 0) {
            error.gstCertificateError = "Upload GST Certificate";
            return false
        } else {
            return true;
        }
    };

    const ValidSubmission = () => {
        if (isValid()) {
            return (
                <button onClick={signUp}
                    type="button"
                    className="btn btn-dark rounded-pill button mb-3"
                >
                    {state.btnValue}
                </button>
            );
        } else {
            return (
                <button
                    disabled
                    type="button"
                    className="btn btn-dark rounded-pill button mb-3"
                >
                    {state.btnValue}
                </button>
            );
        }
    };

    return (
        <Row style={{ marginBottom: 0, marginTop: 0, backgroundColor: '#F5F6FA' }}>
            <Col lg={7} md={7} sm={7} >
                <Slider />
            </Col>

            <Col lg={5} md={5} sm={5} >
                <Row style={{ marginTop: "15px", marginLeft: "5px", marginRight: "30px", justifyContent: "space-between", alignItem: "space-between" }}>
                    <Link to="/BusinessSignupotp" style={{ textDecoration: 'none' }} >
                        <Card style={{ width: '50px', height: "50px", borderRadius: "30px", backgroundColor: "#F5F6FA", border: "none", boxShadow: "5px 5px 11px 5px #0000000D" }}>
                            <Card.Body>
                                <img style={{ height: "30px", width: "27px", marginTop: "-15px", marginLeft: "-11px" }} src={BackIcon} alt="backicon" />
                            </Card.Body>
                        </Card>
                    </Link>
                    <div style={{ marginTop: "10px" }}>
                        <ProgressBar width="100%" steps="Step 2/2" />
                    </div>
                </Row>
                <Container style={{ marginTop: "45px" }}>
                    <h1 className="enter-details" style={{ fontSize: "25px", fontFamily: "Manrope-Bold", marginLeft: "46px", marginBottom: "20px" }}>Enter Details</h1>
                    <Row style={{ justifyContent: "center", alignItem: "space-between" }}>
                        <Col lg={9} >
                            <label style={{ fontSize: "16px", fontFamily: "Manrope-Bold", marginLeft: "15px" }}>GST Number</label>
                            <input
                                type="text"
                                className="field"
                                value={state.GSTNumber}
                                onChange={(e) => setState({ ...state, GSTNumber: e.target.value })}
                                required
                            />
                        </Col>

                        <Col lg={9} >
                            <label style={{ marginTop: "20px", fontSize: "16px", fontFamily: "Manrope-Bold", marginLeft: "15px" }}>Upload GST Certificate</label>
                            <input
                                type="file"
                                className="field"
                                value={state.file}
                                onChange={(e) => setState({ ...state, files: e.target.files[0] })}
                                required
                            />
                        </Col>
                    </Row>


                    {/* <Row style={{ justifyContent: "center", alignItem: "center" }}>

                        <input
                            className="details-checkbox"
                            style={{ marginTop: "5px" }}
                            type="checkbox"
                            name="remember me"
                            value={state.checked}
                            onChange={(e) => Termschange(e)}
                        />
                &ensp;
                <label className="details-label" style={{ fontSize: "14px", fontFamily: "Manrope-Regular" }}>I have read and accepted the <Link to="/terms" target="_blank" style={{ color: "#000000", textDecoration: "none" }}><b style={{ fontFamily: "Manrope-Bold" }}>Terms and Conditions</b></Link></label>
                    </Row> */}
                    <Row style={{ justifyContent: "center", alignItem: "center" }} className="buttons">
                        <Col lg={9} md={12} sm={12} >
                            {ValidSubmission()}
                        </Col>
                    </Row>

                    <Row style={{ alignItem: "center", justifyContent: "center" }}>
                        <h1 className="goto-text" style={{ color: "#92979D", fontSize: "14px", fontFamily: "Manrope-Bold", position: "fixed", bottom: "40px", }}>Go to www.Gordian.in</h1>
                        <Link to="/ContactUs" style={{ textDecoration: "none", marginLeft: "-65px" }}>
                            <h1 className="contact-text" style={{ color: "#000000", fontSize: "14px", fontFamily: "Manrope-Bold", position: "fixed", bottom: "15px", }}>Contact Us</h1>
                        </Link>
                    </Row>
                </Container>
            </Col>
        </Row>
    )
}

export default BusinessSignupMobile2;