import React, { useState } from "react";
import { Row, Col, Container } from "reactstrap";
import TrackSidebar from "./../Tracking/TrackSiderbar";

import Variables from "./../Globals/variables";
import Logo from "./../assets/images/Logo1.png";
import { Link, useHistory } from "react-router-dom";
import Sidebar from "./../Components/Sidebar/sidebar";
import "./../Globals/Fonts.css";
import 'react-confirm-alert/src/react-confirm-alert.css';
import Card from 'react-bootstrap/Card';
import Button from 'react-bootstrap/Button';
import BackIcon from "./../assets/images/back.png";

const BaseURL = Variables.baseUrl

function CancelOrder(props) {

    const name = localStorage.getItem("name");
    const new_name = name.replace(/"/g, "");

    const initState = {
        senderAdd: props.location.state.senderAdd,
        receiverAdd: props.location.state.receiverAdd,
        btnValue: 'Yes, Cancel the order',

    }

    const [state, setState] = useState(initState);
    const history = useHistory();

    async function cancelOrder() {
        const token = localStorage.getItem("token");
        const new_token = token.replace(/"/g, "");
        setState({
            ...state,
            btnValue: "Cancelling Order...",
        });
        let results = await fetch(`${BaseURL}/orders/${props.location.state.orderId}/cancel`, {
            method: "POST",
            headers: {
                "Content-Type": "application/json",
                Authorization: `bearer ${new_token}`,
            },
        });
        results = await results;
        if (results.status === 200 || results.status === 201) {
            results = await results.json();
            alert("Your order has been successfully cancelled");
            setState({
                ...state,
                btnValue: "Yes, Cancel the order",
            });
            history.push("/placeOrder");
            return results;

        } else if (results.status === 401) {
            const refreshToken = localStorage.getItem("refreshToken");
            const data = {
                refreshToken,
            };

            var response = await fetch(`${BaseURL}/users/refresh-token`, {
                method: "POST",
                body: JSON.stringify(data),
                headers: {
                    "Content-Type": "application/json"
                },
            });
            response = await response;
            if (response.status === 200 || response.status === 201) {
                response = await response.json();
                localStorage.setItem("refreshToken", response.refreshToken);
                localStorage.setItem("token", response.jwtToken);
                results = await fetch(`${BaseURL}/orders/${props.location.state.orderId}/cancel`, {
                    method: "POST",
                    headers: {
                        "Content-Type": "application/json",
                        Authorization: `bearer ${response.jwtToken}`,
                    },
                });
                results = await results;
                if (results.status === 200 || results.status === 201) {
                    results = await results.json();
                    alert("Your order has been successfully cancelled");
                    setState({
                        ...state,
                        btnValue: "Yes, Cancel the order",
                    });
                    history.push("/placeOrder");
                    return results;
                } else if (results.status === 412 || results.status === 400 || results.status === 401 || results.status === 404 || results.status === 500) {
                    results = await results.json();
                    alert(results.message);
                    setState({
                        ...state,
                        btnValue: "Yes, Cancel the order",
                    });
                    return results;
                }
                return response;
            }
        } else if (results.status === 401 || results.status === 412 || results.status === 400 || results.status === 404 || results.status === 500) {
            results = await results.json();
            alert(results.message);
            setState({
                ...state,
                btnValue: "Yes, Cancel the order",
            });
            return results;
        }
    }

    return (
        <Row className="m-0">
            <Col
                lg={2} md={2} sm={2}
                className="text-center pt-5"
                style={{ backgroundColor: "white", height: "100vh" }}
            >
                <Row className="gordian-logo" style={{ justifyContent: "center", alignItem: "center" }}>
                    <img src={Logo} alt="Logo" className="logo" />
                </Row>
                <h1 style={{ fontSize: "1.56rem", textAlign: "center", fontFamily: "Manrope-Bold" }}>Gordian</h1>
                <Sidebar />
            </Col>
            <Col lg={7} md={7} sm={7} >
                <h1 style={{ fontSize: "2rem", fontFamily: "Manrope-Bold", marginTop: "20px", color: "#000000" }}>Cancel Order</h1>
                <TrackSidebar />

            </Col>
            <Col lg={3} md={3} sm={3} style={{ backgroundColor: "white" }}>
                <Container>
                    <h1 className="name" style={{ fontSize: "20px", fontWeight: "600", marginTop: "1.25rem", fontFamily: "Manrope-SemiBold" }}>Welcome, {new_name} </h1>
                    <hr />
                    <Row style={{ marginTop: "15px", justifyContent: "space-between", alignItem: "space-between", marginRight: "5px" }} onClick={() => props.history.goBack()}>
                        <Card style={{ width: '50px', height: "50px", borderRadius: "30px", backgroundColor: "#F5F6FA", border: "none", boxShadow: "5px 5px 11px 5px #0000000D" }}>
                            <Card.Body>
                                <img style={{ height: "30px", width: "27px", marginTop: "-15px", marginLeft: "-11px" }} src={BackIcon} alt="backicon" />
                            </Card.Body>
                        </Card>
                    </Row>
                    <h1 className="cancel-order-text" style={{ fontSize: "1.4rem", fontFamily: "Manrope-Bold", marginTop: "20px", color: "#000000", marginLeft: "5px" }}>Are you sure you would like to cancel your Order?</h1>

                    <Card className="cancel-order-card" style={{ width: '300px', height: "240px", borderRadius: "15px", backgroundColor: "#ffffff", border: "none", boxShadow: "5px 5px 11px 5px #0000000D", marginTop: "20px", marginBottom: "10px" }}>
                        <Card.Body>
                            <Row style={{ justifyContent: "space-between", alignItem: "space-between", marginTop: "10px" }}>
                                <h3 style={{ fontFamily: "Manrope-SemiBold", fontSize: "0.9rem", color: "grey", marginLeft: "10px" }}>Pickup</h3>
                                <h3 style={{ fontFamily: "Manrope-Regular", fontSize: "13px", color: "#000000", width: "220px" }}>{state.senderAdd}</h3>
                            </Row>
                            <Row style={{ justifyContent: "space-between", alignItem: "space-between", marginTop: "-10px", }}>
                                <h3 style={{ fontFamily: "Manrope-SemiBold", fontSize: "0.9rem", color: "grey", marginLeft: "10px" }}>Drop</h3>
                                <h3 style={{ fontFamily: "Manrope-Regular", fontSize: "13px", color: "#000000", width: "220px" }}>{state.receiverAdd}</h3>
                            </Row>

                        </Card.Body>
                    </Card>
                    <Row style={{ paddingLeft: "20px" }}>
                        <Button style={{ backgroundColor: "black", borderColor: "black", fontFamily: "Manrope-Bold", fontSize: "1.3rem", width: "100%", borderRadius: "20px" }} onClick={cancelOrder}>{state.btnValue}</Button>{' '}
                    </Row>

                    <Row style={{ justifyContent: "center", alignItem: "center" }}>
                        <h1 style={{ color: "#92979D", fontSize: "0.75rem", fontFamily: "Manrope-SemiBold", position: "fixed", bottom: "40px" }}>For any difficulties</h1>
                        <Link to="/Contactus2">
                            <h1 style={{ fontFamily: "Manrope-Bold", color: "#000000", fontSize: "0.938rem", position: "fixed", bottom: "15px", marginLeft: "-40px" }}>Contact Us</h1>
                        </Link>
                    </Row>

                </Container>
            </Col>
        </Row>
    )
}

export default CancelOrder;
