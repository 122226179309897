import React, { useState } from "react";
import axios from "axios";
import Variables from "../../Globals/variables";
import { Link, useHistory } from "react-router-dom"
import Slider from "./../../Signup&Login/Slider";
import Card from 'react-bootstrap/Card';
import BackIcon from "./../../assets/images/back.png";
import ChatIcon from "./../../assets/images/chat.png";
import { Row, Col, Container } from "reactstrap";
import "./../../Globals/Fonts.css";
import usePasswordToggle from "../usePasswordToggle";
import useConfirmpasswordToggle from "../useConfirmpassToggle";

const BaseUrl = Variables.baseUrl;

const ResetPassword = (props) => {
  console.info("Index::ResetPassword:: Props = ", props, this);
  const [password, setPassword] = useState("");
  const [confirmPass, setConfirmpass] = useState("");
  const history = useHistory();

  const [state, setState] = useState({
    btnValue: "Change Password"
  });

  const [PasswordInputType, ToggleIcon] = usePasswordToggle();
  const [PasswordInputType1, ToggleIcon1] = useConfirmpasswordToggle();

  const submit = (e) => {
    setState({
      ...state,
      btnValue: "Changing Password..."
    })
    e.preventDefault();

    if (password === confirmPass) {
      const payload = {
        token: props.match.params.token,
        newPassword: password,
      };

      axios({
        url: `${BaseUrl}/users/new-password`,
        data: payload,
        method: "POST",
      })
        .then((res) => {
          console.log(res.data.message);
          alert("Password updated successfully. You can login now.");
          setState({
            ...state,
            btnValue: "Change Password"
          })
          history.push("/Signin")
        })
        .catch((error) => {
          alert("Something went wrong. Please try again.");
          console.log("Error, ", error);
          setState({
            ...state,
            btnValue: "Change Password"
          })
        });
    } else {
      alert("Passwords don't match.");
      setState({
        ...state,
        btnValue: "Change Password"
      })
    }
  };

  return (

    <Row style={{ marginBottom: 0, marginTop: 0, backgroundColor: '#F5F6FA' }}>
      <Col lg={7} md={7} >
        <Slider />
      </Col>

      <Col lg={5} md={5} >
        <Row style={{ marginTop: "15px", marginLeft: "5px", marginRight: "20px", justifyContent: "space-between", alignItem: "space-between" }}>
          <Link to="/Home" style={{ textDecoration: 'none' }} >
            <Card style={{ width: '50px', height: "50px", borderRadius: "30px", backgroundColor: "#F5F6FA", marginLeft: "12px", border: "none", boxShadow: "5px 5px 11px 5px #0000000D" }}>
              <Card.Body>
                <img style={{ height: "30px", width: "27px", marginTop: "-15px", marginLeft: "-11px" }} src={BackIcon} alt="backicon" />
              </Card.Body>
            </Card>
          </Link>

          <Card style={{ width: '50px', height: "50px", borderRadius: "30px", backgroundColor: "#F5F6FA", border: "none", boxShadow: "5px 5px 11px 5px #0000000D" }}>
            <Card.Body>
              <img style={{ height: "30px", width: "30px", marginTop: "-18px", marginLeft: "-11px" }} src={ChatIcon} alt="chaticon" />
            </Card.Body>
          </Card>
        </Row>
        <Container style={{ marginTop: "80px" }}>
          <h1 className="resetpassword" style={{ fontSize: "25px", fontFamily: "Manrope-ExtraBold", marginLeft: "56px", marginBottom: "20px" }}>Reset Password</h1>
          <Row style={{ justifyContent: "center", alignItem: "center" }} >
            <Col lg={9}>
              <label style={{ fontSize: "16px", fontFamily: "Manrope-Bold", marginLeft: "15px" }}>Password</label>
              <input
                type={PasswordInputType}
                className="fields"
                name="password"
                value={password}
                onChange={(e) => setPassword(e.target.value)}
                required
              />
              <span style={{ position: "absolute", cursor: "pointer", right: "20px" }}>{ToggleIcon}</span>
            </Col>

            <Col lg={9} >
              <label style={{ fontSize: "16px", fontFamily: "Manrope-Bold", marginLeft: "15px", marginTop: "20px" }}>Confirm Password</label>
              <input
                type={PasswordInputType1}
                className="fields"
                name="confirpass"
                value={confirmPass}
                onChange={(e) => setConfirmpass(e.target.value)}
                required
              />
              <span style={{ position: "absolute", cursor: "pointer", right: "20px" }}>{ToggleIcon1}</span>
            </Col>
          </Row>

          <Link>
            <Col lg={9} style={{ marginTop: "30px" }}>
              {/* {ValidSubmission()} */}
              <button className="button" onClick={submit} >{state.btnValue}</button>
            </Col>
          </Link>
          <Row style={{ alignItem: "center", justifyContent: "center" }}>
            <h1 className="goto-text" style={{ color: "#92979D", fontSize: "14px", fontFamily: "Manrope-Bold", position: "fixed", bottom: "50px", }}>Go to www.Gordian.in</h1>
            <Link to="/ContactUs" style={{ textDecoration: "none", marginLeft: "-65px" }}>
              <h1 className="contact-text" style={{ color: "#000000", fontSize: "14px", fontFamily: "Manrope-Bold", position: "fixed", bottom: "20px", }}>Contact Us</h1>
            </Link>
          </Row>
        </Container>
      </Col>
    </Row>
    // <>
    //   <Row className="m-0">
    //     <Col
    //       lg={2}
    //       className="text-center pt-5"
    //       style={{ backgroundColor: "white", height: "100vh" }}
    //     >
    //       <img src={Logo} alt="Logo" className="logo" />
    //       <Container
    //         style={{ fontSize: "20px" }}
    //         className="mt-5 text-center"
    //         fluid
    //       >
    //         Reset Password
    //       </Container>
    //     </Col>
    //     <Col lg={10} className="Form" style={{ marginTop: '7%', width: '100%' }}>
    //     <h1 className='text-center mb-5'>Reset password</h1>
    //       <form onSubmit={submit}>
    //         <Row className="row text-center">
    //           <Col lg={{ size:6, offset: 3 }}>
    //             <input
    //               className="field"
    //               type="text"
    //               placeholder="Password"
    //               value={password}
    //               onChange={(e) => setPassword(e.target.value)}
    //               required
    //             />
    //           </Col>
    //         </Row>
    //         <Row className="row">
    //           <Col lg={{ size:6, offset: 3 }}>
    //             <input
    //               type="text"
    //               className="field"
    //               placeholder="Confirm password"
    //               value={confirmPass}
    //               onChange={(e) => setConfirmpass(e.target.value)}
    //               required
    //             />
    //           </Col>
    //         </Row>
    //         <br />
    //         <Row className="row">
    //           <Col lg={{ size:6, offset: 4 }}>
    //             <button className="button" type="submit">
    //               Change Password
    //             </button>
    //           </Col>
    //         </Row>
    //       </form>
    //     </Col>
    //   </Row>
    // </>
  );
};

export default ResetPassword;
